import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

// Import Components
import { Card, Empty, Timeline } from 'antd'
// import IssueDetails from './IssueDetails'
import TimelineItem from './TimelineItem'

// Import Actions
// import { getTripTypeStringAndIcon } from '../../../Services/Actions/taskMapActions'
import { formatDateIntoFullDateAndTime } from '../../../Services/Actions/commonActions'

class ApplicationIssues extends React.PureComponent {
    render() {
        const { applicationTrips } = this.props

        // Fint Out Issues
        const allIssues = applicationTrips.map(trip => trip.issues)
        const issues = []
        allIssues.forEach((issue) => issues.push(...issue))

        // Find Out Active Appointments
        const activeAppointments = applicationTrips.map(trip => trip.active_appointment).filter(arr => arr !== null)

        // Combine
        const collection = [...issues, ...activeAppointments]

        return (
            <Card title={'Appointment Timeline'} hoverable style={{ height: '100%' }}>
                {/* {applicationIssues.length === 0 ? <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={'-'} /> :
                    <Timeline mode={'left'}>
                        {
                            applicationTrips.map(trip => {
                                return (
                                    <Timeline.Item
                                        key={trip.id}
                                        label={trip.id + ' - ' + getTripTypeStringAndIcon(trip.tripType).label}
                                        color={(trip?.issues?.length > 0 || trip?.active_appointment) ? 'red' : 'green'}
                                    >
                                        <Space direction={'vertical'}>
                                            {
                                                trip?.issues?.length > 0 &&
                                                trip.issues.map(issue => (
                                                    <Card key={issue.id} hoverable size={'small'}><IssueDetails singleTripDetails={{ ...issue, isActiveAppoinment: false }} /></Card>
                                                ))
                                            }
                                            {
                                                trip?.active_appointment &&
                                                <Card hoverable size={'small'}><IssueDetails singleTripDetails={{ ...trip?.active_appointment, isActiveAppoinment: true }} /></Card>
                                            }

                                        </Space>
                                    </Timeline.Item>
                                )
                            })
                        }
                    </Timeline>
                } */}

                {collection.length === 0 ? <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={'-'} /> :
                    (
                        <Timeline mode='left'>
                            {
                                collection.map(issue => (
                                    <Timeline.Item
                                        key={issue.id}
                                        label={issue.created_at ? formatDateIntoFullDateAndTime(issue.created_at) : formatDateIntoFullDateAndTime(issue.appointment_time)}
                                        // color={(issue?.appointment_time) ? 'green' : 'red'}
                                        color={'red'}
                                    >
                                        <Card hoverable size={'small'}>
                                            <TimelineItem data={issue} trip={applicationTrips?.filter((trip) => trip.id === issue.task_id)} />
                                        </Card>
                                    </Timeline.Item>
                                ))
                            }
                        </Timeline>
                    )}
            </Card>
        )
    }
}

// PropTypes Validation
ApplicationIssues.propTypes = {
    applicationTrips: PropTypes.array,
    applicationIssues: PropTypes.array
}

ApplicationIssues.defaultProps = {
    applicationTrips: [],
    applicationIssues: []
}

const mapStateToProps = (state) => ({
    applicationTrips: state.application.applicationTrips,
    applicationIssues: state.application.applicationIssues
})

export default connect(mapStateToProps, null)(ApplicationIssues)