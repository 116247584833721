import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

// Import Components
import { Form, Select, Radio, Button, Typography, Space, Divider } from 'antd'
import QuestionPreview from './QuestionPreview';

// Import Icons
import { CloseCircleOutlined, PlusOutlined } from '@ant-design/icons';

//Import Actions, and Methods
import { useDispatch, useSelector } from 'react-redux'
import { getTripTypeList } from '../../../Services/Actions/staticOptionAction'
import { assignQuestionToOrganization, fetchOrgOptions } from '../../../Services/Actions/adminPanelActions'

// Import Constants
const { Option } = Select

// form label and field ratio
const formItemLayout = {
    labelAlign: 'left',
    labelCol: {
      xs: {
        span: 24,
      },
    },
    wrapperCol: {
      xs: {
        span: 24,
      },
    },
  };


const AssignQuestion = () => { 
        
    const { orgList } = useSelector(state => state.common)
    const { allQuestionList, subtypeOptions } = useSelector(state => state.adminPanel)

    const [ allTripTypeList, setAllTripTypeList ] = useState();
    const [ previewData, setPreviewData ] = useState({});
    
    const dispatch = useDispatch()
    const [ form ] = Form.useForm();

    // getting and setting all trip list
    useEffect(() => {
        const allTripType = getTripTypeList()
        setAllTripTypeList(allTripType)
    },[])

    // setting up data for dropdown Select component of Question
    const transformedQuestioList = allQuestionList?.questions?.map((item) => {
        return (
            {
                label: item.question,
                type: item.field_type, 
                options: item.field_type === 'MCQ' ? (item?.children.map((option, index) => ' '+ (index+1) + ') ' + option.name)) : 'Input Field',
                value: item?.id,
                question_id: item?.id
            }
        )
    })

    // clearing the field data of the form
    const clearFormField = () => {
        form.resetFields();
    }

    // on form submit, format data and send for api post
    const assignQuestionToBankOnFinish = (values) => {
        const groupData = {
            group_id: values?.organizationID,
            sub_type_id: values?.subtype
        }
        let params = []

        values?.tripWithQuestion.forEach((item) => {
            item?.selectedQuestion?.forEach((questionID) => {
                params.push({
                    'questionID': questionID,
                    'questionTripType': item?.trip,
                    'questionIsRequired': item?.isRequired,
                })
                
            })
        })
        // setting data for preview and submission
        setPreviewData({ questions: params, groupData})
    };

    //submit form  dadta after confirmation
    const submitFormData = () => {
        const params = previewData?.questions
        const groupData = previewData?.groupData
        dispatch(assignQuestionToOrganization( groupData, params, () => clearFormField() ))
        setPreviewData({})
    }

    // cleaning and fetching org options
    const handleSelectOrg = (value) => {        
        // Get Selected Org Options
        dispatch(fetchOrgOptions({ org_id: value }))
    }

    // filtering only valid org
    const withoutAllOrg = orgList?.length > 0 ? orgList.filter(org => org.id !== -1) : []   

    return (
        <div>
            {
                // showoing all input data for questions. if there is a preview data there is a question with Id 
                previewData?.questions?.length > 0 && 
                <QuestionPreview 
                    previewData={previewData} 
                    setPreviewData={ setPreviewData }
                    submitFormData={ ()=> submitFormData() }

                />
            }
            <Form 
                name='assignedProperty' 
                { ...formItemLayout } 
                onFinish={ assignQuestionToBankOnFinish }
                form={ form }
            >
                <Form.Item
                    name='organizationID'
                    label='Organization'
                    rules={[
                    {
                        required: true,
                        message: 'Missing Organization',
                    },
                    ]}
                >
                     <Select
                        style={{ width: '100%' }}
                        placeholder={ 'Select Organization' }
                        optionFilterProp={ 'children' }
                        onChange={ handleSelectOrg }
                        filterOption={ (input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0 }
                    >
                        {
                            withoutAllOrg?.length > 0 && withoutAllOrg.map((item, index) => {
                                return (
                                    <Option key={ index + 1 } value={ item.uuid }>{ item.name }</Option>
                                )
                            })
                        }
                    </Select>
                </Form.Item>

                {/* <Form.Item
                    noStyle
                    shouldUpdate={true}
                >
                {() => ( */}
                    <Form.Item
                    label='Subtype'
                    name={ 'subtype' }
                    rules={[
                        {
                        required: true,
                        message: 'Missing Subtype',
                        },
                    ]}
                    >
                        <Select
                            // disabled={!form.getFieldValue('area')}
                            placeholder='Select Subtype'
                            style={{
                            width: '100%',
                            }}
                        >
                            {
                                subtypeOptions?.length > 0 && subtypeOptions.map((item, index) => {
                                    return (
                                        <Option key={ index + 1 } value={ item.app_subtype_id }>
                                            { item.app_subtype }
                                        </Option>
                                    )
                                })
                            }
                        </Select>
                    </Form.Item>
                {/* )}
                </Form.Item> */}

                <Form.List 
                    name='tripWithQuestion'
                    rules={[
                        {
                            validator: async (_, names) => {
                            if (!names || names.length < 1) {
                                return Promise.reject(new Error('At least 2 Options'));
                            }
                            },
                        },
                    ]}
                >
                    {(fields, { add, remove }) => (
                    <>
                        {fields.map((field) => (
                        <Space key={field.key} direction='vertical' style={{width: '100%'}}>
                            <Form.Item
                                noStyle
                                shouldUpdate={true}
                            >
                            {() => (
                                <Form.Item
                                {...field}
                                label='Trip Type'
                                name={[field.name, 'trip']}
                                rules={[
                                    {
                                    required: true,
                                    message: 'Missing Trip Type',
                                    },
                                ]}
                                >
                                    <Select
                                        // disabled={!form.getFieldValue('area')}
                                        placeholder='Select Trip Type'
                                        style={{
                                        width: '100%',
                                        }}
                                    >
                                        {
                                            allTripTypeList?.length > 0 && allTripTypeList.map((item, index) => {
                                                return (
                                                    <Option key={ index + 1 } value={ item.value }>
                                                        { item.label }
                                                    </Option>
                                                )
                                            })
                                        }
                                    </Select>
                                </Form.Item>
                            )}
                            </Form.Item>

                            <Form.Item
                            {...field}
                            label={ 'Question' }
                            name={[field.name, 'selectedQuestion']}
                            validateTrigger={['onChange', 'onBlur']}
                            rules={[
                                {
                                required: true,
                                message: 'Please select question.',
                                },
                            ]}
                            // noStyle
                            >
                                <Select
                                    mode= {'multiple'}
                                    style={{ width: '100%' }}
                                    placeholder={ 'Select Question' }
                                    showSearch
                                    // filterOption={ (input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0 }
                                    optionFilterProp={ 'children' }
                                >
                                {   
                                    transformedQuestioList?.map(item => (
                                        <Select.Option key={ item.question_id } value={ item.value } style={{borderBottom: '1px solid #e6eef6'}}>
                                            <Typography.Text strong>Question : </Typography.Text> { item.label }
                                            <br />
                                            <Typography.Text strong>Type : </Typography.Text> { item.type }
                                            <br />
                                            <Typography.Text strong>Option : </Typography.Text>{ item.options }
                                        </Select.Option>
                                    )) 
                                }
                                </Select>
                            </Form.Item>
                            <Form.Item
                            {...field}
                            key={'xxx'}
                            label={ 'Are These Questions Mandatory' }
                            name={[field.name, 'isRequired']}
                            validateTrigger={['onChange', 'onBlur']}
                            rules={[
                                {
                                    required: true,
                                    message: 'Please select any option.',
                                },
                            ]}
                            // noStyle
                            >
                                <Radio.Group>
                                    <Radio value={0}> No </Radio>
                                    <Radio value={1}> Yes </Radio>
                                </Radio.Group>
                                    
                            </Form.Item>

                            <Divider orientation={'center'}>
                                <CloseCircleOutlined style={{color:'#f75454', fontSize: '20px'}} onClick={() => remove(field.name)} />
                            </Divider>       
                        </Space>
                        ))}

                        <Form.Item>
                        <Button type='dashed' onClick={() => add()} block icon={<PlusOutlined />}>
                            Assign Question
                        </Button>
                        </Form.Item>
                    </>
                    )}
                </Form.List>
                
                <Form.Item>
                    <Button type='primary' htmlType='submit'>
                        Assign
                    </Button>
                </Form.Item>
            </Form>
        </div>
    )
}

// Props Validation
AssignQuestion.propTypes = {
    orgList: PropTypes.array
}

AssignQuestion.defaultProps = {
    orgList: []
}

export default AssignQuestion