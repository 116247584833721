import { useState } from 'react';
// import { saveAs } from 'file-saver';
import { Button, Image } from 'antd';

const ImageGallery = ({images}) => {
  const [allImages, setAllImages] = useState(images?.allImages||[]);

const downloadImage = (url) => {
  window.open(url, '_blank');
};

//// its prevent download image for cors policy
// const downloadImage = async (url) => {
//   const response = await fetch(url);
//   const blob = await response.blob();
//   const blobUrl = URL.createObjectURL(blob);
//   const a = document.createElement('a');
//   a.href = blobUrl;
//   const baseUrl = url.split('?')[0];
//   a.download = baseUrl.split('/').pop() || 'image.jpg';
//   document.body.appendChild(a);
//   a.click();
//   document.body.removeChild(a);
// };

  return (
    <div style={{ display: "flex", flexWrap: "wrap", gap: "10px", overflow: 'auto', maxHeight: 'calc(100vh - 300px)', justifyContent: "center"}}>
      {allImages.map((image) => (
        <div key={image}  style={{ display: "flex", flexDirection: "column", alignItems: "center", gap: "10px",marginTop: "10px"}}>
          <Image
            src={image}
            width={160}
            height={160}
            className="rounded-lg shadow-md"
          />
          <Button style={{ marginTop: "5px" }} onClick={() => downloadImage(image)}>Download</Button>
        </div>
      ))}
    </div>
  );
};

export default ImageGallery;