import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    isLoading: false,
    appointments: [],
    appointmentsTimeline: [],
}

const appointmentsSlice = createSlice({
    name: 'appointments',
    initialState,
    reducers: {
        setAppointments: (state, action) => {
            state.appointments = action.payload
        },
        setIsLoading: (state, action) => {
            state.isLoading = action.payload
        },
        setAppointmentsTimeline: (state, action) => {
            state.appointmentsTimeline = action.payload
        }
    }
})

export const { setAppointmentsTimeline, setAppointments, setIsLoading } = appointmentsSlice.actions
export default appointmentsSlice.reducer