import React, { useEffect, useState } from 'react';
import moment from 'moment';

// Import Components
import { Button, Card, Space, Table } from 'antd';
import UploadDocumentModal from './UploadDocumentModal';

// Import Actions
import { getDocuments } from '../../../Services/Actions/documentsActions';

const AllDocuments = () => {
  // States
  const [isFileUploadModalOpen, setIsFileUploadModalOpen] = useState(false)
  const [dataSource, setDataSource] = useState([])

  // On Load Get Data
  useEffect(() => {
    getDocuments()
      .then(files => {
        if (files !== undefined) {
          const sortedFiles = files.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
          setDataSource(sortedFiles)
        }
      })
  }, [])

  const columns = [
    {
      key: 'id',
      title: 'ID',
      dataIndex: 'id',
      align: 'center',
    },
    {
      key: 'file_name',
      title: 'File Name',
      dataIndex: 'file_name',
      align: 'center',
    },
    {
      key: 'created_at',
      title: 'Upload Time',
      dataIndex: 'created_at',
      align: 'center',
      render: (value) => {
        return <span>{moment(value).format("DD-MMM-YYYY hh:mm A")}</span>
      }
    },
    {
      key: 'url',
      title: 'Link',
      dataIndex: 'url',
      align: 'center',
      render: (value) => {
        return <a href={value} target='_blank' rel="noreferrer" style={{ color: "#59b994" }}>View</a>
      }
    }
  ]

  return (
    <>
      {/* File Upload Button */}
      <Button
        onClick={() => setIsFileUploadModalOpen(!isFileUploadModalOpen)}
        type='primary'
        style={{ marginLeft: '25px', marginBottom: '20px', backgroundColor: '#007c4a', border: 'none', fontWeight: 'bold' }}
      >
        Upload
      </Button>

      {/* Uploaded File List */}
      <div style={{ width: '100%' }}>
        <Card title={'Table'} hoverable>
          <Space direction={'vertical'} size={'middle'} style={{ width: '100%' }}>
            <Table
              style={{ width: '100%', overflow: 'auto' }}
              columns={columns}
              dataSource={dataSource}
              size='small'
              bordered={true}
              pagination={{
                position: ['bottomCenter'],
                pageSize: 20,
                showSizeChanger: false
              }}
            />
          </Space>
        </Card>
      </div>

      {/* File Upload Modal */}
      {isFileUploadModalOpen && <UploadDocumentModal
        isFileUploadModalOpen={isFileUploadModalOpen}
        setIsFileUploadModalOpen={setIsFileUploadModalOpen}
        setDataSource={setDataSource}
      />}
    </>
  );
};

export default AllDocuments;