import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

// Import Components
import ConcernForm from './ConcernForm'

// Import Actions
import { createConcernsAndTrips } from '../../../Services/Actions/createFileActions'

class ConcernFormWrapper extends React.PureComponent {

    render() {
        const { selectedOption, coApplicantNo, formRef } = this.props
        return (
            <>
                {
                    selectedOption?.id && createConcernsAndTrips(selectedOption?.id, coApplicantNo).map((concern, index, all) => (
                        <div key={index + concern.type} style={{ marginBottom: all.length === index + 1 ? '1rem' : 'none' }}>
                            <ConcernForm
                                title={concern.label + ' ' + concern.title_postfix}
                                concern={concern}
                                trips={concern.trips}
                                formRef={formRef}
                            />
                        </div>
                    ))
                }
            </>
        )
    }
}

// PropTypes
ConcernFormWrapper.propTypes = {
    selectedOption: PropTypes.object,
    formRef: PropTypes.object,
}

ConcernFormWrapper.defaultProps = {
    selectedOption: {},
    formRef: {},
}

// Map State To Props
const mapStateToProps = state => ({
    selectedOption: state.createFile.selectedOption,
    coApplicantNo: state.createFile.coApplicantNo,
})

// Map Dispatch To Props
const mapDispatchToProps = dispatch => ({ dispatch })

export default connect(mapStateToProps, mapDispatchToProps)(ConcernFormWrapper)