import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    allBill: {
        isLoading: false,
        bill: [],
    },
    isDownloading: false
}

const billSlice = createSlice({
    name: 'bill-calculation',
    initialState,
    reducers: {
        setAllBill: (state, action) => {
            state.allBill = action.payload
        },
        setIsDownloading: (state, action) => {
            state.isDownloading = action.payload
        }
    }
})

export const { setAllBill, setIsDownloading } = billSlice.actions
export default billSlice.reducer