import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

// Import Components
import { Form, Input, Select, Button } from 'antd'

// Import Actions
import { addNewConcern } from '../../../Services/Actions/applicationActions'

class AddNewConcern extends React.PureComponent {
    onFinish = values => {
        const { selectedConcern, dispatch } = this.props
        
        const formData = new FormData()

        if (values && values.name) {
            formData.append('application_id', selectedConcern.application_id)
            formData.append('name', values.name)
            formData.append('type', values.type)
            formData.append('image', values.image ? values.image : '')
            formData.append('phone', values.phone)
            formData.append('spouse', '')
            formData.append('designation', values.designation ? values.designation : '')
        }

        dispatch(addNewConcern(selectedConcern, formData))

    }

    normFile = (e) => {
        if (Array.isArray(e)) {
            return e;
        }
        return e && e.target.files[0] ;
    };
    render() {
        return (
            <Form
                name="basic"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
                onFinish={this.onFinish}
                layout='vertical'
            >

                <Form.Item
                    label='Concern Type'
                    name='type'
                    rules={[
                        {
                            required: true,
                            message: 'Please Select concern type!',
                        },
                    ]}
                >
                    <Select placeholder='Select Concern Type'>
                        <Select.Option value='APPLICANT'>Applicant</Select.Option>
                        <Select.Option value='CO_APPLICANT'>Co-Applicant</Select.Option>
                        <Select.Option value='GUARANTOR'>Guarantor</Select.Option>
                    </Select>
                </Form.Item>
                <Form.Item
                    label='Name'
                    name='name'
                    rules={[
                        {
                            required: true,
                            message: 'Please concern name!',
                        },
                    ]}
                >
                    <Input />
                </Form.Item>

                <Form.Item
                    label='Designation'
                    name='designation'
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label='Phone'
                    name='phone'
                >
                    <Input />
                </Form.Item>

                <Form.Item
                    name='image'
                    label='Concern Photo'
                    valuePropName="filelist"
                    getValueFromEvent={this.normFile}
                >
                    <input
                        accept={ 'image/*' }
                        id={ 'second-guarantor-image' }
                        name={ 'image' }
                        type={ 'file' }
                    />
                </Form.Item>


                <Form.Item >
                    <Button 
                        type={ 'primary' }
                        htmlType={ 'submit' }
                    >
                        { 'Submit' }
                    </Button>
                </Form.Item>
            </Form>
        )
    }
}

// Props Validation
AddNewConcern.propTypes = {
    selectedConcern: PropTypes.object,
    dispatch: PropTypes.func,
}

AddNewConcern.defaultProps = {
    selectedConcern: {},
    dispatch: () => null,
}

// Map State To Props
const mapStateToProps = (state) => ({
    selectedConcern: state.application.selectedConcern,
})

// Map Dispatch To Props
const mapDispatchToProps = (dispatch) => ({ dispatch })

export default connect(mapStateToProps, mapDispatchToProps)(AddNewConcern)