import { Card, Col, Result, Row } from "antd";
import React from "react";
import FileUpload from "./FileUpload";
import { isAllowedToAccess } from "../../../Services/Actions/permissionActions";
import { useSelector } from "react-redux";

const BulkUpload = () => {
  return (
    <Row gutter={[12, 8]} style={{ height: "100%" }}>
      {
          <Col
            span={24}
            xs={24}
            style={{ background: "transparent", width: "100%" }}
          >
            <Card title={"Bulk Upload"}>
              <FileUpload />
            </Card>
          </Col>
      }
    </Row>
  );
};

export default BulkUpload;
