import React from 'react';
import { Button, Result } from 'antd';
import { useSelector } from 'react-redux';
const Unauthorized = () => {
  const allowedLink = useSelector((state)=> state.common?.navMenuItems) ?? []
  console.log(allowedLink);
  return(
    <Result
    status="403"
    title="403"
    subTitle="Sorry, you are not authorized to access this page."
    extra={<Button onClick={() => window.location.href = allowedLink?.length > 0 ? allowedLink[0]?.link : "/"} type="primary">Back</Button>}
  />
  )
};
export default Unauthorized;