import axios from 'axios'

// Import Actions
import { setIsLoading, setSummary } from '../Reducers/dashboardReducer'
import { showAlert } from '../Actions/commonActions'

// Import Types
import { DASHBOARD } from '../../Config/config'

// Get Summary Data by Date Range
const getSummary = params => {    
    return async (dispatch) => {
        dispatch(setIsLoading(true))
        try {
            const response = await axios.get(DASHBOARD.GET_SUMMARY, { params })
            dispatch(setSummary( response?.data?.data ? [ { ...response?.data?.data, key: 1 } ] : []))
            dispatch(setIsLoading(false))
        } catch (error) {
            dispatch(setIsLoading(false))

            // Show error alert
            showAlert('error', error.response.data.message ?? error?.message ?? 'Something went wrong')
        }
    }
}

// // Get Easy Access Data
// const getEasyAccess = params => {
//     return async (dispatch) => {
//         dispatch(setIsLoading(true))
//         try {
//             const response = await axios.get(DASHBOARD.GET_EASY_ACCESS, { params })
//             dispatch(setSummary( response?.data?.data ? [ { ...response?.data?.data, key: 2 } ] : []))
//             dispatch(setIsLoading(false))
//         } catch (error) {
//             dispatch(setIsLoading(false))
//         }
//     }
// }

export { getSummary }