import axios from 'axios'
import Pusher from 'pusher-js'

// Import Types
import { TASK_MAP } from '../../Config/config'

// Import Actions
import { setVerifiersGeoLocation, setTripData, setIsDrawerContentLoading, setSelectedTrip, setSelectedTripIndex, setAppointmentData } from '../Reducers/taskMapReducer'
import { setIsDateOrgFilterLoading } from '../Reducers/commonReducer'

// Import Location Markers
import { MARKER_ICONS } from '../../Assets/locationMarkers'

// Import Trip Icons
import { HomeOutlined, BankOutlined, FileTextOutlined, CarOutlined, ShoppingOutlined, ReconciliationOutlined } from '@ant-design/icons'
import { showAlert } from './commonActions'

// Get Initial Verifiers Geo Location
const getInitialVerifiersGeoLocation = () => {
    return async dispatch => {
        try {
            const response = await axios.get(TASK_MAP.GET_VERIFIER_LAST_LOCATION)
            const data = response?.data?.positions
            const transformedData = transformVerifiersLastPositionGeoLocation(data)

            dispatch(setVerifiersGeoLocation(transformedData))

            dispatch(activateSocketConnection())
        } catch (error) {
            // dispatch(setError(error.response.data.message))
        }
    }
}


// Activate Socket Connection
const activateSocketConnection = () => {
    return (dispatch, getState) => {

        // Config Pusher
        window.pusher = new Pusher(
            TASK_MAP.SOCKET.CHANNEL.appKey,     
            TASK_MAP.SOCKET.CONFIG)
        // console.log(window.pusher)

        // Subscribe to channel
        window.pusher.subscribe(TASK_MAP.SOCKET.CHANNEL.channel).bind(TASK_MAP.SOCKET.CHANNEL.event, data => {
            // console.log(data, 'data')

            // Parse Geo Location
            const newLocation = {
                type: 'Feature',
                properties: {
                    id: data?.user_id,
                    name: data?.name,
                    updated_at: data?.updated_at,
                    message: data?.custom_message,
                    state: data?.state
                },
                geometry: {
                    type: 'Point',
                    coordinates: [Number(data?.longitude), Number(data?.latitude)]
                }
            }

            // Get Prev Locations from State
            const prevLocations = getState().taskMap.verifiersGeoLocation

            // Copy Prev Locations features
            const tempFeat = [...prevLocations.features]

            // Check temp features is not null and not undefined
            if (tempFeat !== null && tempFeat !== undefined) {
                // Check if user already exist
                const userExist = tempFeat.find(v => v.properties.id === newLocation.properties.id)
                if (userExist) {
                    // Update user location
                    const index = tempFeat.findIndex(v => v.properties.id === newLocation.properties.id)
                    tempFeat[index] = { ...newLocation }
                } else {
                    // Add new user
                    tempFeat.push(newLocation)
                }
            }

            // Set Verifiers Geo Location
            dispatch(setVerifiersGeoLocation({ type: 'FeatureCollection', features: tempFeat }))

        })
    }
}

// Deactivate Socket
const deactivateSocket = () => {
    return () => {
        if (window.pusher) {
            window.pusher.unsubscribe(TASK_MAP.SOCKET.CHANNEL.channel)
            delete window.pusher
        }
    }
}

// Transform Verifiers Last Position Geo Location
const transformVerifiersLastPositionGeoLocation = (data) => {
    if (!data) {
        return {}
    }
    if (data.length === 0) {
        return {}
    }
    const transformedData = {
        type: 'FeatureCollection',
        features: data.map(d => ({
            type: 'Feature',
            properties: Object.fromEntries(Object.entries(d).filter(v => v[0] !== 'user_last_lon' && v[0] !== 'user_last_lat')),
            geometry: {
                type: 'Point',
                coordinates: [Number(d.user_last_lon), Number(d.user_last_lat)]
            }
        }))
    }

    return transformedData
}

// Get ID from layer properties
const getIdFromLayerProperties = (properties) => {
    if (!properties) {
        return null
    }
    if (properties?.length > 0) {
        const text = properties.map(prop => {
            return prop.id.toString()
        })
        return text.join()
    }
}

// Get Icon base on properties status
const getIconBaseOnStatus = (properties) => {
    if (!properties) {
        return null
    }
    return {
        url: getIconByType(properties),
        width: 100,
        height: 130,
        anchorY: 130,
        mask: false
    }
}

const getIconByType = (properties) => {
    if (!properties) {
        return null
    }
    if (properties?.length > 0) {
        const created = properties.find(prop => prop.status === 1)
        const accepted = properties.find(prop => prop.status === 2)
        const onGoing = properties.find(prop => prop.status === 3)
        const hold = properties.find(prop => prop.status === 4)
        const finished = properties.find(prop => prop.status === 5)
        const canceled = properties.find(prop => prop.status === 6)
        const inactive = properties.find(prop => prop.status === 7)

        if (created) {
            return getIconsByStatusAndType(created.status, created.type)
        }
        if (accepted) {
            return getIconsByStatusAndType(accepted.status, accepted.type)
        }
        if (onGoing) {
            return getIconsByStatusAndType(onGoing.status, onGoing.type)
        }
        if (hold) {
            return getIconsByStatusAndType(hold.status, hold.type)
        }
        if (finished) {
            return getIconsByStatusAndType(finished.status, finished.type)
        }
        if (canceled) {
            return getIconsByStatusAndType(canceled.status, canceled.type)
        }
        if (inactive) {
            return getIconsByStatusAndType(inactive.status, inactive.type)
        }
    }
}

// Get Icons by properties status and type
const getIconsByStatusAndType = (status, type) => {
    switch (status) {
        case 1:
            switch (type) {
                case 'HOUSE':
                case 'GUARANTOR_HOUSE':
                    return MARKER_ICONS.CREATED.CreatedHome
                case 'OFFICE':
                case 'GUARANTOR_OFFICE':
                    return MARKER_ICONS.CREATED.CreatedOffice
                case ('BANK'):
                    return MARKER_ICONS.CREATED.CreatedBank
                case ('DOCUMENT'):
                    return MARKER_ICONS.CREATED.CreatedDocument
                case ('RENTAL'):
                    return MARKER_ICONS.CREATED.CreatedRental
                case ('CAR_QUATATION'):
                    return MARKER_ICONS.CREATED.CreatedCarQuatation

                default:
                    return MARKER_ICONS.CREATED.Created
            }
        case 2:
            switch (type) {
                case 'HOUSE':
                case 'GUARANTOR_HOUSE':
                    return MARKER_ICONS.ALLOCATED.AllocatedHome
                case 'OFFICE':
                case 'GUARANTOR_OFFICE':
                    return MARKER_ICONS.ALLOCATED.AllocatedOffice
                case ('BANK'):
                    return MARKER_ICONS.ALLOCATED.AllocatedBank
                case ('DOCUMENT'):
                    return MARKER_ICONS.ALLOCATED.AllocatedDocument
                case ('RENTAL'):
                    return MARKER_ICONS.ALLOCATED.AllocatedRental
                case ('CAR_QUATATION'):
                    return MARKER_ICONS.ALLOCATED.AllocatedCarQuatation
                default:
                    return MARKER_ICONS.ALLOCATED.Allocated
            }
        case 3:
            switch (type) {
                case 'HOUSE':
                case 'GUARANTOR_HOUSE':
                    return MARKER_ICONS.STARTED.StartedHome
                case 'OFFICE':
                case 'GUARANTOR_OFFICE':
                    return MARKER_ICONS.STARTED.StartedOffice
                case ('BANK'):
                    return MARKER_ICONS.STARTED.StartedBank
                case ('DOCUMENT'):
                    return MARKER_ICONS.STARTED.StartedDocument
                case ('RENTAL'):
                    return MARKER_ICONS.STARTED.StartedRental
                case ('CAR_QUATATION'):
                    return MARKER_ICONS.STARTED.StartedCarQuatation
                default:
                    return MARKER_ICONS.STARTED.Started
            }
        case 4:
            switch (type) {
                case 'HOUSE':
                case 'GUARANTOR_HOUSE':
                    return MARKER_ICONS.HOLD.HoldHome
                case 'OFFICE':
                case 'GUARANTOR_OFFICE':
                    return MARKER_ICONS.HOLD.HoldOffice
                case ('BANK'):
                    return MARKER_ICONS.HOLD.HoldBank
                case ('DOCUMENT'):
                    return MARKER_ICONS.HOLD.HoldDocument
                case ('RENTAL'):
                    return MARKER_ICONS.HOLD.HoldRental
                case ('CAR_QUATATION'):
                    return MARKER_ICONS.HOLD.HoldCarQuatation
                default:
                    return MARKER_ICONS.HOLD.Hold
            }
        case 5:
            switch (type) {
                case 'HOUSE':
                case 'GUARANTOR_HOUSE':
                    return MARKER_ICONS.FINISHED.FinishedHome
                case 'OFFICE':
                case 'GUARANTOR_OFFICE':
                    return MARKER_ICONS.FINISHED.FinishedOffice
                case ('BANK'):
                    return MARKER_ICONS.FINISHED.FinishedBank
                case ('DOCUMENT'):
                    return MARKER_ICONS.FINISHED.FinishedDocument
                case ('RENTAL'):
                    return MARKER_ICONS.FINISHED.FinishedRental
                case ('CAR_QUATATION'):
                    return MARKER_ICONS.FINISHED.FinishedCarQuatation
                default:
                    return MARKER_ICONS.FINISHED.Finished
            }
        case 6:
            switch (type) {
                case 'HOUSE':
                case 'GUARANTOR_HOUSE':
                    return MARKER_ICONS.CANCELED.CanceledHome
                case 'OFFICE':
                case 'GUARANTOR_OFFICE':
                    return MARKER_ICONS.CANCELED.CanceledOffice
                case ('BANK'):
                    return MARKER_ICONS.CANCELED.CanceledBank
                case ('DOCUMENT'):
                    return MARKER_ICONS.CANCELED.CanceledDocument
                case ('RENTAL'):
                    return MARKER_ICONS.CANCELED.CancelRental
                case ('CAR_QUATATION'):
                    return MARKER_ICONS.CANCELED.CancelCarQuatation
                default:
                    return MARKER_ICONS.CANCELED.Canceled
            }
        case 7:
            switch (type) {
                case 'HOUSE':
                case 'GUARANTOR_HOUSE':
                    return MARKER_ICONS.INACTIVE.InactiveHome
                case 'OFFICE':
                case 'GUARANTOR_OFFICE':
                    return MARKER_ICONS.INACTIVE.InactiveOffice
                case ('BANK'):
                    return MARKER_ICONS.INACTIVE.InactiveBank
                case ('DOCUMENT'):
                    return MARKER_ICONS.INACTIVE.InactiveDocument
                case ('RENTAL'):
                    return MARKER_ICONS.INACTIVE.InactiveRental
                case ('CAR_QUATATION'):
                    return MARKER_ICONS.INACTIVE.InactiveCarQuatation
                default:
                    return MARKER_ICONS.INACTIVE.Inactive
            }
        default:
            return null
    }
}

// Get Trip List
const getTripList = params => {
    return async dispatch => {
        // Set loading to true
        dispatch(setIsDateOrgFilterLoading(true))
        try {
            const response = await axios.get(TASK_MAP.GET_TRIP_LIST, { params })
            const data = response?.data?.data?.trips
            const transformedData = transformTripData(data)

            // Set Trip List
            dispatch(setTripData(transformedData))

            // Set loading to false
            dispatch(setIsDateOrgFilterLoading(false))
        } catch (error) {
            console.error(error)
            showAlert('error', error?.message ?? 'Failed to load trip list')
            // Set loading to false
            dispatch(setIsDateOrgFilterLoading(false))
        }
    }
}

// Get Appointment List
const getAppointmentList = params => {
    return async dispatch => {
        // Set loading to true
        dispatch(setIsDateOrgFilterLoading(true))
        try {
            const response = await axios.get(TASK_MAP.GET_APPOINTMENTS, { params })
            const data = response?.data?.data?.appointments
            const transformedData = transformTripData(data)

            // Set Appointment List
            dispatch(setAppointmentData(transformedData))

            // Set loading to false
            dispatch(setIsDateOrgFilterLoading(false))
        } catch (error) {
            console.error(error)
            showAlert('error', error?.message ?? 'Failed to load data')
            // Set loading to false
            dispatch(setIsDateOrgFilterLoading(false))
        }
    }
}

// Transform Trip data
const transformTripData = (data) => {
    if (!data) {
        return null
    }

    // Find multiple trip in same lat and lng    
    let resD = []
    let visited = []
    data.forEach((i) => {
        const filtered = data.filter((j) => i.latitude === j.latitude);
        if (filtered.length > 1) {
            if (!visited.includes(i.latitude)) {
                resD.push(filtered)
            }
            visited.push(i.latitude)
        } else {
            resD.push(filtered)
        }
    })

    // Transform data
    const transformedData = {
        type: 'FeatureCollection',
        features: resD.map(d => ({
            type: 'Feature',
            properties: d,
            geometry: {
                type: 'Point',
                coordinates: [Number(d[0].longitude), Number(d[0].latitude)]
            }
        }))
    }
    return transformedData
}

const getTripTypeStringAndIcon = (tripType) => {
    switch (tripType) {
        case 'HOUSE':
            return {
                label: 'House',
                icon: <HomeOutlined style={{ marginRight: '0.4rem' }} />
            }
        case 'OFFICE':
            return {
                label: 'Office',
                icon: <ShoppingOutlined style={{ marginRight: '0.4rem' }} />
            }
        case 'BANK':
            return {
                label: 'Bank',
                icon: <BankOutlined style={{ marginRight: '0.4rem' }} />
            }
        case 'GUARANTOR_HOUSE':
            return {
                label: 'Guarantor House',
                icon: <HomeOutlined style={{ marginRight: '0.4rem' }} />
            }
        case 'GUARANTOR_OFFICE':
            return {
                label: 'Guarantor Office',
                icon: <ShoppingOutlined style={{ marginRight: '0.4rem' }} />
            }
        case 'DOCUMENT':
            return {
                label: 'Document',
                icon: <FileTextOutlined style={{ marginRight: '0.4rem' }} />
            }
        case 'RENTAL':
            return {
                label: 'Rental',
                icon: <ReconciliationOutlined style={{ marginRight: '0.4rem' }} />
            }
        case 'CAR_QUATATION':
            return {
                label: 'Car Quatation',
                icon: <CarOutlined style={{ marginRight: '0.4rem' }} />
            }
        default:
            return {
                label: '',
                icon: ''
            }
    }
}

// Get Trip Details
const getTripDetails = (tripId, index) => {
    return async dispatch => {

        // Set loading to true
        dispatch(setIsDrawerContentLoading(true))
        dispatch(setSelectedTrip({}))
        try {
            const response = await axios.get(TASK_MAP.GET_TRIP_LIST + tripId)
            const data = response?.data?.data?.trip
            // Set Trip Details
            dispatch(setSelectedTrip(data))

            // Set Selected Index to 0
            dispatch(setSelectedTripIndex(index ?? 0))

            // Set loading to false
            dispatch(setIsDrawerContentLoading(false))
        }
        catch (error) {
            // Set loading to false
            dispatch(setIsDrawerContentLoading(false))

            console.error(error)
            showAlert('error', error?.message ?? 'Failed to load data')
        }
    }
}

// Get Trip Status label
const getTripStatusLabel = (tripStatus) => {
    // 1 => CREATED => rgb(118, 79, 160) => PURPLE
    // 2 => ALLOCATED => rgb(91, 202, 232) => SKY BLUE
    // 3 => STARTED => rgb(65, 84, 164) => BLUE
    // 4 => HOLD => rgb(245, 182, 41) => YELLOW
    // 5 => FINISHED => rgb(53, 170, 128) => GREEN
    // 6 => CANCELLED => rgb(200, 33, 40) => RED
    // 7 => INACTIVE => rgb(168, 170, 172) => GREY
    switch (tripStatus) {
        case 1:
            return 'Created'
        case 2:
            return 'Allocated'
        case 3:
            return 'Started'
        case 4:
            return 'Hold'
        case 5:
            return 'Finished'
        case 6:
            return 'Cancelled'
        case 7:
            return 'Inactive'
        default:
            return 'N/A'
    }
}


export { getInitialVerifiersGeoLocation, deactivateSocket, getIdFromLayerProperties, getIconBaseOnStatus, getTripList, getTripTypeStringAndIcon, getTripDetails, getTripStatusLabel, getAppointmentList }