import axios from "axios";

// Import Actions
import {
  setCreateNewOrgInput,
  setIsCreateNewOrgLoading,
  setIsUpdateOrgLoading,
  setSelectedOrgUpdateOrg,
  setSelectedOrgDeleteOrg,
  setIsDeleteOrgLoading,
  setIsAddUserToOrgLoading,
  setIsDeleteUserFromOrgLoading,
  setIsAddAgentLoading,
  setIsRemoveAgentLoading,
  resetState,
  setAllQuestionList,
  setTripWiseQuestionList,
  setSubtypeOptions,
  setAppTypeOptions,
  setSelectedOrgStations,
} from "../Reducers/adminPanelReducer";
import { showAlert, getOrgList, getVerifiers } from "../Actions/commonActions";

// Import Types
import { ADMIN_PANEL, QUESTIONNAIRE, COMMON } from "../../Config/config";

// Create New Organization
const createNewOrganization = (name) => {
  return async (dispatch) => {
    // Set isCreateNewOrgLoading to true
    dispatch(setIsCreateNewOrgLoading(true));
    try {
      const response = await axios.post(
        ADMIN_PANEL.CREATE_NEW_ORGANIZATION,
        name
      );
      // Check if response is success
      if (response?.data?.status === 200) {

        // Set isCreateNewOrgLoading to false
        dispatch(setIsCreateNewOrgLoading(false));

        // Get Updated Organization List
        dispatch(getOrgList());

        // Return success alert
        showAlert("success", "Organization created successfully");
        return 200;
      } else {
        // Set isCreateNewOrgLoading to false
        dispatch(setIsCreateNewOrgLoading(false));

        // Return error alert
        showAlert("error", response?.data?.message ?? "Something went wrong");
        throw new Error(response?.data?.message ?? "Something went wrong");
      }
    } catch (error) {
      console.error(error);
      // Set isCreateNewOrgLoading to false
      dispatch(setIsCreateNewOrgLoading(false));

      // Return error alert
      showAlert(
        "error",
        error?.response?.data?.message ??
        error?.message ??
        "Something went wrong"
      );
    }
  };
};

// Update Organization
const updateOrganization = (params) => {
  return async (dispatch) => {
    // Set isUpdateOrgLoading to true
    dispatch(setIsUpdateOrgLoading(true));
    try {
      const response = await axios.post(
        ADMIN_PANEL.UPDATE_ORGANIZATION,
        params
      );
      // Check if response is success
      if (response?.data?.status === 200) {

        // Set isUpdateOrgLoading to false
        dispatch(setIsUpdateOrgLoading(false));

        // Get Updated Organization List
        dispatch(getOrgList());

        // Return success alert
        showAlert("success", "Organization updated successfully");
        return;
      } else {
        // Set isUpdateOrgLoading to false
        dispatch(setIsUpdateOrgLoading(false));

        // Return error alert
        showAlert("error", response?.data?.message ?? "Something went wrong");
        throw new Error(response?.data?.message ?? "Something went wrong");
      }
    } catch (error) {
      console.error(error);
      // Set isUpdateOrgLoading to false
      dispatch(setIsUpdateOrgLoading(false));

      // Return error alert
      showAlert(
        "error",
        error?.response?.data?.message ??
        error?.message ??
        "Something went wrong"
      );
    }
  };
};

// Delete Organization
const deleteOrganization = (params) => {
  return async (dispatch) => {
    // Set isDeleteOrgLoading to true
    dispatch(setIsDeleteOrgLoading(true));
    try {
      const response = await axios.delete(ADMIN_PANEL.DELETE_ORGANIZATION, {
        data: params,
      });
      // Check if response is success
      if (response?.data?.status === 200) {
        // Get Updated Organization List
        dispatch(getOrgList());

        // Set Selected Organization to empty Object
        dispatch(setSelectedOrgDeleteOrg({}));

        // Set isDeleteOrgLoading to false
        dispatch(setIsDeleteOrgLoading(false));

        // Return success alert
        showAlert("success", "Organization deleted successfully");
        return;
      } else {
        // Set isDeleteOrgLoading to false
        dispatch(setIsDeleteOrgLoading(false));

        // Return error alert
        showAlert("error", response?.data?.message ?? "Something went wrong");
        throw new Error(response?.data?.message ?? "Something went wrong");
      }
    } catch (error) {
      console.error(error);

      // Set isDeleteOrgLoading to false
      dispatch(setIsDeleteOrgLoading(false));

      // Return error alert
      showAlert(
        "error",
        error?.response?.data?.message ??
        error?.message ??
        "Something went wrong"
      );
    }
  };
};

// Create Organization Station
const createOrganizationStation = (params, clearFormField) => {
  return async (dispatch) => {
    await axios
      .post(
        ADMIN_PANEL.ORGANIZATION_STATION,
        params
      )
      .then((res) => {
        if (res.status === 200) {
          // console.log(res, 'res')
          showAlert("success", "Station created successfully")
          if (clearFormField) {
            clearFormField();
          }
        } else {
          showAlert("error", "Something went wrong")
        }
      })
      .catch((err) => {
        showAlert(
          "error",
          err?.response?.data?.message??
          "Something went wrong",1.0,'error'
        );
      })
  }
}

// Get Organization Station
const getOrganizationStation = (params) => {
  return async (dispatch) => {
    try {
      const res = await axios.get(ADMIN_PANEL.ORGANIZATION_STATION, { params })
      const stations = res?.data?.data?.stations ?? []
      dispatch(setSelectedOrgStations(stations))
      let options = []
      if (stations.length > 0) {
        stations.forEach((option, index) => {
          options.push({
            key: index,
            label: option?.station_type,
            value: option?.station_type
          })
        })
      } else {
        showAlert("warning", "No Station Under This Organization Create One", 3);
      }

      return options
    } catch (error) {
      // Handle error
    }
  }
}

// Add User to Organization
const addUserToOrganization = (params) => {
  return async (dispatch) => {
    // Set isAddUserToOrgLoading to true
    dispatch(setIsAddUserToOrgLoading(true));
    try {
      const response = await axios.post(
        ADMIN_PANEL.ADD_USER_TO_ORGANIZATION,
        params
      );
      // Check if response is success
      if (response?.data?.status === 200) {
        // Get Updated Organization List
        dispatch(getOrgList());

        // Set isAddUserToOrgLoading to false
        dispatch(setIsAddUserToOrgLoading(false));

        // Reset State
        dispatch(resetState());

        // Return success alert
        showAlert("success", "User added to organization successfully");
        return;
      } else {
        // Set isAddUserToOrgLoading to false
        dispatch(setIsAddUserToOrgLoading(false));

        // Return error alert
        showAlert("error", response?.data?.message ?? "Something went wrong");
        throw new Error(response?.data?.message ?? "Something went wrong");
      }
    } catch (error) {
      console.error(error);

      // Set isAddUserToOrgLoading to false
      dispatch(setIsAddUserToOrgLoading(false));

      // Return error alert
      showAlert(
        "error",
        error?.response?.data?.message ??
        error?.message ??
        "Something went wrong"
      );
    }
  };
};

// Remove User from Organization
const deleteUserFromOrganization = (params) => {
  return async (dispatch) => {
    // Set isAddUserToOrgLoading to true
    dispatch(setIsDeleteUserFromOrgLoading(true));
    try {
      const response = await axios.post(
        ADMIN_PANEL.REMOVE_USER_FROM_ORGANIZATION,
        params
      );
      // Check if response is success
      if (response?.data?.status === 200) {
        // Get Updated Organization List
        dispatch(getOrgList());

        // Set isAddUserToOrgLoading to false
        dispatch(setIsDeleteUserFromOrgLoading(false));

        // Reset State
        dispatch(resetState());

        // Return success alert
        showAlert("success", "User removed from organization successfully");
        return;
      } else {
        // Set isAddUserToOrgLoading to false
        dispatch(setIsDeleteUserFromOrgLoading(false));

        // Return error alert
        showAlert("error", response?.data?.message ?? "Something went wrong");
        throw new Error(response?.data?.message ?? "Something went wrong");
      }
    } catch (error) {
      console.error(error);

      // Set isAddUserToOrgLoading to false
      dispatch(setIsDeleteUserFromOrgLoading(false));

      // Return error alert
      showAlert(
        "error",
        error?.response?.data?.message ??
        error?.message ??
        "Something went wrong"
      );
    }
  };
};

// Add Agent
const addAgent = (params) => {
  return async (dispatch) => {
    // Set isAddAgentLoading to true
    dispatch(setIsAddAgentLoading(true));
    try {
      const response = await axios.post(ADMIN_PANEL.ADD_AGENT, params);
      // Check if response is success
      if (response?.data?.status === 200) {
        // Get Updated Verifiers List
        dispatch(getVerifiers());

        // Set isAddAgentLoading to false
        dispatch(setIsAddAgentLoading(false));

        // Reset State
        dispatch(resetState());

        // Return success alert
        showAlert("success", "Agent added successfully");
        return;
      } else {
        // Set isAddAgentLoading to false
        dispatch(setIsAddAgentLoading(false));

        // Return error alert
        showAlert("error", response?.data?.message ?? "Something went wrong");
        throw new Error(response?.data?.message ?? "Something went wrong");
      }
    } catch (error) {
      console.error(error);

      // Set isAddAgentLoading to false
      dispatch(setIsAddAgentLoading(false));

      // Return error alert
      showAlert(
        "error",
        error?.response?.data?.message ??
        error?.message ??
        "Something went wrong"
      );
    }
  };
};

// Remove Agent
const removeAgent = (params) => {
  return async (dispatch) => {
    // Set isRemoveAgentLoading to true
    dispatch(setIsRemoveAgentLoading(true));
    try {
      const response = await axios.post(ADMIN_PANEL.REMOVE_AGENT, params);
      // Check if response is success
      if (response?.data?.status === 200) {
        // Get Updated Verifiers List
        dispatch(getVerifiers());

        // Set isRemoveAgentLoading to false
        dispatch(setIsRemoveAgentLoading(false));

        // Reset State
        dispatch(resetState());

        // Return success alert
        showAlert("success", "Agent removed successfully");
        return;
      } else {
        // Set isRemoveAgentLoading to false
        dispatch(setIsRemoveAgentLoading(false));

        // Return error alert
        showAlert("error", response?.data?.message ?? "Something went wrong");
        throw new Error(response?.data?.message ?? "Something went wrong");
      }
    } catch (error) {
      console.error(error);

      // Set isRemoveAgentLoading to false
      dispatch(setIsRemoveAgentLoading(false));

      // Return error alert
      showAlert(
        "error",
        error?.response?.data?.message ??
        error?.message ??
        "Something went wrong"
      );
    }
  };
};

// get all created questions
const getQuestions = () => {
  return async (dispatch) => {
    try {
      const response = await axios.get(ADMIN_PANEL.GET_ALL_ORG_QUESTION);
      if (!response?.data?.data) {
        dispatch(setAllQuestionList([]));
        return;
      }
      dispatch(setAllQuestionList(response.data.data));
    } catch (err) {
      showAlert("error", err?.message ?? "Question load failed");
      console.error(err);
    }
  };
};

// create question
const createQuestion = async (questionDetails) => {

  try {
    const response = await axios.post(
      ADMIN_PANEL.POST_QUESTION_COPY,
      questionDetails
    );
    if (response?.status !== 200) {
      return [];
    }

    return response.data;
  } catch (err) {
    console.error(err);
    showAlert(
      "error",
      err?.response?.data?.message
        ? err?.response?.data?.message?.message +
        " in " +
        err?.response?.data?.message?.values[0]
        : "Operation Failed"
    );
    return err.response;
  }
};

// assigning questions to organization [Old]
const assignQuestionToOrganization = (
  groupData,
  tripWithQuestionID,
  clearFormField
) => {
  const dataAsFormData = new FormData();
  dataAsFormData.append("group_id", groupData?.group_id);
  dataAsFormData.append("sub_type_id", groupData?.sub_type_id);

  tripWithQuestionID.forEach((item, index) => {
    dataAsFormData.append(`questions[${index}][id]`, item?.questionID);
    dataAsFormData.append(
      `questions[${index}][trip_type]`,
      item?.questionTripType
    );
    dataAsFormData.append(
      `questions[${index}][is_required]`,
      item?.questionIsRequired
    );
  });
  console.table([...dataAsFormData]);
  return async (dispatch) => {
    await axios
      .post(ADMIN_PANEL.ASSIGN_QUESTION, dataAsFormData)
      .then((res) => {
        showAlert("success", "Successfull");
        if (clearFormField) {
          clearFormField();
        }
      })
      .catch((err) => {
        showAlert("error", err?.message ?? "Question Assign Failed");
        console.error(err);
      });
  };
};

// Assign Question To Organization [NEW]
const assignQuesToOrganization = (questions) => {
  return async (dispatch) => {
    await axios
      .post(ADMIN_PANEL.ASSIGN_QUESTION, questions)
      .then((res) => {
        showAlert("success", "Successfull");
      })
      .catch((err) => {
        showAlert("error", err?.message ?? "Question Assign Failed");
        console.error(err);
      });
  };
}

// UnAssign Question From Organization [NEW]
const unAssignQuesFromOrganization = (data) => {
  const fetchParams = {
    group_subtype_id: data.sub_type_id,
    org_id: data.group_id,
    trip_type: data.trip_type
  }

  const unAssignQuesParams = {
    group_id: data.group_id,
    trip_type: data.trip_type,
    sub_type_id: data.sub_type_id,
    questions: data.questions,
  }
  return async (dispatch) => {
    await axios
      .post(ADMIN_PANEL.UNASSIGN_QUESTION, unAssignQuesParams)
      .then((res) => {
        showAlert("success", "Unassign Question Successfully");
        setTimeout(() => {
          dispatch(fetchTripTypeWiseQuestionList(fetchParams))
        }, 1000);
      })
      .catch((err) => {
        showAlert("error", err?.message ?? "Question Un-Assign Failed");
        console.error(err);
      });
  }
}

const addApplicationTypeToOrg = (params, clearFormField) => {
  return async (dispatch) => {
    // await axios.post(`${ADMIN_PANEL.ADD_SUBTYPE_PREFIX}`, params)
    await axios
      .post(
        `${ADMIN_PANEL.ADD_SUBTYPE_PREFIX}?org_id=${params?.org_id}&app_type_id=${params?.app_type_id}&app_subtype=${params?.app_subtype}&app_prefix=${params?.app_prefix}`
      )
      .then((res) => {

        showAlert(
          "success",
          res?.data?.data?.message
            ? res?.data?.data?.message
            : "Assigned Successfully"
        );

        dispatch(fetchOrgOptions({ org_id: params?.org_id }))

        if (clearFormField) {
          clearFormField();
        }
      })
      .catch((err) => {
        showAlert("error", err?.message ?? "Action Failed");
        console.error(err);
      });
  };
};

const transformTripWiseQuestionData = (rawData) => {
  const transformedData = rawData.map((item) => {
    const newItem = {
      id: item?.id,
      field_type: item?.field_type,
      trip_type: item?.trip_type,
      is_required: item?.is_required,
      key: item?.key,
      question: item?.question,
      children: item?.children,
      sort_order: item?.sort_order
    };
    return newItem;
  });
  return transformedData;
};

// fetching question list by subtype
const fetchTripTypeWiseQuestionList = (params) => {
  return async (dispatch) => {
    await axios
      .get(
        `${QUESTIONNAIRE.TRIP_TYPE_WISE_QUESTION}?org_id=${params?.org_id}&group_sub_type_id=${params?.group_subtype_id}&trip_type=${params?.trip_type}`
      )
      .then((res) => {
        if (res?.status !== 200) {
          dispatch(setTripWiseQuestionList([]));
          return;
        }

        // If No Data Found
        if (res?.data?.data?.questions?.length === 0) {
          showAlert('error', 'No Data Found')
        }

        const transformedData = transformTripWiseQuestionData(
          res?.data?.data?.questions
        );

        dispatch(setTripWiseQuestionList(transformedData));
      })
      .catch((err) => {
        console.error(err);
        showAlert(
          "error",
          err?.data?.message ??
          err?.message ??
          "Failed to fetch trip wise question"
        );
      });
  };
};

// Tranform Org Options
const transformedSubtypes = (data) => {
  // Transform data with app_type_id as key and app_type as value and app_subtype_id as key and app_subtype_id as value
  if (data.length === 0) {
    return [];
  }

  // removing duplication of trips
  let extractedTripTypeObject = {};
  let extractedTripType = [];
  data?.forEach((item) => {
    extractedTripTypeObject[item.app_type] = item?.app_type_id;
  });

  // creating array of objects for trip type
  for (const [key, value] of Object.entries(extractedTripTypeObject)) {
    extractedTripType.push({ label: key, app_type_id: value });
  }

  const extractedSubtype = data?.map((item) => {
    return {
      app_type_id: item?.app_type_id,
      app_subtype: item?.app_subtype,
      app_prefix: item?.app_prefix,
      app_subtype_id: item?.app_subtype_id,
    };
  });

  return { extractedSubtype, extractedTripType };
};

// Get Options for Organization
const fetchOrgOptions = (params) => {
  return async (dispatch) => {
    try {
      const response = await axios.get(COMMON.GET_ORG_OPTIONS, { params });
      const options = response?.data?.options ?? [];

      // Transform options
      const subtypes = transformedSubtypes(options).extractedSubtype;
      const tripType = transformedSubtypes(options).extractedTripType;

      dispatch((dispatch) => {
        dispatch(setSubtypeOptions(subtypes));
        dispatch(setAppTypeOptions(tripType));
      });
    } catch (error) {
      console.error(error);

      // Show Alert
      showAlert(
        "error",
        error?.response?.data?.message ??
        error?.message ??
        "Something went wrong"
      );
    }
  };
};

export {
  createNewOrganization,
  updateOrganization,
  deleteOrganization,
  createOrganizationStation,
  getOrganizationStation,
  addUserToOrganization,
  deleteUserFromOrganization,
  addAgent,
  removeAgent,
  getQuestions,
  createQuestion,
  assignQuestionToOrganization,
  addApplicationTypeToOrg,
  unAssignQuesFromOrganization,
  fetchTripTypeWiseQuestionList,
  fetchOrgOptions,
  assignQuesToOrganization
};
