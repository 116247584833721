import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

// Import Components
import { Drawer, Button, Tooltip, Divider, Row, Col, Spin, Tag, Space, Popover, Select } from 'antd'
import DescriptionItem from '../Common/DescriptionItem'
import UpdateTrip from './UpdateTrip'

// Import Icons
import { CloseOutlined, EyeOutlined, LoadingOutlined } from '@ant-design/icons'

// Import Actions
import { setIsTaskDetailsOpen, setSelectedTrips, setSelectedTripIndex } from '../../Services/Reducers/taskMapReducer'
import { getFillColorTripStatus, showAlert, assignVerifier, openTripUpdateModal, getDateTimeDuration } from '../../Services/Actions/commonActions'
import { getTripTypeStringAndIcon, getTripDetails, getTripStatusLabel, getTripList, getAppointmentList } from '../../Services/Actions/taskMapActions'

class DrawerTaskMap extends React.PureComponent {
    state = {
        hoveredIndex: -1,
        selectedVerifier: {}
    }

    componentDidMount = () => {
        const { selectedTrip, verifiers } = this.props

        // Check if selected trip is not null
        if (selectedTrip?.assigned_to) {
            const assigned_to = Number(selectedTrip.assigned_to)
            // Check if verifier is not null and selected verifier is not null
            if (verifiers && verifiers?.length > 0) {
                // Get selected verifier
                const selectedVerifier = verifiers.find(verifier => verifier.id === assigned_to)
                this.setState({ selectedVerifier })
            }
        }
    }

    componentDidUpdate = (prevProps) => {
        const { selectedTrip, verifiers } = this.props
        // Check if previous selected trip and current selected trip are not same
        if (prevProps.selectedTrip?.id !== selectedTrip?.id) {
            this._handleUpdateTrip()
            // Check if selected trip is not null
            if (selectedTrip?.assigned_to) {
                const assigned_to = Number(selectedTrip.assigned_to)
                // Check if verifier is not null and selected verifier is not null
                if (verifiers && verifiers?.length > 0) {
                    // Get selected verifier
                    const selectedVerifier = verifiers.find(verifier => verifier.id === assigned_to)
                    this.setState({ selectedVerifier })
                }
            }
        }
    }

    // Handle on close
    _handleOnClose = () => {
        const { dispatch } = this.props

        // Close Drawer
        dispatch(setIsTaskDetailsOpen(false))

        // Empty Selected Trips
        dispatch(setSelectedTrips([]))

        // Set Selected Trip Index to 0
        dispatch(setSelectedTripIndex(0))
    }

    // Toggle Mouse Hover
    _handleOnMouseHover = index => {
        const { hoveredIndex } = this.state
        this.setState({ hoveredIndex: hoveredIndex === index ? -1 : index })
    }

    // Return Icon by Trip Type
    _getIconByType = (tripType, type) => {
        if (tripType && type === 'icon') {
            const typeInfo = getTripTypeStringAndIcon(tripType)
            return typeInfo.icon
        }
        if (tripType && type === 'text') {
            const typeInfo = getTripTypeStringAndIcon(tripType)
            return typeInfo.label
        }
    }

    // Handle on Click
    _handleOnClick = index => {
        const { selectedTrips, dispatch } = this.props

        // Set Selected Trip Index to 0
        dispatch(setSelectedTripIndex(index))

        // Find Selected Trip from selectedTrips array and Set Selected Trip
        this.setState({ selectedTrip: selectedTrips[index] })

        // Get Trip Details
        dispatch(getTripDetails(selectedTrips[index].id, index))
    }

    // Get Trip Details by Selected Index
    _getTripDetails = () => {
        const { selectedTrips, dispatch, selectedTripIndex } = this.props

        // Find Selected Trip from selectedTrips array and Set Selected Trip
        const selectedTrip = selectedTrips[selectedTripIndex]?.id

        // Get Trip Details by Trip ID
        dispatch(getTripDetails(selectedTrip))
    }

    // View Application Details in a new tab
    _viewApplicationDetails = e => {
        e.preventDefault()

        const { selectedTrip } = this.props

        // Get Trip Details by Selected Trips Applciation ID
        window.open(`/application/${selectedTrip?.application?.id}`, '_blank')
    }

    // Handle Verifier Change
    _handleVerifierChange = (value) => {
        const { verifiers } = this.props
        if (verifiers?.length > 0) {
            const selectedVerifier = verifiers.find(verifier => verifier.id === value)
            this.setState({ selectedVerifier: selectedVerifier ?? {} })
        }
    }

    // Handle Popover Visibility Change
    _handlePopoverVisibilityChange = (visible) => {
        // Check if popover is not visible then set selected verifier as empty object
        if (!visible) {
            this.setState({ selectedVerifier: {} })
        } else {
            const { selectedTrip, verifiers } = this.props
            // Check if selected trip is not null
            if (selectedTrip?.assigned_to) {
                const assigned_to = Number(selectedTrip.assigned_to)
                // Check if verifier is not null and selected verifier is not null
                if (verifiers && verifiers?.length > 0) {
                    // Get selected verifier
                    const selectedVerifier = verifiers.find(verifier => verifier.id === assigned_to)
                    this.setState({ selectedVerifier })
                }
            }
        }
    }

    // Handle Assign Verifier
    _handleAssignVerifier = () => {
        const { previousTaskMapFilterParams, selectedTrip, selectedTripIndex, dispatch } = this.props
        const { selectedVerifier } = this.state
        // Check if selected verifier is not valid
        if (!selectedVerifier?.id) {
            // Show Alert
            showAlert('error', 'Please select a verifier')
            return
        }

        // Check if selected trip is not valid
        if (!selectedTrip?.id) {
            // Show Alert
            showAlert('error', 'Selected trip is not valid')
            return
        }

        // Check if selected trip has assigned_to
        if (selectedTrip?.assigned_to) {
            const assigned_to = Number(selectedTrip.assigned_to)

            // Check if selected verifier id and selected trip id are same
            if (selectedVerifier?.id === assigned_to) {
                // Show Alert
                showAlert('error', 'Selected verifier is already assigned to this trip')
                return
            }
        }

        // Assign Verifier
        dispatch(assignVerifier({ task_id: selectedTrip.id, tasker_id: selectedVerifier.id }, 'trip', { ...selectedTrip, index: selectedTripIndex }))

        // Get updated trip
        dispatch(getTripList(previousTaskMapFilterParams))

        // Get updated appointment 
        dispatch(getAppointmentList(previousTaskMapFilterParams))
    }

    // Handle Update Trip
    _handleUpdateTrip = () => {
        const { dispatch, selectedTrip, selectedTripIndex } = this.props
        dispatch(openTripUpdateModal({ ...selectedTrip, index: selectedTripIndex }))
    }

    render() {
        const { hoveredIndex, selectedVerifier } = this.state
        const { isTaskDetailsOpen, selectedTrips, selectedTrip, isDrawerContentLoading, selectedTripIndex, verifiers, isAssignLoading } = this.props

        return (
            <Drawer
                // style={ isTaskDetailsOpen ? drawerContainerStyle : { ...drawerContainerStyle, width: 0 } }
                style={drawerContainerStyle}
                bodyStyle={{ padding: '15px' }}
                title={'Trip Information'}
                width={window.innerWidth <= 768 ? '100%' : 560}
                placement={'left'}
                visible={isTaskDetailsOpen}
                mask={false}
                closable={false}
                extra={<CloseOutlined onClick={this._handleOnClose} />}
            >
                {
                    selectedTrips?.length > 0 &&
                    selectedTrips.map((trip, index) => (
                        <Tooltip key={index} title={this._getIconByType(trip.type, 'text')}>
                            <Button
                                key={index}
                                type={selectedTripIndex === index ? 'primary' : 'default'}
                                onClick={() => this._handleOnClick(index)}
                                size={'small'}
                                icon={this._getIconByType(trip.type, 'icon')}
                                style={{
                                    margin: 2,
                                    background: getFillColorTripStatus(trip?.status),
                                    color: '#fff',
                                    borderColor: selectedTripIndex === index ? '#000' : hoveredIndex === index ? getFillColorTripStatus(trip?.status) : '',
                                    opacity: hoveredIndex === index ? 1 : selectedTripIndex === index ? 1 : 0.6
                                }}
                                onMouseEnter={() => this._handleOnMouseHover(index)}
                                onMouseLeave={() => this._handleOnMouseHover(index)}
                            >
                                {trip?.id}
                            </Button>
                        </Tooltip>
                    ))
                }
                <Divider style={{ margin: '10px 0px' }} />
                <Spin spinning={isDrawerContentLoading} indicator={<LoadingOutlined style={{ fontSize: 56 }} />}>
                    <div>
                        <p style={{ ...titleStyles, marginBottom: '4px' }}>{'Trip Details'}</p>
                        <Row>
                            <Col span={24}>
                                <DescriptionItem title={'File No.'}
                                    content={
                                        <>
                                            <span>{selectedTrip?.application?.application_id}</span>
                                            <Tooltip title={'View Application'}>
                                                <Button
                                                    type={'link'}
                                                    icon={<EyeOutlined onClick={e => this._viewApplicationDetails(e)} />}
                                                />
                                            </Tooltip>
                                        </>
                                    }
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col span={24}>
                                <DescriptionItem title={'Trip ID'} content={selectedTrip?.id} />
                            </Col>
                        </Row>
                        <Row>
                            <Col span={24}>
                                <DescriptionItem title={'Trip Type'} content={this._getIconByType(selectedTrip?.type, 'text')} />
                            </Col>
                        </Row>
                        <Row>
                            <Col span={24}>
                                <DescriptionItem
                                    title={'Trip Status'}
                                    content={
                                        <Tag color={getFillColorTripStatus(selectedTrip?.status)}>{getTripStatusLabel(selectedTrip?.status)}</Tag>
                                    }
                                />
                            </Col>
                        </Row>
                        {
                            selectedTrip?.status === 5 &&
                            <Row>
                                <Col span={24}>
                                    <DescriptionItem
                                        title={'Approx Completion Time'}
                                        content={
                                            <Tag color={getFillColorTripStatus(selectedTrip?.status)}>
                                                {getDateTimeDuration(selectedTrip?.tasker_got_out, selectedTrip?.tasker_got_in)}
                                            </Tag>
                                        }
                                    />
                                </Col>
                            </Row>
                        }

                        <Row>
                            <Col span={24}>
                                <DescriptionItem
                                    title={'Verifier'}
                                    content={
                                        <Space>
                                            <span style={{ color: !selectedTrip?.agent && 'red', fontWeight: !selectedTrip?.agent && 500 }}>
                                                {selectedTrip?.agent?.name ?? 'Verifier not assigned'}
                                            </span>
                                            <Popover
                                                trigger={['click']}
                                                placement={'bottom'}
                                                title={selectedTrip.assigned_to ? 'Reassign Verifier' : 'Assign Verifier'}
                                                onVisibleChange={this._handlePopoverVisibilityChange}
                                                content={
                                                    <div style={{ width: '224px' }}>
                                                        <Select
                                                            style={{ width: '100%' }}
                                                            showSearch
                                                            allowClear
                                                            placeholder={'Select a verifier'}
                                                            onChange={this._handleVerifierChange}
                                                            value={selectedVerifier?.id ?? null}
                                                            filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                                            optionFilterProp={'children'}
                                                        >
                                                            {
                                                                verifiers?.length > 0 && verifiers.map((item, index) => {
                                                                    return (
                                                                        <Select.Option key={index + 1} value={item.id}>{item.name}</Select.Option>
                                                                    )
                                                                })
                                                            }
                                                        </Select>
                                                        <div style={{ display: 'flex', justifyContent: 'flex-end', width: '100%', marginTop: '0.4rem' }}>
                                                            <Button
                                                                type={'primary'}
                                                                onClick={this._handleAssignVerifier}
                                                                loading={isAssignLoading}
                                                            >
                                                                {selectedTrip.assigned_to ? 'Reassign' : 'Assign'}
                                                            </Button>
                                                        </div>
                                                    </div>
                                                }
                                            >
                                                <Button
                                                    size={'small'}
                                                    type={'link'}
                                                    disabled={(selectedTrip?.status === 6) || (selectedTrip?.status === 7)}
                                                >
                                                    {selectedTrip.assigned_to ? 'Reassign' : 'Assign'}
                                                </Button>
                                            </Popover>
                                        </Space>
                                    }
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col span={24}>
                                <DescriptionItem title={'Created at'} content={selectedTrip?.created_at ?? '-'} />
                            </Col>
                        </Row>
                        <Row>
                            <Col span={24}>
                                <DescriptionItem title={'Trip Start Time'} content={selectedTrip?.start_time ?? '-'} />
                            </Col>
                        </Row>
                        <Row>
                            <Col span={24}>
                                <DescriptionItem title={'Trip End Time'} content={selectedTrip?.end_time ?? '-'} />
                            </Col>
                        </Row>
                        <Row>
                            <Col span={24}>
                                <DescriptionItem title={'Remarks'} content={selectedTrip?.remarks ?? '-'} />
                            </Col>
                        </Row>
                        <Row>
                            <Col span={24}>
                                <DescriptionItem title={'Comment'} content={selectedTrip?.comment ?? '-'} />
                            </Col>
                        </Row>
                        <Row>
                            <Col span={24}>
                                <DescriptionItem title={(selectedTrip?.type === 'OFFICE' || selectedTrip?.type === 'GUARANTOR_OFFICE') ? 'Office Name' : 'Place Name'} content={selectedTrip?.place_name ?? '-'} />
                            </Col>
                        </Row>
                        {
                            selectedTrip?.active_appointment?.reason &&
                            <Row>
                                <Col span={24}>
                                    <DescriptionItem
                                        title={'Appointment Reason'}
                                        content={selectedTrip?.active_appointment?.reason ?? '-'}
                                        styleProp={{ marginBottom: '14px', lineHeight: 0.4 }}
                                    />
                                </Col>
                            </Row>
                        }
                        {
                            selectedTrip?.active_appointment?.appointment_time &&
                            <Row>
                                <Col span={24}>
                                    <DescriptionItem
                                        title={'Appointment Time'}
                                        content={selectedTrip?.active_appointment?.appointment_time ?? '-'}
                                    />
                                </Col>
                            </Row>
                        }
                    </div>

                    <Divider style={{ margin: '10px 0px' }} />
                    <div>
                        <p style={titleStyles}>{'Concern Details'}</p>
                        <Row>
                            <Col span={24}>
                                <DescriptionItem title={'Applicant Name'}
                                    content={(selectedTrip?.applicant?.type === 'APPLICANT' && selectedTrip?.applicant?.name) ?? '-'} />
                            </Col>
                        </Row>
                        <Row>
                            <Col span={24}>
                                <DescriptionItem title={'Contact Person Name'}
                                    content={selectedTrip?.concern?.name ?? '-'} />
                            </Col>
                        </Row>
                    </div>
                    <Divider style={{ margin: '10px 0px' }} />
                    <div>
                        <p style={titleStyles}>{'Address Details'}</p>
                        <Row>
                            <Col span={24}>
                                <DescriptionItem
                                    title={'Given Address'}
                                    content={selectedTrip?.address ?? '-'}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col span={24}>
                                <DescriptionItem
                                    title={'Exact Address'}
                                    content={selectedTrip?.exact_address ?? '-'}
                                />
                            </Col>
                        </Row>


                    </div>
                    <Divider style={{ margin: '10px 0px' }} />
                    <div>
                        <p style={titleStyles}>{'Map and Addresses'}</p>

                        <Row>
                            <Col span={24}>
                                {
                                    selectedTrip?.id &&
                                    <UpdateTrip mapContainerHeight={'300px'} />
                                }
                            </Col>
                        </Row>

                    </div>

                </Spin>
            </Drawer>
        )
    }
}

// JSS Styles
const titleStyles = {
    display: 'block',
    lineHeight: '1.5715',
    background: 'rgba(0, 0, 0, 0.05)',
    borderRadius: '3px',
    padding: '4px 8px',
    fontSize: '17px',
}

const drawerContainerStyle = {
    height: 'calc(100vh)',
    // marginTop: '64px', 
    // width: '540px'
}

// Props Validation
DrawerTaskMap.propTypes = {
    isTaskDetailsOpen: PropTypes.bool,
    selectedTrips: PropTypes.array,
    selectedTrip: PropTypes.object,
    previousTaskMapFilterParams: PropTypes.object,
    isDrawerContentLoading: PropTypes.bool,
    selectedTripIndex: PropTypes.number,
    verifiers: PropTypes.array,
    isAssignLoading: PropTypes.bool,
    dispatch: PropTypes.func
}

DrawerTaskMap.defaultProps = {
    isTaskDetailsOpen: false,
    previousTaskMapFilterParams: {},
    selectedTrips: [],
    selectedTrip: {},
    isDrawerContentLoading: false,
    selectedTripIndex: 0,
    verifiers: [],
    isAssignLoading: false,
    dispatch: () => null
}

const mapStateToProps = state => ({
    isTaskDetailsOpen: state.taskMap.isTaskDetailsOpen,
    previousTaskMapFilterParams: state.taskMap.previousTaskMapFilterParams,
    selectedTrips: state.taskMap.selectedTrips,
    selectedTrip: state.taskMap.selectedTrip,
    isDrawerContentLoading: state.taskMap.isDrawerContentLoading,
    selectedTripIndex: state.taskMap.selectedTripIndex,
    verifiers: state.common.verifiers,
    isAssignLoading: state.common.isAssignLoading,
})

const mapDispatchToProps = dispatch => ({ dispatch })

export default connect(mapStateToProps, mapDispatchToProps)(DrawerTaskMap)