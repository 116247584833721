import axios from 'axios'

// utils
import { PERMISSION } from '../../Config/config'

//actions and reducers
import { setAllPermissionList, setSelectedVerifierPermission, setIsLoadingForPermissionAction } from '../Reducers/permissionReducer'
import { showAlert } from './commonActions'

// gett list of all permission
const fetchPermissionList = () => {
    return dispatch => {
        axios.get(PERMISSION.GET_PERMISSION_LIST)
        .then(res => {
            if(res?.status !== 200){
                return []
            }
            dispatch(setAllPermissionList(res?.data?.data?.permissions ?? []))
        })
        .catch(err => {
            showAlert('error', err?.message ?? 'Failed to load permission list')
            console.error(err)
        })
    }

}

// fetch permission of an individual verifier
const fetchIndividualVerifierPermission = (id) => {
    return dispatch => {

        dispatch(setIsLoadingForPermissionAction(true))

        axios.get(`${PERMISSION.INDIVIDUAL_USER_PERMISSION}?user_id=${id}`)
        .then(res => {
            if(res?.status !== 200){
                dispatch(setSelectedVerifierPermission([])) 
                return
            }
            const permissionList = res?.data?.data?.permissions;
            const parsedPermission = []
            
            // structurring data for form fields option in update permission form 
            Object.entries(permissionList)?.forEach(( item ) => {
                Object.entries( item[1] )?.forEach( submenu => {
                    submenu[1]?.forEach( permissionOption => {
                        parsedPermission.push({
                            name: [ 'selectedPermission', permissionOption?.id ],
                            value: true
                        })
                    })
                })
            })
            // console.log("parsed permission", parsedPermission);

            dispatch(setSelectedVerifierPermission(parsedPermission ?? [])) 
            showAlert('success', res?.data?.message ?? 'Action Successfull')
        })
        .catch(err => {
            console.error(err)
            dispatch(setSelectedVerifierPermission([])) 
            showAlert('error', err?.data?.message ?? err?.message ?? 'Action Failed')
        })
        .finally(() => {
            dispatch(setIsLoadingForPermissionAction(false))
        })
    }
}

// grant/restrict access permission to specific verifier
const syncIndividualVerifierPermission = ( params ) => {
    return dispatch => {

        // start loading state
        dispatch(setIsLoadingForPermissionAction(true))

        axios.post(`${PERMISSION.SYNC_PERMISSION}`, params)
        .then(res => {
            if(res?.status !== 200){
                return
                // showAlert('success', res?.data?.message ?? 'Permission Synced')
            }
            showAlert('success', res?.data?.message ?? 'Permission Synced')
            dispatch(fetchIndividualVerifierPermission( params?.user_id ))

            dispatch(setIsLoadingForPermissionAction(false))
        })
        .catch(err =>{
            console.error(err)
            showAlert('error', err?.message ?? 'Failed to update user permission')
            dispatch(setIsLoadingForPermissionAction(false))
        })
    }
}

// check if the features is allowed to the user
// options = { *menuName, *subMenuName, *permissionID }. This is mandatory
const isAllowedToAccess = (permissionList, options) => {
    if( !options?.permissionID || !options?.menuName || !options?.subMenuName ){
        showAlert('error', 'Panel name, submenu name of permission ID is not found')
        return false
    }

    const currentWindowPermission = permissionList?.[ options?.menuName]?.[ options?.subMenuName ]
    const isFound = currentWindowPermission?.some(item => Number( item?.id ) === Number( options?.permissionID ))
    
    return isFound ? true : false
}

export { fetchPermissionList, fetchIndividualVerifierPermission, syncIndividualVerifierPermission, isAllowedToAccess  }