import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

// Import Components

import ConcernFormOCR from './ConcernFormOCR'
import { createConcernsAndTrips } from '../../../../../Services/Actions/createFileActions'

class ConcernFormWrapperOCR extends React.PureComponent {

    render() {
        const { selectedOption, coApplicantNo, formRef, imagesObj } = this.props
        // console.log(selectedOption, 'selectedOption', coApplicantNo, 'coApplicantNo');
        return (
            <>
                {
                    selectedOption?.id && 
                    createConcernsAndTrips(selectedOption?.id, coApplicantNo).map((concern, index, all) => (
                        <div key={index + concern.type} style={{ marginBottom: all.length === index + 1 ? '1rem' : 'none' }}>
                            <ConcernFormOCR
                                title={concern.label + ' ' + concern.title_postfix}
                                concern={concern}
                                trips={concern.trips}
                                formRef={formRef}
                                imagesObj={this.props.imagesObj}
                            />
                        </div>
                    ))
                }
            </>
        )
    }
}

// PropTypes
ConcernFormWrapperOCR.propTypes = {
    selectedOption: PropTypes.object,
    formRef: PropTypes.object,
}

ConcernFormWrapperOCR.defaultProps = {
    selectedOption: {},
    formRef: {},
}

// Map State To Props
const mapStateToProps = state => ({
    selectedOption: state.createFile.selectedOption,
    coApplicantNo: state.createFile.coApplicantNo,
})

// Map Dispatch To Props
const mapDispatchToProps = dispatch => ({ dispatch })

export default connect(mapStateToProps, mapDispatchToProps)(ConcernFormWrapperOCR)