import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link } from "react-router-dom"; // useLocation

// Import CSS
import "antd/dist/antd.css";
import "../../App.css";

// Import Components
import { Menu } from "antd";

// Import Icons
import {
  FileUnknownOutlined,
  LayoutOutlined,
  FileTextOutlined,
  EnvironmentOutlined,
  BarChartOutlined,
  ScheduleOutlined,
  NodeIndexOutlined,
  FileAddOutlined,
  FileProtectOutlined,
  SafetyCertificateOutlined,
  LineChartOutlined,
  UserOutlined,
  QuestionOutlined,
} from "@ant-design/icons";

//Import Actions
import { logout } from "../../Services/Actions/authActions";
import { showAlert } from "../../Services/Actions/commonActions";
import { setNavMenuItems } from "../../Services/Reducers/commonReducer";

const Navbar = ({ dispatch, allowedPermission }) => {
  // States
  const [selectedMenu, setSelectedMenu] = useState(null);

  // Hanlde Menu Change
  const handleMenuChange = (e) => {
    setSelectedMenu(e.key);
  };

  // get navigation Menu and Submenu property
  const getNavProperty = (menuName) => {
    const menuList = {
      dashboard: {
        id: menuName,
        key: 1,
        label: "Dashboard",
        icon: <LayoutOutlined />,
        link: "/" + menuName,
      },
      reports: {
        id: menuName,
        key: 2,
        label: "Reports",
        icon: <FileTextOutlined />,
        link: "/" + menuName,
      },
      "map-view": {
        id: menuName,
        key: 3,
        label: "Task Map",
        icon: <EnvironmentOutlined />,
        link: "/" + menuName,
      },
      appointments: {
        id: menuName,
        key: 4,
        label: "Appointments",
        icon: <ScheduleOutlined />,
        link: "/" + menuName,
      },
      "pending-list": {
        id: menuName,
        key: 5,
        label: "Pending List",
        icon: <FileUnknownOutlined />,
        link: "/" + menuName,
      },
      "re-cpv": {
        id: menuName,
        key: 6,
        label: "ReCPV",
        icon: <NodeIndexOutlined />,
        link: "/" + menuName,
      },
      "create-file": {
        id: menuName,
        key: 7,
        label: "Create File",
        icon: <FileAddOutlined />,
        link: "/" + menuName,
      },

            /***********************/
            // ***   SUBMENU   *** //
            /**********************/
            
      analytics: {
        id: menuName,
        key: 1,
        label: "Analytics",
        icon: <BarChartOutlined />,
        link: "/" + menuName,
      },
      management: {
        id: "management",
        content: [
          {
            id: "admin-panel",
            key: 2,
            label: "Admin Panel",
            icon: <SafetyCertificateOutlined />,
            link: "/admin-panel",
          },
          {
            id: "documents",
            key: 8,
            label: "Documents",
            icon: <FileProtectOutlined />,
            link: "/documents",
          },
          {
            id: "questionnaire",
            key: 9,
            label: "Company Profile",
            icon: <QuestionOutlined />,
            link: "/company-profile",
          },
          {
            id: "route-statistics",
            key: 3,
            label: "Route Statistics",
            icon: <LineChartOutlined />,
            link: "/route-statistics",
          },
          {
            id: "permissions",
            key: 4,
            label: "Permissions",
            icon: <BarChartOutlined />,
            link: "/permissions",
          },
          {
            id: "requested-trips",
            key: 7,
            label: "Requested Trips",
            icon: <FileUnknownOutlined />,
            link: "/requested-trips",
          },
          {
            id: "bill-calculation",
            key: 5,
            label: "Bill Calculation",
            icon: <FileTextOutlined />,
            link: "/bill-calculation",
          },
          {
            id: "Register",
            key: 6,
            label: "Register",
            icon: <FileTextOutlined />,
            link: "/register",
          },
        ],
      },
    };

    return (
      menuList?.[menuName] ?? {
        id: menuName,
        label: menuName,
        icon: <QuestionOutlined />,
        key: menuName,
        link: "/" + menuName,
      }
    );
  };

  /****************************************
   * AVAILABLE MENU ITEMS LIST: DONT DELETE IT, FOLLOW IT TO TRACK MENUS AND SUM+BMENUS
   ********************************************/
  //     { key: 1, icon: <LayoutOutlined />, label: 'Dashboard', link: '/dashboard', id: 'dashboard' },
  //     { key: 2, icon: <FileTextOutlined />, label: 'Reports', link: '/reports', id: 'reports' },
  //     { key: 3, icon: <EnvironmentOutlined />, label: 'Task Map', link: '/task-map', id: 'task-map' },
  //     { key: 5, icon: <ScheduleOutlined />, label: 'Appointments', link: '/appointments', id: 'appointments' },
  //     { key: 5, icon: <FileUnknownOutlined />, label: 'Pending List', link: '/pending-list', id: 'pending-list' },
  //     { key: 6, icon: <NodeIndexOutlined />, label: 'Re-CPV', link: '/re-cpv', id: 're-cpv' },
  //     { key: 7, icon: <FileAddOutlined />, label: 'Create File', link: '/create-file', id: 'create-file' },
  /************************************************
   *   SUBMENU
   ************************ */
  // { key: 1, icon: <BarChartOutlined />, label: 'Analytics', link: '/analytics', id: 'analytics' },
  // { key: 2, icon: <SafetyCertificateOutlined />, label: 'Admin Panel', link: '/admin-panel', id: 'admin-panel' },
  // { key: 3, icon: <LineChartOutlined />, label: 'Route Statistics', link: '/route-statistics', id: 'route-statistics' },
  // { key: 4, icon: <BarChartOutlined />, label: 'Permissions', link: '/permissions', id: 'permissions' },

  // generate "Menu" for navbar
  const generateNavMenuItems = (panelPermission) => {
    const menuObject = [];
    const subMenuObject = [];

    for (const [menu, subMenu] of Object.entries(panelPermission)) {
      Object.entries(subMenu).forEach((subMenuItem) => {
        const navProperty = getNavProperty(subMenuItem[0]);

        // if menu and submenu are same
        if (menu === subMenuItem[0]) {
          menuObject.push({
            key: navProperty?.key,
            icon: navProperty?.icon,
            label: navProperty?.label,
            link: navProperty?.link,
            id: navProperty?.id,
          });

          return;
        }

        if (navProperty?.id === "management") {
          navProperty?.content?.forEach((subMenuContent) => {
            subMenuObject.push({
              key: subMenuContent?.key,
              icon: subMenuContent?.icon,
              label: subMenuContent?.label,
              link: subMenuContent?.link,
              id: subMenuContent?.id,
            });
          });
          return;
        }

        subMenuObject.push({
          key: navProperty?.key,
          icon: navProperty?.icon,
          label: navProperty?.label,
          link: navProperty?.link,
          id: navProperty?.id,
        });
      });
    }
    dispatch(setNavMenuItems(menuObject))
    return [menuObject, subMenuObject];
  };

  // controliing nav menu access
  const getAllNavMenu = () => {
    if (Object.entries(allowedPermission)?.length < 1) {
      showAlert("error", "You are Restricted");
      return [];
    }
    const [menuItems, subMenuItems] = generateNavMenuItems(allowedPermission);
    let accessGranted = [];
    // rendering menus
    menuItems.forEach((item) => {
      accessGranted.push(
        <Menu.Item key={item.id} icon={item.icon}>
          <Link to={item.link}>{item.label}</Link>
        </Menu.Item>
      );
    });

    subMenuItems?.length &&
      accessGranted.push(
        <Menu.SubMenu
          icon={<UserOutlined />}
          key={"admin-panel-1"}
          title="Admin"
        >
          {subMenuItems.map((item) =>
            item?.id === "management" ? (
              item?.map((managementItem) => (
                <Menu.Item
                  style={subMenuStyles}
                  key={managementItem.key}
                  icon={managementItem.icon}
                >
                  <Link to={managementItem.link}>{managementItem.label}</Link>
                </Menu.Item>
              ))
            ) : (
              <Menu.Item style={subMenuStyles} key={item.key} icon={item.icon}>
                <Link to={item.link}>{item.label}</Link>
              </Menu.Item>
            )
          )}
        </Menu.SubMenu>
      );

    return accessGranted;
  };

  // Handle Logout
  const handleLogout = () => {
    // setIsLogoutLoading(true);

    setTimeout(() => {
      // setIsLogoutLoading(false);

      // Logout
      dispatch(logout());
    }, 1000);
  };

  return (
    <div>
      <div style={{ height: "100vh", }}>
        <Menu
          selectedKeys={[selectedMenu]}
          mode={"inline"}
          onClick={handleMenuChange}
          theme={"dark"}
          className={"scrollable"}
          style={{ maxHeight: "89.5%", overflowY: "auto" }} // Nav Height
        >
          {getAllNavMenu()}
        </Menu>
      </div>
    </div>
  );
};

// Use Prev
const usePrev = (value) => {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  }, [value]);
  return ref.current;
};

// JSS Styles
const subMenuStyles = {
  paddingLeft: "40px",
};

// Prop Types
Navbar.propTypes = {
  isAuthenticated: PropTypes.bool,
  user: PropTypes.object,
  allowedPermission: PropTypes.object,
};

Navbar.defaultProps = {
  isAuthenticated: false,
  user: null,
  allowedPermission: null,
};

const mapStateToProps = (state) => ({
  user: state?.auth?.user ?? null,
  allowedPermission: state?.permission?.allowedPermission ?? null,
});

const mapDispatchToProps = (dispatch) => ({ dispatch });

export default connect(mapStateToProps, mapDispatchToProps)(Navbar);
