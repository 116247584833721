import axios from "axios";
import { REQUESTED_TRIPS } from "../../Config/config";

// Import Reducers
import { setIsLoading, setIsSubmitButtonLoading } from "../Reducers/reportsReducer";
import { setReqTrips, setReqTripsPagination } from "../Reducers/requestedTrips";

// Import Components
import { showAlert } from "./commonActions";
import { message } from "antd";

// Get Reports Data by Date Range
const getRequestedTrips = (params, key) => {
  return async (dispatch) => {
    dispatch(setIsLoading(true));
    if (!key) {
      dispatch(setIsSubmitButtonLoading(true));
    }
    try {
      const response = await axios.get(REQUESTED_TRIPS.GET_REQUESTED_TRIPS);
      const tableDataWithPagination = response?.data?.data;

      // Set Pending List
      dispatch(setReqTrips(tableDataWithPagination?.requested_trips));
      dispatch(setReqTripsPagination(tableDataWithPagination?.requested_trips));

      // Set loading to false
      dispatch(setIsLoading(false));
      dispatch(setIsSubmitButtonLoading(false));
    } catch (err) {
      showAlert("error", err?.message ?? "Failed to load");
      // Set loading to false
      console.error({ err });
    }
  };
};

// @desc: Approve Requested Trip
const postApproveTripRequest = (params) => {
  return (dispatch) => {
    // setting params
    let formData = new FormData();
    formData.append("request_trip_id", params?.tripId);
    formData.append("status", params?.status);

    var confirmationMessage = "Trip Request has been Approved!!!";
    axios
      .post(`${REQUESTED_TRIPS.POST_APPROVE_TRIP_REQUEST}`, formData)
      .then((res) => {
        if (res?.status !== 200) {
          confirmationMessage = "Trip Request has been Approved!!!";
          message.success(confirmationMessage);
        }
      })
      .catch((err) => {
        console.error(err);
        showAlert("error", err?.message ?? "Failed to Approve!!");
        confirmationMessage = err?.message ?? "Failed to Approve!!";
        // alert(confirmationMessage);
        message.error(confirmationMessage);
      });

    // alert(confirmationMessage);
    // message.success("Click on Yes");
    getRequestedTrips();
  };
};

// @desc - Trip Request Cancel
const postCancelTripRequest = (params) => {
  return (dispatch) => {
    // setting params
    let formData = new FormData();
    formData.append("request_trip_id", params?.tripId);
    formData.append("assigned_to", params?.verifierId);
    formData.append("status", params?.status);

    var confirmationMessage = "Trip Request has been Cancelled!!!";
    axios
      .post(`${REQUESTED_TRIPS.POST_APPROVE_TRIP_REQUEST}`, formData)
      .then((res) => {
        if (res?.status !== 200) {
          confirmationMessage = "Trip Request has been Cancelled!!!";
        }
      })
      .catch((err) => {
        console.error(err);
        showAlert("error", err?.message ?? "OPS!!! Failed to Cancelled!!");
        confirmationMessage = err?.message ?? "OPS!!! Failed to Cancelled!!";
        alert(confirmationMessage);
      });

    alert(confirmationMessage);
    getRequestedTrips();
  };
};

// @desc - Assign Requested Trip to New Verifier
const assignRequestedTripToVerifier = (params) => {
  return (dispatch) => {
    let formData = new FormData();
    formData.append("request_trip_id", params?.tripId);
    formData.append("assigned_to", params?.verifierId);
    formData.append("status", params?.status);

    var confirmationMessage = "Trip Request has been Reassigned!!!";

    axios
      .post(`${REQUESTED_TRIPS.POST_APPROVE_TRIP_REQUEST}`, formData)
      .then((res) => {
        if (res?.status !== 200) {
          confirmationMessage = "Trip Request has been Reassigned!!!";
        }
      })
      .catch((err) => {
        console.error(err);
        showAlert("error", err?.message ?? "OPS!! Failed to Reassign!!");
        confirmationMessage = err?.message ?? "OPS!! Failed to Reassign!!";
        alert(confirmationMessage);
      });

    alert(confirmationMessage);
    getRequestedTrips();
  };
};

export {
  getRequestedTrips,
  postApproveTripRequest,
  postCancelTripRequest,
  assignRequestedTripToVerifier,
};
