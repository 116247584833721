import React, { useEffect, useState } from "react";
import { connect, useSelector } from "react-redux";
import { Link } from "react-router-dom";

// Import Configs
import { BASE_URL } from "../../Config/config";

// Import CSS
import "../../App.css";
import "antd/dist/antd.css";

// Import Components
import { Popover, Affix, Button, Avatar, Badge, Modal, Form, Input } from "antd";
import { Header } from "antd/lib/layout/layout";

// Import Icons
import { BellFilled, PoweroffOutlined } from "@ant-design/icons";

// Import Actions
import { getNotifications } from "../../Services/Actions/commonActions";
import { logout, updatePassword } from "../../Services/Actions/authActions";
import { deactivateStatusSocket } from "../../Services/Actions/reportsActions";

const TopNav = ({ dispatch, allowedPermission }) => {
  // Get Data From Redux
  const { notifications, newNotificationCount } = useSelector((state) => state.common);
  const { user } = useSelector((state) => state?.auth);
  const [isOpenSettingsPopup, setIsOpenSettingsPopup] = useState(false);
  const [isOpenChangePasswordModal, setIsOpenChangePasswordModal] = useState(false);

  // States
  const [form] = Form.useForm()
  const [isLogoutLoading, setIsLogoutLoading] = useState(false);
  const NotificationTitle = <span>Notifications</span>;
  const NotificationList = (
    <div>
      {/* notifiacation's tab heading or groupname from api */}
      <nav>
        <div class="nav nav-tabs" id="nav-tab" role="tablist">
          {notifications.map((item, index) => (
            // first element is active element in tabs
            <button
              class={index === 0 ? "nav-link active" : "nav-link"}
              id={`nav-tab-${index}`}
              data-bs-toggle="tab"
              data-bs-target={`#nav-${index}`}
              type="button"
              role="tab"
              aria-controls="nav-home"
              aria-selected="true"
              style={{ fontSize: "10px", fontWeight: "bold" }}
              key={index + item}
            >
              {item?.group}
            </button>
          ))}
        </div>
      </nav>

      {/* notifiacation's tab content */}
      <div class="tab-content" id="nav-tabContent">
        {notifications.map((item, index) => (
          // first element is active element in tabs
          <div
            class={index === 0 ? "tab-pane fade show active" : "tab-pane fade"}
            id={`nav-${index}`}
            role="tabpanel"
            aria-labelledby={`nav-tab-${index}`}
            key={index + item}
          >
            <div className="notify__container" style={notifyContainer}>
              {
                // showing notifiacations based on each group
                item?.notifications?.map((item, index) => (
                  <div key={index} style={notifyItem}>
                    <p style={{ paddingRight: "15px", fontSize: "11px" }}>
                      {item.message}
                    </p>
                    <span
                      style={{
                        fontSize: "7px",
                        marginTop: "15px",
                        color: "grey",
                      }}
                    >
                      {item.time}
                    </span>
                  </div>
                ))
              }
            </div>
          </div>
        ))}
      </div>
    </div>
  );

  // on mount
  useEffect(() => {
    dispatch(getNotifications());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //  handle Notifications
  const handleNotify = () => {
    dispatch(getNotifications());
  };

  // Handle Logout
  const handleLogout = () => {
    setIsLogoutLoading(true);

    setTimeout(() => {
      setIsLogoutLoading(false);

      // Logout
      dispatch(logout());

      dispatch(deactivateStatusSocket());
    }, 1000);
  };

  // Handle Change Password
  const _handleChangePassword = (values) => {
    dispatch(updatePassword(values)).then(res => {
      if (res === 'success') {
        setIsOpenChangePasswordModal(false)
      }
    });
  }

  return (
    <div>
      <Affix offsetTop={0}>
        <Header className="header">
          <div style={logoStyle}>
            <Link to="/" style={{ color: "#59b994" }}>
              Verify
              {BASE_URL === window?._env_?.REACT_APP_STAGING_BASE_URL ? (
                <span
                  style={{
                    paddingLeft: "11px",
                    color: "orange",
                    fontSize: "15px",
                  }}
                >
                  Staging
                </span>
              ) : (
                ""
              )}
            </Link>

            <div style={headerRight}>
              {!user?.email.includes('southeastbank') && (
                <Popover
                  placement="bottomRight"
                  title={NotificationTitle}
                  content={NotificationList}
                  trigger="click"
                  onClick={handleNotify}
                  style={{ paddingTop: "-20px" }}
                >
                  <div
                    style={{
                      paddingRight: "30px",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Badge
                      count={newNotificationCount}
                    // showZero
                    >
                      <BellFilled
                        style={{
                          color: "#ffffff",
                          fontSize: "20px",
                          cursor: "pointer",
                          // paddingRight: "30px",
                          marginTop: "-20px !important",
                          paddingTop: "-20px",
                        }}
                      />
                    </Badge>
                  </div>

                  {/* <span style={{color: "white", fontFamily: "monospace",cursor: "pointer",  fontSize:"15px"}}>Notifications</span> */}
                </Popover>
              )}

              <span
                style={{
                  color: "white",
                  fontSize: "13px",
                  fontFamily: "monospace",
                }}
              >
                {user?.name}
              </span>

              <div style={adminOptionStyle}>
                <Popover
                  trigger={["click"]}
                  placement={"bottomLeft"}
                  // visible={isOpenSettingsPopup}
                  content={
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      {/* Change Password */}
                      <Button
                        style={{ cursor: 'pointer', margin: '0 0 10px 0' }}
                        onClick={() => { setIsOpenSettingsPopup(false); setIsOpenChangePasswordModal(true) }}
                      >
                        Change Password
                      </Button>

                      {/* Logout Button */}
                      <Button
                        type={"primary"}
                        danger
                        onClick={handleLogout}
                        icon={<PoweroffOutlined />}
                        loading={isLogoutLoading}
                      >
                        {"Logout"}
                      </Button>
                    </div>
                  }
                >
                  <Avatar size={30} style={userIconStyle} onClick={() => setIsOpenSettingsPopup(!isOpenSettingsPopup)}>
                    {user?.name?.charAt(0).toUpperCase() ?? "A"}
                  </Avatar>
                </Popover>
              </div>
            </div>
          </div>
        </Header>
      </Affix>

      <Modal
        visible={isOpenChangePasswordModal}
        footer={null}
        onCancel={() => setIsOpenChangePasswordModal(false)}
        title='Change Password'
      >
        <Form
          layout="vertical"
          form={form}
          onFinish={_handleChangePassword}
        >
          {/* Old Password */}
          <Form.Item
            name="oldPass"
            label="Old Password"
            rules={[
              {
                required: true,
                message: "Please enter your old password!",
              },
            ]}
          >
            <Input placeholder="Enter old password" />
          </Form.Item>

          {/* New Password */}
          <Form.Item
            name="newPass"
            label="New Password"
            rules={[
              {
                required: true,
                message: 'Please input your password.'
              },
              {
                min: 6,
                message: 'Password must be at least 6 characters.'
              }
            ]}
          >
            <Input placeholder="Enter new password" />
          </Form.Item>

          {/* Save Button */}
          <Form.Item>
            <Button htmlType="submit" type="primary">
              Save
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

//  styles
const logoStyle = {
  fontFamily: "Oleo Script Swash Caps, cursive",
  textAlign: "left",
  padding: "5px",
  // borderBottom: '1px solid gray',
  fontWeight: "bold",
  fontSize: "32px",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  // paddingBottom: '30px'
};

const headerRight = {
  paddingRight: "10px",
  marginTop: "-30px !important",
  maxHeight: "64px",
  marginRight: "10px !important",
  display: "flex",
  alignItems: "center",
};

const notifyContainer = {
  height: "400px",
  maxHeight: "80vh",
  overflowY: "auto",
  paddingTop: "10px",
};

const notifyItem = {
  width: "100%",
  display: "flex",
  justifyContent: "space-between",
  padding: "10px",
  paddingBottom: "0px",
  borderBottom: "1px solid lightGrey",
};

// JSS Styles
const userIconStyle = {
  backgroundColor: "#4790FF",
  cursor: "pointer",
  fontFamily: "sans-serif",
  paddingTop: "-20px !important",
};

const adminOptionStyle = {
  textAlign: "center",
  padding: "10px",
  margin: "15px auto",
  display: "flex",
  alignItems: "center",
};


// Prop Types
TopNav.propTypes = {
  // isAuthenticated: PropTypes.bool,
  // user: PropTypes.object,
  // allowedPermission: PropTypes.object
};

TopNav.defaultProps = {
  // isAuthenticated: false,
  // user: null,
  // allowedPermission: null,
};

const mapStateToProps = (state) => ({
  // user: state?.auth?.user ?? null,
  // allowedPermission: state?.permission?.allowedPermission ?? null
});

const mapDispatchToProps = (dispatch) => ({ dispatch });

export default connect(mapStateToProps, mapDispatchToProps)(TopNav);
