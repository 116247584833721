import React, { useState } from 'react'

// Import Components
import { Card, Form, Input, Button, Select, Space } from 'antd';

// Import Icons
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';

//Import Actions, Reducers & Methods
import { useDispatch } from 'react-redux'
import { createQuestion, getQuestions } from '../../../Services/Actions/adminPanelActions';
import { showAlert } from '../../../Services/Actions/commonActions';

// form label and field grid ratio
const formItemLayout = {
    labelAlign: 'left',
    labelCol: {
        xs: {
            span: 24,
        },
    },
    wrapperCol: {
        xs: {
            span: 24,
        },
    },
};


const AddQuestion = () => {
    const dispatch = useDispatch()
    const [form] = Form.useForm()
    const [isDuplicateKeyFound, setIsDuplicateKeyFound] = useState(false)
    const [selectedQusType, setSelectedQusType] = useState('')

    // static question type
    const questionTypes = [
        {
            label: 'MCQ',
            value: 'MCQ',
        },
        {
            label: 'SCQ',
            value: 'SCQ',
        },
        {
            label: 'INPUT',
            value: 'INPUT',
        }
    ]

    // format and submitted data on form submit
    const onFinish = (values) => {
        const questionDetails = {
            question: values.question,
            field_type: values.questionType,
            question_key: values.question_key?.trim(),
            options: values.optionsDetails
        }

        createQuestion(questionDetails)
            .then(res => {
                if (res.status === 200) {
                    dispatch(getQuestions())
                    showAlert('success', res?.message ? res?.data?.message : 'Question Created Successfully')
                    form.resetFields()
                    setIsDuplicateKeyFound(false)
                    return
                }
                // if key is not unique. need to show a warning message below input field
                if (res.status === 400 && res?.data?.message?.message.includes('Similarities')) {
                    setIsDuplicateKeyFound(true)
                    return
                }
            })
            .catch(err => {
                showAlert('error', err?.message ?? 'Question Creation Failed')
                console.error(err)
            })
    };


    return (
        <Card>
            <Form
                name='create_question_form'
                {...formItemLayout}
                onFinish={onFinish}
                form={form}
                initialValues={{
                    optionsDetails: [{}]
                }}
            >
                {/* Question Key ID */}
                <Form.Item
                    name={'question_key'}
                    label={'Question Key ID'}
                    validateStatus={isDuplicateKeyFound && 'error'}
                    help={isDuplicateKeyFound && 'This is not a unique key. It already exists in the question list'}
                    rules={[
                        {
                            required: true,
                            message: 'Missing Question Key',
                        },
                    ]}
                >
                    <Input placeholder={'Please enter a unique key ID'} />
                </Form.Item>

                {/* Question Type */}
                <Form.Item
                    name={'questionType'}
                    label={'Question Type'}
                    rules={[
                        {
                            required: true,
                            message: 'Missing Question Type',
                        },
                    ]}
                >
                    <Select options={questionTypes} onChange={(value) => setSelectedQusType(value)} placeholder='Plaease select question type' />
                </Form.Item>

                {/* Unique Question */}
                <Form.Item
                    name={'question'}
                    label={'Unique Question'}
                    rules={[
                        {
                            required: true,
                            message: 'Missing Question',
                        },
                    ]}
                >
                    <Input placeholder='Please enter a unique question' />
                </Form.Item>

                {/* Qustion Options */}
                <Form.List
                    name='optionsDetails'
                    rules={[
                        {
                            validator: async (_, names) => {
                                if (!names || names.length < 1) {
                                    return Promise.reject(new Error('At least 1 Options'));
                                }
                            },
                        },
                    ]}
                >
                    {(fields, { add, remove }, { errors }) => (
                        <>
                            {fields.map((field) => (
                                <Space
                                    key={field.key}
                                >

                                    {/* Option Key Id */}
                                    <Form.Item
                                        {...field}
                                        name={[field.name, 'key']}
                                        label='Option Key ID'
                                        rules={[
                                            {
                                                required: true,
                                                whitespace: true,
                                                message: "Please input option's key or delete this field.",
                                            },
                                        ]}
                                        validateTrigger={['onChange', 'onBlur']}
                                    >
                                        <Input
                                            placeholder="Option Key ID"
                                            style={{
                                                width: '100%',
                                            }}
                                        />
                                    </Form.Item>

                                    {/* Option Name */}
                                    <Form.Item
                                        {...field}
                                        name={[field.name, 'name']}
                                        label='Option Name'
                                        rules={
                                            [
                                                {
                                                    required: true,
                                                    whitespace: true,
                                                    message: "Please input option's name or delete this field.",
                                                },
                                            ]}
                                        validateTrigger={['onChange', 'onBlur']}
                                    >
                                        <Input
                                            placeholder="Option Name"
                                            style={{
                                                width: '100%',
                                            }}
                                        />
                                    </Form.Item>

                                    {/* Remove Option Button */}
                                    {fields.length > 1 ? (
                                        <MinusCircleOutlined
                                            className="dynamic-delete-button"
                                            onClick={() => remove(field.name)}
                                        />
                                    ) : null}
                                </Space>
                            ))}

                            {/* Add Option Button */}
                            <Form.Item>
                                <Button
                                    type="dashed"
                                    onClick={() => add()}
                                    style={{
                                        width: '60%',
                                    }}
                                    icon={<PlusOutlined />}
                                >
                                    Add Answer Option
                                </Button>
                                <Form.ErrorList errors={errors} />
                            </Form.Item>
                        </>
                    )
                    }
                </Form.List >
                <Form.Item>
                    <Button type='primary' htmlType='submit'>
                        Create
                    </Button>
                </Form.Item>
            </Form >
        </Card >
    )
}


export default AddQuestion