import axios from 'axios'

// Import Config
import { AUTH } from '../../Config/config'

// Import Actions, Reducers and Methods
import { setIsAuthenticated, setIsValidating, setToken, setUser, setError, setIsLoading } from '../Reducers/authReducer'
import { getOrgList, getVerifiers, getUsers } from '../Actions/commonActions'
import { showAlert } from '../Actions/commonActions'
import { setAllowedPermission } from '../Reducers/permissionReducer'

// Login Action
export function login(user) {
    return dispatch => {
        // Set `isValidating`
        dispatch(setIsValidating(true))

        axios.post(AUTH.LOGIN, user)
            .then(res => {
                if (res?.data?.data) {
                    const token = res.data.data
                    if (token) {
                        // Validate User
                        dispatch(validateUser(token))
                    }

                } else {
                    // Set Error Msg
                    dispatch(setError(res?.data?.message ?? 'Token Not Found.'))
                    // Show Error Alert
                    showAlert('error', res?.data?.message ?? 'Token Not Found.')

                    throw new Error('Token Not Found')
                }
            })
            .catch(err => {
                console.error(err)

                // Dispatch `authReducer` Values to Redux State
                dispatch(setIsAuthenticated(false))
                dispatch(setToken(null))
                dispatch(setUser({}))

                // Set `isValidating`
                dispatch(setIsValidating(false))

                // Set Error Msg
                dispatch(setError(err?.response?.data?.message ?? err?.message ?? 'Something went wrong.'))

                // Show Error Alert
                showAlert('error', err?.response?.data?.message ?? err?.message ?? 'Something went wrong.')
            })
    }
}

// Logout Action
export function logout() {
    return dispatch => {
        // Set `isValidating`
        dispatch(setIsValidating(true))

        // Clear localStorage
        localStorage.clear()

        // Dispatch `authReducer` Values to Redux State
        dispatch(setIsAuthenticated(false))
        dispatch(setToken(null))
        dispatch(setUser({}))

        // Set `isValidating`
        dispatch(setIsValidating(false))
    }
}

// User Validation
export function validateUser(token) {
    return dispatch => {
        // Set `isValidating`
        dispatch(setIsValidating(true))

        axios.get(AUTH.GET_USER, { headers: { Authorization: `Bearer ${token}` } })
            .then(res => {
                if (res?.data?.data) {
                    const permissions = res?.data?.permissions
                    const user = res.data.data
                    // If no permission is provided
                    if (Object?.entries(permissions)?.length < 1) {
                        logout()
                        dispatch(setIsValidating(false))
                        showAlert('error', 'You are not allowed to enter')
                        return []
                    }

                    // Save `token` & `user` to localStorage
                    localStorage.setItem('token', token)
                    localStorage.setItem('user', JSON.stringify(user))

                    // Dispatch authReducer Values to Redux State
                    dispatch(setIsAuthenticated(true))
                    dispatch(setToken(token))
                    dispatch(setUser(user))
                    dispatch(setAllowedPermission(permissions ?? []))

                    // setting allowed permission list for the current user
                    dispatch(setAllowedPermission(permissions ? permissions : []))

                    // Set axios default token
                    axios.defaults.headers.common.Authorization = `Bearer ${token}`

                    // Get Group List
                    dispatch(getOrgList())

                    // Get Verifiers
                    dispatch(getVerifiers())

                    // Get Users
                    dispatch(getUsers())

                    // Set `isValidating`
                    dispatch(setIsValidating(false))

                } else {
                    // Set Error Msg
                    dispatch(setError(res?.data?.message ?? 'User Not Found'))
                    // Show Error Alert
                    showAlert('error', res?.data?.message ?? 'User Not Found')

                    // Set `isValidating`
                    dispatch(setIsValidating(false))

                    throw new Error('User Not Found')
                }

            })
            .catch(err => {
                console.error(err)

                // Dispatch authReducer Values to Redux State
                dispatch(setIsAuthenticated(false))
                dispatch(setToken(null))
                dispatch(setUser({}))
                dispatch(setAllowedPermission([]))

                // Set `isValidating`
                dispatch(setIsValidating(false))

                // Set Error Msg
                dispatch(setError(err?.response?.data?.message ?? err?.message ?? 'Something went wrong.'))

                const withoutAlertRoutes = ['/', '/login', '/verify-app-privacy']

                // Show Error Alert
                if (!(withoutAlertRoutes.includes(window.location.pathname))) {
                    showAlert('error', err?.response?.data?.message ?? err?.message ?? 'Something went wrong.')
                }
            })
    }
}

// User Registration
export const register = (params, clearForm) => {
    return async (dispatch) => {
        //  Start Loading
        dispatch(setIsLoading(true))

        try {
            const response = await axios.post(AUTH.REGISTER, params)
            //  Check if response is valid
            if (response.status === 200) {
                //  Show success alert
                showAlert('success', 'User has been Registered!!', 3.0)
                // clear form
                clearForm()
            }
            //  loader stopped
            dispatch(setIsLoading(false))
        } catch (error) {
            // Stop Loading
            dispatch(setIsLoading(false))

            // Error Data
            let errorData = typeof error?.response?.data?.message === 'string' ? JSON.parse(error?.response?.data?.message)?.message : error?.response?.data?.message

            if (errorData?.email?.[0]) {
                showAlert('error', errorData.email[0] ?? 'Something went wrong.', 3.0)
            }
            if (errorData?.phone?.[0]) {
                showAlert('error', errorData.phone[0] ?? 'Something went wrong.', 3.0)
            }
            if (errorData?.password?.[0]) {
                showAlert('error', errorData.password[0] ?? 'Something went wrong.', 3.0)
            }
        }
    }
}

// Update Password
export const updatePassword = (params) => {
    return async (dispatch) => {
        try {
            const response = await axios.post(AUTH.UPDATE_PASSWORD, params)
            if (response.status === 200) {
                console.log(response.data?.message)
                if (response?.data?.message) {
                    // setTimeout(() => {
                    //     // Logout
                    //     dispatch(logout());
                    // }, 1000);
                    showAlert('success', response?.data?.message)
                    return 'success'
                }
            }
        } catch (error) {
            showAlert('error', error?.response?.data?.message ?? error?.message ?? 'Something went wrong.')
            return error
        }
    }
}
