import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

// Import Library Components
import {
  Card,
  Table,
  Space,
  Button,
  Popover,
  Select,
  Popconfirm,
} from "antd";
import {
  LoadingOutlined,
} from "@ant-design/icons";


// Import Actions
import { getReportsByInput } from "../Services/Actions/reportsActions";
import {
  assignRequestedTripToVerifier,
  getRequestedTrips,
  postApproveTripRequest,
  postCancelTripRequest,
} from "../Services/Actions/requestedTripsActions";
import { getVerifiers } from "../Services/Actions/commonActions";

class RequestedTrips extends React.PureComponent {
  state = {
    columns: [
      {
        title: "Address",
        dataIndex: "address",
        key: "address",
      },
      {
        title: "Requested By",
        dataIndex: "requested_by",
        key: "requested_by",
      },
      {
        title: "Reference Trip ID",
        dataIndex: "reference_trip_id",
        key: "reference_trip_id",
      },
      {
        title: "Type",
        dataIndex: "type",
        key: "type",
      },
      {
        title: "Comment",
        dataIndex: "comment",
        key: "comment",
      },
      {
        title: "Actions",
        key: "actions",
        render: (text, record) => {
          const { verifiers } = this.props;

          const confirmApprove = (e) => {
            // console.log(e);
            this._handleApprove(e, record);
            this._getReports(1, true);
          };

          const confirmCancel = (e) => {
            // console.log(e);
            this._handleCancelRequest(e, record);
            this._getReports(1, true);
          };

          return (
            <Space direction={"horizontal"} size={"small"}>
              {[
                <div>
                  <Popconfirm
                    title={`Approve Trip Request with Trip ID ${record.reference_trip_id} by ${record.requested_by}? `}
                    description="Are you sure to approve this task?"
                    onConfirm={confirmApprove}
                    okText="Yes"
                    cancelText="No"
                  >
                    <Button size={"small"} type="primary">
                      Approve
                    </Button>
                  </Popconfirm>

                  <Popconfirm
                    title={`Cancel Trip Request with Trip ID ${record.reference_trip_id} by ${record.requested_by}? `}
                    description="Are you sure to approve this task?"
                    onConfirm={confirmCancel}
                    okText="Yes"
                    cancelText="No"
                  >
                    <Button size={"small"} type="primary" danger>
                      Cancel
                    </Button>
                  </Popconfirm>
                  <Popover
                    trigger={["click"]}
                    placement={"bottom"}
                    title={"Reassign Verifier"}
                    content={
                      <div style={{ width: "224px" }}>
                        <Select
                          style={{ width: "100%" }}
                          showSearch
                          allowClear
                          placeholder={"Select a verifier"}
                          onChange={this._handleVerifierChange}
                          // value={selectedVerifier?.id ?? ""}
                          filterOption={(input, option) =>
                            option.children
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          }
                          optionFilterProp={"children"}
                        >
                          {verifiers?.length > 0 &&
                            verifiers.map((item, index) => {
                              return (
                                <Select.Option key={index + 1} value={item.id}>
                                  {item.name}
                                </Select.Option>
                              );
                            })}
                        </Select>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "flex-end",
                            width: "100%",
                            marginTop: "0.4rem",
                          }}
                        >
                          <Button
                            type={"primary"}
                            onClick={(e) =>
                              this._handleAssignVerifier(e, record)
                            }
                            // loading={isAssignLoading}
                          >
                            Reassign
                          </Button>
                        </div>
                      </div>
                    }
                  >
                    <Button
                      size={"small"}
                      type={"link"}
                      // disabled={
                      //   selectedTrip?.status === 6 || selectedTrip?.status === 7
                      // }
                    >
                      Reassign
                    </Button>
                  </Popover>
                </div>,
              ]}
            </Space>
          );
        },
      },
    ],
    startDate: null,
    endDate: null,
    selectedGroup: null,
    selectedCity: null,
    inputValue: "",
    visible: false,
    loading: false,
    openingApplicationID: 0,
    file_no: 0,
    currentApplicationData: null,
    selectedVerifier: null,
  };

  componentDidMount = () => {
    // Get Reports
    this._getReports(1, true);
    getVerifiers();
  };

  // Handle Verifier Change
  _handleVerifierChange = (value) => {
    const { verifiers } = this.props;
    if (verifiers?.length > 0) {
      const selectedVerifier = verifiers.find(
        (verifier) => verifier.id === value
      );
      this.setState({ selectedVerifier: selectedVerifier ?? {} });
    }
  };

  // componentDidUpdate(prevProps, prevState){
  //     const { reqTrips, dispatch} = this.props

  //     if (!_isEqual(prevProps.reqTrips.length, reqTrips.length) ) {
  //         console.log("hit");
  //         this._getReports(1, true)
  //     }

  // }

  // Handle on submit
  // _handleSubmit = (startDate, endDate, selectedGroup, selectedCity) => {
  //     this.setState({
  //         startDate,
  //         endDate,
  //         selectedGroup,
  //         selectedCity,
  //         inputValue: '',
  //     })
  //     setTimeout(() => {
  //         this._getReports(1, false)
  //     }, 100)
  // }

  // // Get Params
  // _getParams = page => {
  //     const { startDate, endDate, selectedGroup, selectedCity } = this.state

  //     const formatedStartDate = startDate ? moment(startDate).format('YYYY-MM-DD') : moment(new Date()).format('YYYY-MM-DD')
  //     const formatedEndDate = endDate ? moment(endDate).format('YYYY-MM-DD') : moment(new Date()).format('YYYY-MM-DD')

  //     // set params
  //     let params = { dateFrom: formatedStartDate, dateTill: formatedEndDate, page: page ?? 1 }
  //     if (selectedGroup && selectedGroup?.uuid !== -1) {
  //         params = { ...params, org_id: selectedGroup.uuid }
  //     }

  //     if (selectedCity && selectedCity?.id !== -1) {
  //         params = { ...params, city: selectedCity.value }
  //     }
  //     return params
  // }

  // Get Reports
  _getReports = (page, key) => {
    const { dispatch, reqTrips } = this.props;

    // get params
    // const params = this._getParams(page)

    // dispatch action
    // dispatch( getReportsByDateRange(params, key) )
    dispatch(getRequestedTrips());
    // console.log("reqTrips", reqTrips);
  };

  // Handle Search
  // _handleSearch = (value) => {
  //     const { dispatch } = this.props
  //     dispatch( getReportsByInput({ q: value }) )

  //     // Set value to state
  //     this.setState({ inputValue: value })
  // }

  // Hanlde Pagination
  _handlePagination = (page, key, path) => {
    const { inputValue } = this.state;
    const { dispatch } = this.props;

    // Check if the path end with search
    if (path && path.endsWith("search")) {
      dispatch(getReportsByInput({ q: inputValue, page: page ?? 1 }));
    } else {
      this._getReports(page, key);
    }
  };

  //  Handle Approve
  _handleApprove = (e, record) => {
    const { dispatch } = this.props;

    const params = {
      tripId: record?.id,
      status: "1",
    };

    dispatch(postApproveTripRequest(params));
    this._getReports(1, true);
  };

  //  Handle Cancel Request
  _handleCancelRequest = (e, record) => {
    const { dispatch } = this.props;

    const params = {
      tripId: record?.id,
      verifierId: 1,
      status: "2",
    };

    dispatch(postCancelTripRequest(params));
    this._getReports(1, true);
  };

  // @desc - Assign Requested Trip Another Verifer
  _handleAssignVerifier = (e, record) => {
    const { selectedVerifier } = this.state;
    const { dispatch } = this.props;

    const params = {
      tripId: record?.id,
      verifierId: selectedVerifier?.id,
      status: "1",
    };

    dispatch(assignRequestedTripToVerifier(params));
    this._getReports(1, true);
  };

  render() {
    const {
      reqTrips,
      isLoading,
      pendingListPegination,
      isSubmitButtonLoading,
      isSearchButtonLoading,
    } = this.props;
    const { columns } = this.state;
    return (
      <div style={{ width: "100%" }}>
        <Card title={"Requested Trips"} style={{ width: "100%" }} hoverable>
          <Space
            direction={"vertical"}
            size={"middle"}
            style={{ width: "100%" }}
          >
            {/* <FilterWithDateOrg
                            showSearch={ true }
                            onSubmit={ this._handleSubmit }
                            isSubmitButtonLoading={ isSubmitButtonLoading }
                            isSearchButtonLoading={ isSearchButtonLoading }
                            showDate={true}
                            showOrg={ true }
                            showCity={ true }
                            handleSearch={ this._handleSearch }
                        /> */}
            <Table
              style={{ width: "100%" }}
              size={"small"}
              columns={columns}
              dataSource={reqTrips}
              bordered={true}
              loading={{
                indicator: <LoadingOutlined style={{ fontSize: 24 }} spin />,
                spinning: isLoading,
              }}
              pendingListPegination={{
                pageSize: pendingListPegination?.per_page,
                total: pendingListPegination?.total,
                pageSizeOptions: [10],
                showSizeChanger: false,
                showTotal: (total, range) =>
                  `${range[0]}-${range[1]} of ${total} files`,
                onChange: (page) => {
                  this._handlePagination(
                    page,
                    true,
                    pendingListPegination?.path
                  );
                },
                current: pendingListPegination?.current_page,
                size: "small",
              }}
            />
          </Space>
        </Card>
      </div>
    );
  }
}

// PropTypes
RequestedTrips.propTypes = {
  reports: PropTypes.array,
  isLoading: PropTypes.bool,
  pendingListPegination: PropTypes.object,
  dispatch: PropTypes.func,
  isSubmitButtonLoading: PropTypes.bool,
  isSearchButtonLoading: PropTypes.bool,
  showDownloadButton: PropTypes.bool,
  isDownloadButtonLoading: PropTypes.bool,
  isBankReportLoading: PropTypes.bool,
  isDeleteButtonLoading: PropTypes.bool,
  selectedApplication: PropTypes.object,
  isStatusUpdateModalOpen: PropTypes.bool,
};

RequestedTrips.defaultProps = {
  // reports: [],
  pendingList: [],
  isLoading: false,
  pendingListPegination: {},
  dispatch: () => null,
  isSubmitButtonLoading: false,
  isSearchButtonLoading: false,
  showDownloadButton: false,
  isDownloadButtonLoading: false,
  isBankReportLoading: false,
  isDeleteButtonLoading: false,
  selectedApplication: null,
  isStatusUpdateModalOpen: false,
};

const mapStateToProps = (state) => ({
  isLoading: state.reports.isLoading,
  // reports: state.reports.reports,
  reqTrips: state?.requestedTrips?.reqTrips ?? [],
  pendingListPegination: state.pendingList.pendingListPegination,
  verifiers: state.common?.verifiers ?? [],
  isSubmitButtonLoading: state.reports.isSubmitButtonLoading,
  isSearchButtonLoading: state.reports.isSearchButtonLoading,
  showDownloadButton: state.reports.showDownloadButton,
  isDownloadButtonLoading: state.reports.isDownloadButtonLoading,
  isBankReportLoading: state.reports.isBankReportLoading,
  isDeleteButtonLoading: state.reports.isDeleteButtonLoading,
  selectedApplication: state.reports.selectedApplication,
  isStatusUpdateModalOpen: state.reports.isStatusUpdateModalOpen,
  reloadModal: state.reports.reloadModal,
});

const mapDispatchToProps = (dispatch) => ({ dispatch });

export default connect(mapStateToProps, mapDispatchToProps)(RequestedTrips);
