import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Card, Row, Col, Segmented, Spin } from 'antd'
import CreateFileFormOCR from './CreateFileFormOCR'
import { resetState } from '../../../../../Services/Reducers/createFileReducer'
import OcrDocuments from '../OCRDocuments'
import MapViewCF from '../../MapViewCF'
import ImageGalary from './ImageGalary'

const OCRDocumentCreateFile = ({ dispatch, allowedPermission, isLoading, ...props }) => {
    const [menu, setMenu] = useState(['Documents', 'Map View', 'Images'])
    const [selectedMenu, setSelectedMenu] = useState('Documents')

    const _handleMenuOnChange = (value) => {
        setSelectedMenu(value)
    }

    useEffect(() => {
        return () => {
            dispatch(resetState())
        }
    }, [dispatch])

    if(isLoading) return <Spin  size="large" style={{ position: 'absolute', top: '90%', left: '50%', transform: 'translate(-50%, -50%)' }} />

    return (
        <div style={container}>
            <Row gutter={[12, 8]} style={rowContainer}>
                <Col span={14} xs={24} lg={24} xl={12} style={colContainer}>
                    <Card title={props?.selectedOrgData?.name}>
                        <CreateFileFormOCR 
                        selectedOrgData={props.selectedOrgData} 
                        stationOptions={props.stationOptions} 
                        setSelectedRow={props.setSelectedRow}
                        imagesObj={props.imagesObj}
                        />
                    </Card>
                </Col>
                <Col span={10} xs={24} lg={24} xl={12} style={colContainer}>
                    <Card title={'Documents & Map View'} style={cardWrapperStyles}>
                        <Segmented
                            options={menu}
                            value={selectedMenu}
                            onChange={_handleMenuOnChange}
                        />
                        {selectedMenu === 'Documents' && <OcrDocuments />}
                        {selectedMenu === 'Map View' && <MapViewCF />}
                        {selectedMenu === 'Images' && <ImageGalary images={props.imagesObj} />}
                    </Card>
                </Col>
            </Row>
        </div>
    )
}

// Styles
const container = {
    borderRadius: '4px',
}

const rowContainer = {
    height: '100%',
}

const colContainer = {
    background: 'transparent',
    width: '100%',
}

const cardWrapperStyles = {
    width: '100%',
    position: 'sticky',
    top: '80px',
}

// PropTypes
OCRDocumentCreateFile.propTypes = {
    dispatch: PropTypes.func,
    allowedPermission: PropTypes.object
}

OCRDocumentCreateFile.defaultProps = {
    dispatch: () => null,
    allowedPermission: null
}

// Map State To Props
const mapStateToProps = state => ({
    allowedPermission: state?.permission?.allowedPermission ?? null,
    isLoading: state?.createFile?.OCR?.processing ?? false
})

// Map Dispatch To Props
const mapDispatchToProps = dispatch => ({ dispatch })

export default connect(mapStateToProps, mapDispatchToProps)(OCRDocumentCreateFile)