import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

// Import Components
import { Space, Select, Button, Modal } from 'antd'

// Import Actions, and Reducers
import { deleteOrganization } from '../../../Services/Actions/adminPanelActions'
import { showAlert } from '../../../Services/Actions/commonActions'
import { setSelectedOrgDeleteOrg } from '../../../Services/Reducers/adminPanelReducer'

const { confirm } = Modal

class DeleteOrg extends React.PureComponent {
    // Handle Modal
    _handleModal = () => {
        const { selectedOrgDeleteOrg, isDeleteOrgLoading, dispatch } = this.props

        // Check if Selected Org is Empty
        if (!selectedOrgDeleteOrg || !selectedOrgDeleteOrg.uuid) {
            // Show Error Alert
            showAlert('error', 'Please Select Organization')
            return
        }

        confirm({
            title: 'Are you sure delete this organization?',
            content: `"${selectedOrgDeleteOrg?.name ?? 'N/A'}" will be deleted permanently.`,
            okText: 'Yes',
            okType: 'danger',
            cancelText: 'No',
            onOk: () => {
                dispatch(deleteOrganization({ group_id: selectedOrgDeleteOrg?.uuid }))
            },
            okButtonProps: {
                loading: isDeleteOrgLoading,
            },
        })
    }

    // Handle Selected Org On Change
    _handleSelectOrg = value => {
        const { dispatch, orgList } = this.props

        // Check if value is empty or undefined or null
        if (!value) {
            // Set Selected Org to Empty Object
            dispatch(setSelectedOrgDeleteOrg({}))
            return
        }
        // Find Selected Org
        const selectedOrg = orgList.find(item => item.uuid === value)

        // If Selected Org Not Exist
        if (!selectedOrg) {
            // Set Selected Org to Empty Object
            dispatch(setSelectedOrgDeleteOrg({}))

            // Show Error Alert
            showAlert('error', 'Selected Organization Not Found')
            return
        }
        // Set Selected Org
        dispatch(setSelectedOrgDeleteOrg(selectedOrg))
    }
    render() {
        const { orgList, selectedOrgDeleteOrg } = this.props
        const _orgList = orgList.filter(item => item.id !== -1)

        return (
            <Space direction={'vertical'} style={{ width: '100%' }}>
                <Select
                    style={{ width: '100%' }}
                    showSearch
                    allowClear
                    placeholder={'Select Organization'}
                    onChange={this._handleSelectOrg}
                    value={selectedOrgDeleteOrg?.uuid ?? null}
                    filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                    optionFilterProp={'children'}
                >
                    {
                        _orgList?.length > 0 && _orgList.map((item, index) => {
                            return (
                                <Select.Option key={index + 1} value={item.uuid}>{item.name}</Select.Option>
                            )
                        })
                    }
                </Select>
                <div style={{ float: 'right' }}>
                    <Button type={'primary'} danger onClick={this._handleModal}>Delete</Button>
                </div>
            </Space>
        )
    }
}

// Props Validation
DeleteOrg.propTypes = {
    dispatch: PropTypes.func,
    orgList: PropTypes.array,
    selectedOrgDeleteOrg: PropTypes.object,
    isDeleteOrgLoading: PropTypes.bool,
}

DeleteOrg.defaultProps = {
    dispatch: () => null,
    orgList: [],
    selectedOrgDeleteOrg: {},
    isDeleteOrgLoading: false,
}

// Map State To Props
const mapStateToProps = state => ({
    orgList: state.common.orgList,
    selectedOrgDeleteOrg: state.adminPanel.selectedOrgDeleteOrg,
    isDeleteOrgLoading: state.adminPanel.isDeleteOrgLoading,
})

// Map Dispatch To Props
const mapDispatchToProps = dispatch => ({ dispatch })

export default connect(mapStateToProps, mapDispatchToProps)(DeleteOrg)