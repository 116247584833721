import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

// Import Configs
import { BROKEN_IMG } from '../../../Config/config'

// Import Components
import { Card, Row, Col, Segmented, Image, Typography, Modal, Divider } from 'antd'
import DescriptionItem from '../../Common/DescriptionItem'
import { ConcernDetailsHeader, ConcernModal } from '../Application'

// Import Icons
import { CloseOutlined, EditFilled } from '@ant-design/icons'

// Import Actions, and Reducers
import { deactivateConcern } from '../../../Services/Actions/applicationActions'
import {openTripUpdateModal} from '../../../Services/Actions/commonActions'
import { isAllowedToAccess } from '../../../Services/Actions/permissionActions'
import { setSelectedConcern, setSelectedTrip, setIsUpdateConcernModalOpen, setPrevSegmentConcernID, setPrevSegmentTripID } from '../../../Services/Reducers/applicationReducer'

// Import Constants
const { confirm } = Modal

class MetaConcernDetails extends React.PureComponent {

    componentDidUpdate(){
        const { prevSegmentTripID, prevSegmentConcernID, selectedConcern, applicationDetails, dispatch } = this.props

        // checkng prevSegmentConcernID exist and currently selectedConcern is not same as previous cycle then match Id to show  the previously selected concern segment tab 
        if(prevSegmentConcernID && selectedConcern?.id !== prevSegmentConcernID){

            // Find previously selected concern
            const selectedMatchConcern = applicationDetails?.concerns?.length > 0 && applicationDetails.concerns.find(concern => concern.id === prevSegmentConcernID)

            // Set selected concern to prreviously selected one
            dispatch(setSelectedConcern(selectedMatchConcern ?? {}))

            // Set selected trip. if previously selected then match it as previously selected one. if previously no trip selected select fiirst one
            const selectedMatchedTrip = selectedMatchConcern?.trips?.length > 0 && ( prevSegmentTripID ? selectedMatchConcern?.trips?.find(item => item?.id === prevSegmentTripID) : selectedMatchConcern?.trips[0])

            // setting rip with it's application id, -- application id  is necessary while ssetting trip
            const selectedTripWithApplicationID = {...selectedMatchedTrip, application_id: selectedMatchConcern.application_id}

            dispatch(setSelectedTrip(selectedTripWithApplicationID ?? {}))

            this._handleUpdateTrip()
        }

    }

    // Hanlde Concern Id Change
    _handleConcernIdChange = (value) => {
        const { dispatch, applicationDetails } = this.props       

        // Find selected concern
        const selectedConcern = applicationDetails?.concerns?.length > 0 && applicationDetails.concerns.find(concern => concern.id === value)

        // Set selected concern and it's id  for next state cycle for default selected concern 
        dispatch(dispatch => {
            dispatch(setPrevSegmentConcernID(value))
            dispatch(setPrevSegmentTripID(0))
            dispatch(setSelectedConcern(selectedConcern ?? {}))
        })
        
        // Set selected initial trip id- if concern change, by default first trip is selected for trip details segment tab section
        const selectedTrip = selectedConcern?.trips?.length > 0 && selectedConcern.trips[0]

        // setting rip with it's application id, -- application id  is necessary while ssetting trip
        const selectedTripWithApplicationID = {...selectedTrip, application_id: selectedConcern.application_id}


        // settng current trip for actions and it's id  for next state cycle for default selected trip 
        dispatch(dispatch => {
            dispatch(setSelectedTrip(selectedTripWithApplicationID ?? {}))
            dispatch(setPrevSegmentTripID(selectedTripWithApplicationID?.id))

        })

        this._handleUpdateTrip()
    }

      //extract all trips including the selected trip
      _extractAllTripsFromApplication(selectedTrip, application){
        let allTrips = []
        application?.concerns?.forEach(item => {
            allTrips = [...allTrips, ...item.trips.map(trip => { return {...trip, application_id: application?.id}})] 
        })
    
        return allTrips
    }


     // Handle Update Trip for Map in application details panel
     _handleUpdateTrip = () => {
        const { dispatch, selectedTrip, applicationDetails } = this.props

        // otherTrip => all trips including selected trip 
        const allTripOfApplication = this._extractAllTripsFromApplication(selectedTrip, applicationDetails)

        // dispatching to show only selected trip marker and verifier in map
        dispatch(openTripUpdateModal(selectedTrip, allTripOfApplication))  
    }


    // Handle Update Concern
    _handleUpdateConcernModal = type => {
        // Update Concern
        const { dispatch } = this.props

        // Check if type is available
        if (!type) {
            return
        }

        // Check if type === open
        if (type === 'open') {
            // Set is update concern modal open
            dispatch(setIsUpdateConcernModalOpen(true))
        }

        // Check if type === close
        if (type === 'close') {
            // Set is update concern modal open
            dispatch(setIsUpdateConcernModalOpen(false))
        }
    }

    // Handle Deactivate Concern
    _handleDeactivateConcern = () => {
        const { selectedConcern, isDeactivateLoading, dispatch } = this.props
        // Deactivate Concern
        confirm({
            title: 'Are you sure delete this concern?',
            content: `"${selectedConcern?.name ?? 'N/A'}" will be deactivated.`,
            okText: 'Yes',
            okType: 'danger',
            cancelText: 'No',
            onOk: () => {
                dispatch(deactivateConcern(selectedConcern))
            },
            okButtonProps: {
                loading: isDeactivateLoading,
            },
        })
    }

    _getMetaDataAsComponent = (metaObj) => {
        if(!metaObj){
            return
        }
        const dataList = []
        for (const [key, value] of Object.entries(metaObj)) {
            dataList.push( <DescriptionItem title={ key[0].toUpperCase() + key.slice(1) } content={ value } /> )
        }
        return dataList
    }

    render() {
        const { applicationDetails, selectedConcern, isUpdateConcernModalOpen, allowedPermission } = this.props
        return (
            <Card 
                title={ 
                    <ConcernDetailsHeader 
                    isPostActionAllowed={ 
                        // PERMISSION ID OF "reports.reports.concern.add" is 20
                        isAllowedToAccess( allowedPermission ,{  menuName: 'reports', subMenuName: 'reports', permissionID: '20' } ) ? true : false
                    } 
                    /> 
                } 
                hoverable style={{ height: '100%' }} 
                >
                <Row gutter={[ 12, 8 ]}>
                    <Col span={ 24 }>
                        <Segmented 
                            options={
                                applicationDetails?.concerns?.length > 0 && applicationDetails?.concerns.map(concern => ({
                                    value: concern?.id,
                                    label: concern?.type
                                }))
                            }
                            value={ selectedConcern?.id }
                            onChange={ this._handleConcernIdChange }
                        />
                    </Col>
                    <Col span={ 24 }>
                        <Row align={ 'bottom' } style={{ border: '1px solid #f0f0f0' }}>
                            <Col xs={ 24 } md={ 24 } style={{ textAlign: 'center' }}>
                                <Image 
                                    preview={ true }
                                    fallback={ BROKEN_IMG }
                                    width={ '70%' }
                                    height={ '70%' }
                                    src={ selectedConcern?.image ?? 'error' }
                                    style={{
                                        maxWidth: '300px',
                                        maxHeight: '300px',
                                        display: 'block',
                                        margin: '0 auto'
                                    }}
                                />
                                <Divider />
                            </Col>
                            <Col xs={ 24 } md={ 16 }>
                                <Card
                                    bordered={ false }
                                    actions={
                                        [   
                                            // PERMISSION ID OF "reports.reports.concern.delete" is 21
                                            isAllowedToAccess( allowedPermission ,{  menuName: 'reports', subMenuName: 'reports', permissionID: '21' } ) &&
                                            <CloseOutlined 
                                                key='deactivate_concern'
                                                onClick={ this._handleDeactivateConcern }
                                            />,

                                            // PERMISSION ID OF "reports.reports.concern.update" is 19
                                            isAllowedToAccess( allowedPermission ,{  menuName: 'reports', subMenuName: 'reports', permissionID: '19' } ) &&
                                            <EditFilled
                                                key='edit_concern'
                                                onClick={ () => this._handleUpdateConcernModal('open') }
                                            />,
                                        ]
                                    }
                                    style={{ height: '100%' }}
                                >       
                                    <Card.Meta
                                        title={
                                            <Typography.Text level={ 4 } strong copyable>
                                                Name : { selectedConcern?.name }
                                            </Typography.Text>
                                        }
                                        description={
                                            <>
                                                <DescriptionItem title={ 'Designation' } content={ selectedConcern?.meta?.designation } />
                                                <DescriptionItem 
                                                    title={ 'Phone' } 
                                                    content={
                                                        <Typography.Text copyable>
                                                            { selectedConcern?.phone }
                                                        </Typography.Text>
                                                    }
                                                />
                                                {
                                                    this._getMetaDataAsComponent(selectedConcern?.meta)
                                                }
                                                {/* <DescriptionItem title={ 'Bank Account Name' } content={ selectedConcern?.meta?.bank_account_name } /> */}
                                            </>                                                
                                        }
                                    />
                                </Card>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                {
                    isUpdateConcernModalOpen && (
                        <ConcernModal
                            modalTitle={ 'Update Concern' }
                            modalType={ 'concern_update' }
                            modalOnClose={ () => this._handleUpdateConcernModal('close') }
                            isConcernModalOpen={ isUpdateConcernModalOpen }
                        />
                    )
                }
            </Card>
        )
    }
}

// Props Validation
MetaConcernDetails.propTypes = {
    dispatch: PropTypes.func,
    applicationDetails: PropTypes.object,
    selectedConcern: PropTypes.object,
    isUpdateConcernModalOpen: PropTypes.bool,
    isDeactivateLoading: PropTypes.bool,
    prevSegmentConcernID: PropTypes.number,
    allowedPermission: PropTypes.object,
}

MetaConcernDetails.defaultProps = {
    dispatch: () => null,
    applicationDetails: null,
    selectedConcern: {},
    isUpdateConcernModalOpen: false,
    isDeactivateLoading: false,
    prevSegmentConcernID: 0,
    allowedPermission: null
}

// Map State To Props
const mapStateToProps = (state) => ({
    applicationDetails: state.application.applicationDetails,
    prevSegmentConcernID: state.application.prevSegmentConcernID,
    prevSegmentTripID: state.application.prevSegmentTripID,
    selectedConcern: state.application.selectedConcern,
    isUpdateConcernModalOpen: state.application.isUpdateConcernModalOpen,
    isDeactivateLoading: state.application.isDeactivateLoading,
    selectedTrip: state.application.selectedTrip,
    allowedPermission: state?.permission?.allowedPermission ?? null
})

// Map Dispatch To Props
const mapDispatchToProps = (dispatch) => ({ dispatch })

export default connect(mapStateToProps, mapDispatchToProps)(MetaConcernDetails)