import React from 'react';
// Import Components
import { Space, Layout, Typography } from 'antd'

// Import Actions
import { formatDateIntoFullDateAndTime } from '../../../Services/Actions/commonActions';

// Import Constants
const { Content } = Layout;
const { Text } = Typography;

const TimelineItem = ({ data, trip }) => {
  const taskId = data?.task_id
  const message = data?.issue || data?.reason
  const appointmentTime = data?.appointment_time
  const tripType = trip?.[0]?.type ?? ''

  return (
    <Layout style={completeLayoutStyle}>
      {/* appointmentTime ? activeLayoutStyle : completeLayoutStyle */}
      <Content style={completeContentStyle}>
        {/* appointmentTime ? activeContentStyle : completeContentStyle */}
        <Space size={5} direction='vertical'>
          <Text style={{ color: '#3e774a' }}>{`Appointment Date - ${formatDateIntoFullDateAndTime(appointmentTime) ?? ''}`}</Text>
          <Space>
            <Text>Task Id: {taskId} - {tripType ?? ''}</Text>
          </Space>
          <Text>{`Reason: ${message}`}</Text>
        </Space>
      </Content>
    </Layout>
  );
};

// Styles
const completeLayoutStyle = { border: '1px solid #ffbcbc', backgroundColor: '#FFFFFF' }
const activeLayoutStyle = { border: '1px solid #68c97d', backgroundColor: '#FFFFFF' }
const completeContentStyle = { backgroundColor: '#ffdbdb', margin: '5px', padding: '5px', fontSize: '12px' }
const activeContentStyle = { backgroundColor: '#ceffd9', margin: '5px', padding: '5px', fontSize: '12px' }

export default TimelineItem;