import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

// Import Components
import { Typography, Row, Col, Button, Tooltip } from 'antd'
import { ConcernModal } from '../Application'

// Import Icons
import { PlusOutlined } from '@ant-design/icons'

// Import Actions
import { setIsAddNewConcernModalOpen } from '../../../Services/Reducers/applicationReducer'

class ConcernDetailsHeader extends React.PureComponent {
    // Handle Open Concern Add Modal
    _handleOpenConcernAddModal = type => {
        const { dispatch } = this.props
        // Check if  type is invalid
        if(!type) {
            return
        }

        // Check if type === open
        if(type === 'open') {
            dispatch(setIsAddNewConcernModalOpen(true))
            return
        }

        // Check if type === close
        if(type === 'close') {
            dispatch(setIsAddNewConcernModalOpen(false))
            return
        }
        return
    }
    render() {
        const { isAddNewConcernModalOpen, isPostActionAllowed } = this.props
        
        return (
            <div>
                <Row gutter={[ 12, 12 ]}>
                    <Col span={ 12 }>
                        <Typography>
                            { 'Concern Details' }
                        </Typography>
                    </Col>
                    {
                        isPostActionAllowed &&
                        <Col span={ 12 }>
                            <div style={{ float: 'right' }}>
                                <Tooltip title={ 'Add a new concern' } placement={ 'topRight' }>
                                    <Button 
                                        type={ 'primary' } 
                                        icon={ <PlusOutlined /> } 
                                        onClick={ () => this._handleOpenConcernAddModal('open') } 
                                    />
                                </Tooltip>
                            </div>
                        </Col>
                    }
                </Row>
                {
                    isAddNewConcernModalOpen && (
                        <ConcernModal
                            modalTitle={ 'Add New Concern' }
                            modalType={ 'new_concern' }
                            modalOnClose={ () => this._handleOpenConcernAddModal('close') }
                            isConcernModalOpen={ isAddNewConcernModalOpen }
                        />
                    )
                }
            </div>
        )
    }
}

// Props Validation
ConcernDetailsHeader.propTypes = {
    dispatch: PropTypes.func,
    selectedConcern: PropTypes.object,
    isAddNewConcernModalOpen: PropTypes.bool,
    isPostActionAllowed: PropTypes.bool
}

ConcernDetailsHeader.defaultProps = {
    dispatch: () => null,
    selectedConcern: {},
    isAddNewConcernModalOpen: false,
    isPostActionAllowed: true
}

// Map State To Props
const mapStateToProps = state => ({
    selectedConcern: state.application.selectedConcern,
    isAddNewConcernModalOpen: state.application.isAddNewConcernModalOpen,
})

// Map Dispatch To Props
const mapDispatchToProps = dispatch => ({ dispatch })

export default connect(mapStateToProps, mapDispatchToProps)(ConcernDetailsHeader)