// **************
// PROPS through componens
// isSuggestionBoxOpenProp -> boolean ->  true to show suggestion box for exact address -> default true
// getExactAddress -> function
// **************
import React from 'react'
import PropTypes from 'prop-types'
import parse from 'autosuggest-highlight/parse'
import match from 'autosuggest-highlight/match'

// Import Components
import { Button, Card, Input, Select, Tooltip } from 'antd'

// Import Actions
import { getRupantorAddress, getAutoCompleteAddress } from '../../Services/Actions/commonActions'

// Import Icons
import { CloseOutlined } from '@ant-design/icons'

class ExactAddressAutocomplete extends React.PureComponent {
    state = {
        addressList: [],
        selectedSearchOption: 'default',
        searchInput: '',
    }

    componentDidMount = () => {
        const { input } = this.props
        this.setState({
            searchInput: input ?? '',
        })
    }

    componentDidUpdate = (prevProps) => {
        const { searchInput } = this.state
        const { input } = this.props
        if (input !== prevProps.input) {
            this.setState({ searchInput: input ?? searchInput ?? '' })
        }
    }

    // Handle Search
    _handleSearch = e => {
        const { selectedSearchOption } = this.state
        const { getExactAddress, toggleSuggestionBox } = this.props
        const value = e.target.value


        if (value) {
            // Set input value
            this.setState({ searchInput: value })


            // Get Address
            const getAddress = () => {
                // Check selected search option
                if (selectedSearchOption !== 'default') {
                    getRupantorAddress(value)
                        .then(res => {
                            // Set Address List and open suggestion box
                            this.setState({
                                addressList: [res],
                            })
                            toggleSuggestionBox(true)
                        })
                }

                if (value !== '') {
                    // Get AutoComplete Address
                    getAutoCompleteAddress({ q: value })
                        .then(res => {
                            // console.log(res, 'res')
                            // Set Address List
                            this.setState({
                                addressList: res,
                            })
                            toggleSuggestionBox(true)
                        })
                }
                if (value === '') {
                    this.setState({ addressList: [] })
                }
            }
            getAddress()
        } else {
            this.setState({
                addressList: [],
                searchInput: value
            })

            // Set to form via getExactAddress prop
            getExactAddress('')
        }
    }


    // Handle AutoComplete Select
    _handleAutocompleteSelect = (e) => {
        const { getExactAddress, toggleSuggestionBox } = this.props
        const { addressList } = this.state
        const selectedAddress = addressList[e.currentTarget.value]

        console.log(selectedAddress, 'selectedAddress')
        console.log(e.currentTarget.value, 'e.currentTarget.value');
        console.log(addressList, 'addressList');
        // Check if value and option is valid
        if (selectedAddress?.address) {
            const address = {
                address: selectedAddress.address,
                area: selectedAddress.area,
                latitude: selectedAddress.latitude,
                longitude: selectedAddress.longitude,
                city: selectedAddress.city,
            }

            // Set address to search input and closse  ssuggestion box
            this.setState({
                searchInput: selectedAddress.address,
            })
            // calling parent func to close suggestion box
            toggleSuggestionBox(false)

            // Set to form via getExactAddress prop
            getExactAddress(address)
        }
    }

    // Handle Selected Search Option
    _handleSelectedSearchOption = value => {
        const { selectedSearchOption } = this.state
        // Check if selected search option has changed
        if (value !== selectedSearchOption) {
            // Set selected search option
            this.setState({ selectedSearchOption: value, addressList: [] })
        }
    }

    //closing suggestion box for exact address
    _closeSuggestion = () => {
        const { toggleSuggestionBox } = this.props

        toggleSuggestionBox(false)
    }



    render() {
        const { input, customWidth, isSuggestionBoxOpenProp, toggleSuggestionBox } = this.props
        const { selectedSearchOption, addressList, searchInput } = this.state

        console.log(input, 'input');
        console.log(searchInput, 'searchInput');

        return (
            <Input.Group compact layout={'horizontal'}>
                <Input.TextArea
                    style={{ width: customWidth ? customWidth : '100%' }}
                    placeholder={'Enter exact address..'}
                    rows={window.innerWidth > 768 ? 4 : 6}
                    onChange={this._handleSearch}
                    value={searchInput ? searchInput : input ? input : ''}
                />
                <br />
                {
                    addressList?.length > 0 && isSuggestionBoxOpenProp &&
                    <Card style={STYLE.suggestionBox} bodyStyle={{ padding: '5px 10px' }}>
                        <ul style={{ padding: '3px' }}>
                            <Tooltip placement="left" title={'Close Suggestion'}>
                                <Button onClick={this._closeSuggestion} icon={<CloseOutlined />} danger size={'small'} style={STYLE.closeButton}>
                                </Button>
                            </Tooltip>
                            {
                                addressList.map((address, idx) => {
                                    const matches = match(address.address, searchInput)
                                    const parts = parse(address.address, matches)

                                    return (
                                        <li
                                            style={STYLE.listItems}
                                            key={idx}
                                            value={idx}
                                            onClick={this._handleAutocompleteSelect}
                                        >
                                            {
                                                parts && parts.length > 0 &&
                                                parts.map((part, index) => (
                                                    <span
                                                        key={index}
                                                        value={'address'}
                                                        style={{ fontWeight: part.highlight ? 700 : 400 }}
                                                    >
                                                        {part.text}
                                                    </span>
                                                ))
                                            }
                                        </li>
                                    )
                                })
                            }
                        </ul>
                    </Card>
                }
                <br />
                <Select onChange={this._handleSelectedSearchOption} value={selectedSearchOption} style={{ width: '35%', minWidth: '130px' }} >
                    <Select.Option value={'default'}>{'Default'}</Select.Option>
                    <Select.Option value={'rupantor'}>{'Rupantor'}</Select.Option>
                </Select>
            </Input.Group>
        )
    }
}

const STYLE = {
    suggestionBox: {
        position: 'absolute',
        zIndex: 10,
        width: '99%'
    },
    closeButton: {
        position: 'absolute',
        right: '10px',
        margin: 0,
        backgroundColor: '#fff'
    },
    listItems: {
        listStyleType: 'none',
        borderBottom: '1px solid #f2f2f2',
        padding: '3px 0'
    }
}

// Props Validation
ExactAddressAutocomplete.propTypes = {
    isSuggestionBoxOpenProp: PropTypes.bool,
    toggleSuggestionBox: PropTypes.func,
    customWidth: PropTypes.string
}

ExactAddressAutocomplete.defaultProps = {
    isSuggestionBoxOpenProp: false,
    toggleSuggestionBox: () => null,
    customWidth: '100%'
}

export default ExactAddressAutocomplete