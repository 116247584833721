import React, { useState, useRef, useEffect } from "react";

const ImageZoomInOut = ({ imageUrl, scale }) => {
  // States
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const imageRef = useRef(null);

  // On Change Image and Scale
  useEffect(() => {
    const image = imageRef.current;
    let isDragging = false;
    let prevPosition = { x: 0, y: 0 };

    // Handle Mouse Down
    const _handleMouseDown = (e) => {
      isDragging = true;
      prevPosition = { x: e.clientX, y: e.clientY };
    };

    // Handle Mouse Move
    const _handleMouseMove = (e) => {
      if (!isDragging) return;
      const deltaX = e.clientX - prevPosition.x;
      const deltaY = e.clientY - prevPosition.y;
      prevPosition = { x: e.clientX, y: e.clientY };
      setPosition((position) => ({
        x: position.x + deltaX,
        y: position.y + deltaY,
      }));
    };

    // Handle Mouse Up
    const _handleMouseUp = () => {
      isDragging = false;
    };

    image?.addEventListener("mousedown", _handleMouseDown);
    image?.addEventListener("mousemove", _handleMouseMove);
    image?.addEventListener("mouseup", _handleMouseUp);

    return () => {
      image?.removeEventListener("mousedown", _handleMouseDown);
      image?.removeEventListener("mousemove", _handleMouseMove);
      image?.removeEventListener("mouseup", _handleMouseUp);
    };
  }, [imageRef, scale]);

  return (
    <div
      style={{
        backgroundColor: "#ffffff",
        position: "relative",
        overflow: "hidden",
        border: "1px solid #D9D9D9",
        width: "99%"
      }}
    >
      <img
        ref={imageRef}
        src={imageUrl}
        alt=""
        style={{
          width: "35vw",
          margin: "0 auto",
          height: "auto",
          transform: `scale(${scale}) translate(${position.x}px, ${position.y}px)`,
          cursor: "move",
        }}
        draggable={false}
      />
    </div>
  );
}

export default ImageZoomInOut;