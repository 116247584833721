import axios from 'axios'

// Import Actions and Reducer
import { setApplicationDetails, setApplicationDetailsLoading, setSelectedConcern, setSelectedTrip, setApplicationIssues, setApplicationTrips, setIsAddNewConcernModalOpen, setIsUpdateConcernModalOpen, setIsUpdateFileNoButtonLoading, setIsUpdateFileNoModalOpen, setIsFileUploading, setIsAddNewTripButtonLoading, setIsAddNewTripModalOpen, setIsDeactivateLoading, setIsUpdateStationLoading } from '../Reducers/applicationReducer'
import { showAlert, getAttachmentTypeLabel } from '../Actions/commonActions'

// Import Types
import { APPLICATION } from '../../Config/config'

// syncing concern with updated/api fetched data
const syncConcernWithUpdatedData = (prevConcern, newConcernData) => {
    const matchedConcern = newConcernData.filter(item => item.id === prevConcern?.id)
    if (matchedConcern.length > 0) {
        return matchedConcern[0]
    }
    return newConcernData[0]
}

// syncing trip with updated/api fetched data
const syncTripWithUpdatedData = (prevTrip, newConcernData) => {
    const syncedData = newConcernData?.trips.filter(item => item.id === prevTrip?.id)
    if (syncedData.length > 0) {
        return syncedData[0]
    }
    return newConcernData?.trips[0]
}

// Get Application Details
const getApplicationDetails = id => {
    return async (dispatch, getState) => {
        const { selectedTrip, selectedConcern } = getState().application

        dispatch(setApplicationDetailsLoading(true))
        try {
            const response = await axios.get(APPLICATION.GET_APPLICATIONS_DETAILS + id)

            const data = response?.data?.data?.application
            // Check if data is not valid
            if (!data) {
                // Set loading to false
                dispatch(setApplicationDetailsLoading(false))

                // Show Error Alert
                showAlert('error', 'Application not found')
                return
            }

            // Find Application Attachments
            const attachments = transformApplicationAttachments(data?.attachments ?? [])

            // Update Application Details
            dispatch(setApplicationDetails({
                ...data,
                attachments,
            }))

            // Check if concern is array and length is greater than 0
            if (data?.concerns && data?.concerns.length > 0) {
                const syncedConcern = syncConcernWithUpdatedData(selectedConcern, data?.concerns)

                // Set Selected Concern Id if exist choose selected one to sync with current concern tab in application panel
                dispatch(setSelectedConcern(selectedConcern?.id ? syncedConcern : data?.concerns[0]))

                // Check this concern has trips
                if (data?.concerns[0]?.trips && data?.concerns[0]?.trips.length > 0) {
                    // // syncing Selected Trip Id
                    const syncedTrip = syncTripWithUpdatedData(selectedTrip, syncedConcern)
                    let tripWithAddedApplicationID
                    // if there is no upddated trip data and also it is not synced 
                    if (!syncedTrip?.id) {
                        tripWithAddedApplicationID = {
                            ...data?.concerns[0]?.trips[0],
                            application_id: data.id
                        }
                    }
                    // if there upddated trip data and also it is synced 
                    if (syncedTrip?.id) {
                        tripWithAddedApplicationID = {
                            ...syncedTrip,
                            application_id: data.id
                        }
                    }
                    dispatch(setSelectedTrip(tripWithAddedApplicationID))
                }
            }

            // Get Application Issues and Trips
            const { trips, issues } = findIssues(data?.concerns)

            // Set Application Trips
            dispatch(setApplicationTrips(trips))

            // Set Application Issues
            dispatch(setApplicationIssues(issues))

            // Set loading to false
            dispatch(setApplicationDetailsLoading(false))
        } catch (error) {
            console.error(error)

            // Set loading to false
            dispatch(setApplicationDetailsLoading(false))

            // Show Error Alert
            showAlert('error', error?.response?.data?.message ?? error?.message ?? 'Something went wrong.')

        }
    }
}

// Find Issues array from concern array
const findIssues = (concerns) => {
    // Check if concerns is not an array and empty
    if (!Array.isArray(concerns) || concerns.length === 0) {
        return []
    }

    let trips = []
    let issues = []
    concerns.forEach(concern => {
        // Check if concern has no trips or trips is not an array
        if (!Array.isArray(concern.trips) || concern.trips.length === 0) {
            return
        }

        // Check if concern has trips and trips is an array length is greater than 0
        if (Array.isArray(concern.trips) && concern.trips.length > 0) {
            // Loop through concern trips
            concern.trips.forEach(trip => {
                // Push trip to trips array
                trips.push(trip)

                // Check if trip has no issues or issues is not an array
                if (!Array.isArray(trip.issues) || trip.issues.length === 0) {
                    return
                }

                // Check if trip has issues and issues is an array length is greater than 0
                if (Array.isArray(trip.issues) && trip.issues.length > 0) {
                    // Loop through trip issues
                    trip.issues.forEach(issue => {
                        // Push issue to issues array
                        issues.push(issue)
                    })
                }
            })
        }

    })
    return { trips, issues }
}

// Transform Application Attachments
const transformApplicationAttachments = (attachments) => {
    // Check if attachments is not an array and empty
    if (!Array.isArray(attachments) || attachments.length === 0) {
        return []
    }

    // Distinct attachments by type with url and return only array
    const uniqueTypes = [...new Set(attachments.map(attachment => attachment.type))]

    const distinctAttachments = uniqueTypes.map((type, index) => {
        const obj = {
            key: index + 1,
            type,
            typeLabel: getAttachmentTypeLabel(type),
            attachments: attachments.filter(attachment => attachment.type === type)
        }
        return obj
    })

    return distinctAttachments
}

// Open Upload File Modal
const openUploadFileModal = () => {

}

// Open Add New Concern Modal
const openAddNewConcernModal = () => {
    return dispatch => {
        dispatch(setIsAddNewConcernModalOpen(true))
    }
}

// Update Concern
const updateConcern = (concern, data) => {
    return async dispatch => {
        try {
            const response = await axios.post(APPLICATION.UPDATE_CONCERN + concern.id, data,
                { params: { _method: 'PUT' } })

            // Check if response is valid
            if (response?.data?.status === 200) {
                // Get Application Details
                dispatch(getApplicationDetails(concern.application_id))

                // Close Concern Update Modal
                dispatch(setIsUpdateConcernModalOpen(false))

                // Show Success Alert
                showAlert('success', 'Concern updated successfully.')
                return
            } else {
                // Show Error Alert
                showAlert('error', 'Something went wrong.')
            }
        } catch (error) {
            console.error(error)

            // Show Error Alert
            showAlert('error', error?.response?.data?.message ?? error?.message ?? 'Something went wrong.')
        }
    }
}

// Add New Concern
const addNewConcern = (concern, data) => {
    return async dispatch => {
        try {
            const response = await axios.post(APPLICATION.ADD_NEW_CONCERN, data)

            // Check if response is valid
            if (response?.data?.status === 200) {
                // Get Application Details
                dispatch(getApplicationDetails(concern.application_id))

                // Close Concern Update Modal
                dispatch(setIsAddNewConcernModalOpen(false))

                // Show Success Alert
                showAlert('success', 'Concern added successfully.')
                return
            } else {
                // Show Error Alert
                showAlert('error', 'Something went wrong.')
            }
        } catch (error) {
            console.error(error)

            // Show Error Alert
            showAlert('error', error?.response?.data?.message ?? error?.message ?? 'Something went wrong.')
        }
    }
}

// Update Verified Status
const updateVerifiedStatus = (application, data) => {
    return async dispatch => {
        try {
            const response = await axios.post(APPLICATION.UPDATE_VERIFIED_STATUS + application.id, data)

            // check if response is valid
            if (response?.data?.status === 200) {
                // Get Application Details
                dispatch(getApplicationDetails(application.id))

                // Show Success Alert
                showAlert('success', 'Verified Remarks Updated Successfully.')
            } else {
                // Set Loading to false
                dispatch(setIsUpdateFileNoButtonLoading(false))

                // Show Error Alert
                showAlert('error', 'Something went wrong.')
            }
        } catch (error) {
            // Show Error Alert
            showAlert('error', error?.response?.data?.message ?? error?.message ?? 'Something went wrong.')
        }
    }
}

const updateDownloadStatus = (application, data) => {
    return async dispatch => {
        try {
            const response = await axios.post(APPLICATION.UPDATE_DOWNLOAD_STATUS + application.id, data)

            // check if response is valid
            if (response?.data?.status === 200) {
                // Get Application Details
                dispatch(getApplicationDetails(application.id))

                // Show Success Alert
                showAlert('success', 'Download Status Updated Successfully.')
            } else {
                // Set Loading to false
                dispatch(setIsUpdateFileNoButtonLoading(false))

                // Show Error Alert
                showAlert('error', 'Something went wrong.')
            }
        } catch (error) {
            // Show Error Alert
            showAlert('error', error?.response?.data?.message ?? error?.message ?? 'Something went wrong.')
        }
    }
}

// Update File No.
const updateFileNo = (application, data) => {
    return async dispatch => {
        // Set Loading to true
        dispatch(setIsUpdateFileNoButtonLoading(true))
        try {
            const response = await axios.post(APPLICATION.UPDATE_FILE_NO + application.id, data)

            // Check if response is valid
            if (response?.data?.status === 200) {
                // Set Loading to false
                dispatch(setIsUpdateFileNoButtonLoading(false))
                // Get Application Details
                dispatch(getApplicationDetails(application.id))

                // Close Concern Update Modal
                dispatch(setIsUpdateFileNoModalOpen(false))

                // Show Success Alert
                showAlert('success', 'File No. updated successfully.')
                return
            } else {
                // Set Loading to false
                dispatch(setIsUpdateFileNoButtonLoading(false))

                // Show Error Alert
                showAlert('error', 'Something went wrong.')
            }
        } catch (error) {
            console.error(error)

            // Set Loading to false
            dispatch(setIsUpdateFileNoButtonLoading(false))

            // Show Error Alert
            showAlert('error', error?.response?.data?.message ?? error?.message ?? 'Something went wrong.')
        }
    }
}

// Upload Files
const uploadFiles = (application, data) => {
    return async dispatch => {
        // Set Loading to true
        dispatch(setIsFileUploading(true))
        try {
            const response = await axios.post(APPLICATION.UPLOAD_FILES, data)

            // Check if response is valid
            if (response?.data?.status === 200) {
                // Set Loading to false
                dispatch(setIsFileUploading(false))

                // Get Application Details
                dispatch(getApplicationDetails(application.id))

                // Show Success Alert
                showAlert('success', 'File uploaded successfully.')
                return
            } else {
                // Set Loading to false
                dispatch(setIsFileUploading(false))

                // Show Error Alert
                showAlert('error', 'Something went wrong.')
            }
        } catch (error) {
            console.error(error)

            // Set Loading to false
            dispatch(setIsFileUploading(false))

            // Show Error Alert
            showAlert('error', error?.response?.data?.message ?? error?.message ?? 'Something went wrong.')
        }
    }
}

// update station
const updateStation = (application, data) => {
    return async dispatch => {
        // Set Loading to true
        dispatch(setIsUpdateStationLoading(true))
        try {
            const response = await axios.post(APPLICATION.UPDATE_STATION + application.id, data)

            // Check if response is valid
            if (response?.data?.status === 200) {
                // Set Loading to false
                dispatch(setIsUpdateStationLoading(false))
                // Get Application Details
                dispatch(getApplicationDetails(application.id))

                // Show Success Alert
                showAlert('success', 'Station. updated successfully.')
                return "success"
            } else {
                // Set Loading to false
                dispatch(setIsUpdateStationLoading(false))

                // Show Error Alert
                showAlert('error', 'Something went wrong.')
            }
        } catch (error) {
            console.error(error)

            // Set Loading to false
            dispatch(setIsUpdateStationLoading(false))

            // Show Error Alert
            showAlert('error', error?.response?.data?.message ?? error?.message ?? 'Something went wrong.')
            return "error"
        }
    }
}

// Add New Trip
const addNewTrip = (application, data) => {
    return async dispatch => {
        // Set isAddNewTripButtonLoading to true
        dispatch(setIsAddNewTripButtonLoading(true))
        try {
            const response = await axios.post(APPLICATION.ADD_NEW_TRIP, data)

            // Check if response is valid
            if (response?.data?.status === 200) {
                // Set isAddNewTripButtonLoading to false
                dispatch(setIsAddNewTripButtonLoading(false))

                // Get Application Details
                dispatch(getApplicationDetails(application.id))

                // Close Concern Update Modal
                dispatch(setIsAddNewTripModalOpen(false))

                // Show Success Alert
                showAlert('success', 'Trip added successfully.')
                return
            } else {
                // Show Error Alert
                showAlert('error', 'Something went wrong.')
            }
        } catch (error) {
            console.error(error)

            // Set isAddNewTripButtonLoading to false
            dispatch(setIsAddNewTripButtonLoading(false))

            // Show Error Alert
            showAlert('error', error?.response?.data?.message ?? error?.message ?? 'Something went wrong.')
        }
    }
}

// Deactivate Concern
const deactivateConcern = (concern) => {
    return async dispatch => {
        // Set isDeactivateLoading to true
        dispatch(setIsDeactivateLoading(true))
        try {
            const response = await axios.post(APPLICATION.UPDATE_CONCERN + concern.id + APPLICATION.DEACTIVATE_CONCERN)

            // Check if response is valid
            if (response?.data?.status === 200) {
                // Get Application Details
                dispatch(getApplicationDetails(concern.application_id))

                // Close Concern Update Modal
                dispatch(setIsDeactivateLoading(false))

                // Show Success Alert
                showAlert('success', 'Concern deactivated successfully.')
                return
            } else {
                // Show Error Alert
                showAlert('error', 'Something went wrong.')
            }
        } catch (error) {
            console.error(error)

            // Set isDeactivateLoading to false
            dispatch(setIsDeactivateLoading(false))

            // Show Error Alert
            showAlert('error', error?.response?.data?.message ?? error?.message ?? 'Something went wrong.')
        }
    }
}

// Export Actions
export { getApplicationDetails, openUploadFileModal, openAddNewConcernModal, updateConcern, addNewConcern, updateFileNo, updateVerifiedStatus, updateDownloadStatus, uploadFiles, addNewTrip, deactivateConcern, updateStation }