import React from "react";
import NormalCreateFile from "../Components/Layouts/CreateFile/NormalCreateFile";
import { Card, Result, Segmented, Space, Tabs } from "antd";
import BulkUpload from "../Components/Layouts/CreateFile/BulkUpload";
import OCRCreateFile from "../Components/Layouts/CreateFile/OCR/OCRFlie/OCRCreateFile";
import { isAllowedToAccess } from "../Services/Actions/permissionActions";
import { useSelector } from "react-redux";

const { TabPane } = Tabs;
const CreateFile = () => {
  // const [menu, setMenu] = React.useState(['Bulk Upload', 'Normal Create', 'OCR'])
  const [selectedMenu, setSelectedMenu] = React.useState("Bulk Upload");
  const allowedPermission = useSelector(
    (state) => state.permission.allowedPermission
  ) ?? null;
  // TAB STYLE
  const tabStyle = (tabName) => ({
    backgroundColor:
      selectedMenu === tabName ? "#4790FF" : "rgba(0, 0, 0, 0.09)",
    color: selectedMenu === tabName ? "white" : "black",
    padding: tabName === "OCR" ? "4px 34px 4px 34px" : "4px 6px 4px 6px",
    borderRadius: "4px",
  });
  return (
    <>
      <Card title={"Create File"} hoverable>
        <Space direction={"vertical"} style={{ width: "100%" }}>
          <Tabs
            className="custom-tabs"
            activeKey={selectedMenu}
            onChange={setSelectedMenu}
          >
            <TabPane
              tab={<div style={tabStyle("Bulk Upload")}>Bulk Upload</div>}
              key="Bulk Upload"
            >
              {
                // PERMISSION ID OF "create-file.create-file.create.bulk" is 6
                isAllowedToAccess(allowedPermission, {
                  menuName: "create-file",
                  subMenuName: "create-file",
                  permissionID: "6",
                }) ? (
                  <BulkUpload key={selectedMenu} />
                ) : (
                  <Result
                    style={{ maxWidth: "500px" , margin: "auto"}}
                    status="403"
                    title="403"
                    subTitle="Sorry, you are not authorized to access Bulk Upload."
                    // extra={<Button type="primary">Back Home</Button>}
                  />
                )
              }
            </TabPane>
            <TabPane
              tab={
                <div style={tabStyle("Create From Dashboard")}>
                  Create From Dashboard
                </div>
              }
              key="Create From Dashboard"
            >
              {
                // PERMISSION ID OF "create-file.create-file.create.single" is 5
                isAllowedToAccess(allowedPermission, {
                  menuName: "create-file",
                  subMenuName: "create-file",
                  permissionID: "5",
                }) ? (
                  <NormalCreateFile key={selectedMenu} />
                ) : (
                  <Result
                    style={{ maxWidth: "500px" , margin: "auto"}}
                    status="403"
                    title="403"
                    subTitle="Sorry, you are not authorized to access Create File."
                    // extra={<Button type="primary">Back Home</Button>}
                  />
                )
              }
            </TabPane>
            <TabPane tab={<div style={tabStyle("OCR")}>OCR</div>} key="OCR">
              <OCRCreateFile key={selectedMenu} />
            </TabPane>
          </Tabs>
        </Space>
      </Card>
    </>
  );
};

export default CreateFile;
