import React, { useEffect, useState } from 'react';

// Impory CSS 
import '../../../App.css'

// Import Components
import { Select, Space, Typography } from 'antd';
import QuestionList from './QuestionList';

// Import Actions, Reducers, and Methods
import { useDispatch, useSelector } from 'react-redux';
import { fetchOrgOptions, fetchTripTypeWiseQuestionList } from '../../../Services/Actions/adminPanelActions'
import { getStaticTripTypeList } from '../../../Services/Actions/staticOptionAction'
import { setTripWiseQuestionList } from '../../../Services/Reducers/adminPanelReducer';

const TripWiseQuestionListContainer = () => {
    // Get Data From Redux
    const { tripWiseQuestionList, subtypeOptions, appTypeOptions } = useSelector(state => state.adminPanel)
    const { orgList } = useSelector(state => state.common)

    // States
    const dispatch = useDispatch()
    const [questionOnState, setQuestionOnState] = useState([])
    const [allStaticTripType, setAllStaticTripType] = useState([])
    const [selectedApplicationType, setSelectedApplicationType] = useState('')
    const [selectedOrgId, setSelectedOrgId] = useState('')
    const [selectedSubType, setSelectedSubType] = useState('')


    // onMount - fetching tripTypw wise question list and All static trip type option
    useEffect(() => {
        const allStaticTripType = getStaticTripTypeList()
        allStaticTripType.unshift({ id: -1, value: 'ALL', label: 'All' })
        setAllStaticTripType(allStaticTripType)

        // fetching trip wise question list
        // dispatch(fetchTripTypeWiseQuestionList())
    }, [])

    // if questionlist gets update then updated list will be render through filterQuestion()
    useEffect(() => {
        // filterQuestion(selectedApplicationType, tripWiseQuestionList)
        setQuestionOnState(tripWiseQuestionList)
    }, [tripWiseQuestionList])

    // every time new trip gets selected list also gets updated
    // useEffect(() => {
    //     filterQuestion(selectedApplicationType, tripWiseQuestionList)
    // },[selectedApplicationType])

    // take trip type and list and set matched data to state
    // const filterQuestion = (type, list) => {
        // if(type !== 'ALL'){
        // const filteredData = list?.filter(item => item.trip_type.toLowerCase() === type.toLocaleLowerCase())
        // setQuestionOnState(filteredData)
        // return
        // }
        // setQuestionOnState(list)
    // }

    // searching question from the question list maintaining trip type
    const searchQuestion = (e) => {
        const keyword = e.target.value.toLowerCase()
        // // if no search keyword available render full list 
        if (keyword.length === 0) {
            setQuestionOnState(tripWiseQuestionList)
            return
        }
        // filtering data by keyword in quetion name
        const filteredData = []
        tripWiseQuestionList.forEach(item => {
            if ((item?.field_type?.toLowerCase()?.includes(keyword) ||
                item?.question?.toLowerCase()?.includes(keyword) ||
                item?.key?.toLowerCase()?.includes(keyword)) ||
                item?.trip_type?.toLowerCase()?.includes(keyword)
            ) {
                filteredData.push(item)
            }
        })
        setQuestionOnState(filteredData)
    }

    // updating selected trip type
    const changeApplicationType = (values) => {
        setSelectedApplicationType(values)
    }

    // cleaning and fetching org options
    const handleSelectOrg = (value) => {
        // Get Selected Org Options
        setSelectedOrgId(value)
        setSelectedSubType('')
        dispatch(dispatch => (
            dispatch(fetchOrgOptions({ org_id: value })),
            dispatch(setTripWiseQuestionList([]))
        ))
    }

    // fetching subtyppe wise question
    const onSubtypeSelect = (sub_type_id) => {

        setSelectedSubType(sub_type_id)
        const params = {
            group_subtype_id: sub_type_id,
            org_id: selectedOrgId
        }
        // fetching trip wise question list
        dispatch(fetchTripTypeWiseQuestionList(params))

    }

    // filtering only valid org
    const withoutAllOrg = orgList?.length > 0 ? orgList.filter(org => org.id !== -1) : []

    return (
        <Space direction={'vertical'} style={fillWidthStyle}>
            <Typography.Text style={{ color: 'rgba(0, 0, 0, 0.8)' }}>Organization</Typography.Text>
            <Select
                style={{ width: '100%' }}
                size={'small'}
                placeholder={'Select Organization'}
                optionFilterProp={'children'}
                onChange={handleSelectOrg}
                filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
            >
                {
                    withoutAllOrg?.length > 0 && withoutAllOrg.map((item, index) => {
                        return (
                            <Select.Option key={index + 1} value={item.uuid}>{item.name}</Select.Option>
                        )
                    })
                }
            </Select>

            <Typography.Text style={{ color: 'rgba(0, 0, 0, 0.8)' }}>Application Type</Typography.Text>
            <Select
                size={'small'}
                style={listMetaSyyle}
                // defaultValue={ 'ALL' }
                onChange={changeApplicationType}
                placeholder='Select Application Type'
            >
                {
                    appTypeOptions?.map(item => (
                        <Select.Option
                            key={item?.app_type_id}
                            value={item?.app_type_id}
                        >
                            {item?.label}
                        </Select.Option>
                    ))
                }
            </Select>

            <Typography.Text style={{ color: 'rgba(0, 0, 0, 0.8)' }}>Subtype</Typography.Text>
            <Select
                // disabled={!form.getFieldValue('area')}
                size={'small'}
                style={{
                    width: '100%',
                }}
                // value={ selectedSubType }
                onChange={onSubtypeSelect}
                placeholder='Select Subtype'
            >
                {
                    subtypeOptions?.length > 0 && subtypeOptions.map((item, index) => {
                        if (item?.app_type_id === selectedApplicationType) {
                            return (
                                <Select.Option key={index + 1} value={item?.app_subtype_id}>
                                    {item?.app_subtype}
                                </Select.Option>
                            )
                        }
                    })
                }
            </Select>

            <QuestionList
                questionSource={questionOnState}
                onSearch={searchQuestion}
            />
        </Space>
    )

}

const listMetaSyyle = {
    // width: '100% !important'
    display: 'block',
    padding: 0
}


const fillWidthStyle = {
    width: '100%',
    minWidth: '-moz-available',          /* WebKit-based browsers will ignore this. */
    minWidth: '-webkit-fill-available',  /* Mozilla-based browsers will ignore this. */
    minWidth: 'fill-available',
}

export default TripWiseQuestionListContainer