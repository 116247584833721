import React from 'react'

// Import Components
import { Form, Input, Select } from 'antd'

class NewConcernReCPV extends React.PureComponent {
    // Hnadle Image
    normFile = (e) => {
        if (Array.isArray(e)) {
            return e;
        }
        return e && e.target.files[0];
    }
    render() {
        const { _handleSelectedConcernType } = this.props
        
        return (
            <div>
                <Form.Item
                    label='Concern Type'
                    name='concernType'
                    rules={[
                        {
                            required: true,
                            message: 'Please Select concern type.',
                        },
                    ]}
                >
                    <Select placeholder='Select Concern Type' onChange={_handleSelectedConcernType}>
                        <Select.Option value='APPLICANT'>Applicant</Select.Option>
                        <Select.Option value='CO_APPLICANT'>Co-Applicant</Select.Option>
                        <Select.Option value='GUARANTOR'>Guarantor</Select.Option>
                    </Select>
                </Form.Item>
                <Form.Item
                    label='Name'
                    name='name'
                    rules={[
                        {
                            required: true,
                            message: 'Concern name cannot be empty.',
                        },
                    ]}
                >
                    <Input />
                </Form.Item>

                <Form.Item
                    label='Designation'
                    name='designation'
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label='Phone'
                    name='phone'
                    rules={[
                        {
                            required: true,
                            message: 'Concern phone number cannot be empty.',
                        },
                    ]}
                >
                    <Input />
                </Form.Item>

                <Form.Item
                    name='image'
                    label='Concern Photo'
                    valuePropName="filelist"
                    getValueFromEvent={this.normFile}
                >
                    <input
                        accept='image/*'
                        id='second-guarantor-image'
                        name='image'
                        type='file'
                    />
                </Form.Item>
                
            </div>
        )
    }
}

export default NewConcernReCPV