import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    isTrackerTableDataLoading: false,
    trackerTableData: [],
    files: [],
    isFilesLoading: false,
    selectedFile: {},
    isSelectedFileLoading: false,
    isReCPVTracksReportDownloading: false,
}

const recpvReducer = createSlice({
    name: 'recpv',
    initialState,
    reducers: {
        setIsTrackerTableDataLoading: (state, action) => {
            state.isTrackerTableDataLoading = action.payload
        },
        setTrackerTableData: (state, action) => {
            state.trackerTableData = action.payload
        },
        setFiles: (state, action) => {
            state.files = action.payload
        },
        setIsFilesLoading: (state, action) => {
            state.isFilesLoading = action.payload
        },
        setSelectedFile: (state, action) => {
            state.selectedFile = action.payload
        },
        setIsSelectedFileLoading: (state, action) => {
            state.isSelectedFileLoading = action.payload
        },
        setIsReCPVTracksReportDownloading: (state, action) => {
            state.isReCPVTracksReportDownloading = action.payload
        }
    }
})

export const { setIsTrackerTableDataLoading, setTrackerTableData, setFiles, setIsFilesLoading, setSelectedFile, setIsSelectedFileLoading, setIsReCPVTracksReportDownloading } = recpvReducer.actions
export default recpvReducer.reducer
