import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

// Import Components
import { Row, Col, Card } from 'antd'
import AssignQuestionContainer from './AssignQuestionContainer'
import CreateQuestionContainer from './CreateQuestionContainer' 
import TripWiseQuestionListContainer from './TripWiseQuestionListContainer'
import QuestionListContainer from './QuestionListContainer'

// Import Actions
import { getQuestions } from '../../../Services/Actions/adminPanelActions'


class QuestionnaireContainer extends React.PureComponent {   
    componentDidMount(){
        const { dispatch } = this.props

        // fetching all questions from API
        dispatch(getQuestions())
    }
    
    render() {
        return (
            <div>
                 <Row gutter={[ 12, 12 ]}>
                    <Col span={ 24 }>
                        <Card title={'Assign Question to Organization'} hoverable>
                            <AssignQuestionContainer /> 
                        </Card>
                    </Col>
                    <Col xs={24} md={24}>
                        <Card title={'Trip Specific Question'} hoverable>
                            <TripWiseQuestionListContainer />
                        </Card>
                    </Col>
                    <Col xs={24} md={12}>
                        <Card title={'Create Question'} hoverable>
                            <CreateQuestionContainer />
                        </Card>
                    </Col>
                    <Col xs={24} lg={14}>
                        <Card title={'All Available Question List'} hoverable>
                            <QuestionListContainer />
                        </Card>
                    </Col>
                </Row>
            </div>
        )
    }
}

// Props Validation
QuestionnaireContainer.propTypes = {
    dispatch: PropTypes.func 
}

QuestionnaireContainer.defaultProps = {
    dispatch: () => null
}

// Map State To Props
const mapDispatchToProps = dispatch => ({ dispatch })

export default connect(null, mapDispatchToProps)(QuestionnaireContainer)