import React, { useState } from 'react'

// Import Components
import { Space, Input, Button, Form, Upload, Modal } from 'antd'

// Import Icons
import { UploadOutlined } from '@ant-design/icons';


// Import Actions, and Reducers
import { useDispatch } from 'react-redux';
import { createNewOrganization } from '../../../Services/Actions/adminPanelActions'

const CreateOrg = () => {
  // States
  const dispatch = useDispatch()
  const [form] = Form.useForm()
  const [previewOpen, setPreviewOpen] = useState(false)
  const [previewImage, setPreviewImage] = useState('')
  const [previewTitle, setPreviewTitle] = useState('')

  // Handle Reset
  const _handleReset = () => {
    form.resetFields()
    setPreviewOpen(false)
    setPreviewImage('')
    setPreviewTitle('')
  }

  // Get Base64
  const _getBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = () => resolve(reader.result)
      reader.onerror = (error) => reject(error)
    });


  // Handle Preview
  const _handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await _getBase64(file.originFileObj);
    }
    setPreviewImage(file.url || file.preview);
    setPreviewOpen(true);
    setPreviewTitle(file.name || file.url.substring(file.url.lastIndexOf('/') + 1));
  };

  // Handle Create New Org
  const _handleCreateNewOrg = (value) => {
    // Modify Form Data
    const formData = new FormData()
    for (const key in value) {
      if (key === 'name') {
        formData.append(key, value[key])
      } else {
        formData.append(key, value[key].file)
      }
    }

    // Create New Organization
    dispatch(createNewOrganization(formData))
      .then(status => {
        if (status === 200) {
          _handleReset()
        }
      })
  }

  // Validate File
  const _validateFile = (data) => {
    return new Promise((resolve, reject) => {
      if (data && data.file.size > (2048 * 1000)) {
        reject('File size exceeds the maximum limit is 2MB')
      } else {
        resolve('Success')
      }
    })
  }

  // Upload Button
  const UploadButton = (
    <Button icon={<UploadOutlined />}>Upload</Button>
  )

  return (
    <>
      <Space direction={'vertical'} style={{ width: '100%' }}>
        <Form
          name='create-org-form'
          layout='vertical'
          onFinish={_handleCreateNewOrg}
          form={form}
        >
          {/* Name */}
          <Form.Item
            name='name'
            label="Organization Name"
            rules={[
              {
                required: true,
                message: 'Please enter organization name'
              }
            ]}
          >
            <Input
              placeholder="Enter Organization Name"
            />
          </Form.Item>

          {/* Operation Manager Seal */}
          <Form.Item
            name='operation_manager_seal'
            label="Operation Manager Seal"
            rules={[
              {
                required: true,
                message: 'Please Enter Operation Manager Seal'
              },
              {
                validator(_, data) {
                  return _validateFile(data)
                }
              }
            ]}
          >
            <Upload
              listType='picture'
              maxCount={1}
              accept="image/jpeg, image/png, image/jpg"
              beforeUpload={() => { return false }}
              onPreview={(e) => _handlePreview(e)}
            >
              {UploadButton}
            </Upload>
          </Form.Item>

          {/* Operation Manager Sign */}
          <Form.Item
            name='operation_manager_sign'
            label="Operation Manager Sign"
            rules={[
              {
                required: true,
                message: 'Please Enter Operation Manager Sign'
              },
              {
                validator(_, data) {
                  return _validateFile(data)
                }
              }
            ]}
          >
            <Upload
              listType='picture'
              maxCount={1}
              accept="image/jpeg, image/png, image/jpg"
              beforeUpload={() => { return false }}
              onPreview={(e) => _handlePreview(e)}
            >
              {UploadButton}
            </Upload>
          </Form.Item>

          {/* In-Station Team Lead Seal */}
          <Form.Item
            name='in_station_team_lead_seal'
            label='In-Station Team Lead Seal'
            rules={[
              {
                required: true,
                message: 'Please Enter In-Station Team Lead Seal'
              },
              {
                validator(_, data) {
                  return _validateFile(data)
                }
              }
            ]}
          >
            <Upload
              listType='picture'
              maxCount={1}
              accept="image/jpeg, image/png, image/jpg"
              beforeUpload={() => { return false }}
              onPreview={(e) => _handlePreview(e)}
            >
              {UploadButton}
            </Upload>
          </Form.Item>

          {/* In-Station Team Lead Sign */}
          <Form.Item
            name='in_station_team_lead_sign'
            label='In-Station Team Lead Sign'
            rules={[
              {
                required: true,
                message: 'Please Enter In-Station Team Lead Sign'
              },
              {
                validator(_, data) {
                  return _validateFile(data)
                }
              }
            ]}
          >
            <Upload
              listType='picture'
              maxCount={1}
              accept="image/jpeg, image/png, image/jpg"
              beforeUpload={() => { return false }}
              onPreview={(e) => _handlePreview(e)}
            >
              {UploadButton}
            </Upload>
          </Form.Item>

          {/* Out-Station Team Lead Seal */}
          <Form.Item
            name='out_station_team_lead_seal'
            label='Out-Station Team Lead Seal'
            rules={[
              {
                required: true,
                message: 'Please Enter Out-Station Team Lead Seal'
              },
              {
                validator(_, data) {
                  return _validateFile(data)
                }
              }
            ]}
          >
            <Upload
              listType='picture'
              maxCount={1}
              accept="image/jpeg, image/png, image/jpg"
              beforeUpload={() => { return false }}
              onPreview={(e) => _handlePreview(e)}
            >
              {UploadButton}
            </Upload>
          </Form.Item>

          {/* Out-Station Team Lead Sign */}
          <Form.Item
            name='out_station_team_lead_sign'
            label='Out-Station Team Lead Sign'
            rules={[
              {
                required: true,
                message: 'Please Enter Out-Station Team Lead Sign'
              },
              {
                validator(_, data) {
                  return _validateFile(data)
                }
              }
            ]}
          >
            <Upload
              listType='picture'
              maxCount={1}
              accept="image/jpeg, image/png, image/jpg"
              beforeUpload={() => { return false }}
              onPreview={(e) => _handlePreview(e)}
            >
              {UploadButton}
            </Upload>
          </Form.Item>

          {/* Save Button */}
          <Form.Item>
            <Button
              htmlType="submit"
              type="primary">
              Create
            </Button>
          </Form.Item>
        </Form>
      </Space >

      {/* Modal */}
      <Modal visible={previewOpen} title={previewTitle} footer={null} onCancel={() => setPreviewOpen(false)}>
        <img
          alt="example"
          style={{
            width: '100%',
          }}
          src={previewImage}
        />
      </Modal>
    </>
  )
}

export default CreateOrg