import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

// Import Components
import { Form, Card, Select, Upload, Button } from "antd";
import { UploadOutlined } from "@ant-design/icons";

// Import Actions
import { uploadFiles } from "../../../Services/Actions/applicationActions";
import { getTripTypeStringAndFilledIcon, showAlert } from "../../../Services/Actions/commonActions";

//  all file types for file upload
const fileTypes = [
  {
    label: "LOI",
    value: "loi",
  },
  {
    label: "Applicant Sign",
    value: "applicant_sign",
  },
  {
    label: "Guarantor Sign",
    value: "guarantee_sign",
  },
  {
    label: "Co-Applicant LOI",
    value: "co_applicant_loi",
  },
  {
    label: "Co-Applicant Sign",
    value: "co_applicant_sign",
  },
  {
    label: "Rental Agreement",
    value: "rental_agreement",
  },
  {
    label: "Car Quotation",
    value: "car_quotation",
  },
  {
    label: "Salary Statement",
    value: "salary_statement",
  },
  {
    label: "Bank Statement",
    value: "bank_statement",
  },
  {
    label: "Withdraw Letter",
    value: "withdraw_letter",
  },
  {
    label: "Applicant Residence",
    value: "applicant_residence",
  },
  {
    label: "Applicant Office",
    value: "applicant_office",
  },
  {
    label: "Guarantor Residence",
    value: "guarantee_residence",
  },
  {
    label: "Guarantor Office",
    value: "guarantee_office",
  },
  {
    label: "Co-Applicant Residence",
    value: "co_applicant_residence",
  },
  {
    label: "Co-Applicant Office",
    value: "co_applicant_office",
  },
  {
    label: "Rental Images",
    value: "rental_images",
  },
];

class FileUploadModal extends React.PureComponent {
  state = {
    loi: null,
    applicant_sign: null,
    guarantor_sign: null,
    selectedFile: null,
    selectedTrip: "",
    selectedFileType: "",
    isLoading: false,
  };

  // Handle Trips On Change
  _handleTripsOnChange = (value) => {
    // Set State
    this.setState({ selectedTrip: value ?? "" });
  };

  // Handle File Type On Change
  _handleFileTypeOnChange = (value) => {
    // Set State
    this.setState({ selectedFileType: value ?? "" });
  };

  // Handle File Upload Change
  _handleFileUploadChange = (info) => {
    const file = info.fileList;

    // Check if file is not valid
    if (file) {
      // Set to State
      this.setState({ selectedFile: file });
    } else this.setState({ selectedFile: null });
  };

  // Handle LOI File Upload Change
  _handleLOIFileUploadChange = (info) => {
    const file = info.fileList;

    // Check if file is not valid
    if (file) {
      // Set to State
      this.setState({ loi: file });
    } else this.setState({ loi: null });
  };

  // Handle Applicant Sign File Upload Change
  _handleApplicantSignFileUploadChange = (info) => {
    const file = info.fileList;

    // Check if file is not valid
    if (file) {
      // Set to State
      this.setState({ applicant_sign: file });
    } else this.setState({ applicant_sign: null });
  };

  // Handle Guarantor Sign File Upload Change
  _handleGuarantorSignFileUploadChange = (info) => {
    const file = info.fileList;

    // Check if file is not valid
    if (file) {
      // Set to State
      this.setState({ guarantor_sign: file });
    } else this.setState({ guarantor_sign: null });
  };

  // Handle File Upload
  _handleFileUpload = () => {
    const { selectedTrip, selectedFile, selectedFileType } = this.state;
    const { dispatch, applicationDetails } = this.props;

    this.setState({ isLoading: true });

    // Check if selected trip is not empty
    if (!selectedTrip) {
      // Show alert
      showAlert("error", "Please select trip type.");
      this.setState({ isLoading: false });
      return;
    }

    if (selectedFile) {
      const formData = new FormData();

      // Append Trip
      formData.append("trip_id", selectedTrip);
      formData.append("application_id", applicationDetails?.id);

      // Check if LOI is uploaded
      if (selectedFile?.length > 0) {
        selectedFile.forEach((l, index) => {
          // Append to form data
          formData.append(`${selectedFileType}[${index}]`, l.originFileObj);
        });
      }

      // Upload Files
      dispatch(uploadFiles(applicationDetails, formData));
      //   this.setState({ isLoading: false });
    } else {
      // Show alert
      showAlert("error", "Please upload at least one file.");
      this.setState({ isLoading: false });
    }
  };

  render() {
    const { applicationDetails } = this.props;
    const { isLoading } = this.state;

    return (
      <Card
        title={"Upload Additional Files"}
        headStyle={{ textAlign: "center", fontWeight: "bold" }}
        hoverable
      >
        <Form
          layout={"horizontal"}
          labelCol={{
            span: 6,
            xs: 24,
            sm: 24,
            md: 12,
            lg: 12,
            xl: 12,
            xxl: 12,
          }}
          wrapperCol={{
            span: 24,
            xs: 24,
            sm: 24,
            md: 12,
            lg: 12,
            xl: 12,
            xxl: 12,
          }}
          onFinish={() => this._handleFileUpload()}
        >
          <Form.Item
            // label={ 'Select Trip' }
            rules={[{ required: true, message: "Please select trip!" }]}
            wrapperCol={{ span: 24 }}
            style={{ textAlign: "center" }}
          >
            <Select
              onChange={this._handleTripsOnChange}
              style={{ width: "70%", maxWidth: "600px" }}
              placeholder={"Select trip"}
              showSearch
            >
              {applicationDetails?.concerns?.length > 0 &&
                applicationDetails?.concerns.map((concern) => (
                  <Select.OptGroup
                    key={concern?.id}
                    label={`${concern?.type ?? "N/A"} - ${
                      concern?.name ?? "N/A"
                    }`}
                  >
                    {concern?.trips?.length > 0 &&
                      concern?.trips.map((trip) => (
                        <Select.Option key={trip?.id} value={trip?.id}>
                          {`${trip?.id} - ${
                            trip?.type
                              ? getTripTypeStringAndFilledIcon(trip?.type).label
                              : "N/A"
                          }`}
                        </Select.Option>
                      ))}
                  </Select.OptGroup>
                ))}
            </Select>
          </Form.Item>
          <Form.Item
            // label={ 'Select Trip' }
            rules={[{ required: true, message: "Please select file type!" }]}
            wrapperCol={{ span: 24 }}
            style={{ textAlign: "center" }}
          >
            <Select
              onChange={this._handleFileTypeOnChange}
              style={{ width: "70%", maxWidth: "600px" }}
              placeholder={"Select file type"}
              showSearch
            >
              {fileTypes.map((item) => (
                <Select.Option key={item.value} value={item.value}>
                  {item.label}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            label={"Select File"}
            rules={[{ required: true, message: "Please select file!" }]}
            style={{ marginBottom: "10px" }}
          >
            <Upload
              name={"file"}
              onChange={this._handleFileUploadChange}
              multiple={true}
              // maxCount={ 1 }
              beforeUpload={() => {
                return false;
              }}
            >
              {/* <p className={ 'ant-upload-drag-icon' }>
                                <UploadOutlined />
                            </p> */}
              <Button icon={<UploadOutlined />}>Choose Files</Button>
            </Upload>
          </Form.Item>
          <Form.Item
            wrapperCol={{
              span: 24,
            }}
          >
            <Button
              style={{ display: "block", margin: "10px auto 0 auto" }}
              loading={isLoading}
              type="primary"
              htmlType="submit"
            >
              Upload
            </Button>
          </Form.Item>
        </Form>
      </Card>
    );
  }
}

// Props Validation
FileUploadModal.propTypes = {
  dispatch: PropTypes.func,
  applicationDetails: PropTypes.object,
  isFileUploadModalOpen: PropTypes.bool,
  isFileUploading: PropTypes.bool,
};

FileUploadModal.defaultProps = {
  dispatch: () => null,
  applicationDetails: {},
  isFileUploadModalOpen: false,
  isFileUploading: false,
};

// Map State To Props
const mapStateToProps = (state) => ({
  applicationDetails: state.application.applicationDetails,
  isFileUploadModalOpen: state.application.isFileUploadModalOpen,
  isFileUploading: state.application.isFileUploading,
});

// Map Dispatch To Props
const mapDispatchToProps = (dispatch) => ({ dispatch });

export default connect(mapStateToProps, mapDispatchToProps)(FileUploadModal);
