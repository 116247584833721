import React, { useEffect, useState } from 'react';

// Import Components
import { Space, Select, Input, Form, Button, Upload, Modal } from 'antd'

// Import Icons
import { UploadOutlined } from '@ant-design/icons';

// Import Actions, and Reducers
import { useDispatch, useSelector } from 'react-redux';
import { setSelectedOrgUpdateOrg } from '../../../Services/Reducers/adminPanelReducer'
import { updateOrganization } from '../../../Services/Actions/adminPanelActions'
import { showAlert } from '../../../Services/Actions/commonActions'

const UpdateOrg = () => {
  // States
  const dispatch = useDispatch()
  const [form] = Form.useForm()
  const operationManagerSeal = Form.useWatch('operation_manager_seal', form)
  const operationManagerSign = Form.useWatch('operation_manager_sign', form)
  const inStationTeamLeadSeal = Form.useWatch('in_station_team_lead_seal', form)
  const inStationTeamLeadSign = Form.useWatch('in_station_team_lead_sign', form)
  const outStationTeamLeadSeal = Form.useWatch('out_station_team_lead_seal', form)
  const outStationTeamLeadSign = Form.useWatch('out_station_team_lead_sign', form)
  const [previewOpen, setPreviewOpen] = useState(false)
  const [previewImage, setPreviewImage] = useState('')
  const [previewTitle, setPreviewTitle] = useState('')

  // Get Data From Redux
  const orgList = useSelector(state => state.common.orgList)
  const selectedOrgUpdateOrg = useSelector(state => state.adminPanel.selectedOrgUpdateOrg)
  const isUpdateOrgLoading = useSelector(state => state.adminPanel.isUpdateOrgLoading)

  const _getBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = () => resolve(reader.result)
      reader.onerror = (error) => reject(error)
    });
  }

  // Handle Preview
  const _handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await _getBase64(file.originFileObj);
    }
    setPreviewImage(file.url || file.preview);
    setPreviewOpen(true);
    setPreviewTitle(file.name || '');
  };

  // Handle Update Org
  const _handleUpdateOrg = (value) => {
    // Modify Form Data
    const formData = new FormData()
    for (const key in value) {
      if (key === 'name') {
        formData.append(key, value[key])
      } else if (value && value[key]?.file) {
        formData.append(key, value[key].file)
      }
    }

    formData.append('group_id', selectedOrgUpdateOrg?.uuid)

    // Update Organization
    dispatch(updateOrganization(formData))
  }

  // Handle Selected Org On Change
  const _handleSelectOrg = (value) => {
    form.resetFields()
    // Check if value is empty or undefined or null
    if (!value) {

      // Set Selected Org
      dispatch(setSelectedOrgUpdateOrg({}))
      return
    }
    // Find Selected Org
    const selectedOrg = orgList.find(item => item.uuid === value)

    // If Selected Org Not Exist
    if (!selectedOrg) {

      // Set Selected Org
      dispatch(setSelectedOrgUpdateOrg({}))

      // Show Error Alert
      showAlert('error', 'Selected Organization Not Found')
      return
    }
    // Set Selected Org
    dispatch(setSelectedOrgUpdateOrg(selectedOrg))
  }

  // Validate File
  const _validateFile = (data) => {
    return new Promise((resolve, reject) => {
      if (data && data.file.size > (2048 * 1000)) {
        reject('File size exceeds the maximum limit is 2MB')
      } else {
        resolve('Success')
      }
    })
  }

  // Get URL
  const getFileList = (newData, oldData) => {
    // console.log(newData, 'newData')
    // console.log(oldData, 'oldData')

    // New Data Exist
    if (newData && newData?.fileList.length > 0) {
      return [{
        url: URL.createObjectURL(newData?.fileList[0].originFileObj)
      }]
    }

    // If Only Old Data Exist
    if (!newData && oldData) {
      return [{
        url: oldData
      }]
    }

    // If Both Data is Not Exist
    if (!newData && !oldData) {
      return []
    }
  }

  useEffect(() => {
    form.setFieldsValue({
      name: selectedOrgUpdateOrg?.name,
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedOrgUpdateOrg])

  // Upload Button
  const UploadButton = (
    <Button icon={<UploadOutlined />}>Upload</Button>
  )

  return (
    <>
      <Space direction={'vertical'} style={{ width: '100%' }}>
        {/* Select Organization */}
        <Select
          style={{ width: '100%' }}
          // showSearch
          // allowClear
          placeholder={'Select Organization'}
          onChange={_handleSelectOrg}
          value={selectedOrgUpdateOrg?.uuid ?? null}
          filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
          optionFilterProp={'children'}
        >
          {
            orgList?.length > 0 && orgList.map((item, index) => {
              return (
                <Select.Option key={index + 1} value={item.uuid}>{item.name}</Select.Option>
              )
            })
          }
        </Select>

        {/* Update Form */}
        <Form
          name='create-org-form'
          layout='vertical'
          onFinish={_handleUpdateOrg}
          form={form}
        >

          {/* Name */}
          <Form.Item
            name='name'
            label="Organization Name"
            rules={[
              {
                required: false,
                message: 'Please enter organization name'
              }
            ]}
          >
            <Input
              placeholder="Enter Organization Name"
            />
          </Form.Item>

          {/* Operation Manager Seal */}
          <Form.Item
            name='operation_manager_seal'
            label="Operation Manager Seal"
            rules={[
              {
                required: false,
                message: 'Please Enter Operation Manager Seal'
              },
              {
                validator(_, data) {
                  return _validateFile(data)
                }
              }
            ]}
          >
            <Upload
              listType='picture'
              maxCount={1}
              accept="image/jpeg, image/png, image/jpg"
              beforeUpload={() => { return false }}
              fileList={getFileList(operationManagerSeal, selectedOrgUpdateOrg?.operation_manager_seal)}
              onPreview={(e) => _handlePreview(e)}
            >
              {UploadButton}
            </Upload>
          </Form.Item>

          {/* Operation Manager Sign */}
          <Form.Item
            name='operation_manager_sign'
            label="Operation Manager Sign"
            rules={[
              {
                required: false,
                message: 'Please Enter Operation Manager Sign'
              },
              {
                validator(_, data) {
                  return _validateFile(data)
                }
              }
            ]}
          >
            <Upload
              listType='picture'
              maxCount={1}
              accept="image/jpeg, image/png, image/jpg"
              beforeUpload={() => { return false }}
              fileList={getFileList(operationManagerSign, selectedOrgUpdateOrg?.operation_manager_sign)}
              onPreview={(e) => _handlePreview(e)}
            >
              {UploadButton}
            </Upload>
          </Form.Item>

          {/* In-Station Team Lead Seal */}
          <Form.Item
            name='in_station_team_lead_seal'
            label='In-Station Team Lead Seal'
            rules={[
              {
                required: false,
                message: 'Please Enter In-Station Team Lead Seal'
              },
              {
                validator(_, data) {
                  return _validateFile(data)
                }
              }
            ]}
          >
            <Upload
              listType='picture'
              maxCount={1}
              accept="image/jpeg, image/png, image/jpg"
              beforeUpload={() => { return false }}
              fileList={getFileList(inStationTeamLeadSeal, selectedOrgUpdateOrg?.in_station_team_lead_seal)}
              onPreview={(e) => _handlePreview(e)}
            >
              {UploadButton}
            </Upload>
          </Form.Item>

          {/* In-Station Team Lead Sign */}
          <Form.Item
            name='in_station_team_lead_sign'
            label='In-Station Team Lead Sign'
            rules={[
              {
                required: false,
                message: 'Please Enter In-Station Team Lead Sign'
              },
              {
                validator(_, data) {
                  return _validateFile(data)
                }
              }
            ]}
          >
            <Upload
              listType='picture'
              maxCount={1}
              accept="image/jpeg, image/png, image/jpg"
              beforeUpload={() => { return false }}
              fileList={getFileList(inStationTeamLeadSign, selectedOrgUpdateOrg?.in_station_team_lead_sign)}
              onPreview={(e) => _handlePreview(e)}
            >
              {UploadButton}
            </Upload>
          </Form.Item>

          {/* Out-Station Team Lead Seal */}
          <Form.Item
            name='out_station_team_lead_seal'
            label='Out-Station Team Lead Seal'
            rules={[
              {
                required: false,
                message: 'Please Enter Out-Station Team Lead Seal'
              },
              {
                validator(_, data) {
                  return _validateFile(data)
                }
              }
            ]}
          >
            <Upload
              listType='picture'
              maxCount={1}
              accept="image/jpeg, image/png, image/jpg"
              beforeUpload={() => { return false }}
              fileList={getFileList(outStationTeamLeadSeal, selectedOrgUpdateOrg?.out_station_team_lead_seal)}
              onPreview={(e) => _handlePreview(e)}
            >
              {UploadButton}
            </Upload>
          </Form.Item>

          {/* Out-Station Team Lead Sign */}
          <Form.Item
            name='out_station_team_lead_sign'
            label='Out-Station Team Lead Sign'
            rules={[
              {
                required: false,
                message: 'Please Enter Out-Station Team Lead Sign'
              },
              {
                validator(_, data) {
                  return _validateFile(data)
                }
              }
            ]}
          >
            <Upload
              listType='picture'
              maxCount={1}
              accept="image/jpeg, image/png, image/jpg"
              beforeUpload={() => { return false }}
              fileList={getFileList(outStationTeamLeadSign, selectedOrgUpdateOrg?.out_station_team_lead_sign)}
              onPreview={(e) => _handlePreview(e)}
            >
              {UploadButton}
            </Upload>
          </Form.Item>

          {/* Update Button */}
          <Form.Item>
            <Button
              htmlType="submit"
              type="primary"
              loading={isUpdateOrgLoading}
            >
              Update
            </Button>
          </Form.Item>
        </Form>
      </Space>

      {/* Modal */}
      <Modal visible={previewOpen} title={previewTitle} footer={null} onCancel={() => setPreviewOpen(false)}>
        <img
          alt="example"
          style={{
            width: '100%',
          }}
          src={previewImage}
        />
      </Modal>
    </>
  );
};

export default UpdateOrg;