import { createSlice } from '@reduxjs/toolkit'
import { getOCRList } from '../Actions/createFileActions'

const initialState = {
    selectedOrg: {},
    orgBranch: [
        {
            name: 'LankaBangla Finance Limited',
            branch: [
                { id: 1, value: 'Banani Branch', label: 'Banani Branch' },
                { id: 2, value: 'Dhanmondi Branch', label: 'Dhanmondi Branch' },
                { id: 3, value: 'Mirpur Branch', label: 'Mirpur Branch' },
                { id: 4, value: 'Motijheel Branch', label: 'Motijheel Branch' },
                { id: 5, value: 'Uttara Branch', label: 'Uttara Branch' },
                { id: 6, value: 'Narayanganj Branch', label: 'Narayanganj Branch' },
                { id: 7, value: 'Narsingdi Branch', label: 'Narsingdi Branch' },
                { id: 8, value: 'Agrabad Branch', label: 'Agrabad Branch' },
                { id: 9, value: 'Cumilla Branch', label: 'Cumilla Branch' },
                { id: 10, value: 'Chowmuhani Branch', label: 'Chowmuhani Branch' },
                { id: 11, value: 'Sylhet Branch', label: 'Sylhet Branch' },
                { id: 12, value: 'Rajshahi Branch', label: 'Rajshahi Branch' },
                { id: 13, value: 'Bogura Branch', label: 'Bogura Branch' },
                { id: 14, value: 'Mymensingh Branch', label: 'Mymensingh Branch' },
                { id: 15, value: 'Khulna Branch', label: 'Khulna Branch' },
                { id: 16, value: 'Jashore Branch', label: 'Jashore Branch' },
                { id: 17, value: 'Keraniganj Branch', label: 'Keraniganj Branch' },
                { id: 18, value: 'Board Bazar-Gazipur Branch', label: 'Board Bazar-Gazipur Branch' },
                { id: 19, value: 'Dinajpur Branch', label: 'Dinajpur Branch' },
                { id: 20, value: 'Faridpur Branch', label: 'Faridpur Branch' },
                { id: 21, value: 'Savar Branch', label: 'Savar Branch' },
                { id: 22, value: 'Gulshan Branch', label: 'Gulshan Branch' },
                { id: 23, value: 'Habiganj Branch', label: 'Habiganj Branch' },
                { id: 24, value: 'Kushtia Branch', label: 'Kushtia Branch' },
                { id: 25, value: 'Bangshal Branch', label: 'Bangshal Branch' },
            ]
        }
    ],
    orgOptions: [],
    selectedOption: {},
    applicationTags: [
        { id: 1, value: 'ordinary', label: 'Ordinary' },
        { id: 2, value: 'ccep', label: 'CCEP' },
        { id: 3, value: 'sme', label: 'SME' },
        { id: 4, value: 'bundle', label: 'Bundle' },
        { id: 5, value: 'recpv', label: 'ReCPV' },
        { id: 6, value: 'recpv-full', label: 'ReCPV Full' },
    ],
    isResetClicked: false,
    isLoading: false,
    isOCRLoading: false,
    markerData: [],
    coApplicantNo: 0,
    OCR: {
        results: null,
        previewImages: [],
        processing: false
    },
    formData: {},
    OCRList: [],
    orgSubType: null,
}

const createFileReducer = createSlice({
    name: 'createFile',
    initialState,
    reducers: {
        setSelectedOrg: (state, action) => {
            state.selectedOrg = action.payload
        },
        setOrgOptions: (state, action) => {
            state.orgOptions = action.payload
        },
        setSelectedOption: (state, action) => {
            state.selectedOption = action.payload
        },
        setIsResetClicked: (state, action) => {
            state.isResetClicked = action.payload
        },
        setIsLoading: (state, action) => {
            state.isLoading = action.payload
        },
        setIsOCRLoading: (state, action) => {
            state.isOCRLoading = action.payload
        },
        setMarkerData: (state, action) => {
            state.markerData = action.payload
        },
        setCoApplicantNo: (state, action) => {
            state.coApplicantNo = action.payload
        },
        setOcrResults: (state, action) => {
            state.OCR.results = action.payload
        },
        setPreviewImages: (state, action) => {
            state.OCR.previewImages = action.payload
        },
        setOCRProcessing: (state, action) => {
            state.OCR.processing = action.payload
        },
        setFormData: (state, action) => {
            state.formData = action.payload
        },
        setOCRList: (state, action) => {
            state.OCRList = action.payload
        },
        setOrgSubType: (state, action) => {
            state.orgSubType = action.payload
        }
        ,
        resetState: () => { return { ...initialState } }
    }
})

export const {
    setSelectedOrg,
    setOrgOptions,
    setSelectedOption,
    setIsResetClicked,
    setIsLoading,
    setIsOCRLoading,
    setMarkerData,
    setCoApplicantNo,
    setOcrResults,
    setPreviewImages,
    setOCRProcessing,
    setFormData,
    setOCRList,
    setOrgSubType,
    resetState
} = createFileReducer.actions
export default createFileReducer.reducer

