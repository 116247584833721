import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

// Import library Components
import {
  Form,
  Select,
  Button,
  Space,
  Checkbox,
  Row,
  Col,
  Tag,
  Card,
} from "antd";

// style
import "../../../App.css";

//import actions and reducers
import {
  fetchIndividualVerifierPermission,
  isAllowedToAccess,
  syncIndividualVerifierPermission,
} from "../../../Services/Actions/permissionActions";
import {
  setIsLoadingForPermissionAction,
  setSelectedVerifierPermission,
} from "../../../Services/Reducers/permissionReducer";

const UpdatePermission = ({ verifiers }) => {
  // useState
  const [selectedVerifierId, setSelectedVerifierId] = useState("");

  // const [ fields, setFields ] = useState([])

  // useSelector
  const { selectedVerifierPermission } = useSelector(
    (state) => state.permission
  );
  const { allPermissionList } = useSelector((state) => state.permission);
  const allowedPermission = useSelector(
    (state) => state.permission.allowedPermission
  );

  const dispatch = useDispatch();
  const [formRef] = Form.useForm();

  // update on "selectedVerifierPermission" change
  useEffect(() => {
    formRef.setFields(selectedVerifierPermission);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedVerifierPermission]);

  // unmount
  useEffect(() => {
    // cleanup
    return () => {
      dispatch(setSelectedVerifierPermission([]));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // changing selected verifier by id
  const _handleSelectedVerifier = (value) => {
    //setting current verifier

    // fetching verifiers current permissions
    dispatch(fetchIndividualVerifierPermission(value));
    setSelectedVerifierId(value);
  };

  // on form submit post form data
  const _submitFormData = (values) => {
    const user_id = values.verifierId;
    let permissions = [];

    // transforming permission data fromat to send through API
    for (const [key, value] of Object.entries(values?.selectedPermission)) {
      if (value) {
        permissions.push(key);
      }
    }

    // request sync permission
    const params = { user_id, permissions };
    dispatch(syncIndividualVerifierPermission(params));
  };

  const _handleSelectAll = (e) => {
    let value = e.target.value;

    const selectedModule = Object.entries(allPermissionList)?.find(
      (element) => element > value
    );

    let newSelectedPermissions = selectedVerifierPermission;

    let newSelectedPermissionIds = [];

    if (e.target.checked) {
      dispatch(setIsLoadingForPermissionAction(true));

      //  making an array of already selected ids
      newSelectedPermissions.forEach((i) => {
        let id = i.name[1];
        newSelectedPermissionIds.push(id);
      });

      //  making an array of all available ids of a certain module like --admin, create file etc
      let allPermissionOptionIds = [];
      Object.entries(selectedModule[1])?.forEach((submenu) => {
        submenu[1]?.forEach((permissionOption, permissionIndex) => {
          allPermissionOptionIds.push(permissionOption?.id);
        });
      });

      //  filtering the ids that are not available in already selected ids array
      let uniqueIds = allPermissionOptionIds.filter(
        (o) => newSelectedPermissionIds.indexOf(o) === -1
      );

      // making the new array of selected permission of a certain user according to structure of data
      uniqueIds.forEach((i) => {
        newSelectedPermissions = [
          ...newSelectedPermissions,
          {
            name: ["selectedPermission", i],
            value: true,
          },
        ];
      });
    } else {
      // dispatch(setIsLoadingForPermissionAction(true))

      //  making an array of already selected ids
      newSelectedPermissions.forEach((i) => {
        let id = i?.name[1];
        newSelectedPermissionIds.push(id);
      });

      //  making an array of all available ids of a certain module like --admin, create file etc
      let allPermissionOptionIds = [];
      Object.entries(selectedModule[1])?.forEach((submenu) => {
        submenu[1]?.forEach((permissionOption, permissionIndex) => {
          allPermissionOptionIds.push(permissionOption?.id);
        });
      });

      // console.log("allPermissionIds", allPermissionOptionIds);
      // console.log("newSelectedPermission", newSelectedPermissionIds);
      //  filtering the ids that are not available in already selected ids array
      // let uniqueIds2 = newSelectedPermissionIds.filter((o) => allPermissionOptionIds.indexOf(o) === -1);

      newSelectedPermissionIds = newSelectedPermissionIds.filter(
        (val) => !allPermissionOptionIds.includes(val)
      );
      // array1 = array1.filter(val => !array2.includes(val));
      // console.log("Selected ", newSelectedPermissionIds);
      // allPermissionOptionIds = allPermissionOptionIds.filter(val => !newSelectedPermissionIds.includes(val));
      // console.log("uniqueIds", uniqueIds2);
      // // let uniqueIds = allPermissionOptionIds.filter((o) => newSelectedPermissionIds.indexOf(o) === -1);
      // // making the new array of selected permission of a certain user according to structure of data
      newSelectedPermissions = [];
      newSelectedPermissionIds.forEach((i) => {
        newSelectedPermissions = [
          ...newSelectedPermissions,
          {
            name: ["selectedPermission", i],
            value: true,
          },
        ];
      });
      // console.log("newSelectedPermission", newSelectedPermissions);

      dispatch(setSelectedVerifierPermission(newSelectedPermissions));
      // console.log("newSelectedPermission", newSelectedPermissions);
      // dispatch(setIsLoadingForPermissionAction(false))
    }

    dispatch(setSelectedVerifierPermission(newSelectedPermissions));
    // console.log("newSelectedPermission", newSelectedPermissions);
    dispatch(setIsLoadingForPermissionAction(false));
    formRef.setFields(newSelectedPermissions);
  };

  return (
    <Space direction={"vertical"} style={fillWidthStyle}>
      <Form
        form={formRef}
        id={"accessForm"}
        name="assignedProperty"
        {...formItemLayout}
        onFinish={_submitFormData}
        key={selectedVerifierId}
        // fields={ fields }
        // fields={[
        //     {
        //         name: [ 'selectedPermission', 1 ],
        //         value: true
        //     },
        //     {
        //         name: [ 'selectedPermission', 5 ],
        //         value: true
        //     },
        // ]}
      >
        <Form.Item
          name="verifierId"
          label="Select User"
          rules={[
            {
              required: true,
              message: "User Missing",
            },
          ]}
          initialValue={selectedVerifierId ?? null}
        >
          <Select
            style={{ width: "100%" }}
            showSearch
            placeholder={"Select User"}
            key={selectedVerifierId}
            onChange={_handleSelectedVerifier}
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            optionFilterProp={"children"}
          >
            {verifiers?.length > 0 &&
              verifiers.map((item, index) => {
                return (
                  <Select.Option key={index} value={item.id}>
                    {item.name}
                  </Select.Option>
                );
              })}
          </Select>
        </Form.Item>
        {selectedVerifierId ? (
          <>
            <Row>
              {Object.entries(allPermissionList)?.map((item, index) => (
                <Col span={6} xs={24} sm={12} md={8} xl={8} key={index}>
                  <Card
                    title={item[0]}
                    style={{ height: "450px", overflowY: "auto" }}
                  >
                    {Object.entries(item[1])?.map((submenu) =>
                      submenu[1]?.map((permissionOption, permissionIndex) => [
                        <Form.Item
                          name={[
                            "selectedPermission",
                            `${permissionOption?.id}`,
                          ]}
                          valuePropName="checked"
                          wrapperCol={{
                            offset: 0,
                            span: 16,
                          }}
                          key={permissionOption?.id + permissionIndex}
                        >
                          <Checkbox value={item?.id ?? ""}>
                            <Tag color={"#7e89ea"} style={{ margin: "2px" }}>
                              {permissionOption?.name}
                            </Tag>
                          </Checkbox>
                        </Form.Item>,
                      ])
                    )}
                  </Card>
                </Col>
              ))}
            </Row>

            <Row>
              <Card
                title="Select All (Deselect Manually)"
                style={{ height: "380px", overflowY: "auto" }}
              >
                {Object.entries(allPermissionList)?.map((item, index) => (
                  <Col span={6} xs={24} sm={12} md={8} xl={8} key={index}>
                    <Checkbox value={item[0] ?? ""} onChange={_handleSelectAll}>
                      <Tag color={"red"} style={{ margin: "2px" }}>
                        {item[0]}
                      </Tag>
                    </Checkbox>
                  </Col>
                ))}
              </Card>
            </Row>

            {
              // PERMISSION ID OF "admin.management.permission.update" is 73
              isAllowedToAccess(allowedPermission, {
                menuName: "admin",
                subMenuName: "management",
                permissionID: "73",
              }) && (
                <Form.Item>
                  <Button type="primary" htmlType="submit">
                    Update Permission
                  </Button>
                </Form.Item>
              )
            }
          </>
        ) : (
          ""
        )}
      </Form>
    </Space>
  );
};

// form label and field ratio
const formItemLayout = {
  labelAlign: "left",
  labelCol: {
    xs: {
      span: 24,
    },
  },
  wrapperCol: {
    xs: {
      span: 24,
    },
  },
};

// multiple minWidth is intentional
const fillWidthStyle = {
  width: "100%",
  minWidth: "-moz-available" /* WebKit-based browsers will ignore this. */,
  minWidth:
    "-webkit-fill-available" /* Mozilla-based browsers will ignore this. */,
  minWidth: "fill-available",
};

export default UpdatePermission;
