import React, { useState } from 'react';

// Import Components
import { Button, Col, Form, Input, Row, Select, Table, Typography } from 'antd';

// Import Actions, Methods, & Reducers
import { useDispatch, useSelector } from 'react-redux';
import { addApplicationTypeToOrg, fetchOrgOptions } from '../../../Services/Actions/adminPanelActions';
import { setTripWiseQuestionList, setSelectedOrgId } from '../../../Services/Reducers/adminPanelReducer';

// Import Constants
const { Title } = Typography;

// Left Table parts
const columns = [
  {
    title: 'Subtype',
    dataIndex: 'app_subtype',
    key: 'subtype',
  },
  {
    title: 'Prefix',
    dataIndex: 'app_prefix',
    key: 'prefix',
  },

];

const CreateSubType = () => {
  // Get Data From Redux
  const { subtypeOptions, appTypeOptions } = useSelector(state => state.adminPanel)
  const { orgList } = useSelector(state => state.common)
  const selectedOrgId = useSelector(state => state.adminPanel.selectedOrgId ?? null)

  // States
  const dispatch = useDispatch()
  const [form] = Form.useForm()
  const [subTypeDataSource, setSubTypeDataSource] = useState([])

  // Update Type Options and Set Subtype with Prefix
  const _onChangeApplicationType = (values) => {
    const result = []
    if (subtypeOptions?.length > 0) {
      subtypeOptions.forEach((item, index) => {
        if (item?.app_type_id === values) {
          return result.push({ ...item, id: index })
        }
      })
    }

    setSubTypeDataSource(result)
  }

  // On Select Organization
  const _onSelectOrg = (value) => {
    // Get Selected Org Options
    dispatch(setSelectedOrgId(value))

    // Fetch Org Options
    dispatch(dispatch => (
      dispatch(fetchOrgOptions({ org_id: value })),
      dispatch(setTripWiseQuestionList([]))
    ))

    // Clear Previous States
    setSubTypeDataSource([])
  }

  // Clear Form Data
  const clearFormField = () => {
    form.resetFields();
  }

  // On Submit Form
  const _onSubmitForm = (values) => {
    // sending post request to add sub-type adn prfix
    dispatch(addApplicationTypeToOrg({
      org_id: selectedOrgId,
      ...values
    }, () => clearFormField()))
  }

  // filtering only valid org
  const withoutAllOrg = orgList?.length > 0 ? orgList.filter(org => org.id !== -1) : []

  return (
    <div style={{ background: "#F6F6F6", padding: "24px 42px" }}>
      {/* Select Organization */}
      <Select
        style={{ width: '230px' }}
        showSearch
        placeholder="Select Organization"
        optionFilterProp="children"
        onChange={_onSelectOrg}
        filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
      >
        {
          withoutAllOrg?.length > 0 && withoutAllOrg.map((item, index) => {
            return (
              <Select.Option key={index + 1} value={item.uuid}>{item.name}</Select.Option>
            )
          })
        }
      </Select>

      <Row justify='space-between' style={{ marginTop: "20px" }}>
        {/* Left Table part */}
        <Col style={{ background: 'white', padding: '12px 24px' }} xs={24} sm={24} md={11} lg={11} xl={11}>
          <section>
            <Title level={4} style={{ marginTop: 20, color: '#0067B1' }}>Subtype List</Title>
            {/* Application Type */}
            <div style={{ marginTop: '10px' }}>
              <p>Type</p>
              <Select
                showSearch
                style={{ width: '100%' }}
                placeholder='Select Application Type'
                optionFilterProp="children"
                onChange={(value) => _onChangeApplicationType(value)}
              >
                {
                  appTypeOptions?.map(item => (
                    <Select.Option
                      key={item?.app_type_id}
                      value={item?.app_type_id}
                    >
                      {item?.label}
                    </Select.Option>
                  ))
                }
              </Select>

            </div>

            {/* SubType with Prefix */}
            <Table pagination={false} style={{ marginTop: 20 }} columns={columns} dataSource={subTypeDataSource} />
          </section>
        </Col>

        {/* Right Table Part */}
        <Col style={{ background: 'white', padding: '12px 24px' }} xs={24} sm={24} md={11} lg={11} xl={11}>
          <section>
            <Title level={4} style={{ marginTop: 20, color: '#0067B1' }}>Subtype Add</Title>
            <div style={{ marginTop: '10px' }}>
              <p>Type</p>
              <Form
                form={form}
                name='application_type_subType_prefix'
                onFinish={_onSubmitForm}
                colon={false}
                layout='vertical'
              >
                {/* Application Type */}
                <Form.Item name="app_type_id" rules={[{ required: true }]}>
                  <Select
                    style={{ width: '100%' }}
                    showSearch
                    allowClear
                    placeholder={'Select Application Type'}
                    filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                    optionFilterProp={'children'}
                  >
                    {
                      appTypeOptions?.map(item => (
                        <Select.Option
                          key={item?.app_type_id}
                          value={item?.app_type_id}
                        >
                          {item?.label}
                        </Select.Option>
                      ))
                    }
                  </Select>
                </Form.Item>

                {/* Application Sub Type */}
                <div style={{ marginTop: '10px' }}>
                  <p>SubType</p>
                  <Form.Item name="app_subtype" rules={[{ required: true }]}>
                    <Input placeholder="Enter Application Sub Type" />
                  </Form.Item>
                </div>

                {/* Application Prefix */}
                <div style={{ marginTop: '10px' }}>
                  <p>Prefix</p>
                  <Form.Item name="app_prefix" rules={[{ required: true }]}>
                    <Input placeholder="Enter Application Prefix" />
                  </Form.Item>
                </div>

                {/* Submit Button */}
                <div style={{ display: 'flex', justifyContent: 'end', marginTop: '20px' }}>
                  <Form.Item>
                    <Button type="primary" htmlType="submit">
                      Submit
                    </Button>
                  </Form.Item>
                </div>
              </Form>
            </div>
          </section>
        </Col>
      </Row>
    </div>
  )
}
export default CreateSubType;
