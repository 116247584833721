import React, { useState } from 'react';

// Import Components
import { Button, Form, Input, Modal, Upload } from 'antd';

// Import Hooks
import { useForm } from 'antd/lib/form/Form'

// Import Icons
import { UploadOutlined } from '@ant-design/icons';
import { getDocuments, uploadDocument } from '../../../Services/Actions/documentsActions';

const UploadDocumentModal = ({ isFileUploadModalOpen, setIsFileUploadModalOpen, setDataSource }) => {

  // States
  const [form] = useForm()
  const [fileList, setFileList] = useState([])
  const [uploadedFile, setUploadedFile] = useState(null)

  // Handle File Upload
  const _handleFileUpload = (values) => {

    if (fileList?.length === 1) {
      const formData = new FormData()
      formData.append('file', uploadedFile?.[0].originFileObj)
      formData.append('name', values.name)
      formData.append('sort_order', values.sort_order)

      // Call API
      uploadDocument(formData)
        .then(data => {
          if (data !== undefined) {
            _handleCancel()

            getDocuments()
              .then(files => {
                if (files !== undefined) {
                  const sortedFiles = files.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
                  setDataSource(sortedFiles)
                }
              })
          }
        })
    }

  }

  // Handle Cancel
  const _handleCancel = () => {
    setIsFileUploadModalOpen(false)
    form.resetFields()
  }

  // Handle File Upload Changes
  const _handleFileUploadChange = (info) => {
    const file = info.fileList

    // Check if file is not valid
    if (file) {
      // Set to State
      setUploadedFile(file)
    }
    else {
      setUploadedFile(null)
    }
  }

  // File Upload Props
  const props = {
    onRemove: (file) => {
      const index = fileList.indexOf(file);
      const newFileList = fileList.slice();
      newFileList.splice(index, 1);
      setFileList(newFileList);
    },
    beforeUpload: (file) => {
      setFileList([...fileList, file]);
      return false;
    },
    multiple: false,
    maxCount: 1,
    fileList,
  };

  return (
    <>
      <Modal title='Upload Document' visible={isFileUploadModalOpen} onCancel={_handleCancel} footer={null} >
        <Form
          layout={'vertical'}
          labelCol={{ span: 6, xs: 24, sm: 24, md: 12, lg: 12, xl: 12, xxl: 12 }}
          wrapperCol={{ span: 24, xs: 24, sm: 24, md: 12, lg: 12, xl: 12, xxl: 12 }}
          form={form}
          onFinish={_handleFileUpload}
        >
          {/* Name */}
          <Form.Item
            name='name'
            label='Name'
            rules={[
              {
                required: true,
                message: 'Please enter file name'
              },
              {
                min: 6,
                message: 'Name must be atleast 6 characters'
              }
            ]}
          >
            <Input placeholder='File Name' />
          </Form.Item>

          {/* Sort Order */}
          <Form.Item
            name='sort_order'
            label='Sort Order'
            rules={[
              {
                // type: 'number',
                required: true,
                message: 'Please enter sort order!'
              }]}
          >
            <Input
              placeholder='Enter Sort Order'
            />
          </Form.Item>

          {/* File */}
          <Form.Item
            name='file'
            label='Document'
            rules={[{ required: true, message: 'Please select file!' }]}
          >
            <Upload {...props} onChange={_handleFileUploadChange} >
              <Button icon={<UploadOutlined />} disabled={fileList?.length === 1}>Select File</Button>
            </Upload>
          </Form.Item>

          {/* Submit */}
          <Form.Item>
            <Button htmlType='submit' type='primary' style={{ backgroundColor: '#007c4a', border: 'none', fontWeight: 'bold' }}>Upload</Button>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default UploadDocumentModal;