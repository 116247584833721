import React from "react";
import { connect } from "react-redux";
import dayjs from "dayjs";
import PropTypes from "prop-types";

// Import Components
import { Button, Card, Result, Space } from "antd";
import DashboardSummaryTable from "../Components/Layouts/DashboardSummaryTable";
import FilterWithDateOrg from "../Components/Layouts/FilterWithDateOrg";

// Import Actions
import { getSummary } from "../Services/Actions/dashboardActions";
import { isAllowedToAccess } from "../Services/Actions/permissionActions";

class Dashboard extends React.PureComponent {
  componentDidMount() {
    this._handleSubmit();
  }

  // Handle on submit
  _handleSubmit = (startDate, endDate, selectedGroup, selectedCity) => {
    const { dispatch, user, allowedPermission } = this.props;

    const formatedStartDate = startDate
      ? startDate.format("YYYY-MM-DD")
      : dayjs(new Date()).format("YYYY-MM-DD");
    const formatedEndDate = endDate
      ? endDate.format("YYYY-MM-DD")
      : dayjs(new Date()).format("YYYY-MM-DD");

    // set params
    let params = { dateFrom: formatedStartDate, dateTill: formatedEndDate };
    // if org filter is restricted. we show  only that specific org data as default
    // PERMISSION ID OF "dashboard.dashboard.filter.organization" is 2
    if (
      !isAllowedToAccess(allowedPermission, {
        menuName: "dashboard",
        subMenuName: "dashboard",
        permissionID: "2",
      })
    ) {
      params = { ...params, org_id: user?.org_id ?? null };
    }
    // if not restricted and changed option from drowpdown
    if (selectedGroup && selectedGroup?.uuid !== -1) {
      params = { ...params, org_id: selectedGroup.uuid };
    }

    if (selectedCity && selectedCity?.id !== -1) {
      params = { ...params, city: selectedCity.value };
    }

    // dispatch action
    dispatch(getSummary(params));
  };

  render() {
    const { allowedPermission } = this.props;

    return (
      <div>
        <Card title={"Dashborad"} hoverable>
          <Space
            direction={"vertical"}
            size={"middle"}
            style={{ width: "100%" }}
          >
            <FilterWithDateOrg
              showDate={isAllowedToAccess(allowedPermission, {
                menuName: "dashboard",
                subMenuName: "dashboard",
                permissionID: "1",
              })}
              onSubmit={this._handleSubmit}
              // PERMISSION ID OF "dashboard.dashboard.filter.organization" is 2
              showOrg={isAllowedToAccess(allowedPermission, {
                menuName: "dashboard",
                subMenuName: "dashboard",
                permissionID: "2",
              })}
              showCity={isAllowedToAccess(allowedPermission, {
                menuName: "dashboard",
                subMenuName: "dashboard",
                permissionID: "3",
              })}
            />
            {isAllowedToAccess(allowedPermission, {
              menuName: "dashboard",
              subMenuName: "dashboard",
              permissionID: "4",
            }) ? (
              <DashboardSummaryTable />
            ) : (
              <Result
                style={{ maxWidth: "500px", margin: "auto" }}
                status="403"
                title="403"
                subTitle="Sorry, you are not authorized to access Dashboard Analytics."
              />
            )}
          </Space>
        </Card>
      </div>
    );
  }
}

// Prop Types
Dashboard.propTypes = {
  allowedPermission: PropTypes.object,
  user: PropTypes.object,
};

Dashboard.defaultProps = {
  allowedPermission: null,
  user: null,
};

const mapStateToProps = (state) => ({
  user: state?.auth?.user ?? null,
  allowedPermission: state?.permission?.allowedPermission ?? null,
});

const mapDispatchToProps = (dispatch) => ({ dispatch });

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
