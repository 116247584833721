import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

// Import Components
import { Card, Row, Col } from "antd";
import { CreateOrg, UpdateOrg, DeleteOrg, CreateOrgStation } from "./index";
import ApplicationTypeAssigner from "./ApplicationTypeAssigner";

// Import Actions, and Reducers
import { createNewOrganization } from "../../../Services/Actions/adminPanelActions";
import { showAlert } from "../../../Services/Actions/commonActions";
import { setCreateNewOrgInput } from "../../../Services/Reducers/adminPanelReducer";
import SelectedOrgInfo from "./SelectedOrgInfo";
import { isAllowedToAccess } from "../../../Services/Actions/permissionActions";

class OrgManagement extends React.PureComponent {
  // Handle Create New Org Input On Change
  _handleCreateNewOrgInputOnChange = (e) => {
    e.preventDefault();
    const { dispatch } = this.props;
    const { value } = e.target;

    // Set Create New Org Input
    dispatch(setCreateNewOrgInput(value));
  };

  // Handle Create New Org
  _handleCreateNewOrg = (value) => {
    const { dispatch } = this.props;

    // Check if value is empty or undefined or null
    if (!value) {
      // Show Error Alert
      showAlert("error", "Organization Name is Required");
      return;
    }
    dispatch(createNewOrganization({ name: value }));
  };
  render() {
    const { createNewOrgInput, isCreateNewOrgLoading, allowedPermission } =
      this.props;
    return (
      <Row gutter={[12, 12]}>
        {
          // PERMISSION ID OF "admin.management.org.add" is 67
          isAllowedToAccess(allowedPermission, {
            menuName: "admin",
            subMenuName: "management",
            permissionID: "67",
          }) && (
            <Col lg={12} md={24}>
              <Card title={"Create New Organization"} hoverable>
                <CreateOrg />
              </Card>
            </Col>
          )
        }

        {
          // PERMISSION ID OF "admin.management.org.update" is 69
          isAllowedToAccess(allowedPermission, {
            menuName: "admin",
            subMenuName: "management",
            permissionID: "69",
          }) && (
            <Col lg={12} md={24}>
              <Card title={"Update Organization"} hoverable>
                <UpdateOrg />
              </Card>
            </Col>
          )
        }

        {
          // PERMISSION ID OF "admin.management.org.delete" is 68
          isAllowedToAccess(allowedPermission, {
            menuName: "admin",
            subMenuName: "management",
            permissionID: "68",
          }) && (
            <Col lg={12} md={24}>
              <Card title={"Delete Organization"} hoverable>
                <DeleteOrg />
              </Card>
            </Col>
          )
        }

        <Col lg={12} md={24}>
          <Card title={"Add Sub Type and Prefix"} hoverable>
            <ApplicationTypeAssigner />
          </Card>
        </Col>

        <Col lg={12} md={24}>
          <Card title={"Create Organization Station"} hoverable>
            <CreateOrgStation />
          </Card>
        </Col>
        <Col lg={12} md={24}>
          <Card title={"Selected Organizations Info"} hoverable>
            <SelectedOrgInfo />
          </Card>
        </Col>
      </Row>
    );
  }
}

// Props Validation
OrgManagement.propTypes = {
  dispatch: PropTypes.func,
  createNewOrgInput: PropTypes.string,
  isCreateNewOrgLoading: PropTypes.bool,
};

OrgManagement.defaultProps = {
  dispatch: () => null,
  createNewOrgInput: "",
  isCreateNewOrgLoading: false,

  allowedPermission: null,
};

// Map State To Props
const mapStateToProps = (state) => ({
  createNewOrgInput: state.adminPanel.createNewOrgInput,
  isCreateNewOrgLoading: state.adminPanel.isCreateNewOrgLoading,
  allowedPermission: state?.permission?.allowedPermission ?? null,
});

// Map Dispatch To Props
const mapDispatchToProps = (dispatch) => ({ dispatch });

export default connect(mapStateToProps, mapDispatchToProps)(OrgManagement);
