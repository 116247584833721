import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import moment from "moment";

// Import Components
import { Card, Space, Tag, Button, Result } from "antd";
import { DownloadOutlined } from "@ant-design/icons";
import FilterWithDateOrg from "../FilterWithDateOrg";
import BasicTable from "../BasicTable";

// Import Actions
import {
  getRecpvTrackerTableData,
  downloadRecpvTracksReport,
} from "../../../Services/Actions/recpvActions";
import { setTrackerTableData } from "../../../Services/Reducers/recpvReducer";
import { isAllowedToAccess } from "../../../Services/Actions/permissionActions";

class TrackerTableReCPV extends React.PureComponent {
  state = {
    tableColumns: [
      {
        title: "Applicant Name",
        dataIndex: "applicant_name",
        key: "applicant_name",
      },
      {
        title: "File no.",
        dataIndex: "file_no",
        key: "file_no",
      },
      {
        title: "Date",
        dataIndex: "date",
        key: "date",
      },
      {
        title: "Trips",
        dataIndex: "trips",
        key: "trips",
      },
      {
        title: "Billing Type",
        dataIndex: "billing_type",
        key: "billing_type",
        render: (billing_type) => (
          <Tag color={billing_type !== "REGULAR" ? "gold" : "green"}>
            {billing_type === "REGULAR"
              ? "Regular"
              : billing_type === "FULL_IN"
              ? "Full In"
              : ""}
          </Tag>
        ),
      },
      {
        title: "Reason",
        dataIndex: "reason",
        key: "reason",
      },
    ],
    startDate: null,
    endDate: null,
    selectedGroup: null,
    selectedCity: null,
  };

  componentDidMount() {
    this._handleSubmit();
  }

  // Handle on submit
  _handleSubmit = (startDate, endDate, selectedGroup, selectedCity) => {
    this.setState({
      startDate,
      endDate,
      selectedGroup,
      selectedCity,
    });
    setTimeout(() => {
      this._getReCPVTracksReport(1, false);
    }, 100);
  };

  // Get Params
  _getParams = () => {
    const { startDate, endDate, selectedGroup, selectedCity } = this.state;

    const formatedStartDate = startDate
      ? moment(startDate).format("YYYY-MM-DD")
      : moment(new Date()).format("YYYY-MM-DD");
    const formatedEndDate = endDate
      ? moment(endDate).format("YYYY-MM-DD")
      : moment(new Date()).format("YYYY-MM-DD");

    // set params
    let params = { dateFrom: formatedStartDate, dateTill: formatedEndDate };
    if (selectedGroup && selectedGroup?.uuid !== -1) {
      params = { ...params, org_id: selectedGroup.uuid };
    }

    if (selectedCity && selectedCity?.id !== -1) {
      params = { ...params, city: selectedCity.value };
    }
    return params;
  };

  // Handle Submit
  _getReCPVTracksReport = (startDate, endDate, selectedGroup, selectedCity) => {
    const { dispatch } = this.props;

    // Get Params
    const params = this._getParams();

    // dispatch action
    dispatch(getRecpvTrackerTableData(params));
  };

  // Handle ReCPV Download
  _downloadReCPVReport = () => {
    const { dispatch } = this.props;

    // get params
    const params = this._getParams();

    // Download Report
    dispatch(downloadRecpvTracksReport(params));
  };
  render() {
    const { tableColumns } = this.state;
    const { data, isLoading, isReCPVTracksReportDownloading, allowedPermission } = this.props;
    return (
      <Card title={"Re-CPV Tracks"} hoverable>
        <Space direction={"vertical"} style={{ width: "100%" }}>
          <FilterWithDateOrg
            showDate={isAllowedToAccess(allowedPermission, { menuName: 're-cpv', subMenuName: 're-cpv', permissionID: '41' })}
            showOrg={isAllowedToAccess(allowedPermission, { menuName: 're-cpv', subMenuName: 're-cpv', permissionID: '42' })}
            showCity={isAllowedToAccess(allowedPermission, { menuName: 're-cpv', subMenuName: 're-cpv', permissionID: '43' })}
            onSubmit={this._handleSubmit}
            isSubmitButtonLoading={isLoading}
          />
          {
            isAllowedToAccess(allowedPermission, { menuName: 're-cpv', subMenuName: 're-cpv', permissionID: '46' }) ?
            <BasicTable
            column={tableColumns}
            data={data}
            isLoading={isLoading}
            footer={() => (
              <div style={{ textAlign: "right" }}>
                { // PERMISSION ID OF "re-cpv.re-cpv.re-cpv.download_list" is 47
                isAllowedToAccess(allowedPermission, { menuName: 're-cpv', subMenuName: 're-cpv', permissionID: '47' })&&<Button
                  type={"primary"}
                  icon={<DownloadOutlined />}
                  loading={isReCPVTracksReportDownloading}
                  onClick={() => {
                    this._downloadReCPVReport();
                  }}
                >
                  {"Download ReCPV Tracks Report"}
                </Button>}
              </div>
            )}
          />:
          <Result
            status="403"
            title="403"
            subTitle="Sorry, you are not authorized to access Re-CPV List."
          />
          }
        </Space>
      </Card>
    );
  }
}

// PropTypes Validation
TrackerTableReCPV.propTypes = {
  date: PropTypes.array,
  isLoading: PropTypes.bool,
  isReCPVTracksReportDownloading: PropTypes.bool,
};

TrackerTableReCPV.defaultProps = {
  date: [],
  isLoading: false,
  isReCPVTracksReportDownloading: false,
  allowedPermission: null,
};

const mapStateToProps = (state) => ({
  data: state.recpv.trackerTableData,
  isLoading: state.recpv.isTrackerTableDataLoading,
  isReCPVTracksReportDownloading: state.recpv.isReCPVTracksReportDownloading,
  allowedPermission: state?.permission?.allowedPermission ?? null,
});

export default connect(mapStateToProps, null)(TrackerTableReCPV);
