// Import Location Images
import Created from './Created.png'
import CreatedHome from './CreatedHome.png'
import CreatedDocument from './CreatedDocument.png'
import CreatedOffice from './CreatedOffice.png'
import CreatedBank from './CreatedBank.png'
import CreatedRental from './CreatedRental.png'
import CreatedCarQuatation from './CreatedCar.png'

import Allocated from './Allocated.png'
import AllocatedHome from './AllocatedHome.png'
import AllocatedDocument from './AllocatedDocument.png'
import AllocatedOffice from './AllocatedOffice.png'
import AllocatedBank from './AllocatedBank.png'
import AllocatedRental from './AllocatedRental.png'
import AllocatedCarQuatation from './AllocatedCar.png'

import Started from './Started.png'
import StartedHome from './StartedHome.png'
import StartedDocument from './StartedDocument.png'
import StartedOffice from './StartedOffice.png'
import StartedBank from './StartedBank.png'
import StartedRental from './StartedRental.png'
import StartedCarQuatation from './StartedCar.png'

import Hold from './Hold.png'
import HoldHome from './HoldHome.png'
import HoldDocument from './HoldDocument.png'
import HoldOffice from './HoldOffice.png'
import HoldBank from './HoldBank.png'
import HoldRental from './HoldRental.png'
import HoldCarQuatation from './HoldCar.png'

import Finished from './Finished.png'
import FinishedHome from './FinishedHome.png'
import FinishedDocument from './FinishedDocument.png'
import FinishedOffice from './FinishedOffice.png'
import FinishedBank from './FinishedBank.png'
import FinishedRental from './FinishedRental.png'
import FinishedCarQuatation from './FinishedCar.png'

import Canceled from './Canceled.png'
import CanceledHome from './CanceledHome.png'
import CanceledDocument from './CanceledDocument.png'
import CanceledOffice from './CanceledOffice.png'
import CanceledBank from './CanceledBank.png'
import CancelRental from './CanceledBank.png'
import CancelCarQuatation from './CanceledCar.png'

import Inactive from './Inactive.png'
import InactiveHome from './InactiveHome.png'
import InactiveDocument from './InactiveDocument.png'
import InactiveOffice from './InactiveOffice.png'
import InactiveBank from './InactiveBank.png'
import InactiveRental from './InactiveRental.png'
import InactiveCarQuatation from './InactiveCar.png'

const MARKER_ICONS = {
    CREATED: {
        Created,
        CreatedHome,
        CreatedDocument,
        CreatedOffice,
        CreatedBank,
        CreatedRental,
        CreatedCarQuatation
    },
    ALLOCATED: {
        Allocated,
        AllocatedHome,
        AllocatedDocument,
        AllocatedOffice,
        AllocatedBank,
        AllocatedRental,
        AllocatedCarQuatation
    },
    STARTED: {
        Started,
        StartedHome,
        StartedDocument,
        StartedOffice,
        StartedBank,
        StartedRental,
        StartedCarQuatation
    },
    HOLD: {
        Hold,
        HoldHome,
        HoldDocument,
        HoldOffice,
        HoldBank,
        HoldRental,
        HoldCarQuatation
    },
    FINISHED: {
        Finished,
        FinishedHome,
        FinishedDocument,
        FinishedOffice,
        FinishedBank,
        FinishedRental,
        FinishedCarQuatation
    },
    CANCELED: {
        Canceled,
        CanceledHome,
        CanceledDocument,
        CanceledOffice,
        CanceledBank,
        CancelRental,
        CancelCarQuatation
    },
    INACTIVE: {
        Inactive,
        InactiveHome,
        InactiveDocument,
        InactiveOffice,
        InactiveBank,
        InactiveRental,
        InactiveCarQuatation
    }
}

export { MARKER_ICONS }