import React from "react";
import ReactDom from "react-dom";
import { Provider } from "react-redux";
import * as Sentry from "@sentry/react";

// Import Components
import App from "./App";

// Import Styles
import "./index.css";
import "bootstrap/dist/css/bootstrap.css";

// Import Redux Store
import store from "./Services/Store/store";

Sentry.init({
  dns: "https://e30ec9eda7704da78daa46285728be49@sentry.barikoimaps.dev/10",
  integrations: [
    new Sentry.BrowserTracing({
      environment: "production",
    }),
    new Sentry.Replay()
  ],
  tracesSampleRate: 1.0,
});

ReactDom.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);
