import axios from 'axios'
import moment from 'moment'

// Import Actions
import { setIsLoading, setAppointments, setAppointmentsTimeline } from '../Reducers/appointmentsReducer'

// Import Types
import { APPOINTMENTS } from '../../Config/config'
import { showAlert } from './commonActions'

// Get Appointments
const getAppointments = ( params ) => {
    return async (dispatch) => {
        // Set Loading to true
        dispatch(setIsLoading(true))
        try {
            const response = await axios.get( APPOINTMENTS.GET_APPOINTMENTS, { params } )

            const data = response?.data?.data?.appointments

            if(!data) {
                dispatch(setAppointments([]))
                dispatch(setIsLoading(false))             
            }
            
            const transformedData = transformAppointmentsData(data)
            dispatch(setAppointments(transformedData))

            // Set Loading to false
            dispatch(setIsLoading(false))
        } catch (error) {

            // Set Loading to false
            dispatch(setIsLoading(false))
            // dispatch(setError(error.response.data.message))
        }
    }
}

// Transform Appointments Data
const transformAppointmentsData = ( appointments ) => {
    return appointments.map( (appointment, index) => {
        return {
            key: index + 1,
            trip_info: {                
                trip_id: appointment?.id,
                trip_type: appointment?.type,
                trip_status: appointment?.status,
                reason: appointment?.active_appointment?.reason,
                active_appointment_id: appointment.active_appointment.id
            },
            trip_id: appointment?.id,
            application_id: appointment?.application?.application_id ?? 'N/A',
            applicant_name: appointment?.application?.applicant?.name ?? 'N/A',
            agent: appointment?.agent?.name,            
            status: ( appointment?.status === 4 || appointment?.status === 5 ) ? getTripStatus(appointment?.status) : 'N/A',
            org_name: appointment?.application?.group?.name,
            appointment_time: formatDate(appointment?.active_appointment?.appointment_time),
        }
    })
}

// Get Trip Status
function getTripStatus(status) {
    // 'CREATED'   => 1, - purple  764FA0
    // 'ALLOCATED' => 2, - orange  5BCAE8
    // 'STARTED'   => 3, - blue  4154A4
    // 'HOLD'      => 4, - yellow F5B629
    // 'FINISHED'  => 5, - green  35AA80
    // 'CANCELED'  => 6, - red C82128
    // 'INACTIVE'  => 7  - grey A8AAAC

    // Check status number and return label and color
    switch(status) {
        case 1:
            return { label: 'Created', color: '#764FA0' }
        case 2:
            return { label: 'Allocated', color: '#5BCAE8' }
        case 3:
            return { label: 'Started', color: '#4154A4' }
        case 4:
            return { label: 'Hold', color: '#F5B629' }
        case 5:
            return { label: 'Finished', color: '#35AA80' }
        case 6:
            return { label: 'Canceled', color: '#C82128' }
        case 7:
            return { label: 'Inactive', color: '#A8AAAC' }
        default:
            return { label: 'N/A', color: '' }
    }
}

// Format Report Date
function formatDate(date) {
    if(!date) {
        return ''
    }
    return moment(date).format('DD-MMM-YYYY hh:mm A')
}

const getAppointmentTimeline = (tripID) => {
    return dispatch => {
        dispatch(setIsLoading(true))

        axios.get(APPOINTMENTS.GET_APPLICATIONS_TIMELINE + tripID)
        .then(res => {
            if(res.status === 200 && res?.data?.data?.appointment_history){
                dispatch(setAppointmentsTimeline(res.data.data.appointment_history))
                dispatch(setIsLoading(false))
            }
        })
        .catch(err => {
            console.error(err)
            showAlert('error', err?.message ?? 'Failed to load timeline')
            dispatch(setIsLoading(false))
        })
    }
}

export { getAppointments, getAppointmentTimeline }