import React from 'react'
import { connect } from 'react-redux'
import _isEqual from 'fast-deep-equal'

// Import Components
import CFMapGL from './CreateFile/CFMapGL'

class UpdateTripMap extends React.PureComponent {
    state={
        textLabelForGeojsonData: []
    }

    componentDidMount(){
         const generateTextlabelGeojson = this._getGeoJsonData()
         this.setState({
            textLabelForGeojsonData: generateTextlabelGeojson
         })
    }
    
    componentDidUpdate(prevProps, prevState){
        const { markerDataTripUpdate } = this.props

        if(!_isEqual(prevProps.markerDataTripUpdate, markerDataTripUpdate)){
            const generateTextlabelGeojson = this._getGeoJsonData()
            this.setState({
               textLabelForGeojsonData: generateTextlabelGeojson
            })
        }

    }

    _getGeoJsonData = () => {

        const { markerDataTripUpdate } = this.props

        const geoJson = {
            "type": "FeatureCollection",
            "features": [],
        }
       
        if (markerDataTripUpdate && markerDataTripUpdate?.length > 0) {


             // Find multiple trip in same lat and lng    
            // let resD = []
            // let visited = []
            // markerDataTripUpdate.forEach((i) => {
            //     const filtered = markerDataTripUpdate.filter((j) => i.latitude === j.latitude && i.longitude === j.longitude);
            //     if (filtered.length > 1) {
            //         if (!visited.includes(i.latitude)) {
            //             resD.push(filtered)
            //         }
            //         visited.push(i.latitude)
            //     } else {
            //         resD.push(filtered)
            //     }
            // })

            // Transform data
            // const transformedData = {
            //     type: 'FeatureCollection',
            //     features: resD.map(d => ({
            //         type: 'Feature',
            //         properties: d,
            //         geometry: {
            //             type: 'Point',
            //             coordinates: [Number(d[0].longitude), Number(d[0].latitude)]
            //         }
            //     })) 
            // }

            markerDataTripUpdate.forEach((data, i) => {

                // if (data?.isVerifier) {
                    geoJson.features.push({
                        "type": "Feature",
                        "geometry": {
                            "type": "Point",
                            "coordinates": [data?.longitude, data?.latitude]
                        },
                        "properties": {
                            ...data,
                            "description":  data?.isVerifier ? data?.label : data?.trip_id,
                        },
                    })
                // } 
                // else if(data?.properties[0]?.isEndLocation){
                //     // data?.properties?.isEndLocation ? data?.verifierName + ' ' + data?.tripEndTime :
                //     const endLocationLabel =  data?.properties[0]?.verifierName + ':' + data?.properties[0]?.tripEndTime
                //     geoJson.features.push({
                //         "type": "Feature",
                //         "geometry": {
                //             "type": "Point",
                //             "coordinates": data?.geometry?.coordinates
                //         },
                //         "properties": {
                //             ...data?.properties[0],
                //             "description":  endLocationLabel,
                //         },
                //     })
                // }
            })
        }

        return geoJson
    }


    render() {
        const { markerDataTripUpdate, getDragEndLngLat, mapContainerHeight } = this.props
        const { textLabelForGeojsonData } = this.state


        // const textLabelForGeojsonData = this._getGeoJsonData()s

        return (
            <div style={{ width: '100%', height: '100%' }}>
                <CFMapGL
                    markerData={markerDataTripUpdate ?? []}
                    geojsonData={textLabelForGeojsonData ?? []}
                    getDragEndLngLat={getDragEndLngLat}
                    adjustZoomOnMarkerDrag={false}
                    mapHeight={mapContainerHeight}
                />
            </div>
        )
    }
}

// Map State To Props
const mapStateToProps = state => ({
    markerDataTripUpdate: state.common.markerDataTripUpdate,
})

export default connect(mapStateToProps, null)(UpdateTripMap)