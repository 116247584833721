import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import moment from "moment";
// Import Components
import {
  Card,
  Space,
  Tag,
  Table,
  Tooltip,
  List,
  Button,
  Modal,
  Result,
  Input,
  Popover,
} from "antd";
import { LoadingOutlined, LineChartOutlined, SearchOutlined, InfoOutlined, InfoCircleOutlined } from "@ant-design/icons";
import FilterWithDateOrg from "../Components/Layouts/FilterWithDateOrg";

// Import Actions
import { getAppointments } from "../Services/Actions/appointmentsActions";
import { getTripTypeStringAndIcon } from "../Services/Actions/taskMapActions";
import CustomTimeline from "../Components/Layouts/Appointment/CustomTimeline";
import { isAllowedToAccess } from "../Services/Actions/permissionActions";

class Appointments extends React.PureComponent {
  // search filter
  constructor(props) {
    super(props);
    this.state = {
      openTimeline: false,
      selectedAppointmentID: null,
      searchText: '',
      searchedColumn: '',
      filteredAppointments: [],
      columns: [
        {
          title: "Trip Id",
          dataIndex: "trip_info",
          key: "trip_info",
          fixed: "left",
          render: ({ trip_id, trip_type, trip_status, reason }) => (
            <div>
              <Tooltip
                placement={"topLeft"}
                title={
                  <div>
                    <List
                      size={"small"}
                      dataSource={[
                        { label: "Trip ID", value: trip_id },
                        {
                          label: "Trip Type",
                          value: this._getIconByType(trip_type, "text"),
                        },
                        { label: "Reason", value: reason ?? "-" },
                      ]}
                      renderItem={(item, index) => (
                        <List.Item
                          key={index + "_reason"}
                          style={{ color: "#FFF" }}
                        >
                          {`${item.label}: ${item.value}`}
                        </List.Item>
                      )}
                    />
                  </div>
                }
                color={trip_status === 4 ? "red" : "rgba(53, 170, 128, 1)"}
              >
                <Tag
                  icon={this._getIconByType(trip_type, "icon")}
                  style={{ cursor: "pointer" }}
                  color={
                    reason && trip_status === 4 ? "red" : !reason ? "gold" : ""
                  }
                >
                  {trip_id}
                </Tag>
              </Tooltip>
            </div>
          ),
        },
        {
          title: "File No.",
          dataIndex: "application_id",
          key: "application_id",
        },
        {
          title: "Applicant Name",
          dataIndex: "applicant_name",
          key: "applicant_name",
        },
        {
          title: "Agent Name",
          dataIndex: "agent",
          key: "agent",
        },
        {
          title: "Org Name",
          dataIndex: "org_name",
          key: "org_name",
        },
        {
          title: "Status",
          dataIndex: "status",
          key: "status",
          render: ({ label, color }) => <Tag color={color}>{label}</Tag>,
        },
        {
          title: "Appointment Time",
          dataIndex: "appointment_time",
          key: "appointment_time",
        },
        {
          title: "Actions",
          dataIndex: "trip_info",
          key: "trip_info",
          render: ({ trip_id, active_appointment_id }) => (
            <div>
              <Tooltip placement={"topLeft"} title={"Appointment Timeline"}>
                <Button
                  onClick={() =>
                    this._handleTimelineModal(true, active_appointment_id)
                  }
                  type="primary"
                  icon={<LineChartOutlined />}
                  size={"small"}
                />
              </Tooltip>
            </div>
          ),
        },
      ], // Initialize columns here or in componentDidMount
    };
    this.searchInput = React.createRef();
  }


  componentDidMount = () => {
    // Get all appointments
    this._handleSubmit();
  };

  _handleTimelineModal(openingFlag, appointmentID) {
    // while opening trip id is required, that's why this will call only for openingFlag = true
    if (appointmentID && openingFlag === true) {
      this.setState({
        openTimeline: openingFlag,
        selectedAppointmentID: appointmentID,
      });
    } else {
      // usually executes when modal is being closed
      this.setState({
        openTimeline: openingFlag,
        selectedAppointmentID: null,
      });
    }
  }

  // Return Icon by Trip Type
  _getIconByType = (tripType, type) => {
    if (tripType && type === "icon") {
      const typeInfo = getTripTypeStringAndIcon(tripType);
      return typeInfo.icon;
    }
    if (tripType && type === "text") {
      const typeInfo = getTripTypeStringAndIcon(tripType);
      return typeInfo.label;
    }
  };

  // Handle Submit
  _handleSubmit = (startDate, endDate, selectedGroup, selectedCity) => {
    const { dispatch } = this.props;

    const formatedStartDate = startDate
      ? startDate.format("YYYY-MM-DD")
      : moment(new Date()).format("YYYY-MM-DD");
    const formatedEndDate = endDate
      ? endDate.format("YYYY-MM-DD")
      : moment(new Date()).format("YYYY-MM-DD");

    // set params
    let params = { dateFrom: formatedStartDate, dateTill: formatedEndDate };
    if (selectedGroup && selectedGroup?.uuid !== -1) {
      params = { ...params, org_id: selectedGroup.uuid };
    }

    if (selectedCity && selectedCity?.id !== -1) {
      params = { ...params, city: selectedCity.value };
    }

    // dispatch action
    dispatch(getAppointments(params));

    // Update the state with filtered appointments and search text
    this.setState({
      filteredAppointments: this.props.appointments,
      searchText: "",
    });
  };
  // Handle Search
  handleSearch = (value) => {
    const { appointments } = this.props;
    const searchText = value.toLowerCase();
    const filteredAppointments = appointments.filter((appointment) =>
      (appointment?.trip_info?.trip_id).toString().toLowerCase().includes(searchText) ||
      appointment?.application_id.toLowerCase().includes(searchText) ||
      appointment.applicant_name.toLowerCase().includes(searchText)
    );
    // Update the state with filtered appointments and search text
    this.setState({
      filteredAppointments: filteredAppointments,
      searchText: value,
    });
  };


  // Handle Reset
  handleReset = () => {

  };


  render() {
    const { columns, openTimeline, selectedAppointmentID, searchText } = this.state;
    const { isLoading, appointments, allowedPermission } = this.props;
    const dataToShow = searchText ? this.state.filteredAppointments : appointments;
    return (
      <div style={{ width: "100%" }}>
        <Card title={"Appointments"} hoverable>
          <Space
            direction={"vertical"}
            size={"middle"}
            style={{ width: "100%" }}
          >
            <FilterWithDateOrg
              onSubmit={this._handleSubmit}
              isSubmitButtonLoading={isLoading}
              showDate={isAllowedToAccess(allowedPermission, {
                menuName: "appointments",
                subMenuName: "appointments",
                permissionID: "28",
              })}
              showOrg={isAllowedToAccess(allowedPermission, {
                menuName: "appointments",
                subMenuName: "appointments",
                permissionID: "29",
              })}
              showCity={isAllowedToAccess(allowedPermission, {
                menuName: "appointments",
                subMenuName: "appointments",
                permissionID: "30",
              })}
            />
            {isAllowedToAccess(allowedPermission, {
              menuName: "appointments",
              subMenuName: "appointments",
              permissionID: "31",
            }) ? (
              <Table
                title={() =>
                  <div style={{ display: "flex" }}>
                    <Input.Search
                    id="fileName"
                    style={{ maxWidth: '300px', marginRight: '10px' }}
                    enterButton
                    allowClear
                    size={"small"}
                    value={searchText}
                    onChange={(e) => this.handleSearch(e.target.value)}
                    placeholder="Search Trip Id,File No,Applicant Name"
                    onSearch={() => this.handleSearch(searchText)}
                  />
                  <Popover placement="bottom" title="Info" content={<p style={{ color: 'red' }}>Please choose a date to view data from the previous day.</p>} trigger="hover">
                    <InfoCircleOutlined style={{ color: 'red', cursor: 'pointer', marginLeft: '10px', fontSize: '18px' }} />
                  </Popover>
                  </div>
                }
                style={{ width: "100%", overflow: "auto" }}
                columns={columns}
                dataSource={dataToShow}
                bordered={true}
                loading={{
                  indicator: <LoadingOutlined style={{ fontSize: 24 }} spin />,
                  spinning: isLoading,
                }}
                pagination={{
                  total: dataToShow?.length,
                  pageSizeOptions: [10],
                  showSizeChanger: false,
                  showTotal: (total, range) =>
                    `${range[0]}-${range[1]} of ${total} trips`,
                }}
              />
            ) : (
              <Result
                style={{ maxWidth: "700px", margin: "auto" }}
                status="403"
                title="403"
                subTitle="Sorry, you are not authorized to access Appointments List."
              />
            )}
          </Space>
        </Card>
        {
          <Modal
            title={"Appointment Timeline"}
            centered
            key={openTimeline}
            visible={openTimeline}
            onCancel={() => this._handleTimelineModal(false)}
            footer={null}
          >
            <CustomTimeline
              key={"ID-" + selectedAppointmentID}
              appointmentID={selectedAppointmentID}
            />
          </Modal>
        }
      </div>
    );
  }
}

// PropTypes
Appointments.propTypes = {
  isLoading: PropTypes.bool,
  dispatch: PropTypes.func,
  appointments: PropTypes.array,
};

Appointments.defaultProps = {
  isLoading: false,
  appointments: [],
  dispatch: () => null,
  allowedPermission: null,
};

const mapStateToProps = (state) => ({
  isLoading: state.appointments.isLoading,
  appointments: state.appointments.appointments,
  allowedPermission: state?.permission?.allowedPermission ?? null,
});

const mapDispatchToProps = (dispatch) => ({ dispatch });

export default connect(mapStateToProps, mapDispatchToProps)(Appointments);
