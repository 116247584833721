import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    reqTrips: [],
    reqTripsPagination: {},
}

const requestedTripsSlice = createSlice({
    name: 'requestedTrips',
    initialState,
    reducers: {
        setReqTrips: (state, action) => {
            state.reqTrips = action.payload
        },
        setReqTripsPagination: (state, action) => {
            state.reqTripsPagination = action.payload
        }
    }
})

export const { setReqTrips, setReqTripsPagination } = requestedTripsSlice.actions
export default requestedTripsSlice.reducer