import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

// Import Components
import { Card, Segmented, Space } from 'antd'
import { AgentManagement, OrgManagement } from '../Components/Layouts/AdminPanel'

// Import Reducers
import { setSelectedMenu, resetState } from '../Services/Reducers/adminPanelReducer'

class AdminPanel extends React.PureComponent {
    // Handle Menu On Change
    _handleMenuOnChange = (selectedMenu) => {
        const { dispatch } = this.props

        // Reset State
        dispatch(resetState())

        // Set Selected Menu
        dispatch(setSelectedMenu(selectedMenu))
    }
    render() {
        const { menus, selectedMenu } = this.props
        return (
            <div>
                <Card title={'Admin Panel'} hoverable>
                    <Space direction={'vertical'} style={{ width: '100%' }}>
                        <Segmented
                            options={menus}
                            value={selectedMenu}
                            onChange={this._handleMenuOnChange}
                            block={true}
                        />
                        {selectedMenu === 1 && <AgentManagement />}
                        {selectedMenu === 2 && <OrgManagement />}
                        {/* { selectedMenu === 3 && <QuestionnaireContainer /> } */}
                    </Space>
                </Card>
            </div>
        )
    }
}

// Props Validation
AdminPanel.propTypes = {
    menus: PropTypes.array,
    selectedMenu: PropTypes.number,
}

AdminPanel.defaultProps = {
    menus: [],
    selectedMenu: 1,
}

// Map State To Props
const mapStateToProps = (state) => ({
    menus: state.adminPanel.menus,
    selectedMenu: state.adminPanel.selectedMenu,
})

// Map Dispatch To Props
const mapDispatchToProps = (dispatch) => ({ dispatch })

export default connect(mapStateToProps, mapDispatchToProps)(AdminPanel)