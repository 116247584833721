import React, { useState, useEffect } from 'react';

// Import Methods
import { useSelector } from 'react-redux';

// Import Components
import { Divider, Modal, Space, Tag, Typography } from 'antd';

// Import CSS
// import 'antd/dist/antd.css';

const QuestionPreview = ({ previewData, setPreviewData, submitFormData }) => {

    // Get Data From Redux
    const { orgList } = useSelector(state => state.common)
    const { allQuestionList } = useSelector(state => state.adminPanel)

    // States
    const [isModalVisible, setIsModalVisible] = useState(true);
    const [previewQuestions, setPreviewQuestions] = useState([])
    const [orgName, setOrgName] = useState('')

    // On Load
    useEffect(() => {
        const selectedOrgName = orgList?.find(item => item?.uuid === previewData?.group_id)
        let matchedQuestion = [];
        previewData?.questions?.forEach(selectedQuestion => {
            allQuestionList?.questions?.forEach(item => {
                if (item?.id === selectedQuestion?.questionID) {
                    matchedQuestion.push(
                        {
                            ...item,
                            isRequired: selectedQuestion?.questionIsRequired,
                            tripType: selectedQuestion?.questionTripType
                        }
                    )
                }
            })
        })
        setOrgName(selectedOrgName?.name)
        setPreviewQuestions(matchedQuestion)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    // Handle Ok
    const handleOk = () => {
        submitFormData()
        setIsModalVisible(false);
    };

    // Handle Cancel
    const handleCancel = () => {
        setIsModalVisible(false);
        setPreviewData({})
    };

    return (
        <>
            <Modal title="Question Preview" visible={isModalVisible} onOk={handleOk} onCancel={handleCancel} width={'70vw'}>
                <Typography.Title leve={4}>
                    {
                        orgName
                    }
                </Typography.Title>
                <Space direction='vertical'>
                    {
                        previewQuestions?.map(item => (
                            <Space direction='vertical' key={item?.id}>
                                <Typography.Text >Trip: {item?.tripType}</Typography.Text>
                                <Typography.Text >Question: {item?.question}</Typography.Text>
                                <Typography.Text >Is Required: {item?.isRequired ? 'Yes' : ' No'}</Typography.Text>
                                <Typography.Text >Option Type: <Tag color={item?.field_type === 'MCQ' ? '#4cc4b2' : '#969fff'}>{item?.field_type ?? 'Missing'}</Tag></Typography.Text>
                                {
                                    item?.field_type === 'MCQ' &&
                                    <Typography.Text >
                                        Options: <Space direction='horizontal'>{item?.children?.map(option => <Tag color={'#583072'} >{option?.name}</Tag>)}</Space>
                                    </Typography.Text>
                                }
                                <Divider fullWidth />
                            </Space>
                        ))
                    }
                </Space >
            </Modal>
        </>
    );
};

export default QuestionPreview;