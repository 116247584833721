import React from 'react'
import PropTypes from 'prop-types' 
import { connect } from 'react-redux'

// Import Components
import { Card, Row, Col } from 'antd'
import AllEndTrip from '../AllEndTrip'
import AddNewTrip from './AddNewTrip'
import { ApplicationHeader, ConcernDetails,TripDetails, ApplicationIssues, Documents, FileUploadModal } from './index'

// Import Actions
import { isAllowedToAccess } from '../../../Services/Actions/permissionActions'

class ApplicationDetails extends React.PureComponent {
    render() {

        const { selectedTripToUpdate, isUpdateTripModalOpen, selectedMultipleTripToUpdate, allowedPermission } = this.props

        return (
            <div>
                <Card 
                    title={ 
                        <ApplicationHeader 
                            isPostActionAllowed={ 
                                // PERMISSION ID OF "reports.reports.file.update" is 14
                                isAllowedToAccess( allowedPermission ,{  menuName: 'reports', subMenuName: 'reports', permissionID: '14' } ) ? true : false
                            }
                        /> 
                    } 
                    headStyle={{ border: '1px solid white' }}
                >
                    <Row gutter={[ 12, 12 ]}>
                        <Col span={ 10 } xs={ 24 } md={ 12 }>
                            <ConcernDetails />
                        </Col>
                        <Col span={ 14 } xs={ 24 } md={ 12 }>
                            <TripDetails />
                        </Col>
                        <Col xs={ 24 } md={ 24 } lg={ 12 }>
                            <Documents />
                        </Col>
                        {
                            (selectedMultipleTripToUpdate.length > 0 || selectedTripToUpdate?.id) && isUpdateTripModalOpen && 
                            <Col span={ 14 } xs={ 24 } md={ 24 } lg={ 12 }>
                                <Card title={ 'All Trip End Location' } hoverable style={{ height: '100%' }}>
                                    <AllEndTrip />
                                </Card>
                            </Col>
                        }
                        <Col xs={ 24 } md={ 12 }>
                            <ApplicationIssues />
                        </Col>
                        {
                            // controlling access
                            // PERMISSION ID OF "reports.reports.attachment.upload" is 27
                            isAllowedToAccess( allowedPermission ,{  menuName: 'reports', subMenuName: 'reports', permissionID: '27' } ) && 
                                <Col xs={ 24 } key={ 'file-upload' }>
                                    <FileUploadModal />
                                </Col>
                        }
                        {
                             // PERMISSION ID OF ""reports.reports.trip.add" is 22
                             isAllowedToAccess( allowedPermission ,{  menuName: 'reports', subMenuName: 'reports', permissionID: '22' } ) &&
                            <Col xs={ 24 } key={ 'add-new-trip' }>
                                <AddNewTrip />
                            </Col>
                        }
                    </Row>                    
                </Card>                
            </div>
        )
    }
}

// Props Validation
ApplicationDetails.propTypes = {
    selectedTripToUpdate: PropTypes.object,
    isUpdateTripModalOpen: PropTypes.bool,
    selectedMultipleTripToUpdate: PropTypes.array,
    allowedPermission: PropTypes.object
}
ApplicationDetails.defaultProps = {
    dispatch: () => null,
    selectedTripToUpdate: null,
    isUpdateTripModalOpen: false,
    selectedMultipleTripToUpdate: [],
    allowedPermission: null,
}

const mapStateToProps =(state) => ({
    selectedTripToUpdate: state?.common?.selectedTripToUpdate ?? null,
    isUpdateTripModalOpen: state?.common?.isUpdateTripModalOpen ?? false,
    selectedMultipleTripToUpdate: state?.common?.selectedMultipleTripToUpdate ?? [],
    allowedPermission: state?.permission?.allowedPermission ?? null
})

const mapDispatchToProps = dispatch => ({ dispatch })


export default connect(mapStateToProps, mapDispatchToProps)(ApplicationDetails)