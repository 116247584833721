/* Creating a reducer. */
import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    isLoading: false,
    summary: [],
}

const dashboardSlice = createSlice({
    name: 'dashboard',
    initialState,
    reducers: {
        setIsLoading: (state, action) => {
            state.isLoading = action.payload
        },
        setSummary: (state, action) => {
            state.summary = action.payload
        },        
    }
})

export const { setIsLoading, setSummary, setError } = dashboardSlice.actions
export default dashboardSlice.reducer