import React from "react";

// Import Components
import { Card, Row, Col } from "antd";
import {
  AddAgent,
  RemoveAgent,
  AddUserToOrg,
  DeleteUserFromOrg,
} from "./index";
import { useSelector } from "react-redux";
import { isAllowedToAccess } from "../../../Services/Actions/permissionActions";

const AgentManagement = () => {
  const allowedPermission = useSelector(
    (state) => state?.permission?.allowedPermission ?? null
  );

  return (
    <div>
      <Row gutter={[12, 12]}>
        {
          // PERMISSION ID OF "admin.management.agent.add" is 64
          isAllowedToAccess(allowedPermission, {
            menuName: "admin",
            subMenuName: "management",
            permissionID: "64",
          }) && (
            <Col span={12} md={12} xs={24}>
              <Card title={"Add Agent"} hoverable>
                <AddAgent />
              </Card>
            </Col>
          )
        }

        {
          // PERMISSION ID OF "admin.management.agent.remove" is 65
          isAllowedToAccess(allowedPermission, {
            menuName: "admin",
            subMenuName: "management",
            permissionID: "65",
          }) && (
            <Col span={12} md={12} xs={24}>
              <Card title={"Remove Agent"} hoverable>
                <RemoveAgent />
              </Card>
            </Col>
          )
        }
        {isAllowedToAccess(allowedPermission, {
          menuName: "admin",
          subMenuName: "management",
          permissionID: "64",
        }) && (
          <Col span={12} md={12} xs={24}>
            <Card title={"Add User to Org"} hoverable>
              <AddUserToOrg />
            </Card>
          </Col>
        )}
        {isAllowedToAccess(allowedPermission, {
          menuName: "admin",
          subMenuName: "management",
          permissionID: "65",
        }) && (
          <Col span={12} md={12} xs={24}>
            <Card title={"Delete User from Org"} hoverable>
              <DeleteUserFromOrg />
            </Card>
          </Col>
        )}
      </Row>
    </div>
  );
};

export default AgentManagement;
