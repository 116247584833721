import React from 'react'
import axios from 'axios'

// Import Components
import { Row, Col, Input, Button, Form } from 'antd'
import NewConcernReCPV from './NewConcernReCPV'
import NewAdditionalReCPVTrip from './NewAdditionalReCPVTrip'

// Import Types
import { RECPV } from '../../../Config/config'

// Import Actions
import { showAlert } from '../../../Services/Actions/commonActions'

class AddConcernReCPV extends React.PureComponent {
    state = {
        selectedConcernType: null,
        addressDetailsOne: {},
        addressDetailsTwo: {},
        notExact: {
            tripOne: false,
            tripTwo: false,
        },
        loading: false,
        update: new Date(),
        tripList: []
    }

    // Handle AutoComplete Exact Address
    _getExactAddress = (value, option) => {

        if (value?.id) {
            const exactAddressDetails = value
            if (value.id === `additional-recpv-trip-1`) {
                this.setState({ addressDetailsOne: exactAddressDetails })
            } else if (value.id === `additional-recpv-trip-2`) {
                this.setState({ addressDetailsTwo: exactAddressDetails })
            } else {
                // Show alert
                showAlert('error', 'Something went wrong!')
            }
        }
    }

    // handle not exact
    _handleNotExact = e => {
        const { notExact } = this.state
        if (e.target.name === 'additional-recpv-trip-1') {
            this.setState({ notExact: { ...notExact, tripOne: e.target.checked } })
        } else if (e.target.name === 'additional-recpv-trip-2') {
            this.setState({ notExact: { ...notExact, tripTwo: e.target.checked } })
        } else {
            // Show alert
            showAlert('error', 'Something went wrong!')
        }
    }

    // on submit 
    _onSubmit = values => {

        this.setState({ loading: true })
        
        if (values && values.concernType && values.type1 && values.address1) {

            const { applicationID } = this.props
            if (applicationID) {
                const concernData = new FormData()
                concernData.append('application_id', applicationID)
                concernData.append('name', values.name)
                concernData.append('type', values.concernType)
                concernData.append('image', values.image ? values.image : '')
                concernData.append('phone', values.phone)
                concernData.append('spouse', '')
                concernData.append('designation', values.designation ? values.designation : '')

                axios.post(RECPV.ADD_NEW_CONCERN, concernData)
                .then(res => {
                    if (res.data.status === 200) {
                        if (res.data.data.concern && res.data.data.concern.id) {
                            const { addressDetailsOne, addressDetailsTwo, notExact } = this.state
                            const concernID = res.data.data.concern.id
                            const tripOne = {
                                type: values.type1,
                                address: values.address1,
                                notExact: notExact.tripOne,
                                reason: values.reason,
                                bankAccountName: values.bankAccountName1 ? values.bankAccountName1 : '',
                                bankAccountNumber: values.bankAccountNumber1 ? values.bankAccountNumber1 : '',
                                bankName: values.bankName1 ? values.bankName1 : '',
                                bankBranch: values.bankBranch1 ? values.bankBranch1 : ''

                            }
                            const tripTwo = {
                                type: values.type2,
                                address: values.address2,
                                notExact: notExact.tripTwo,
                                reason: values.reason,
                                bankAccountName: values.bankAccountName2 ? values.bankAccountName2 : '',
                                bankAccountNumber: values.bankAccountNumber2 ? values.bankAccountNumber2 : '',
                                bankName: values.bankName2 ? values.bankName2 : '',
                                bankBranch: values.bankBranch2 ? values.bankBranch2 : ''
                            }
                            this._createAdditionalTrip(concernID, tripOne, addressDetailsOne, 'tripOne')
                            this._createAdditionalTrip(concernID, tripTwo, addressDetailsTwo, 'tripTwo')
                        }
                    } else {
                        // Show alert
                        showAlert('error', 'Something went wrong!')
                        this.setState({ loading: false })
                    }
                }).catch(err => {
                    // Show alert
                    this.setState({ loading: false })
                    showAlert('error', err?.message ?? 'Something went wrong!')
                    console.error(err)
                })
            }






        }
    }

    _createAdditionalTrip = (concernID, trip, addressDetails, type) => {
        const { applicationID } = this.props
        if (concernID && trip && addressDetails && applicationID) {
            const data = new FormData()

            if (addressDetails && addressDetails.address && addressDetails.latitude) {
                data.append('application_id', applicationID)
                data.append('concern_id', concernID)
                data.append('type', trip.type)
                data.append('address', trip.address)
                data.append('is_address_exact', trip.notExact ? 0 : 1)
                data.append('reason', trip.reason)
                data.append('tag', 'RE_CPV')

                data.append('exact_address', addressDetails.address)
                data.append('latitude', Number(addressDetails.latitude))
                data.append('longitude', Number(addressDetails.longitude))
                data.append('place_name', addressDetails.placeName ? addressDetails.placeName : '')
                data.append('area', addressDetails.area)
                data.append('comment', '')

                if (trip.type === 'BANK') {
                    data.append('bank[account_title]', addressDetails.bankAccountName)
                    data.append('bank[account_number]', addressDetails.bankAccountNumber)
                    data.append('bank[name]', addressDetails.bankName)
                    data.append('bank[branch]', addressDetails.bankBranch)
                }

                axios.post(RECPV.GET_TRIP_LIST, data).then(res => {
                    if (res.data.status === 200) {
                        const tripId = res.data.data.trips[0]
                        const { tripList } = this.state
                        this.setState({ tripList: [...tripList, tripId] })
                        if (type === 'tripTwo') {
                            this._submitToReCPVTracks(applicationID, trip.reason)
                        }
                        showAlert('success', res?.data?.message ?? 'Added Successfully')
                        this.setState({ loading: false })
                    } else {
                        // Show Alert
                        showAlert('error', res?.data?.message ?? 'Something went wrong')
                        this.setState({ loading: false })

                    }

                }).catch(err => {
                    console.error(err)
                    // Show Alert
                    showAlert('error', err?.response?.data?.message ?? err?.message ?? 'Something went wrong')
                    this.setState({ loading: false })
                })
            }
        }

    }

    // RE-CPV TRACKS
    _submitToReCPVTracks = (applicationId, reason) => {
        const { tripList } = this.state
        const { reload } = this.props
        if (applicationId && reason && tripList && tripList.length === 2) {
            const formData = new FormData()

            tripList.map((trip, index) => formData.append(`trips[${index}]`, trip))            
            formData.append('reason', reason)
            formData.append('type', 'REGULAR')
            formData.append('application_id', applicationId)

            // data post
            axios.post(RECPV.GET_RECPV_TRACKER_DATA, formData).then(res => {
                if (res.data.status === 200) {
                    this.setState({ loading: false, placeName: '' })
                    // Show Alert
                    showAlert('success', res?.data?.message ?? 'Concern & Re-CPV Trip created successfully.')
                    this._clearFormData()
                    reload()
                    this._updateComponent()

                } else {
                    // Show Alert
                    showAlert('error', res?.data?.message ?? 'Something went wrong')
                    this.setState({ loading: false })
                }
            }).catch(err => {
                // Show Alert
                showAlert('error', err?.response?.data?.message ?? err?.message ?? 'Something went wrong')
                this.setState({ loading: false })
                console.error(err)
            })
        }
    }

    // update component
    _updateComponent = () => {
        this.setState({ update: new Date() })
    }

    // handle place name1
    _handlePlaceName1 = e => {
        this.setState(prevState => ({
            addressDetailsOne: {
                ...prevState.addressDetailsOne,
                placeName: e.target.value
            }
        }))
    }

    // handle place name2
    _handlePlaceName2 = e => {
        this.setState(prevState => ({
            addressDetailsTwo: {
                ...prevState.addressDetailsTwo,
                placeName: e.target.value
            }
        }))
    }

    // handle selected concern from <NewConcernReCPV /> card component to show relevent trip type in <NewAdditionalReCPVTrip /> component
    _handleSelectedConcernType = (value) => {

        this.setState({
            selectedConcernType: value
        })
    }

    // clear Form Data
    formRef = React.createRef()
    _clearFormData = () => {
        this.formRef.current.resetFields()
    }
    render() {
        const { addressDetailsOne, addressDetailsTwo, loading, selectedConcernType } = this.state
        return (
            <div>
                <Form onFinish={this._onSubmit} layout='vertical' ref={this.formRef}>
                    <div >
                        <Form.Item
                            name='reason'
                            rules={[{ required: true, message: 'Reason cannot be empty.' }]}
                        >
                            <Input
                                style={{
                                    textAlign: 'left'
                                }}
                                placeholder='Reason'
                            />
                        </Form.Item>
                    </div>

                    <Row gutter={[16, 24]}>
                        <Col span={8} xs={ 24 } md={ 12 } xl={ 8 }>
                            <div style={{ ...container }}>
                                <NewConcernReCPV 
                                    _handleSelectedConcernType={this._handleSelectedConcernType}
                                />
                            </div>
                        </Col>
                        <Col span={8} xs={ 24 } md={ 12 } xl={ 8 }>
                            <div style={{ ...container }}>
                                <NewAdditionalReCPVTrip
                                    id={`additional-recpv-trip-1`}
                                    addressDetails={addressDetailsOne}
                                    getExactAddress={this._getExactAddress}
                                    handleNotExact={this._handleNotExact}
                                    handlePlaceName={this._handlePlaceName1}
                                    tripNo={1}
                                    selectedConcernType={selectedConcernType}
                                    isRequired={ true }
                                />
                            </div>
                        </Col>
                        <Col span={8} xs={ 24 } md={ 12 } xl={ 8 }>
                            <div style={{ ...container }}>
                                <NewAdditionalReCPVTrip
                                    id={`additional-recpv-trip-2`}
                                    addressDetails={addressDetailsTwo}
                                    getExactAddress={this._getExactAddress}
                                    handleNotExact={this._handleNotExact}
                                    handlePlaceName={this._handlePlaceName2}
                                    tripNo={2}
                                    selectedConcernType={selectedConcernType}
                                    isRequired={ false }
                                />
                            </div>
                        </Col>
                    </Row>
                    <div style={{ float: 'right', margin: '1rem 0 0 0' }}>
                        <Form.Item noStyle>
                            <Button
                                loading={loading}
                                type='primary'
                                htmlType='submit'>
                                Submit
                            </Button>
                        </Form.Item>
                    </div>
                </Form>
            </div>
        )
    }
}

// JSS Styles
const container = {
    display: 'block',
    justifyContent: 'center',
    height: '100%',
    border: '1px solid rgba(0, 0, 0, 0.1)',
    borderRadius: '4px',
    padding: '0.8rem',
}

export default AddConcernReCPV