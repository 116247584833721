import React from 'react';
import { Link } from 'react-router-dom';

const PrivacyPolicy = () => {

  return (
    <>
      <div className='custom-container-header-wrapper'>
        <div className='custom-container-header'>
          <div style={logoStyle}>
            <Link to="/" style={{ color: "#59b994" }}>
              Verify
            </Link>
          </div>
        </div>
      </div>
      <div className='custom-container'>
        <h1>Privacy Policy of Barikoi Verify App</h1>
        <p>This Application only collects Users location.</p>
        <p>Updated on : <u>12/12/2023</u></p>

        <h3>Types of Data collected</h3>
        <p>Among the types of Personal Data that this Application collects, by itself or through third parties, there are: Approximate location permission (continuous) and push notifications permission.</p>
        <p>Complete details on each type of Personal Data collected are provided in the dedicated sections of this privacy policy or by specific explanation texts displayed prior to the Data collection.</p>
        <p>Unless specified otherwise, all Data requested by this Application is mandatory and failure to provide this Data may make it impossible for this Application to provide its services. In cases where this Application specifically states that some Data is not mandatory, Users are free not to communicate this Data without consequences to the availability or the functioning of the Service.</p>
        <p>Users who are uncertain about which Personal Data is mandatory are welcome to contact the Owner.</p>
        <p>Any use of Cookies – or of other tracking tools – by this Application or by the owners of third-party services used by this Application serves the purpose of providing the Service required by the User, in addition to any other purposes described in the present document and in the Cookie Policy, if available.</p>
        <p>Users are responsible for any third-party Personal Data obtained, published or shared through this Application and confirm that they have the third party's consent to provide the Data to the Owner.</p>

        <b>Legal Age</b>
        <p>Barikoi Verify App does not knowingly solicit and/or collect personal data from anyone under the legal age of their country of origin. If you are under the legal age in your country of origin, you are not authorized to submit any personal data to us.</p>

        <h3>Mode and place of processing the Data</h3>
        <b>Methods of processing</b>

        <p>Barikoi Verify App does not knowingly solicit and/or collect personal data from anyone under the legal age of their country of origin. If you are under the legal age in your country of origin, you are not authorized to submit any personal data to us.</p>
        <p>The Data processing is carried out using computers and/or IT enabled tools, following organizational procedures and modes strictly related to the purposes indicated. In addition to the Owner, in some cases, the Data may be accessible to certain types of persons in charge, involved with the operation of this Application (administration, sales, marketing, legal, system administration) or external parties (such as third-party technical service providers, mail carriers, hosting providers, IT companies, communications agencies) appointed, if necessary, as Data Processors by the Owner. The updated list of these parties may be requested from the Owner at any time.</p>

        <b>Legal basis of processing</b>
        <p>The Owner may process some Personal Data relating to Users if one of the following applies:</p>

        <ul>
          <li>
            <b>Users have given their consent for one or more specific purposes. Note: Under some legislations the Owner may be allowed to process Personal Data until the User objects to such processing (“opt-out”), without having to rely on consent or any other of the following legal bases. This, however, does not apply, whenever the processing of Personal Data is subject to European data protection law;</b>
          </li>

          <li>
            <b>
              provision of Data is necessary for the performance of an agreement with the User and/or for any pre-contractual obligations thereof;
            </b>
          </li>

          <li>
            <b>
              processing is necessary for compliance with a legal obligation to which the Owner is subject;
            </b>
          </li>

          <li>
            <b>
              processing is related to a task that is carried out in the public interest or in the exercise of official authority vested in the Owner;
            </b>
          </li>

          <li>
            <b>
              processing is necessary for the purposes of the legitimate interests pursued by the Owner or by a third party.
            </b>
          </li>
        </ul>

        <p>In any case, the Owner will gladly help to clarify the specific legal basis that applies to the processing, and in particular whether the provision of Personal Data is a statutory or contractual requirement, or a requirement necessary to enter into a contract.</p>

        <b>Place</b>
        <br />
        <p>The Data is processed at the Owner's operating offices and in any other places where the parties involved in the processing are located</p>
        <p>Depending on the User's location, data transfers may involve transferring the User's Data to a country other than their own. To find out more about the place of processing of such transferred Data, Users can check the section containing details about the processing of Personal Data</p>

        <b>Retention time</b>
        <p>Some user information shall be processed and stored for as long as required by the purpose they have been collected for.</p>
        <p>Therefore:</p>

        <ul>
          <li>
            <b>
              User Information collected for purposes related to the performance of a contract between the Owner and the User shall be retained until such contract has been fully performed.
            </b>
          </li>

          <li>
            <b>
              User Information collected for the purposes of the Owner’s legitimate interests shall be retained as long as needed to fulfill such purposes. Users may find specific information regarding the legitimate interests pursued by the Owner within the relevant sections of this document or by contacting the Owner.
            </b>
          </li>
        </ul>

        <p>
          The Owner may be allowed to retain User Information for a longer period whenever the User has given consent to such processing, as long as such consent is not withdrawn. Furthermore, the Owner may be obliged to retain Personal Data for a longer period whenever required to do so for the performance of a legal obligation or upon order of an authority.
        </p>

        <p>
          Once the retention period expires, Personal Data shall be deleted. Therefore, the right of access, the right to erasure, the right to rectification and the right to data portability cannot be enforced after expiration of the retention period.
        </p>

        <b>Third Party Services and limitation of liability</b>
        <br />
        <p>
          Barikoi Verify App app may contain links to other websites, apps and resources provided by third parties and partners as well as services which are interoperable with third party and/or partners’ services. If you use any third party or partner service which is interoperable with Barikoi Verify App or follow a link from our site or Barikoi Verify App app to any third party or partner service, please note that (1) you consent that we share your personal date with the concerned third party and/or party for the service provisions and (2) they have their own privacy policies and that we do not accept any responsibility or liability for these policies- your consent is therefore implied. Please check these policies before you submit any personal data to these services provided by third parties.
        </p>
        <p>
          Barikoi Verify App takes all reasonable efforts to secure your personal data loss, misuse, unauthorized access and destruction while under our control. However, we cannot guarantee that such information will not be lost, disclosed or accessed by accidental circumstances or by the unauthorized acts of third parties and we may not be held liable for it.
        </p>
        <p>The Site and/or App may indeed provide links to third party websites. Because we have no control over such websites, you acknowledge and agree that we do not endorse such websites and are not responsible or liable, directly or indirectly. Your use of any website linked to from the website is subject to their policies and procedures, and you acknowledge and agree that your use of all such websites is subject to such policies and procedures and not to the barikoi Terms & Conditions of Use and Privacy Policy.</p>
        <p>The Site and/or App may indeed provide links to third party websites. Because we have no control over such websites, you acknowledge and agree that we do not endorse such websites and are not responsible or liable, directly or indirectly. Your use of any website linked to from the website is subject to their policies and procedures, and you acknowledge and agree that your use of all such websites is subject to such policies and procedures and not to the Barikoi Verify App Terms & Conditions of Use and Privacy Policy.</p>
        <p>We use barikoi Maps APIs and this privacy policy incorporates the barikoi Privacy Policy <a href="https://www.barikoi.com/privacy">barikoi.com/privacy</a> as amended by barikoi from time to time.</p>

        <h3>The purposes of processing</h3>
        <p>The Data concerning the User is collected to allow the Owner to provide its Service, comply with its legal obligations, respond to enforcement requests, protect its rights and interests (or those of its Users or third parties), detect any malicious or fraudulent activity, as well as the following: Device permissions for Personal Data access, Analytics, Registration and authentication, Access to third-party accounts, Registration and authentication provided directly by this Application and Advertising.</p>
        <p>For specific information about the Personal Data used for each purpose, the User may refer to the section “Detailed information on the processing of Personal Data".</p>

        <h3>Device permissions for Personal Data access</h3>
        <p>Depending on the User's specific device, this Application may request certain permissions that allow it to access the User's device Data as described below.</p>
        <p>By default, these permissions must be granted by the User before the respective information can be accessed. Once the permission has been given, it can be revoked by the User at any time. In order to revoke these permissions, Users may refer to the device settings or contact the Owner for support at the contact details provided in the present document.</p>
        <p>The exact procedure for controlling Barikoi Verify App permissions may be dependent on the User's device and software.</p>
        <p>Please note that the revoking of such permissions might impact the proper functioning of this Application.</p>
        <p>If User grants any of the permissions listed below, the respective Personal Data may be processed (i.e accessed to, modified or removed) by this Application.</p>

        <b>Approximate location permission (continuous)</b>
        <p>Used for accessing the User's approximate device location. This Application may collect, use, and share User location Data in order to provide location-based services.</p>

        <h3>Detailed information on the processing of Personal Data</h3>
        <p>Personal Data is collected for the following purposes and using the following services:</p>

        <b>Device permissions for Personal Data access</b>
        <p>This Application requests certain permissions from Users that allow it to access the User's device Data as described below.</p>

        <b>Device permissions for Personal Data access (this Application)</b>
        <p>This Application requests certain permissions from Users that allow it to access the User's device Data as summarized here and described within this document.</p>
        <p>Personal Data processed: Approximate location permission (continuous); Camera permission.</p>

        <b>Registration and authentication provided directly by this Application</b>
        <p>By registering or authenticating, Users allow this Application to identify them and give them access to dedicated services. The Personal Data is collected and stored for registration or identification purposes only. The Data collected are only those necessary for the provision of the service requested by the Users.</p>

        <b>Direct registration (this Application)</b>
        <p>The User registers by filling out the registration form and providing the Personal Data directly from the Admin panel.</p>
        <p>Personal Data processed: email address, password.</p>

        <h3>The rights of Users</h3>
        <p>Users may exercise certain rights regarding their Data processed by the Owner. In particular, Users have the right to do the following:</p>
        <ul>
          <li>
            <b>Withdraw their consent at any time.Users have the right to withdraw consent where they have previously given their consent to the processing of their Personal Data.</b>
          </li>

          <li>
            <b>Object to processing of their Data.Users have the right to object to the processing of their Data if the processing is carried out on a legal basis other than consent. Further details are provided in the dedicated section below.</b>
          </li>

          <li>
            <b>Access their Data.Users have the right to learn if Data is being processed by the Owner, obtain disclosure regarding certain aspects of the processing and obtain a copy of the Data undergoing processing.</b>
          </li>

          <li>
            <b>Verify and seek rectification.Users have the right to verify the accuracy of their Data and ask for it to be updated or corrected.</b>
          </li>

          <li>
            <b>Restrict the processing of their Data.   Users have the right, under certain circumstances, to restrict the processing of their Data. In this case, the Owner will not process their Data for any purpose other than storing it.</b>
          </li>

          <li>
            <b>Have their Personal Data deleted or otherwise removed. Users have the right, under certain circumstances, to obtain the erasure of their Data from the Owner.</b>
          </li>

          <li>
            <b>Receive their Data and have it transferred to another controller.Users have the right to receive their Data in a structured, commonly used and machine readable format and, if technically feasible, to have it transmitted to another controller without any hindrance. This provision is applicable provided that the Data is processed by automated means and that the processing is based on the User's consent, on a contract which the User is part of or on pre-contractual obligations thereof.</b>
          </li>

          <li>
            <b>Lodge a complaint.  Users have the right to bring a claim before their competent data protection authority.</b>
          </li>
        </ul>
        <br />

        <b>Details about the right to object to processing</b>
        <p>Where Personal Data is processed for a public interest, in the exercise of an official authority vested in the Owner or for the purposes of the legitimate interests pursued by the Owner, Users may object to such processing by providing a ground related to their particular situation to justify the objection.</p>
        <p>Users must know that, however, should their Personal Data be processed for direct marketing purposes, they can object to that processing at any time without providing any justification. To learn whether the Owner is processing Personal Data for direct marketing purposes, Users may refer to the relevant sections of this document.</p>

        <b>How to exercise these rights</b>
        <p>Any requests to exercise User rights can be directed to the Owner through the contact details provided in this document. These requests can be exercised free of charge and will be addressed by the Owner as early as possible and always within one month.</p>

        <h3>Additional information about Data collection and processing</h3>
        <b>Legal action</b>
        <p>The User's Personal Data may be used for legal purposes by the Owner in Court or in the stages leading to possible legal action arising from improper use of this Application or the related Services.</p>
        <p>The User declares to be aware that the Owner may be required to reveal personal data upon request of public authorities.</p>

        <b>Additional information about User's Personal Data</b>
        <p>In addition to the information contained in this privacy policy, this Application may provide the User with additional and contextual information concerning particular Services or the collection and processing of Personal Data upon request.</p>

        <b>System logs and maintenance</b>
        <p>For operation and maintenance purposes, this Application and any third-party services may collect files that record interaction with this Application (System logs) using other Personal Data (such as the IP Address) for this purpose</p>

        <b>Information not contained in this policy</b>
        <p>More details concerning the collection or processing of Personal Data may be requested from the Owner at any time. Please see the contact information at the beginning of this document.</p>

        <b>How “Do Not Track” requests are handled</b>
        <p>This Application does not support “Do Not Track” requests.</p>

        <b>Changes to this privacy policy</b>
        <p>The Owner reserves the right to make changes to this privacy policy at any time by notifying its Users on this page and possibly within this Application and/or - as far as technically and legally feasible - sending a notice to Users via any contact information available to the Owner. It is strongly recommended to check this page often, referring to the date of the last modification listed at the bottom.</p>
        <p>Should the changes affect processing activities performed on the basis of the User’s consent, the Owner shall collect new consent from the User, where required.</p>
      </div>
    </>
  );
};

// Styles
const logoStyle = {
  fontFamily: "Oleo Script Swash Caps, cursive",
  textAlign: "left",
  padding: "5px",
  fontWeight: "bold",
  fontSize: "32px",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
};

const buttonWrapperStyles = {
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
}

export default PrivacyPolicy;