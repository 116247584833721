import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    isLoading: false,
    tasks: [],
    error: null,
    isTaskDetailsOpen: false,
    verifiersGeoLocation: {},
    tripData: {},
    selectedTrips: [],
    appointmentData: [],
    selectedTrip: {},
    selectedTripIndex: 0,
    isDrawerContentLoading: false,
    selectedTripLayerType: 'regular',
    previousTaskMapFilterParams: {},
}

const taskMapSlice = createSlice({
    name: 'taskMap',
    initialState,
    reducers: {
        setIsLoading: (state, action) => {
            state.isLoading = action.payload
        },
        setTasks: (state, action) => {
            state.tasks = action.payload
        },
        setError: (state, action) => {
            state.error = action.payload
        },
        setIsTaskDetailsOpen: (state, action) => {
            state.isTaskDetailsOpen = action.payload
        },
        setVerifiersGeoLocation: (state, action) => {            
            state.verifiersGeoLocation = action.payload
        },
        setTripData: (state, action) => {
            state.tripData = action.payload
        },
        setAppointmentData: (state, action) => {
            state.appointmentData = action.payload
        },
        setSelectedTrips: (state, action) => {
            state.selectedTrips = action.payload
        },
        setSelectedTrip: (state, action) => {
            state.selectedTrip = action.payload
        },
        setIsDrawerContentLoading: (state, action) => {
            state.isDrawerContentLoading = action.payload
        },
        setSelectedTripIndex: (state, action) => {
            state.selectedTripIndex = action.payload
        },
        setSelectedTripLayerType: (state, action) => {
            state.selectedTripLayerType = action.payload
        },
        setPreviousTaskMapFilterParams: (state, action) => {
            state.previousTaskMapFilterParams = action.payload
        }
    }
})

export const { setPreviousTaskMapFilterParams, setIsLoading, setTasks, setIsTaskDetailsOpen, setVerifiersGeoLocation, setTripData, setAppointmentData, setSelectedTrips, setSelectedTrip, setIsDrawerContentLoading, setSelectedTripIndex, setSelectedTripLayerType } = taskMapSlice.actions
export default taskMapSlice.reducer