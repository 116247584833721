import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    isLoadingForPermissionAction: false,
    allPermissionList: [],
    selectedVerifierPermission: [],
    allowedPermission: null,
}

const permissionReducer = createSlice({
    name: 'permissionPanel',
    initialState,
    reducers: {
        setIsLoadingForPermissionAction: (state, action) => {
            state.isLoadingForPermissionAction = action.payload
        },
        setAllPermissionList: (state, action) => {
            state.allPermissionList = action.payload
        },
        setSelectedVerifierPermission: (state, action) => {
            state.selectedVerifierPermission = action.payload
        },
        setAllowedPermission: (state, action) => {
            state.allowedPermission = action.payload
        },

    }
})

export const { setAllPermissionList, setSelectedVerifierPermission, setIsLoadingForPermissionAction, setAllowedPermission } = permissionReducer.actions
export default permissionReducer.reducer