import React, { useState } from 'react';

// Import Components
import { Button } from 'antd';
import Slider from 'react-slick';
import ImageZoomInOut from './ImageZoomInOut';

// Import Slick CSS
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

// Import Actions, Reducers & Methods
import { useSelector } from 'react-redux';

const OcrDocuments = () => {
  // States
  const [scale, setScale] = useState(1);

  // Handle Zoom In
  const _handleZoomIn = () => {
    setScale((scale) => scale + 0.3);
  };

  // Handle Zoom Out
  const _handleZoomOut = () => {
    setScale((scale) => scale - 0.3);
  };
  
  // Get Value From Redux
  const previewImages = useSelector(state => state?.createFile?.OCR?.previewImages ?? [])

  // Settings
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    swipeToSlide: false,
    swipe: false,
    appendDots: dots => (
      <div
        style={{
          backgroundColor: "transparent",
          padding: "5px",
        }}
      >
        <ul style={{ margin: "0px", display: 'flex', justifyContent: 'center', gap: '5px' }}> {dots} </ul>
      </div>
    ),
    customPaging: (i) => (
      <div
        className="custom-dot"
        style={{
          width: "30px",
          color: "#000",
          border: "1px solid #1677ff",
          backgroundColor: "#fff"
        }}
      >
        {i + 1}
      </div>
    )
  };

  return (
    <div>
      {/* Zoom In/Out */}
      {previewImages.length > 0 && (
        <div style={{ position: 'absolute', zIndex: 10000, top: 130, right: 40 }}>
          <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
            <Button
              type='primary'
              size='small'
              style={{ cursor: 'pointer' }}
              onClick={_handleZoomIn}>
              +
            </Button>
            <Button
              type='primary'
              size='small'
              style={{ cursor: 'pointer' }}
              onClick={_handleZoomOut}>
              -
            </Button>
          </div>
        </div>
      )}

      {/* Image Slider */}
      <Slider {...settings}>
        {previewImages.map((image, index) => (
          <div
            key={index}
          >
            <ImageZoomInOut imageUrl={image.url} scale={scale} />
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default OcrDocuments;