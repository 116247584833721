import { configureStore } from '@reduxjs/toolkit'

// Import Reducers
import {
    authReducer,
    commonReducer,
    dashboardReducer,
    reportsReducer,
    analyticsReducer,
    taskMapReducer,
    appointmentsReducer,
    applicationReducer,
    recpvReducer,
    createFileReducer,
    adminPanelReducer,
    pendingListReducer,
    routeStatisticsReducer,
    permissionReducer,
    billReducer,
    requestedTrips
} from '../Reducers'

// Config Redux Store
const store = configureStore({
    reducer: {
        auth: authReducer,
        common: commonReducer,
        dashboard: dashboardReducer,
        reports: reportsReducer,
        analytics: analyticsReducer,
        taskMap: taskMapReducer,
        appointments: appointmentsReducer,
        application: applicationReducer,
        recpv: recpvReducer,
        createFile: createFileReducer,
        adminPanel: adminPanelReducer,
        pendingList: pendingListReducer,
        routeStatistics: routeStatisticsReducer,
        permission: permissionReducer,
        bill: billReducer,
        requestedTrips: requestedTrips,
    },
    middleware: getDefaultMiddleware =>
        getDefaultMiddleware({
            serializableCheck: false,
        }),
    devTools: window?._env_?.NODE_ENV !== 'production',
})

export default store