import React from 'react'
import { CreateSubType, QuestionList, ListingQuestion } from '../Components/Layouts/Questionnaire'

const Questionnaire = () => {
  return (
    <>
      <CreateSubType />
      <QuestionList />
      <ListingQuestion />
    </>
  )
}

export default Questionnaire