import React, { useEffect, useState } from "react";
import PropTypes from 'prop-types'

// Import Components
import { Card, Form, Input, Button, Select } from "antd";

// Import Assests
import img from './../Assets/Images/register.avif';

// Import Icons
import { LoadingOutlined } from "@ant-design/icons";

// Import Actions, Reducers and Methods
import { useDispatch, useSelector } from "react-redux";
import { register } from '../Services/Actions/authActions'

const Register = () => {

    // Get Data From Redux
    const commonData = useSelector(state => state.common)
    const isLoading = useSelector(state => state.auth.isLoading)
    // const responseData = useSelector(state => state.auth.responseData)

    // States
    const dispatch = useDispatch()
    const [_orgList, set_orgList] = useState([])
    const { orgList } = commonData
    const [form] = Form.useForm();

    // On Orglist Update
    useEffect(() => {
        const onlyList = orgList.filter(item => item.id !== -1)
        set_orgList(onlyList)

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [orgList?.length])


    // Clear Form
    const _clearForm = () => {
        // Clear data from form
        form.resetFields();
    }

    // On Form Submit
    const onFinish = (params) => {
        // adding userType to 1 by default to all user on form data
        params.userType = 1;
        dispatch(register(params, _clearForm))
    };

    return (
        <Card title={'Register'} hoverable>
            <div className="row">
                <div className="col-md-6 d-flex align-items-center justify-content-center">
                    {isLoading ?
                        // Loader
                        <LoadingOutlined
                            className="col-md-6"
                            style={{ fontSize: 24, color: 'blue' }}
                            spin
                        />
                        :
                        <Form
                            name="register"
                            onFinish={onFinish}
                            labelCol={{
                                span: 8,
                            }}
                            wrapperCol={{
                                span: 16,
                            }}
                            labelAlign="left"
                            labelWrap
                            colon={false}
                            form={form}
                        >
                            {/* Name */}
                            <Form.Item name="name" label="Name" rules={[{ required: true, message: 'Please input name!', }]} >
                                <Input placeholder={`Enter Name`} />
                            </Form.Item>

                            {/* Email */}
                            <Form.Item name="email" label="Email" rules={[{ required: true, type: 'email', message: 'Please input email!', }]}  >
                                <Input placeholder={`Enter Email`} />
                            </Form.Item>

                            {/* Password */}
                            <Form.Item name="password" label="Password" rules={[{ required: true, message: 'Please input password!' }]}  >
                                <Input.Password placeholder={`Enter  Password`} />
                            </Form.Item>

                            {/* Phone */}
                            <Form.Item name="phone" label="Phone" rules={[{ required: true, message: 'Please input Phone Number!', }]}  >
                                <Input placeholder={`Enter Phone Number`} />
                            </Form.Item>

                            {/* Role */}
                            <Form.Item name="role" label="Role" rules={[{ required: true }]}>
                                <Select
                                    style={{ width: '100%' }}
                                    showSearch
                                    allowClear
                                    placeholder={'Select Role'}
                                    optionFilterProp={'children'}
                                >
                                    <Select.Option value="OPERATION-AGENT">Operation Agent</Select.Option>
                                    <Select.Option value="FIELD-AGENT">Field Agent</Select.Option>
                                </Select>
                            </Form.Item>

                            {/* Organizations */}
                            <Form.Item name="organizations" label="Organizations" rules={[{ required: true }]}>
                                <Select
                                    mode="multiple"
                                    allowClear
                                    style={{
                                        width: '100%',
                                    }}
                                    placeholder="Please select"
                                    optionFilterProp={'children'}
                                >
                                    {
                                        _orgList?.length > 0 && _orgList?.map((item, index) => {
                                            return (
                                                <Select.Option key={index + 1} value={item?.uuid}>{item?.name}</Select.Option>
                                            )
                                        })
                                    }
                                </Select>
                            </Form.Item>

                            {/* Submit */}
                            <Form.Item label={null}>
                                <Button type="primary" htmlType="submit">
                                    Submit
                                </Button>
                            </Form.Item>
                        </Form>
                    }
                </div>
                <div className="col-md-6 d-flex justify-content-end " >
                    <img className="hidden-mobile" src={img} alt="" />
                </div>
            </div>
        </Card>
    )
}

// Props Validation
Register.propTypes = {
    dispatch: PropTypes.func,
    orgList: PropTypes.array,
}

Register.defaultProps = {
    dispatch: () => null,
    orgList: []
}

export default Register


