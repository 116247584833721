import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

// library
import { Card, Col, Result, Row } from "antd";

//import components
import { UpdatePermission } from "../Components/Layouts/PermissionPanel";

// import actions and reducers
import {
  fetchPermissionList,
  isAllowedToAccess,
} from "../Services/Actions/permissionActions";

const PermissionPanel = () => {
  const [verifierList, setVerifierList] = useState([]);
  const { users } = useSelector((state) => state.common);
  const allowedPermission =
    useSelector((state) => state.permission)?.allowedPermission ?? null;
  const dispatch = useDispatch();

  // on MOUNT
  useEffect(() => {
    dispatch(fetchPermissionList());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // removing 'All Verifier' option
  useEffect(() => {
    const newList = users?.filter((item) => item?.name !== "All Verifier");

    setVerifierList(newList);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [users?.length]);

  return (
    <div>
      <Row gutter={[12, 12]}>
        { // PERMISSION ID OF "admin.management.permission.list" is 72
        isAllowedToAccess(allowedPermission, {
          menuName: "admin",
          subMenuName: "management",
          permissionID: "72",
        }) ? (
          <Col lg={24}>
            <Card title={"Update Access Permission"} hoverable>
              {/* <GrantPermission verifiers={ verifierList } /> */}
              <UpdatePermission verifiers={verifierList} />
            </Card>
          </Col>
        ) : <Result
              style={{ maxWidth: '700px', margin: 'auto' }}
              status="403"
              title="403"
              subTitle="Sorry, you are not authorized to access Permission Panel."
            />
         
        }
        {/*************************
         *  WORK IN PROGRESS below
         * ************************/}
        {/* <Col xs={ 24 } lg={ 12 }>
                    <Card title={ 'User Permissions' } hoverable>
                        <IndividualUserPermission verifiers={ verifierList } />
                    </Card>
                </Col>
                <Col xs={ 24 } lg={ 12 }>
                    <Card title={ 'Allow / Restrict Access' } hoverable>
                        <GrantPermission verifiers={ verifierList } />
                    </Card>
                </Col> */}
        {/*************************
         *  WORK IN PROGRESS above
         * ************************/}
      </Row>
    </div>
  );
};

export default PermissionPanel;
