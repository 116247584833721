import React, { useEffect } from 'react';
import { Timeline } from 'antd';
import { useSelector } from 'react-redux';
import { getAppointmentTimeline } from '../../../Services/Actions/appointmentsActions';
import { useDispatch } from 'react-redux';


const CustomTimeline = ({appointmentID}) => {

const dispatch = useDispatch()

useEffect(() => {
  dispatch(getAppointmentTimeline(appointmentID))
// eslint-disable-next-line react-hooks/exhaustive-deps
},[])

const applicationStates = useSelector(state => state.appointments)

  return (
    <>
      <Timeline mode={'left'}>
        {
         applicationStates?.appointmentsTimeline.length > 0 && applicationStates?.appointmentsTimeline[0].map((item, index) => (
            <Timeline.Item key={index + '-' + item.appointment_time} label={item.appointment_time}>{item.reason ? item.reason : '-'}</Timeline.Item>
          ))
        }
      </Timeline>
    </>
  );
};

export default CustomTimeline;