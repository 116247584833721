import React from "react";

// Import Components
import { Space } from "antd";
import {
  TrackerTableReCPV,
  CreateReCPVTrips,
} from "../Components/Layouts/ReCPV";
import { useSelector } from "react-redux";
import { isAllowedToAccess } from "../Services/Actions/permissionActions";

const ReCPV = () => {
  const allowedPermission =
    useSelector((state) => state.permission.allowedPermission) ?? null;
  return (
    <Space direction={"vertical"} style={{ width: "100%" }}>
      {
        // PERMISSION ID OF "re-cpv.re-cpv.re-cpv.add" is 45
        isAllowedToAccess(allowedPermission, {
          menuName: "re-cpv",
          subMenuName: "re-cpv",
          permissionID: "45",
        }) && 
        <CreateReCPVTrips />
      }
      <TrackerTableReCPV />
    </Space>
  );
};
export default ReCPV;
