import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import store from '../../../Services/Store/store'

// Import Reducers
import { setFormData, setMarkerData } from '../../../Services/Reducers/createFileReducer'

// Import Components
import { Form, Input, Checkbox, Typography } from 'antd'
import ExactAddressAutocomplete from '../ExactAddressAutocomplete'

class TripForm extends React.PureComponent {
    state = {
        address: '',
        exact_address: '',
        placename: '',
        comment: '',
        bank_name: '',
        bank_branch_name: '',
        account_title: '',
        account_number: '',
        not_exact: false,
        isRequired: false,
        isSuggestionBoxOpenProp: false
    }

    componentDidUpdate = (prevProps) => {
        const { isResetClicked } = this.props
        if (isResetClicked !== prevProps.isResetClicked) {
            this.setState({
                address: '',
                exact_address: '',
                placename: '',
                comment: '',
                bank_name: '',
                bank_branch_name: '',
                account_title: '',
                account_number: '',
                not_exact: false,
                isRequired: false,
            })
        }
    }

    // Handle Get Exact Address
    _handleGetExactAddress = address => {
        const { trip, formRef, markerData, dispatch } = this.props

        // Get Current Redux Form Data
        const formData = store.getState().createFile.formData

        // Set to State
        this.setState({ exact_address: address?.address ?? '', placename: address.placename ?? '' })

        // Set Exact Address in form        
        formRef.current.setFieldsValue({
            [`${trip.concern.identifier}`]: {
                [`${trip.identifier}`]: {
                    exact_address: address?.address ?? '',
                    area: address?.area ?? '',
                    latitude: address?.latitude ?? '',
                    longitude: address?.longitude ?? '',
                    city: address?.city ?? '',
                }
            }
        })

        // Set Redux Form Data
        dispatch(setFormData({
            ...formData,
            [`${trip.concern.identifier}`]: {
                ...formData?.[`${trip.concern.identifier}`],
                [`${trip.identifier}`]: {
                    ...formData?.[`${trip.concern.identifier}`]?.[`${trip.identifier}`],
                    exact_address: address?.address ?? '',
                    area: address?.area ?? '',
                    latitude: address?.latitude ?? '',
                    longitude: address?.longitude ?? '',
                    city: address?.city ?? '',
                }
            }

        }))

        // Set required        
        setTimeout(() => {
            this._handleRequiredFields()
        }, 100)

        const id = `${trip.concern.identifier}_${trip.identifier}`

        // Check if address is null or empty
        if (!address) {
            // Filter out marker data with id
            const filteredMarkerData = markerData.filter(m => m.id !== id)
            // Set marker data
            dispatch(setMarkerData(filteredMarkerData))

        } else {
            // Filter out marker data with id
            const filteredMarkerData = markerData.filter(m => m.id !== id)

            // Set Marker Data
            dispatch(setMarkerData([
                ...filteredMarkerData,
                {
                    id,
                    latitude: address.latitude,
                    longitude: address.longitude,
                    label: `${trip.concern.label} ${trip.label}`,
                    concern: trip.concern.label,
                }
            ]))
        }
    }

    // Handle on change
    _handleOnChange = e => {
        const { trip, formRef, dispatch } = this.props

        const { name, value, checked } = e.target

        // Get Current Redux Form Data
        const formData = store.getState().createFile.formData

        this.setState({ [name]: name === 'not_exact' ? checked : value })

        if (name === 'placename') {
            // Set Exact Address in formRef
            formRef.current.setFieldsValue({
                [`${trip.concern.identifier}`]: {
                    [`${trip.identifier}`]: {
                        [name]: value ?? '',
                    }
                }
            })
        }

        dispatch(setFormData({
            ...formData,
            [`${trip.concern.identifier}`]: {
                ...formData?.[`${trip.concern.identifier}`],
                [`${trip.identifier}`]: {
                    ...formData?.[`${trip.concern.identifier}`]?.[`${trip.identifier}`],
                    // [name]: value ?? '',
                    [name ?? 'not_exact']: name ? value : checked
                }

            }

        }))

        // Set required        
        setTimeout(() => {
            this._handleRequiredFields()
        }, 100)
    }

    // Handle Required Fields
    _handleRequiredFields = () => {
        const { address, exact_address, placename, comment, bank_name, bank_branch_name, account_number, account_title } = this.state
        const { formRef, trip } = this.props

        // Check if state values are empty or not and return false
        if (address === '' || exact_address === '' || placename === '' || comment === '') {
            // Clear error msg from formRef for name field
            formRef.current.setFields([
                { name: [`${trip.concern.identifier}`, `${trip.identifier}`, 'address'], errors: null },
                { name: [`${trip.concern.identifier}`, `${trip.identifier}`, 'exact_address'], errors: null }
            ])

            // Check if trip type is 'BANK'
            if (trip.type === 'BANK') {
                formRef.current.setFields([
                    { name: [`${trip.concern.identifier}`, `${trip.identifier}`, 'bank_name'], errors: null },
                    { name: [`${trip.concern.identifier}`, `${trip.identifier}`, 'bank_branch_name'], errors: null },
                    { name: [`${trip.concern.identifier}`, `${trip.identifier}`, 'account_number'], errors: null },
                    { name: [`${trip.concern.identifier}`, `${trip.identifier}`, 'account_title'], errors: null }
                ])
            }

            // Set Required to false
            this.setState({ isRequired: false })
        }

        // Check if one of state values has value and return true
        if (address !== '' || exact_address !== '' || placename !== '' || comment !== '' || bank_name !== '' || bank_branch_name !== '' || account_number !== '' || account_title !== '') {
            // Set Required to true
            this.setState({ isRequired: true })
        }
    }

    // changes isSuggestionBoxOpenProp to open/close suggestion box for exact address
    _handleSuggestionOpenForxactAddress = (booleanValue) => {
        // if suggestion box is open then click in anywhere will close the box
        this.setState({ isSuggestionBoxOpen: booleanValue })
    }

    // get user defined label for "placename"
    _getLabelForPlaceName = (tripType) => {
        const { selectedOrg } = this.props
        if (selectedOrg?.name?.includes('ShopUp') && tripType === 'OFFICE') {
            return 'business name'
        }
        if (tripType === 'OFFICE' || tripType === 'GUARANTOR_OFFICE') {
            return 'Office Name'
        }
        return 'Place Name'
    }

    render() {
        const { address, placename, comment, not_exact, isRequired, isSuggestionBoxOpen, bank_name, bank_branch_name, account_number, account_title } = this.state
        const { trip, formRef } = this.props

        return (
            <div onClick={isSuggestionBoxOpen ? () => this._handleSuggestionOpenForxactAddress(false) : null}>
                <Form.Item
                    label={'Given Address'}
                    name={[`${trip.concern.identifier}`, `${trip.identifier}`, 'address']}
                    rules={[{ required: isRequired, message: 'Please enter address' }]}
                >
                    <Input
                        placeholder={`Enter given address..`}
                        value={address}
                        onChange={this._handleOnChange}
                        name={'address'}
                    />
                </Form.Item>
                <Form.Item
                    label={<Typography.Text>Exact Address</Typography.Text>}
                    name={[`${trip.concern.identifier}`, `${trip.identifier}`, 'exact_address']}
                    rules={[{ required: isRequired, message: 'Please enter exact address' }]}
                >
                    <ExactAddressAutocomplete
                        input={formRef.current.getFieldValue(`${trip?.concern?.identifier}`)?.[trip?.identifier]?.['exact_address'] ?? ''}
                        getExactAddress={this._handleGetExactAddress}
                        isSuggestionBoxOpenProp={isSuggestionBoxOpen}
                        toggleSuggestionBox={this._handleSuggestionOpenForxactAddress}
                    />
                </Form.Item>
                <Form.Item name={[`${trip.concern.identifier}`, `${trip.identifier}`, 'area']} noStyle>
                    <div></div>
                </Form.Item>
                <Form.Item name={[`${trip.concern.identifier}`, `${trip.identifier}`, 'latitude']} noStyle>
                    <div></div>
                </Form.Item>
                <Form.Item name={[`${trip.concern.identifier}`, `${trip.identifier}`, 'longitude']} noStyle>
                    <div></div>
                </Form.Item>
                <Form.Item name={[`${trip.concern.identifier}`, `${trip.identifier}`, 'city']} noStyle>
                    <div></div>
                </Form.Item>
                <Form.Item
                    label={'Not Exact'}
                    name={[`${trip.concern.identifier}`, `${trip.identifier}`, 'not_exact']}
                    valuePropName={'checked'}
                >
                    <Checkbox onChange={this._handleOnChange} value={not_exact} />
                </Form.Item>
                <Form.Item
                    label={this._getLabelForPlaceName(trip?.type)}
                    name={[`${trip.concern.identifier}`, `${trip.identifier}`, 'placename']}
                >
                    <Input
                        name={'placename'}
                        value={placename}
                        placeholder={(trip?.type === 'OFFICE' || trip?.type === 'GUARANTOR_OFFICE') ? 'Enter office name' : 'Enter place name..'}
                        onChange={this._handleOnChange}
                    />
                </Form.Item>
                <Form.Item
                    label={'Comment'}
                    name={[`${trip.concern.identifier}`, `${trip.identifier}`, 'comment']}
                >
                    <Input
                        name={'comment'}
                        value={comment}
                        placeholder={`Enter your comment`}
                        onChange={this._handleOnChange}
                    />
                </Form.Item>
                {
                    trip.type === 'BANK' &&
                    <>
                        <Form.Item
                            label={'Bank Name'}
                            name={[`${trip.concern.identifier}`, `${trip.identifier}`, 'bank_name']}
                            rules={[{ required: isRequired, message: 'Please enter bank name' }]}
                        >
                            <Input
                                name='bank_name'
                                value={bank_name}
                                placeholder={`Enter bank name`}
                                onChange={this._handleOnChange}
                            />
                        </Form.Item>
                        <Form.Item
                            label={'Bank Branch Name'}
                            name={[`${trip.concern.identifier}`, `${trip.identifier}`, 'bank_branch_name']}
                        >
                            <Input
                                name='bank_branch_name'
                                value={bank_branch_name}
                                placeholder={`Enter bank branch name`}
                                onChange={this._handleOnChange}
                            />
                        </Form.Item>
                        <Form.Item
                            label={'Account Title'}
                            name={[`${trip.concern.identifier}`, `${trip.identifier}`, 'account_title']}
                            rules={[{ required: isRequired, message: 'Please enter account title' }]}
                        >
                            <Input
                                name='account_title'
                                value={account_title}
                                placeholder={`Enter bank account title`}
                                onChange={this._handleOnChange}
                            />
                        </Form.Item>
                        <Form.Item
                            label={'Account Number'}
                            name={[`${trip.concern.identifier}`, `${trip.identifier}`, 'account_number']}
                            rules={[{ required: isRequired, message: 'Please enter account number' }]}
                        >
                            <Input
                                name='account_number'
                                value={account_number}
                                placeholder={`Enter bank account number`}
                                onChange={this._handleOnChange}
                            />
                        </Form.Item>
                    </>
                }
            </div>
        )
    }
}

// PropTypes
TripForm.propTypes = {
    trip: PropTypes.object,
    formRef: PropTypes.object,
    isResetClicked: PropTypes.bool,
    markerData: PropTypes.array,
    selectedOrg: PropTypes.object,
    ocrResults: PropTypes.object
}

TripForm.defaultProps = {
    trip: {},
    formRef: {},
    isResetClicked: false,
    markerData: [],
    selectedOrg: null,
    ocrResults: null
}

// Map State To Props
const mapStateToProps = state => ({
    isResetClicked: state.createFile.isResetClicked,
    markerData: state.createFile.markerData,
    selectedOrg: state?.createFile?.selectedOrg ?? null,
    ocrResults: state?.createFile?.OCR?.results ?? null
})

// Map Dispatch To Props
const mapDispatchToProps = dispatch => ({ dispatch })

export default connect(mapStateToProps, mapDispatchToProps)(TripForm)