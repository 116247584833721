import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import _isEqual from 'fast-deep-equal'


// Import Components
import { Space, Row, Col } from 'antd'

// Import Actions
import { getReverseGeoData, showAlert, updateTrip } from '../../Services/Actions/commonActions'
import { setMultipleMarkerDataTripUpdate } from '../../Services/Reducers/commonReducer'
import AllEndTripMapContainer from './AllEndTripMapContainer'

class AllEndTrip extends React.PureComponent {
    state = {
        address: '',
        exact_address: '',
        is_address_exact: 0,
        place_name: '',
        area: '',
        remarks: '',
        latitude: 0,
        longitude: 0,
        initialTripData: null
    }

    // setting map data for update form into state
    _setFormDataToState(singleSelectedTrip, selectedTripFromApplicationReducer){

        const tripData = singleSelectedTrip.id ? singleSelectedTrip : selectedTripFromApplicationReducer

        const selectedTripData = {
            address: tripData?.address ?? '',
            exact_address: tripData?.exact_address ?? '',
            is_address_exact: tripData.is_address_exact ?? 0,
            place_name: tripData?.place_name ?? '',
            area: tripData?.area ?? '',
            remarks: tripData?.remarks ?? '',   
            latitude: tripData?.latitude ?? 0,
            longitude: tripData?.longitude ?? 0,
        }

         // Set state from selected trip
         this.setState({ ...selectedTripData, initialTripData: tripData })
    }

    // setting marker data for only single selected trip
    _setMarkerDataForSingleSelectedTrip(singleSelectedTrip){
        let markerData = []
        
        // Initialize marker data
       
        // Check if selected trip has agent and agent has user_last_lat and user_last_lon
        if (singleSelectedTrip?.agent && singleSelectedTrip?.agent?.user_last_lat && singleSelectedTrip?.agent?.user_last_lon) {
            // Create agent marker
            const agentLocation = {
                id: singleSelectedTrip?.agent?.id ?? 'agent',
                latitude: singleSelectedTrip?.agent?.user_last_lat ?? 0,
                longitude: singleSelectedTrip?.agent?.user_last_lon ?? 0,
                label: singleSelectedTrip?.agent?.name ?? '',
                draggable: false,
            }
            markerData.push(agentLocation)
        }

        // Create exact address marker
        const tripLocation = {
            id: singleSelectedTrip?.exact_address,
            trip_id: singleSelectedTrip?.id,
            concern: 'Exact Address',
            label: 'Exact Address',
            latitude: singleSelectedTrip?.latitude ?? 0,
            longitude: singleSelectedTrip?.longitude ?? 0,
            draggable: true,
        }
        markerData.push(tripLocation)

        return markerData
    }

    // for multiple trip in one map. setting marker data
    _setMarkerDataForMultipleSelectedTrip(arrayOfTripObj){
        const { selectedTrip } = this.props

        let markerData = []

        // Initialize marker data
        arrayOfTripObj.forEach(trip => {
            // setting marker for trip ending point
            if (trip?.end_time) {
                const endLocation = {
                    id: trip?.exact_address,
                    trip_id: trip?.id,
                    isEndLocation: true,
                    tripEndTime: trip?.end_time,
                    verifierName: trip?.agent?.name ?? '',
                    latitude: trip?.end_latitude ?? null,
                    longitude: trip?.end_longitude ?? null,
                    tripType: trip?.type,
                    draggable: false,
                }
                markerData.push(endLocation)
            }

            // setting other trip except selectedTrip 
            if(selectedTrip.id !== trip.id){
                 // creating location marker data
                const tripLocation = {
                    id: trip?.exact_address,
                    trip_id: trip?.id,
                    concern: 'Exact Address',
                    haveFence: true,
                    isAddressOfEndedTrip: true,
                    status: trip?.status,
                    tripEndTime: trip?.end_time,
                    label: `${trip?.agent?.name}`,
                    tripType: trip?.type,
                    latitude: trip?.latitude ?? null,
                    longitude: trip?.longitude ?? null,
                    draggable: false,
                }
                markerData.push(tripLocation)
            }
            // selected trip marker
            if(selectedTrip.id === trip.id){
                // creating location marker data
               const tripLocation = {
                   id: trip?.exact_address,
                   trip_id: trip?.id,
                   concern: 'Exact Address',
                    //    selectedNow: true,
                    haveFence: true,
                    isAddressOfEndedTrip: true,
                    status: trip?.status,
                    tripEndTime: trip?.end_time,
                    label: `${trip?.agent?.name}`,
                    tripType: trip?.type,
                    latitude: trip?.latitude ?? null,
                    longitude: trip?.longitude ?? null,
                    draggable: false,
                }
               markerData.push(tripLocation)
           }
             
        });

        return markerData
    }


    componentDidMount = () => {        
        const { selectedTripToUpdate, selectedTrip, selectedMultipleTripToUpdate, dispatch } = this.props
        let markerData = []
        //storing update form data for selected trip into state
        this._setFormDataToState(selectedTripToUpdate,selectedTrip)

        // setting marker data for multiple selected trip
        if(selectedMultipleTripToUpdate.length > 0){
         
            markerData = this._setMarkerDataForMultipleSelectedTrip(selectedMultipleTripToUpdate)
        }   

        // Set markerData for Trip Update
        dispatch(setMultipleMarkerDataTripUpdate(markerData))

    }    

    componentDidUpdate(prevProps, prevState){
        const {selectedTripToUpdate, selectedTrip, selectedConcern, selectedMultipleTripToUpdate, dispatch} = this.props

        if (!_isEqual(prevProps.selectedTripToUpdate, selectedTripToUpdate) || 
            !_isEqual(prevProps.selectedMultipleTripToUpdate, selectedMultipleTripToUpdate) ||
            !_isEqual(prevProps.selectedConcern, selectedConcern) || 
            !_isEqual(prevProps.selectedTrip, selectedTrip)
            ) { 
            let markerData = []
            
            // setting marker data for multiple selected trip
            if(selectedMultipleTripToUpdate.length > 0){
                markerData = this._setMarkerDataForMultipleSelectedTrip(selectedMultipleTripToUpdate)
            }


            // Set markerData for Trip Update
            dispatch(setMultipleMarkerDataTripUpdate(markerData))
        }

    }

    // Handle On Change
    _handleOnChange = (e) => {
        const { name, value, checked } = e.target        
        const checkedValue = checked === true ? 1 : 0

        this.setState({ [name]: name === 'is_address_exact' ? checkedValue : value })
    }
    

    // Handle Get Exact Address
    _handleGetExactAddress = (address) => {
        const { exact_address } = this.state
        const { dispatch, markerDataTripUpdate } = this.props

        // Fiktered out previous markerData
        const updatedMarkerData = markerDataTripUpdate.filter(item => item.id !== exact_address)

        this.setState({ 
            exact_address: address?.address ?? '',
            area: address?.area ?? '',
            latitude: address?.latitude ?? 0,
            longitude: address?.longitude ?? 0,
        })

        // // Set markerData for Trip Update
        dispatch(setMultipleMarkerDataTripUpdate([
            ...updatedMarkerData,
            {
                id: address?.address ?? '',
                concern: 'Exact Address',
                label: 'Exact Address',
                latitude: address?.latitude ?? 0,
                longitude: address?.longitude ?? 0,
                draggable: true,
            }
        ]))
    }

    // Get dragend long lat
    _handleDragend = (longitude, latitude) => {
        const { dispatch } = this.props
        dispatch(getReverseGeoData({latitude, longitude}))
            .then(data => {
                if(data) {
                    this.setState({ 
                        exact_address: data.address ?? '',
                        area: data.area ?? '',
                        latitude,
                        longitude,
                    })
                }
            })
    }

    // Handle Trip Update
    _handleTripUpdate = () => {
        const { address, is_address_exact, exact_address, place_name, area, latitude, longitude, remarks } = this.state
        const { selectedTrip, applicationDetails, dispatch, previousTaskMapFilterParams } = this.props
        const selectedTripWithapplicationID = {...selectedTrip, application_id: applicationDetails?.id}

        // Create params
        const params = {
            address,
            exact_address,
            place_name,
            area,
            latitude,
            longitude,
            is_address_exact,
            remarks,
            reason: '',
            _method: 'PUT',
        }

        // Create State value without initialTripData, in order to compare changes between initialTripData
        const stateWithOutInitialTripData = this.state
        delete stateWithOutInitialTripData.initialTripData
       
        // Check if selected trip lat long is changed among multiple trip in map
        if(selectedTrip.latitude !== latitude || selectedTrip.longitude !== longitude || selectedTrip.place_name !== place_name || selectedTrip.address !== address || selectedTrip.exact_address !== exact_address || selectedTrip.is_address_exact !== is_address_exact || selectedTrip.remarks !== remarks){
            // Update trip
            dispatch(updateTrip(params, 'application', selectedTripWithapplicationID, previousTaskMapFilterParams))
            return
        }
        // Show alert
        showAlert('warning', 'Cannot Update.')

    }
    render() {
        const { address, is_address_exact, place_name, remarks, exact_address } = this.state
        const { selectedTripToUpdate, selectedMultipleTripToUpdate } = this.props
        return (
            <div>
                <Space direction={ 'vertical' } style={{ width: '100%' }}>
                    <Row gutter={[ 12, 12 ]}>
                        <Col span={ 24 } xs={ 24 }>
                            <AllEndTripMapContainer getDragEndLngLat={ this._handleDragend } />
                        </Col>
                    </Row>                                      
                </Space>
            </div>
        )
    }
}

// styled conponents for overriding antd checkbox style
// const VerticalCheckboxGroup = styled(Checkbox.Group)`
//   .ant-checkbox-group-item {
//     display: flex!important;
//   }
// `

// Props Validation
AllEndTrip.propTypes = {
    dispatch: PropTypes.func,
    isUpdateTripModalOpen: PropTypes.bool,
    isUpdateTripButtonLoading: PropTypes.bool,
    selectedTripToUpdate: PropTypes.object,
    selectedMultipleTripToUpdate: PropTypes.array,
    markerDataTripUpdate: PropTypes.array,
}

AllEndTrip.defaultProps = {
    dispatch: () => null,
    isUpdateTripModalOpen: false,
    isUpdateTripButtonLoading: false,
    selectedTripToUpdate: {},
    selectedMultipleTripToUpdate: [],
    markerDataTripUpdate: [],
}

// Map State To Props
const mapStateToProps = state => ({
    isUpdateTripModalOpen: state.common.isUpdateTripModalOpen,
    isUpdateTripButtonLoading: state.common.isUpdateTripButtonLoading,
    selectedTripToUpdate: state.common.selectedTripToUpdate,
    selectedMultipleTripToUpdate: state.common.selectedMultipleTripToUpdate,
    markerDataTripUpdate: state.common.markerDataTripUpdate,
    previousTaskMapFilterParams: state.taskMap.previousTaskMapFilterParams,
    selectedTrip: state.application.selectedTrip,
    selectedConcern: state.application.selectedConcern,
    applicationDetails: state.application.applicationDetails,
})

// Map Dispatch To Props
const mapDispatchToProps = dispatch => ({ dispatch })

export default connect(mapStateToProps, mapDispatchToProps)(AllEndTrip)