import React from 'react'
import { connect } from 'react-redux'

// Import Components
import { Card } from 'antd'
import AssignQuestion from './AssignQuestion'

class AssignQuestionContainer extends React.PureComponent {   

    render() {
        return (
            <Card hoverable>
                <AssignQuestion />
            </Card>
        )
    }
}


// Map State To Props
const mapStateToProps = (state) => ({

})

// Map Dispatch To Props
const mapDispatchToProps = (dispatch) => ({ dispatch })

export default connect(mapStateToProps, mapDispatchToProps)(AssignQuestionContainer)