import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    pendingList: [],
    pendingListPegination: {},
}

const pendingListSlice = createSlice({
    name: 'pendingList',
    initialState,
    reducers: {
        setPendingList: (state, action) => {
            state.pendingList = action.payload
        },
        setPendingListPegination: (state, action) => {
            state.pendingListPegination = action.payload
        }
    }
})

export const { setPendingList, setPendingListPegination } = pendingListSlice.actions
export default pendingListSlice.reducer