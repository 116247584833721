import React from 'react'
import { connect } from 'react-redux'
// import parse from 'wellknown'


// Import Components
import CFMapGL from './CreateFile/CFMapGL'

class UpdateTripMap extends React.PureComponent {



    _getGeoJsonData = () => {

        const { multipleMarkerDataTripUpdate } = this.props

        const geoJson = {
            "type": "FeatureCollection",
            "features": [],
        }
       
        if (multipleMarkerDataTripUpdate && multipleMarkerDataTripUpdate?.length > 0) {


             // Find multiple trip in same lat and lng    
            let resD = []
            let visited = []
            multipleMarkerDataTripUpdate.forEach((i) => {
                const filtered = multipleMarkerDataTripUpdate.filter((j) => i.latitude === j.latitude && i.longitude === j.longitude);
                if (filtered.length > 1) {
                    if (!visited.includes(i.latitude)) {
                        resD.push(filtered)
                    }
                    visited.push(i.latitude)
                } else {
                    resD.push(filtered)
                }
            })

            // Transform data
            const transformedData = {
                type: 'FeatureCollection',
                features: resD.map(d => ({
                    type: 'Feature',
                    properties: d,
                    geometry: {
                        type: 'Point',
                        coordinates: [Number(d[0].longitude), Number(d[0].latitude)]
                    }
                })) 
            }

            transformedData.features.forEach((data, i) => {

                if (data?.properties[0]?.haveFence) {
                    geoJson.features.push({
                        "type": "Feature",
                        "geometry": {
                            "type": "Point",
                            "coordinates": data?.geometry?.coordinates
                        },
                        "properties": {
                            ...data?.properties[0],
                            "description":  this._getIdFromLayerProperties(data?.properties),
                        },
                    })
                } 
                else if(data?.properties[0]?.isEndLocation){
                    // data?.properties?.isEndLocation ? data?.verifierName + ' ' + data?.tripEndTime :
                    const endLocationLabel =  data?.properties[0]?.verifierName + ':' + data?.properties[0]?.tripEndTime
                    geoJson.features.push({
                        "type": "Feature",
                        "geometry": {
                            "type": "Point",
                            "coordinates": data?.geometry?.coordinates
                        },
                        "properties": {
                            ...data?.properties[0],
                            "description":  endLocationLabel,
                        },
                    })
                }
            })
        }

        return geoJson
    }


    // Get ID from layer properties
    _getIdFromLayerProperties = (properties) => {
        if(!properties) {
            return null
        }
        if(properties?.length > 0) {
            const text = properties.map(prop => {
                return prop.trip_id.toString()
            })

            return text.join()
        }
    }


    render() {
        const { multipleMarkerDataTripUpdate } = this.props
        const geoJsonData = this._getGeoJsonData()

        return (
            <div style={{ width: '100%', height: '100%' }}>
                <CFMapGL markerData={multipleMarkerDataTripUpdate ?? []} geojsonData={geoJsonData} mapHeight={'500px'} />
            </div>
        )
    }
}

// Map State To Props
const mapStateToProps = state => ({
    multipleMarkerDataTripUpdate: state.common.multipleMarkerDataTripUpdate,
})

export default connect(mapStateToProps, null)(UpdateTripMap)