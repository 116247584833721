import React from 'react'
import { connect } from 'react-redux'
import moment from 'moment'

// Import Components
import DeckGL from 'deck.gl'
import { StaticMap } from 'react-map-gl'
import LabeledPointLayer from '../Layouts/LabeledPointLayer'
import LabeledIconLayer from '../Layouts/LabeledIconLayer'

// Import Actions
import { setIsTaskDetailsOpen, setSelectedTrips, setSelectedTripIndex, setSelectedTrip } from '../../Services/Reducers/taskMapReducer'
import { getTripDetails } from '../../Services/Actions/taskMapActions'

// Import Types
import { MAP } from '../../Config/config'
import { isAllowedToAccess } from '../../Services/Actions/permissionActions'
import { UserOutlined } from '@ant-design/icons'

class MapGL extends React.PureComponent {
    state = {
        viewState: {
            longitude: 90.46027047540736,
            latitude: 23.76716255930807,
            zoom: 10.5,
            pitch: 0,
            bearing: 0,
        },
        layers: [],
        hoverInfo: {
            isHovered: false,
        },
        hoverTrip: {
            isHovered: false,
        }
    }

    componentDidMount = () => {
        const { verifiersGeoLocation } = this.props
        const layer = this._createLabeledPointLayer(verifiersGeoLocation)
        this.setState({ layers: [layer] })
    }

    componentDidUpdate = (prevProps) => {
        const { verifiersGeoLocation } = this.props        
        if (prevProps.verifiersGeoLocation !== verifiersGeoLocation) {
            const layer = this._createLabeledPointLayer(verifiersGeoLocation)
            this.setState({ layers: [layer] })
        }
    }

    // Hanlde View State Change
    _onViewStateChange = ({ viewState }) => {
        if(viewState) {
            this.setState({ viewState })
        }        
    }

    // Create LabelPointLayer
    _createLabeledPointLayer = (data) => {
        if(!data) {
            return null
        }
        return new LabeledPointLayer({            
            data: data?.features ?? [],
            onHover: (info, event) => this._handleHover(info, event),
        })
    }

    // Create Labeled Icon Layer
    _createLabeledIconLayer = (data) => {
        if(!data) {
            return null
        }
        return new LabeledIconLayer({
            data: data?.features ?? [],
            onClick: (info, event) => this._handleTripClick({info, event}),
            onHover: (info, event) => this._handleTripHover(info, event),
        })
    }

    // Handle Hover
    _handleHover = info => {       
        if(info?.object) {
            this.setState({ 
                hoverInfo: { 
                    isHovered: true, 
                    data: info.object, 
                    msg:  info.object.properties.custom_message ? info.object.properties.custom_message : info.object.properties.message,
                    date: info?.object?.properties?.updated_at ? 
                        moment(info?.object?.properties?.updated_at).format('DD-MMM-YYYY hh:mm:ss A') : 'N/A',
                    info: info
                }
            })
        } else {
            this.setState({ hoverInfo: { isHovered: false, data: null, msg: null, date: null, info: null } })
        }        
    }

        // Handle Trip Hover
        _handleTripHover = info => {  
            if(info?.object?.properties[0]?.agent?.name) {
                this.setState({ 
                    hoverTrip: { 
                        isHovered: true, 
                        verifier: info?.object?.properties[0]?.agent?.name, 
                        info: info,
                    }
                })
            } else {
                this.setState({ hoverTrip: { isHovered: false, verifier: null } })
            }              
        }

    // Handle Trip Click
    _handleTripClick = (info, event) => {
        if(info?.info?.object?.properties?.length > 0) {
            const { dispatch, allowedPermission } = this.props
            
            // PERMISSION ID OF "map-view.map-view.filter.date" is 36
            if(isAllowedToAccess( allowedPermission ,{  menuName: 'map-view', subMenuName: 'map-view', permissionID: '36' })){
                // Set isTaskDetailsOpen to opposite value
                dispatch( setIsTaskDetailsOpen(true) )
            }

            // Set Selected Trips
            dispatch( setSelectedTrips(info.info.object.properties) )

            // Set Selected Trip Index to 0
            dispatch( setSelectedTripIndex(0) )

            // Set Selected Trip
            dispatch( setSelectedTrip(info.info.object.properties[0]) )

            // Get Trip Details
            dispatch( getTripDetails(info.info.object.properties[0]?.id) )
        }
    }

    render() {
        const { viewState, layers, hoverInfo, hoverTrip } = this.state
        const { tripData, appointmentData, selectedTripLayerType, allowedPermission } = this.props
        

        return (            
            <DeckGL
                viewState={ viewState }
                onViewStateChange={ this._onViewStateChange }
                controller={ true }
                layers={ [  // PERMISSION ID OF "map-view.map-view.agent live tracking" is 40
                            (isAllowedToAccess( 
                                allowedPermission ,
                                {  menuName: 'map-view', subMenuName: 'map-view', permissionID: '40' })) ? 
                                    layers 
                                    : [],
                            // shows trip data or appointment data on map
                            this._createLabeledIconLayer(
                                selectedTripLayerType === 'appointments' ? 
                                    appointmentData 
                                    : selectedTripLayerType === 'regular'? 
                                        tripData 
                                        : ''
                            ) 
                         ] 
                        }
            >
                <StaticMap mapStyle={ MAP.STYLE } />
                {
                    hoverInfo?.isHovered && (                        
                        <div style={{ 
                            position: 'absolute',
                            height: '100%',
                            zIndex: 1,
                            pointerEvents: 'none',
                            left: hoverInfo?.info.x,
                            top: hoverInfo?.info?.msg ? hoverInfo?.info.y - 65 : hoverInfo?.info.y - 40 
                        }}>                            
                            <div style={{
                                backgroundColor: '#fff',
                                borderRadius: '2px',
                                boxShadow: '0 0 5px rgba(0, 0, 0, 0.3)'
                            }}>
                                <div style={{ fontSize: '0.7rem', display: 'flex' }}>
                                    <div                                        
                                        style={{
                                            width: '30px',
                                            background: '#1687a7',
                                            padding: '4px',
                                            color: '#fff',
                                            borderRadius: hoverInfo?.info?.msg ? '4px 0px 0px 0px' : '4px 0px 0px 4px'
                                        }}
                                    >
                                        LUT
                                    </div>
                                    <div style={{ padding: '4px' }}>{ hoverInfo?.date }</div>
                                </div>
                                {
                                    hoverInfo?.info?.msg &&
                                    (
                                        <div style={{ fontSize: '0.7rem', borderTop: '1px solid grey', display: 'flex' }}>
                                            <div                                                
                                                style={{
                                                    width: '30px',
                                                    background: '#e9896a',
                                                    padding: '4px',
                                                    color: '#fff',
                                                    borderRadius: '0px 0px 0px 4px'
                                                }}
                                            >
                                                MSG
                                            </div>
                                            <div style={{ padding: '4px' }}>{ hoverInfo?.info?.msg }</div>
                                        </div>
                                    )
                                }
                            </div>                        
                        </div>
                    )
                }

                {  // on hover trip shows verifier name
                    hoverTrip?.isHovered && (                        
                        <div style={{ 
                            position: 'absolute',
                            height: '100%',
                            zIndex: 1,
                            pointerEvents: 'none',
                            left: hoverTrip?.info.x,
                            top: hoverTrip?.info?.verifier ? hoverTrip?.info.y - 65 : hoverTrip?.info.y - 40 
                        }}>                            
                            <div style={{
                                backgroundColor: '#fff',
                                borderRadius: '2px',
                                boxShadow: '0 0 5px rgba(0, 0, 0, 0.3)'
                            }}>
                                <div style={{ fontSize: '0.7rem', display: 'flex' }}>
                                    <div                                        
                                        style={{
                                            width: '40px',
                                            background: '#1687a7',
                                            padding: '4px',
                                            color: '#fff',
                                            borderRadius: '4px 0px 0px 4px'
                                        }}
                                    >
                           
                                        <span>Agent</span> 
                                    </div>
                                    <div style={{ padding: '4px' }}>{ hoverTrip?.verifier }</div>
                                </div>
                               
                            </div>                        
                        </div>
                    )
                }
            </DeckGL>
        )
    }
}

const mapStateToProps = (state) => ({
    loading: state.taskMap.loading,
    verifiersGeoLocation: state.taskMap.verifiersGeoLocation,
    tripData: state.taskMap.tripData,
    appointmentData: state.taskMap.appointmentData,
    selectedTripLayerType: state.taskMap.selectedTripLayerType,
    allowedPermission: state?.permission?.allowedPermission ?? null,
})

export default connect(mapStateToProps, null)(MapGL)