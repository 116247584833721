import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

// Import Components
import { Space, Select, Button } from 'antd'

// Import Actions, and Reducers
import { addUserToOrganization } from '../../../Services/Actions/adminPanelActions'
import { showAlert } from '../../../Services/Actions/commonActions'
import { setSelectedOrgAddUserToOrg, setSelectedUserAddUserToOrg } from '../../../Services/Reducers/adminPanelReducer'

class AddUserToOrg extends React.PureComponent {
    // Handle Selected Org On Change
    _handleSelectOrg = value => {        
        const { dispatch, orgList } = this.props

        // Check if value is empty or undefined or null
        if (!value) {
            // Set Selected Org
            dispatch(setSelectedOrgAddUserToOrg({}))
            return
        }
        // Find Selected Org
        const selectedOrg = orgList.find(item => item.uuid === value)
        
        // If Selected Org Not Exist
        if (!selectedOrg) {
            // Set Selected Org
            dispatch(setSelectedOrgAddUserToOrg({}))

            // Show Error Alert
            showAlert('error', 'Selected Organization Not Found')
            return
        }
        // Set Selected Org
        dispatch(setSelectedOrgAddUserToOrg(selectedOrg))
    }

    // Handle Selected User On Change
    _handleSelectUser = value => {
        const { dispatch, users } = this.props

        // Check if value is empty or undefined or null
        if (!value) {
            // Set Selected User
            dispatch(setSelectedUserAddUserToOrg({}))
            return
        }
        // Find Selected User
        const selectedUser = users.find(item => item.id === value)
        
        // If Selected User Not Exist
        if (!selectedUser) {
            // Set Selected User
            dispatch(setSelectedUserAddUserToOrg({}))

            // Show Error Alert
            showAlert('error', 'Selected User Not Found')
            return
        }
        // Set Selected User
        dispatch(setSelectedUserAddUserToOrg(selectedUser))
    }

    // Handle Add User To Org
    _handleAddUserToOrg = () => {
        const { dispatch, selectedOrgAddUserToOrg, selectedUserAddUserToOrg } = this.props        

        // Check if Selected Org and User Not Exist or has empty object
        if (!selectedOrgAddUserToOrg || Object.keys(selectedOrgAddUserToOrg).length === 0 || !selectedUserAddUserToOrg || Object.keys(selectedUserAddUserToOrg).length === 0) {        
            // Show Error Alert
            showAlert('error', 'Please Select Organization And User')
            return
        }
        // Add User To Org
        dispatch(addUserToOrganization({ user_id: selectedUserAddUserToOrg.id, org_id: selectedOrgAddUserToOrg.uuid }))
    }
    render() {
        const { orgList, users, selectedOrgAddUserToOrg, selectedUserAddUserToOrg, isAddUserToOrgLoading } = this.props
        const _orgList = orgList.filter(item => item.uuid !== -1)
        
        return (
            <div>
                <Space direction={ 'vertical' } style={{ width: '100%' }}>
                    <Select
                        style={{ width: '100%' }}                                        
                        showSearch
                        allowClear                    
                        placeholder={ 'Select Organization' }
                        onChange={ this._handleSelectOrg }
                        value={ selectedOrgAddUserToOrg?.uuid ?? null }
                        filterOption={ (input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0 }
                        optionFilterProp={ 'children' }
                    >
                        {
                            _orgList?.length > 0 && _orgList.map((item, index) => {
                                return (
                                    <Select.Option key={ index + 1 } value={ item.uuid }>{ item.name }</Select.Option>
                                )
                            })
                        }
                    </Select>
                    <Select
                        style={{ width: '100%' }}                                        
                        showSearch
                        allowClear                    
                        placeholder={ 'Select User' }
                        onChange={ this._handleSelectUser }
                        value={ selectedUserAddUserToOrg?.id ?? null }
                        filterOption={ (input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0 }
                        optionFilterProp={ 'children' }
                    >
                        {
                            users?.length > 0 && users.map((item, index) => {
                                return (
                                    <Select.Option key={ index + 1 } value={ item.id }>{ item.name }</Select.Option>
                                )
                            })
                        }
                    </Select>
                    <div style={{ float: 'right' }}>
                        <Button 
                            type={ 'primary' }
                            loading={ isAddUserToOrgLoading }
                            onClick={ this._handleAddUserToOrg }
                        >
                            { 'Add' }
                        </Button>
                    </div>
                </Space>
            </div>
        )
    }
}

// Props Validation
AddUserToOrg.propTypes = {
    dispatch: PropTypes.func,
    orgList: PropTypes.array,
    users: PropTypes.array,
    selectedOrgAddUserToOrg: PropTypes.object,
    selectedUserAddUserToOrg: PropTypes.object,
    isAddUserToOrgLoading: PropTypes.bool,
}

AddUserToOrg.defaultProps = {
    dispatch: () =>  null,
    orgList: [],
    users: [],
    selectedOrgAddUserToOrg: {},
    selectedUserAddUserToOrg: {},
    isAddUserToOrgLoading: false,
}

// Map State To Props
const mapStateToProps = (state) => ({
    orgList: state.common.orgList,
    users: state.common.users,
    selectedOrgAddUserToOrg: state.adminPanel.selectedOrgAddUserToOrg,
    selectedUserAddUserToOrg: state.adminPanel.selectedUserAddUserToOrg,
    isAddUserToOrgLoading: state.adminPanel.isAddUserToOrgLoading,
})

// Map Dispatch To Props
const mapDispatchToProps = (dispatch) => ({ dispatch })

export default connect(mapStateToProps, mapDispatchToProps)(AddUserToOrg)