import { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'

// Import Components
import { Button } from 'antd'

// Import Icons
import { BankOutlined } from '@ant-design/icons'

// Import Actions
import { showAlert } from '../../../Services/Actions/commonActions'
import { downloadBankReport } from '../../../Services/Actions/reportsActions'

const BankDownloadButton = ({ application }) => {
    const [selectedApplication, setSelectedApplication] = useState(null)    
    const { isBankReportLoading } = useSelector(state => state.reports)
    const dispatch = useDispatch()

    // in useEffect, check if isBankReportLoading is false then set selectedApplication to null
    useEffect(() => {
        if (!isBankReportLoading) {
            setSelectedApplication(null)
        }
    }, [isBankReportLoading])

    // Handle Bank Report Download
    const _handleBankReportDownload = () => {
        // Set Selected Application
        setSelectedApplication(application)

        // Check if Selected Application is Empty
        if(!application?.id) {
            // Show error message
            showAlert('error', 'Something went wrong!')
            return
        }
        dispatch(downloadBankReport(application?.id))
    }    
    return (
        <Button 
            icon={ <BankOutlined /> }
            size={ 'small' }
            onClick={ () => _handleBankReportDownload() }
            loading={ isBankReportLoading && selectedApplication?.id === application?.id }
        />
    )
}

export default BankDownloadButton