import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

// Import Components
import { Card, Tabs } from "antd";
import { ChooseTrips, AddConcernReCPV, AdditionalReCPVTrip } from "../ReCPV";

// Import Actions
import { getApplicationDetails } from "../../../Services/Actions/recpvActions";
import { isAllowedToAccess } from "../../../Services/Actions/permissionActions";

class ApplicationDetailsContainer extends React.PureComponent {
  // Get Updated Application Details
  _getUpdatedApplicationDetails = () => {
    const { dispatch, selectedFile } = this.props;
    dispatch(getApplicationDetails(selectedFile?.id));
  };
  render() {
    const { selectedFile, allowedPermission } = this.props;
    return (
      <div>
        {selectedFile?.id && (
          <Card
            title={`File No.: ${selectedFile?.application_id ?? "N/A"}`}
            hoverable
          >
            {
              <Tabs defaultActiveKey="1" type="card">
                <Tabs.TabPane tab="Choose Trips" key="1">
                  {selectedFile?.concerns &&
                    selectedFile?.concerns.length > 0 && (
                      <ChooseTrips
                        concerns={selectedFile?.concerns}
                        selectedApplicationId={selectedFile?.id}
                        reload={this._getUpdatedApplicationDetails}
                      />
                    )}
                </Tabs.TabPane>
                {
                  // PERMISSION ID OF "re-cpv.re-cpv.concern.add" is 44
                  isAllowedToAccess(allowedPermission, {
                    menuName: "re-cpv",
                    subMenuName: "re-cpv",
                    permissionID: "44",
                  }) && (
                    <Tabs.TabPane tab="Add Concern" key="2">
                      <AddConcernReCPV
                        reload={this._getUpdatedApplicationDetails}
                        applicationID={selectedFile?.id}
                      />
                    </Tabs.TabPane>
                  )
                }
                <Tabs.TabPane tab="Add Additional Trips" key="3">
                  <AdditionalReCPVTrip
                    reload={this._getUpdatedApplicationDetails}
                    concerns={selectedFile?.concerns}
                    selectedApplicationId={selectedFile?.id}
                  />
                </Tabs.TabPane>
              </Tabs>
            }
          </Card>
        )}
      </div>
    );
  }
}

// PropTypes Validation
ApplicationDetailsContainer.propTypes = {
  selectedFile: PropTypes.object,
  isSelectedFileLoading: PropTypes.bool,
};

ApplicationDetailsContainer.defaultProps = {
  selectedFile: {},
  isSelectedFileLoading: false,
  allowedPermission: null,
};

const mapStateToProps = (state) => ({
  selectedFile: state.recpv.selectedFile,
  isSelectedFileLoading: state.recpv.isSelectedFileLoading,
  allowedPermission: state?.permission?.allowedPermission ?? null,
});

const mapDispatchToProps = (dispatch) => ({ dispatch });

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ApplicationDetailsContainer);
