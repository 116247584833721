import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

// Import Components
import { Card, Button, Form, Select, Input, Checkbox, Row, Col, Typography } from 'antd'
import ExactAddressAutocomplete from '../ExactAddressAutocomplete'

// Import Actions
import { showAlert } from '../../../Services/Actions/commonActions'
import { getTripTypeByConcernType } from '../../../Services/Actions/staticOptionAction'
import { addNewTrip } from '../../../Services/Actions/applicationActions'

class AddNewTrip extends React.PureComponent {
    state = {
        selectedConcern: null,
        trip_types: [],
        selectedTripType: null,
        address: '',
        exact_address: '',
        is_address_exact: 0,
        area: '',
        latitude: 0,
        longitude: 0,
        place_name: '',
        comment: '',
        bank_account_name: '',
        bank_account_number: '',
        bank_name: '',
        bank_branch: '',
        isSuggestionBoxOpen: false
    }

    // Create Form Ref
    _formRef = React.createRef()

    // Handle Concern On Change
    _handleConcernOnChange = (value, optionObject) => {
        // Check if valid is invalid
        if(!value) {
            // Show alert
            showAlert('error', 'Please select a concern!')
        }

        const getTripTypeOfSelectedConcernType = getTripTypeByConcernType(optionObject?.type)

        this.setState({ 
            selectedConcern: value ?? null,
            trip_types: getTripTypeOfSelectedConcernType
        })
    }

    // Handle Trip Type On Change
    _handleTripTypeOnChange = (value) => {
        // Check if valid is invalid
        if(!value) {
            // Show alert
            showAlert('error', 'Please select a trip type!')
        }

        this.setState({ selectedTripType: value ?? null })
    }

    // Handle Input On Change
    _handleInputOnChange = (e) => {
        const { name, value, checked } = e.target
        const checkedValue = checked === true ? 0 : 1

        this.setState({ [name]: name === 'is_address_exact' ? checkedValue : value })

    }

    // Handle Exact Address On Change
    _handleExactAddressOnChange = (address) => {
        this.setState({
            exact_address: address?.address ?? '',
            area: address?.area ?? '',
            latitude: address?.latitude ?? 0,
            longitude: address?.longitude ?? 0,
        })
    }

    // Handle Submit
    _handleSubmit = (values) => {
        const { selectedConcern, selectedTripType, address, exact_address, is_address_exact, place_name, latitude, longitude, area, bank_name, bank_branch, bank_account_name, bank_account_number, comment } = this.state
        const { dispatch, applicationDetails } = this.props

        // Check if exact address, latitude and longitude is not empty
        if(!exact_address || !latitude || !longitude) {
            // Show alert
            showAlert('error', 'Please enter an exact address!')
            return
        }

        // Create payload
        const formData =  new FormData()
        formData.append('application_id', applicationDetails?.id ?? '')
        formData.append('type', selectedTripType ?? '')
        formData.append('concern_id', selectedConcern ?? '')
        formData.append('tag', 'REGULAR')
        formData.append('address', address ?? '')
        formData.append('exact_address', exact_address ?? '')
        formData.append('is_address_exact', is_address_exact ?? null)
        formData.append('latitude', latitude ?? '')
        formData.append('longitude', longitude ?? '')
        formData.append('place_name', place_name ?? '')
        formData.append('area', area ?? '')
        formData.append('comment', comment ?? '')

        // Check if trip type is bank
        if(selectedTripType === 'BANK') {
            formData.append('bank[account_title]', bank_account_name ?? '')
            formData.append('bank[account_number]', bank_account_number ?? '')
            formData.append('bank[name]', bank_name ?? '')
            formData.append('bank[branch]', bank_branch ?? '')
        }

        // Dispatch action
        dispatch(addNewTrip(applicationDetails, formData))
    }

    // changes isSuggestionBoxOpenProp to open/close suggestion box for exact address
    _handleSuggestionOpenForxactAddress = (booleanValue) => {
        const { isSuggestionBoxOpen } = this.state

        // if suggestion box is open then click in anywhere will close the box
            this.setState({isSuggestionBoxOpen: booleanValue})
    }


    render() {
        const { isSuggestionBoxOpen, selectedConcern, selectedTripType, trip_types, address, place_name, comment, bank_account_name, bank_account_number, bank_name, bank_branch, is_address_exact } = this.state
        const { applicationDetails } = this.props
       
        return (
            // suggestion box only close by onClick if box is already open
            <Card onClick={isSuggestionBoxOpen ? () => this._handleSuggestionOpenForxactAddress(false) : null} title={ 'Create Additional Trip' } hoverable headStyle={{textAlign: 'center', fontWeight: 'bold'}}>
                <Form
                    layout={ 'vertical' }
                    ref={ this._formRef }
                    onFinish={ this._handleSubmit }
                    style={{width: '100%'}}
                >
                    <Row>
                        <Col span={12} xs={ 24 } lg={ 12 }>
                            <Form.Item
                                label={ 'Select Concern' }
                                name={ 'concern' }
                                rules={ [{ required: true, message: 'Please select concern!' }] }
                            >
                                <Select
                                    style={{ width: '98%' }}
                                    placeholder={ 'Select Concern' }
                                    showSearch
                                    filterOption={ (input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0 }
                                    optionFilterProp={ 'children' }
                                    value={ selectedConcern }
                                    onChange={ this._handleConcernOnChange }
                                >
                                    { applicationDetails.concerns.map(concern => (
                                        <Select.Option key={ concern.id } value={ concern.id } type={ concern.type }>
                                            { concern.name + ' - ' + concern.type }
                                        </Select.Option>
                                    )) }
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={12}  xs={ 24 } lg={ 12 }>
                            <Form.Item
                                label={ 'Select Trip Type' }
                                name={ 'trip_type' }
                                rules={ [{ required: true, message: 'Please select trip type!' }] }
                            >
                                <Select
                                    style={{ width: '98%' }}
                                    placeholder={ 'Select Trip Type' }
                                    showSearch
                                    filterOption={ (input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0 }
                                    optionFilterProp={ 'children' }
                                    value={ selectedTripType }
                                    onChange={ this._handleTripTypeOnChange }
                                >
                                    { trip_types.map(trip_type => (
                                        <Select.Option key={ trip_type.id } value={ trip_type.value }>
                                            { trip_type.label }
                                        </Select.Option>
                                    )) }
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={12}  xs={ 24 } lg={ 12 }>
                            <Form.Item
                                label={ 'Given Address' }
                                name={ 'address' }
                                rules={ [{ required: true, message: 'Please enter given address!' }] }
                            >
                                <Input 
                                    name={ 'address' }
                                    onChange={ this._handleInputOnChange }
                                    value={ address }
                                    placeholder={ 'Enter given address..' }
                                    style={{ width: '98%' }}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={12}  xs={ 24 } lg={ 12 }>
                        <Form.Item
                                label={ 'Place Name' }
                                name={ 'place_name' }
                            >
                                <Input 
                                    name={ 'place_name' }
                                    onChange={ this._handleInputOnChange }
                                    value={ place_name }
                                    placeholder={ 'Enter plcae name..' }
                                    style={{ width: '98%' }}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item
                                    label={<Typography.Text>Exact Address</Typography.Text> }
                                    name={ 'exact_address' }
                                >
                                     {/* <Input.TextArea rows={4} /> */}
                                    <ExactAddressAutocomplete 
                                        getExactAddress={ this._handleExactAddressOnChange } 
                                        customWidth={'99%'}
                                        isSuggestionBoxOpenProp={isSuggestionBoxOpen}
                                        toggleSuggestionBox={this._handleSuggestionOpenForxactAddress}
                                    />
                                </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item label={ 'Not Exact' } valuePropName={ 'checked' }>
                                <Checkbox 
                                    name={ 'is_address_exact' }
                                    onChange={ this._handleInputOnChange }
                                    value={ is_address_exact }
                                    checked={!is_address_exact}
                                />
                            </Form.Item>
                        </Col>
                       
                        <Col span={12}  xs={ 24 } lg={ 12 }>
                            <Form.Item
                                label={ 'Comment' }
                                name={ 'comment' }
                            >
                                <Input 
                                    name={ 'comment' }
                                    onChange={ this._handleInputOnChange }
                                    value={ comment }
                                    placeholder={ 'Enter your comment here..' }
                                    style={{ width: '98%' }}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={12}></Col>

                        {
                            selectedTripType === 'BANK' &&
                            <>
                                <Col span={12}>
                                    <Form.Item
                                        label={ 'Bank Account Name' }
                                        name={ 'bank_account_name' }
                                        rules={ [{ required: selectedTripType === 'BANK', message: 'Please enter bank account name!' }] }
                                    >
                                        <Input 
                                            name={ 'bank_account_name' }
                                            onChange={ this._handleInputOnChange }
                                            value={ bank_account_name }
                                            placeholder={ 'Enter bank account name..' }
                                            style={{ width: '98%' }}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item
                                        label={ 'Bank Account Number' }
                                        name={ 'bank_account_number' }
                                        rules={ [{ required: selectedTripType === 'BANK', message: 'Please enter bank account number!' }] }
                                    >
                                        <Input 
                                            name={ 'bank_account_number' }
                                            onChange={ this._handleInputOnChange }
                                            value={ bank_account_number }
                                            placeholder={ 'Enter bank account number..' }
                                            style={{ width: '98%' }}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item
                                        label={ 'Bank Name' }
                                        name={ 'bank_name' }
                                        rules={ [{ required: selectedTripType === 'BANK', message: 'Please enter bank name!' }] }
                                    >
                                        <Input 
                                            name={ 'bank_name' }
                                            onChange={ this._handleInputOnChange }
                                            value={ bank_name }
                                            placeholder={ 'Enter bank name..' }
                                            style={{ width: '98%' }}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item
                                        label={ 'Bank Branch' }
                                        name={ 'bank_branch' }
                                        rules={ [{ required: selectedTripType === 'BANK', message: 'Please enter bank branch!' }] }
                                    >
                                        <Input 
                                            name={ 'bank_branch' }
                                            onChange={ this._handleInputOnChange }
                                            value={ bank_branch }
                                            placeholder={ 'Enter bank branch name..' }
                                            style={{ width: '98%' }}
                                        />
                                    </Form.Item>
                                </Col>
                            </>
                        }
                    </Row>
                     <Form.Item >
                        <Button type="primary" htmlType="submit">
                            Submit
                        </Button>
                    </Form.Item>
                </Form>
            </Card>
        )
    }
}

// Props Validation
AddNewTrip.propTypes = {
    dispatch: PropTypes.func,
}

AddNewTrip.defaultProps = {
    dispatch: () => null,
    applicationDetails: {},
}

// Map State To Props
const mapStateToProps = state => ({
    applicationDetails: state.application.applicationDetails,
})

// Map Dispatch To Props
const mapDispatchToProps = dispatch => ({ dispatch })

export default connect(mapStateToProps, mapDispatchToProps)(AddNewTrip)