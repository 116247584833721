const DescriptionItem = ({ title, content, styleProp }) => (
    <div style={{ ...descriptionWrapper, ...styleProp }}>
      <p style={{ ...descriptionLabel }}>{ title }:</p>
      { content }
    </div>
)

const descriptionWrapper = {
    marginBottom: '2px',
    color: 'rgba(0, 0, 0, 0.65)',
    fontSize: '14px',
    // lineHeight: '0.8',
}

const descriptionLabel = {
    display: 'inline-block',
    marginRight: '8px',
    color: 'rgba(0, 0, 0, 0.85)',
    fontWeight: 'bold'
}

export default DescriptionItem