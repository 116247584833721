import React, { useRef } from 'react';
import axios from 'axios';

// Import Configs
import { CREATE_FILE } from '../../../../Config/config';

// Import Reducers, and Methods
import { useDispatch, useSelector } from 'react-redux';
import { setOCRProcessing, setOcrResults, setPreviewImages } from '../../../../Services/Reducers/createFileReducer';

// Import Icons
import { CheckOutlined, LoadingOutlined, ScanOutlined } from '@ant-design/icons';
import { showAlert } from '../../../../Services/Actions/commonActions';

const UploadOCRDocuments = ({ orgName, type }) => {

  // States
  const fileInputRef = useRef(null)
  const dispatch = useDispatch()
  let images = []

  // Get Data From Redux
  const isProcessing = useSelector(state => state?.createFile?.OCR?.processing ?? false)
  const ocrResults = useSelector(state => state?.createFile?.OCR?.results ?? null)

  // On Upload Image
  /* 
  Handles the file upload event triggered by the user. Uploads selected image files
 to the OCR (Optical Character Recognition) API, retrieves results, and updates the
 Redux store with OCR results and preview images for display. 
 */
  const _onUploadFile = (e) => {
    if (!e.target.files.length || !orgName || !type) {
      return
    }
    console.log(e.target.files, "filesttt");

    // Set OCR Processing `true`
    dispatch(setOCRProcessing(true))

    // Clear Previous Images
    images = []
    dispatch(setPreviewImages([]))

    // Files
    const files = [...e.target.files]
    console.log(files, "files");

    // Declare formData
    let formData = new FormData();

    // Append selected `file` to FormData
    files?.forEach((file) => {
      formData.append('file', file)
      images.push({ url: URL.createObjectURL(file) })
    })

    // Append `bank_name` & `type`
    formData.append('bank_name', orgName);
    formData.append('type', type);

    
    // Call API
    axios.post(CREATE_FILE.OCR, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
      .then(res => {
        // Show Alert
        showAlert("success", "OCR Result Found");

        // Set Results
        dispatch(setOcrResults(res.data.results))

        // Set Preview Images
        dispatch(setPreviewImages(images))
      })
      .catch(() => {

        // Show Alert
        showAlert("error", "Something Went Wrong");

        // Clear Previous States
        images = []
        dispatch(setOcrResults(null))
        dispatch(setPreviewImages([]))
      })
      .finally(() => {
        // Set OCR Processing `false`
        dispatch(setOCRProcessing(false))
        console.log(formData, "formData");
      });

    // Clear Previous Input Ref
    fileInputRef.current.value = null
  }

  return (
    <div style={{ marginBottom: '10px' }}>
      <p style={{ margin: '0 0 10px 0', padding: '0px' }}>Upload OCR Documents</p>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <div>
          <label htmlFor='scanable-file' className="scanable-file-upload">
            <ScanOutlined style={{
              fontSize: '50px',
            }} />
            <input
              type="file"
              id='scanable-file'
              ref={fileInputRef}
              onChange={(e) => _onUploadFile(e)} 
              accept="image/png, image/jpeg"
              multiple
            />
          </label>
        </div>
        {/* OCR Status */}
        <div style={{ marginLeft: '10px', fontWeight: 'bold' }}>
          {/* OCR Processing */}
          {isProcessing && (
            <span style={ocrStatusWrapper}>
              Processing... < LoadingOutlined />
            </span>)}

          {/* OCR Done */}
          {!isProcessing && ocrResults && (
            <span style={ocrStatusWrapper}>
              Done <CheckOutlined style={{ color: 'green' }} />
            </span>
          )}
        </div>
      </div>
    </div>
  );
};

// Styles
const ocrStatusWrapper = {
  display: 'flex',
  alignItems: 'center',
  gap: '10px'
}

export default UploadOCRDocuments;