import React from "react"

// Import Components
import { Card, Col, Row } from "antd"
import RouteMapContainer from "../Components/Layouts/RouteStatistics/RouteMapContainer"

const RouteStatistics = () => {

    return(
        <div>
            <Row gutter={[ 12, 12 ]}>
                <Col xs={ 24 } lg={ 24 }>
                    <Card title={ 'Mapper Travel Path' } hoverable>
                        <RouteMapContainer />
                    </Card>
                </Col>
            </Row>
        </div>
    )
}

export default RouteStatistics