import React, { useEffect, useState } from "react";

// Import Library Components
import { Form, Input, Radio, Card, Button, Row, Col, Space, Result, Typography, Tag, message, Switch } from "antd";
import Spinner from "../../Common/Spinner";

// Import Icons
import { HomeFilled, ShoppingFilled, BankFilled } from "@ant-design/icons";

// Import Actions, Methods & Reducers
import { useDispatch, useSelector } from "react-redux";
import { getTopSheet, downloadTopsheet, updateTopsheet } from "../../../Services/Actions/reportsActions";
import { showAlert, getFillColorTripStatus, getTripTypeStringAndFilledIcon, getTripStatusLabelAndColor } from "../../../Services/Actions/commonActions";
import { setIsTopsheetUpdateLoading, setReloadModal } from "../../../Services/Reducers/reportsReducer";
import { setIsLoading } from "../../../Services/Reducers/commonReducer";

// Import Constants
const { Text, Title } = Typography;

const UpdateTopsheet = () => {
  const [selectedIndex, setSelectedIndex] = useState(0)
  // this params definition is intentionally followed to match exactly api params
  const dispatch = useDispatch();
  const [external_remarks, setExternal_remarks] = useState("");
  const applications = useSelector((state) => state.reports);
  const { applicationTopsheet, selectedApplication } = applications;
  
  const [isVerifiedStatus, setIsVerifiedStatus] = useState(false)

  useEffect(() => {
    if (applicationTopsheet?.trips?.length > 0) {
      setIsVerifiedStatus(applicationTopsheet?.trips[selectedIndex]?.is_verified)
    }
  }, [applicationTopsheet, selectedIndex])

  useEffect(() => {
    dispatch(getTopSheet(applications.selectedApplication.id));
  }, [dispatch, applications.selectedApplication.id]);

  // Check if applicationDetailsLoading is true
  if (applications.isTopsheetUpdateLoading) {
    return (
      <div style={containerStyles}>
        <Spinner />
      </div>
    );
  }

  // Check if id is not valid or applicationDetails is empty
  if (applications?.applicationTopsheet?.trips?.length === 0) {
    return (
      <div style={containerStyles}>
        <Result
          status={"404"}
          subTitle={"Sorry, This Application has no trip."}
        />
      </div>
    );
  }

  // Download Topsheet
  const downloadApplicationTopSheet = (selectedApplicationObject) => {
    dispatch(
      downloadTopsheet(
        selectedApplicationObject.id,
        selectedApplicationObject.file_no
      )
    );
  };


  // initial form value
  let initialFormValue =
    applicationTopsheet &&
    (applicationTopsheet.id !== undefined
      ? applicationTopsheet.trips.map((item) => {
        return { trip_id: item.id, data: {} };
      })
      : null);

  if (applicationTopsheet && applicationTopsheet.id !== undefined) {
    applicationTopsheet.trips.map((element, tripIndex) =>
      Object.values(element?.question_answers)?.map((item) => (
        (initialFormValue[tripIndex] = {
          trip_id: element.id,
          data: {
            ...initialFormValue[tripIndex]?.data,
            [`${item.key}-${item.id}`]: Object.values(item.answers)[0],
          },
        })
      )
      ))
  }

  // Generate Answers for New Structure
  const _generateAnswers = (currentData) => {

    // current trip id
    const { tripID } = currentData;

    // find current trip with questions
    const currentTrip = applicationTopsheet.trips.filter((trip) => trip.id === tripID)?.[0]

    // delete tripID
    delete currentData.tripID
    let answers = {}

    Object.entries(currentData)
      .forEach(([key, value]) => {
        // current question
        const currentQustion = currentTrip?.['question_answers']?.[key.split("-")[0]]

        if (currentQustion !== undefined) {
          if (currentQustion.field_type === 'INPUT' && currentQustion.options.length > 1) {
            const modifiedValue = {}
            for (const key in value) {
              if (!isNaN(key)) {
                continue; // Skip numbered keys
              }

              modifiedValue[key] = value[key];
            }

            answers[`${key.split("-")[0]}`] = modifiedValue

          } else if (currentQustion.field_type === 'INPUT' && currentQustion.options.length === 1) {
            let obj = {}
            const currentOptionKey = currentQustion?.options?.[0]?.option_key
            obj[`${currentOptionKey}`] = value
            answers[`${key.split("-")[0]}`] = obj

          } else if (currentQustion.field_type === 'MCQ' || currentQustion.field_type === 'SCQ') {
            let obj = {}
            const currentOptionKey = currentQustion?.options?.filter((option) => option.name === value)?.[0]?.option_key
            obj[`${currentOptionKey}`] = value
            answers[`${key.split("-")[0]}`] = obj
          }
        }
      })
    return answers
  }


  // On submitting the form
  const onFormSubmit = (values) => {
    //  initial fixed data of the form
    const initialFixedFormData = initialFormValue.filter(
      (item) => item.trip_id === values.tripID
    );

    //  converting new data in api paremeter format
    dispatch(setIsLoading(false));

    const answerCollection = _generateAnswers(values)

    // console.log(answerCollection, 'answerCollection')

    let _tripId = initialFixedFormData?.[0]?.trip_id
    let _remarks = values[`remarks-${_tripId}`]
    let _external_remarks = external_remarks ? external_remarks : applicationTopsheet?.external_remarks
    let _application_id = applicationTopsheet?.id

    const _bankInfo = {
      id: values.id,
      bank_name: values.bank_name,
      account_number: values.account_number,
      account_title: values.account_title,
      branch_name: values.branch_name,
      designation: values.designation,
      officer_phone: values.officer_phone,
      officer_telephone: values.officer_telephone,
      task_id: values.task_id,
    };

    let simpleParam = {
      trip_id: _tripId,
      remarks: _remarks,
      external_remarks: _external_remarks,
      application_id: _application_id,
      is_verified: isVerifiedStatus,
    }

    if (values.bank_name) {
      simpleParam = {
        trip_id: _tripId,
        remarks: _remarks,
        external_remarks: _external_remarks,
        application_id: _application_id,
        bank_accounts: _bankInfo,
      }
    }

    const paramToSubmit = {
      answers: answerCollection,
      ...simpleParam,
    };
    //  reloading the modal with updated data
    dispatch(setReloadModal(false));
    message.loading({ content: 'Processing...', duration: 10 })
    if (paramToSubmit.length !== 0) {
      // console.log(paramToSubmit, 'paramToSubmit')
      updateTopsheet(applications.selectedApplication.id, paramToSubmit)
        .then((res) => {
          if (res.status === 200) {
            message.destroy()
            // showAlert('success', `Total ${res.data.data.totalUpdated} Data ${res.data.data.message}`)
            showAlert("success", `Data Updated Successfully`);
            dispatch(getTopSheet(applications.selectedApplication.id));

            // dispatch(setIsTopsheetUpdateLoading(false))
            dispatch(setIsLoading(true));
            //  reloading the modal with updated data
            dispatch(setReloadModal(true));
          }
        })
        .catch((err) => {
          dispatch(setIsTopsheetUpdateLoading(false));
          message.destroy()
          // Show Error Alert
          showAlert(
            "error",
            err?.response?.data?.message ?? err?.message ?? "Update Failed."
          );
          console.error(err);
        })

    }
    if (paramToSubmit.length === 0) {
      dispatch(setIsTopsheetUpdateLoading(false));
      showAlert("error", "No data changed to save");
    }
  };

  // handle external Remarks
  const _handleExternalRemarks = (e) => {
    const value = e.target.value;
    let outputString = value?.replace(/\n/g, '<br>');

    setExternal_remarks(outputString);
  };

  return (
    <div>
      <Row gutter={[12, 12]}>
        <Col span={24} xs={24} md={24}>
          <Space
            direction={"vertical"}
            style={{ width: "100%", height: "100%" }}
          >
            <Card>
              {
                <>
                  {/* tab header */}
                  <nav>
                    <div className="nav nav-tabs" id="nav-tab" role="tablist">
                      {applicationTopsheet &&
                        applicationTopsheet.id !== undefined &&
                        applicationTopsheet.trips.map((item, index) => (
                          // first element is active element in tabs
                          <button
                            className={index === selectedIndex ? "nav-link active" : "nav-link"}
                            id={`nav-tab-${index}`}
                            data-bs-toggle="tab"
                            data-bs-target={`#nav-${index}`}
                            type="button"
                            role="tab"
                            aria-controls="nav-home"
                            aria-selected="true"
                            style={{ fontSize: "10px", fontWeight: "bold", zIndex: '1000' }}
                            key={index}
                            onClick={() => setSelectedIndex(() => index)}
                          >
                            {`${item?.concern_type}'S ${item?.type} Trip ID: ${item?.id}`}
                          </button>
                        ))}
                    </div>
                  </nav>

                  {/* tab content */}
                  <div className="tab-content" id="nav-tabContent">
                    {applicationTopsheet &&
                      applicationTopsheet.id !== undefined &&
                      applicationTopsheet.trips.map((item, index) => (
                        // first element is active element in tabs
                        <div
                          className={
                            index === 0
                              ? "tab-pane fade show active"
                              : "tab-pane fade"
                          }
                          id={`nav-${index}`}
                          role="tabpanel"
                          aria-labelledby={`nav-tab-${index}`}
                          key={index + item}
                        >
                          <div>
                            {
                              // showing notifiacations based on each group
                              applicationTopsheet &&
                              applicationTopsheet.id !== undefined &&
                              applicationTopsheet.trips?.map(
                                (element, tripIndex) =>
                                  selectedIndex === tripIndex ? (
                                    <Card
                                      tab={
                                        <span
                                          style={{
                                            color: getFillColorTripStatus(
                                              element.status
                                            ),
                                          }}
                                        >
                                          {
                                            getTripTypeStringAndFilledIcon(
                                              element.type,
                                              getFillColorTripStatus(
                                                element.status
                                              )
                                            ).icon
                                          }
                                          {`${element.type} TRIP - ${element.id}`}
                                        </span>
                                      }
                                      key={element.id}
                                    >
                                      <Form
                                        labelCol={{ span: 8 }}
                                        wrapperCol={{ span: 16 }}
                                        layout={"horizontal"}
                                        initialValues={
                                          {...initialFormValue[tripIndex].data,
                                          isVerified: element.is_verified}
                                        }
                                        onFinish={onFormSubmit}
                                      >
                                        <Card
                                          style={{
                                            marginBottom: 15,
                                            textAlign: "center",
                                            lineHeight: "8px",
                                          }}
                                        >
                                          <Title
                                            level={5}
                                            style={{
                                              color: getFillColorTripStatus(
                                                element.status
                                              ),
                                            }}
                                          >
                                            {element.concern_type}{" "}
                                            {element.type} TRIP - {element.id}
                                          </Title>

                                          <br />
                                          <Typography.Text strong={true}>
                                            TRIP STATUS:{" "}
                                            <Tag
                                              color={getFillColorTripStatus(
                                                element.status
                                              )}
                                            >
                                              {
                                                getTripStatusLabelAndColor(
                                                  element.status
                                                ).label
                                              }
                                            </Tag>
                                          </Typography.Text>
                                        </Card>
                                        
                                        {Object.values(element?.question_answers).length > 0 && Object.values(element?.question_answers)?.map((item, index) => (
                                          item.field_type ===
                                            "INPUT" ? (
                                            item.options.length >
                                              1 ? (
                                              <Form.Item
                                                label={
                                                  item.question
                                                }
                                                key={`${item.key + index
                                                  }`}
                                                name={`${item.key}-${item.id}`}
                                              >
                                                <Input.Group compact>
                                                  <Space
                                                    direction={"vertical"}
                                                  >
                                                    {
                                                      item.options.map((option, optionIndex) => {
                                                        return (
                                                          <div key={optionIndex}>
                                                            <Text>{option.name}</Text>
                                                            <Form.Item
                                                              name={[
                                                                `${item.key}-${item.id}`,
                                                                `${option.option_key}`,
                                                              ]}
                                                              // noStyle
                                                              initialValue={
                                                                `${item.answers[option.option_key] ?? ''}`
                                                              }
                                                            >
                                                              <Input
                                                                style={{
                                                                  width: "100%",
                                                                }}
                                                                placeholder={
                                                                  `Input ${option.name}`
                                                                }
                                                              />
                                                            </Form.Item>
                                                          </div>
                                                        )
                                                      })
                                                    }
                                                  </Space>
                                                </Input.Group>
                                              </Form.Item>
                                            ) : (
                                              <Form.Item
                                                label={
                                                  item.question
                                                }
                                                key={
                                                  item.key + index
                                                }
                                                name={`${item.key}-${item.id}`}
                                              >
                                                <Input />
                                              </Form.Item>
                                            )
                                          ) : (
                                            (item.field_type === "MCQ" || item.field_type === "SCQ") && (
                                              <Form.Item
                                                label={
                                                  item.question
                                                }
                                                key={
                                                  item.key + index
                                                }
                                                name={`${item.key}-${item.id}`}
                                              >
                                                <Radio.Group>
                                                  {item.options.map(
                                                    (questionOption) => (
                                                      <Radio.Button
                                                        key={
                                                          questionOption.id
                                                        }
                                                        value={
                                                          questionOption.name
                                                        }
                                                      >
                                                        {
                                                          questionOption.name
                                                        }
                                                      </Radio.Button>
                                                    )
                                                  )}
                                                </Radio.Group>
                                              </Form.Item>
                                            )
                                          )

                                        ))}
                                        <Form.Item
                                          label={"isVerified"}
                                          key={"isVerified"}
                                          name={"isVerified"}
                                        >
                                          <Switch
                                           checked={isVerifiedStatus}
                                           checkedChildren="Successful "
                                           unCheckedChildren="Unsuccessful "
                                           style={{ marginLeft: '10px' }}
                                           onChange={() => setIsVerifiedStatus(!isVerifiedStatus===true?1:0)}
                                           >
                                        </Switch> 
                                        </Form.Item>

                                        <Form.Item
                                          initialValue={element.remarks}
                                          label={"Remarks"}
                                          key={element.remarks + element.id}
                                          name={`${"remarks"}-${element.id}`}
                                        >
                                          <Input />
                                        </Form.Item>

                                        {(element.bank_account !== undefined && element.type === 'BANK' && Object.keys(element.bank_account).length > 0) ? (
                                          <Card
                                            title="Bank Information"
                                            style={{ textAlign: "center" }}
                                          >
                                            <Form.Item
                                              initialValue={
                                                element.bank_account.id
                                              }
                                              label={"Id"}
                                              key={element.bank_account.id}
                                              name={`id`}
                                              hidden
                                            >
                                              <Input disabled={true} />
                                            </Form.Item>
                                            <Form.Item
                                              initialValue={
                                                element.bank_account.bank_name
                                              }
                                              label={"Bank Name"}
                                              key={
                                                element.bank_account.bank_name
                                              }
                                              name={`bank_name`}
                                            >
                                              <Input />
                                            </Form.Item>
                                            <Form.Item
                                              initialValue={
                                                element.bank_account
                                                  .branch_name
                                              }
                                              label={"Branch Name"}
                                              key={
                                                element.bank_account
                                                  .branch_name
                                              }
                                              name={`branch_name`}
                                            >
                                              <Input />
                                            </Form.Item>
                                            <Form.Item
                                              initialValue={
                                                element.bank_account
                                                  .account_number
                                              }
                                              label={"Account Number"}
                                              key={
                                                element.bank_account
                                                  .account_number
                                              }
                                              name={`account_number`}
                                            >
                                              <Input />
                                            </Form.Item>
                                            <Form.Item
                                              initialValue={
                                                element.bank_account
                                                  .account_title
                                              }
                                              label={"Account Title"}
                                              key={
                                                element.bank_account
                                                  .account_title
                                              }
                                              name={`account_title`}
                                            >
                                              <Input />
                                            </Form.Item>
                                            <Form.Item
                                              initialValue={
                                                element.bank_account
                                                  .officer_phone
                                              }
                                              label={"Officer Phone"}
                                              key={
                                                element.bank_account
                                                  .officer_phone
                                              }
                                              name={`officer_phone`}
                                            >
                                              <Input />
                                            </Form.Item>

                                            <Form.Item
                                              initialValue={
                                                element.bank_account
                                                  .officer_telephone
                                              }
                                              label={"Officer Telephone"}
                                              key={
                                                element.bank_account
                                                  .officer_telephone
                                              }
                                              name={`officer_telephone`}
                                            >
                                              <Input />
                                            </Form.Item>
                                            <Form.Item
                                              initialValue={
                                                element.bank_account
                                                  .designation
                                              }
                                              label={"Designation"}
                                              key={
                                                element.bank_account
                                                  .designation
                                              }
                                              name={`designation`}
                                            >
                                              <Input />
                                            </Form.Item>
                                          </Card>
                                        ) : (
                                          ""
                                        )}

                                        <Card
                                          style={{ marginBottom: "15px" }}
                                          title={
                                            <Typography.Text type={"danger"}>
                                              Overall Remarks
                                            </Typography.Text>
                                          }
                                        >
                                          <Input.TextArea
                                            onChange={_handleExternalRemarks}
                                            rows={4}
                                            defaultValue={
                                              applicationTopsheet?.external_remarks ===
                                                "null"
                                                ? ""
                                                : applicationTopsheet?.external_remarks?.replace(/<br>/g, '\n')
                                            }
                                          />
                                        </Card>

                                        <Form.Item
                                          initialValue={element.id}
                                          hidden
                                          name={"tripID"}
                                        >
                                          <Input />
                                        </Form.Item>

                                        <Form.Item
                                          wrapperCol={{
                                            offset: 8,
                                            span: 16,
                                          }}
                                        >
                                          {getTripStatusLabelAndColor(
                                            element.status
                                          ).label !== "Inactive" &&
                                            getTripStatusLabelAndColor(
                                              element.status
                                            ).label !== "Hold" ? (
                                            <Button
                                              type={"primary"}
                                              htmlType={"submit"}
                                              icon={
                                                `${element.type}` ===
                                                  "HOUSE" ? (
                                                  <HomeFilled />
                                                ) : `${element.type}` ===
                                                  "OFFICE" ? (
                                                  <ShoppingFilled />
                                                ) : `${element.type}` ===
                                                  "BANK" ? (
                                                  <BankFilled />
                                                ) : (
                                                  ""
                                                )
                                              }
                                            >
                                              {`Save ${element.type.toLowerCase()} trip - ${element.id
                                                }`}
                                            </Button>
                                          ) : null}
                                          <Button
                                            onClick={() =>
                                              downloadApplicationTopSheet(
                                                applications.selectedApplication
                                              )
                                            }
                                          >
                                            Download Topsheet
                                          </Button>
                                        </Form.Item>
                                      </Form>
                                    </Card>
                                  ) : (
                                    ""
                                  )
                              )
                            }
                          </div>
                        </div>
                      ))}
                  </div>
                </>
              }
            </Card>
          </Space>
        </Col>
      </Row>
    </div>
  );
};

// JSS Styles
const containerStyles = {
  height: "100%",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
};

export default UpdateTopsheet;
