import React from 'react'
import { connect } from 'react-redux'

// Import Components
import CFMapGL from './CFMapGL'

class MapViewCF extends React.PureComponent {
    render() {
        const { markerData } = this.props
        return (
            <div style={mapContainerStyles}>
                <CFMapGL markerData={ markerData ?? [] } />
            </div>
        )
    }
}

// Styles
const mapContainerStyles = {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: `${window.innerHeight - 295}px`,
    boxShadow: '0px 0px 12px 2px rgba(0, 0, 0, 0.1)',
    maxWidth: '100%'
}

// Map State To Props
const mapStateToProps = state => ({    
    markerData: state.createFile.markerData,
})

export default connect(mapStateToProps, null)(MapViewCF)