import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import _isEqual from 'fast-deep-equal'

// Import Components
import DeckGL from 'deck.gl'
import { TripsLayer } from '@deck.gl/geo-layers'
import { StaticMap } from 'react-map-gl'

// Import Map Configs
import { MAP } from '../../Config/config'

class DGlMap extends React.PureComponent {
    state = {
        viewState: {
            longitude: 90.46027047540736,
            latitude: 23.76716255930807,
            zoom: 11.5,
            pitch: 0,
            bearing: 0,
        },
        layers: [],
    }


    componentDidUpdate = (prevProps) => {
        const { visitedPathData } = this.props
        const { viewState } = this.state

        if (!_isEqual(prevProps.visitedPathData, visitedPathData)) {

            const tripLayer = new TripsLayer({
                id: 'TripsLayer',
                data: visitedPathData,
                currentTime: 500,
                fadeTrail: false,
                capRounded: true,
                getColor: [0, 0, 0],
                getPath: d => d.waypoints.map(p => p.coordinates),
                jointRounded: true,
                widthMinPixels: 3,
                opacity: 1,
            });

            this.setState({
                layers: tripLayer,
            })

            if (visitedPathData.length) {
                this.setState({
                    viewState: {
                        ...viewState,
                        longitude: visitedPathData?.[0]?.waypoints?.[0]?.coordinates[0],
                        latitude: visitedPathData?.[0]?.waypoints?.[0]?.coordinates[1],
                    }
                })
            }
        }
    }

    // creating deckgl path layer
    _createPathlayer = (layerData) => {

        const tripLayer = new TripsLayer({
            id: 'TripsLayer',
            data: layerData,
            currentTime: 500,
            getTimestamps: d => d.waypoints.map(p => p.timestamp - 1554772579000),
            trailLength: 600,
            capRounded: true,
            getColor: [253, 128, 103],
            getPath: d => d.waypoints.map(p => p.coordinates),
            jointRounded: true,
            widthMinPixels: 8,
            opacity: 0.8,
        });

        this.setState({
            layers: tripLayer
        })
    }

    // Hanlde View State Change
    _onViewStateChange = ({ viewState }) => {
        if (viewState) {
            this.setState({ viewState })
        }
    }


    render() {
        const { viewState, layers } = this.state
        return (
            <DeckGL
                viewState={viewState}
                onViewStateChange={this._onViewStateChange}
                controller={true}
                layers={layers}
            >
                <StaticMap mapStyle={MAP.STYLE} />
            </DeckGL>
        )
    }
}

DGlMap.propTypes = {
    loading: PropTypes.bool,
    visitedPathData: PropTypes.array
}
DGlMap.defaultProps = {
    loading: false,
    visitedPathData: []
}

const mapStateToProps = (state) => ({
    loading: state?.routeStatistics?.loading ?? false,
})

export default connect(mapStateToProps, null)(DGlMap)