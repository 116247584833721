import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    applicationDetails: null,
    applicationDetailsLoading: false,
    selectedConcern: {},
    selectedTrip: {},
    applicationIssues: [],
    applicationTrips: [],
    prevSegmentTripID: 0, // this is for default selected trip segment in trip details container 
    prevSegmentConcernID: 0, // this is for default selected trip segment in trip details container  // mustbe clean on application.jsx unmount
    
    // File Upload
    isFileUploading: false,

    // Add New Concern
    isAddNewConcernModalOpen: false,

    // Update Concern
    isUpdateConcernModalOpen: false,

    // Update File No.
    isUpdateFileNoModalOpen: false,
    isUpdateFileNoButtonLoading: false,

    // Add New Trip
    isAddNewTripModalOpen: false,
    isAddNewTripButtonLoading: false,

    // Deactivate Concern
    isDeactivateLoading: false,
    // station update
    isUpdateStationLoading: false,  
}

const applicationSlice = createSlice({
    name: 'application',
    initialState,
    reducers: {
        setApplicationDetails: (state, action) => {
            state.applicationDetails = action.payload
        },
        setApplicationDetailsLoading: (state, action) => {
            state.applicationDetailsLoading = action.payload
        },
        setSelectedConcern: (state, action) => {
            state.selectedConcern = action.payload
        },
        setSelectedTrip: (state, action) => {
            state.selectedTrip = action.payload
        },
        setApplicationIssues: (state, action) => {
            state.applicationIssues = action.payload
        },
        setApplicationTrips: (state, action) => {
            state.applicationTrips = action.payload
        },
        setIsFileUploading: (state, action) => {
            state.isFileUploading = action.payload
        },
        setIsAddNewConcernModalOpen: (state, action) => {
            state.isAddNewConcernModalOpen = action.payload
        },
        setIsUpdateConcernModalOpen: (state, action) => {
            state.isUpdateConcernModalOpen = action.payload
        },
        setIsUpdateFileNoModalOpen: (state, action) => {
            state.isUpdateFileNoModalOpen = action.payload
        },
        setIsUpdateFileNoButtonLoading: (state, action) => {
            state.isUpdateFileNoButtonLoading = action.payload
        },
        setIsAddNewTripModalOpen: (state, action) => {
            state.isAddNewTripModalOpen = action.payload
        },
        setIsAddNewTripButtonLoading: (state, action) => {
            state.isAddNewTripButtonLoading = action.payload
        },
        setIsDeactivateLoading: (state, action) => {
            state.isDeactivateLoading = action.payload
        },
        setPrevSegmentTripID: (state, action) => {
            state.prevSegmentTripID = action.payload
        },
        setPrevSegmentConcernID: (state, action) => {
            state.prevSegmentConcernID = action.payload
        },
        setIsUpdateStationLoading: (state, action) => {
            state.isUpdateStationLoading = action.payload
        }
    }
})

export default applicationSlice.reducer
export const { setPrevSegmentConcernID, setPrevSegmentTripID, setApplicationDetails, setApplicationDetailsLoading, setSelectedConcern, setSelectedTrip, setApplicationIssues, setApplicationTrips, setIsAddNewConcernModalOpen, setIsUpdateConcernModalOpen, setIsUpdateFileNoModalOpen, setIsUpdateFileNoButtonLoading, setIsFileUploading, setIsAddNewTripModalOpen, setIsAddNewTripButtonLoading, setIsDeactivateLoading, setIsUpdateStationLoading } = applicationSlice.actions