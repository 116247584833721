import React, { useEffect, useState } from 'react';

// Import Actions, Methods, & Reducers
import { useDispatch, useSelector } from 'react-redux';

// Import Components
import { Button, Checkbox, Col, InputNumber, Modal, Row, Select, Table, Form } from 'antd';
import { getTripTypeList } from '../../../Services/Actions/staticOptionAction';
import { showAlert } from '../../../Services/Actions/commonActions';
import { assignQuesToOrganization } from '../../../Services/Actions/adminPanelActions';
const { Option } = Select

const AssignQuestion = ({ setIsAssignQuestions, isAssignModalVisible, handleAssignModalCancel, setIsAssignModalVisible, selectedQuestionsForAssign, setSelectedQuestionsForAssign, columns }) => {

  // States
  const dispatch = useDispatch()
  const [allTripTypeList, setAllTripTypeList] = useState();
  const [subTypeId, setSubTypeId] = useState(null)
  const [tripType, setTripType] = useState(null)
  const [isRequired, setIsRequired] = useState(false)
  const [form] = Form.useForm()

  // Get Value From Redux
  const { subtypeOptions } = useSelector(state => state.adminPanel)
  const selectedOrgId = useSelector(state => state.adminPanel.selectedOrgId ?? null)

  // Function to submit the "Assign Questions" modal
  const _handleAssignModalSubmit = () => {
    const modifiedQuestion = []
    selectedQuestionsForAssign?.forEach(question => {
      modifiedQuestion.push({
        id: question.id,
        question: question.question,
        is_required: isRequired,
        sort_order: question.sort_order
      })
    })

    const modifiedObject = {
      group_id: selectedOrgId,
      trip_type: tripType,
      sub_type_id: subTypeId,
      questions: modifiedQuestion
    }

    // Check Sort Order
    const missingOrder = modifiedQuestion.find(question => {
      return typeof question.sort_order === 'object'
    })

    // Validate Sort Order
    if (missingOrder && Object.keys(missingOrder).length > 0) {
      showAlert('error', 'Please Set Order')
    }

    if (!subTypeId) {
      showAlert('error', 'Please Set Sub Type')
    }

    if (!tripType) {
      showAlert('error', 'Please Set Trip Type')
    }

    // If All Okay
    if (typeof missingOrder === 'undefined' && subTypeId && tripType) {
      dispatch(assignQuesToOrganization(modifiedObject))
      setSelectedQuestionsForAssign([])
      setIsAssignQuestions(true)
      _clearFields()
    }
  };

  // Clear Fields
  const _clearFields = () => {
    form.setFieldsValue({
      sub_type: null,
      trip_type: null
    })
    setSubTypeId(() => null)
    setTripType(() => null)
    setIsRequired(() => false)
    setIsAssignModalVisible(false)
  }

  // Function to handle the change of sort order for a selected question
  const _handleSortOrderChange = (indexKey, sortOrder) => {
    const updatedSelectedQuestions = selectedQuestionsForAssign.map((question) => {
      if (question.key === indexKey) {
        return { ...question, sort_order: sortOrder };
      }
      return question;
    });

    setSelectedQuestionsForAssign(updatedSelectedQuestions);
  };

  // Get and Set All Trip List
  useEffect(() => {
    const allTripType = getTripTypeList()
    setAllTripTypeList(allTripType)
  }, [])

  // Update Sort Order `0`
  useEffect(() => {
    const sortOrderCollection = []
    selectedQuestionsForAssign.forEach((data) => sortOrderCollection.push({ ...data, sort_order: 0 }))
    setSelectedQuestionsForAssign(() => sortOrderCollection)

    if (!isAssignModalVisible) {
      Modal.destroyAll()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAssignModalVisible])

  return (
    <Modal
      title="Assign Questions"
      visible={isAssignModalVisible}
      onCancel={() => { handleAssignModalCancel(); _clearFields() }}
      footer={[
        <Button key="back" onClick={() => { handleAssignModalCancel(); _clearFields() }}>
          Cancel
        </Button>,
        <Button key="submit" type="primary" onClick={_handleAssignModalSubmit}>
          Assign
        </Button>,
      ]}
    >
      <Table
        showHeader={true}
        pagination={false}
        style={{ width: '100%', border: '1px solid #d9d9d9' }}
        columns={[
          ...columns, // Include existing columns
          {
            title: 'Sort Order',
            dataIndex: 'sort_order',
            align: 'center',
            render: (sortOrder, question) => (
              <InputNumber
                min={0}
                size='small'
                value={sortOrder}
                onChange={(value) => _handleSortOrderChange(question.key, value)}
                placeholder='Sort Order'
                style={{ width: "100px" }}
              />
            ),
          },
        ]}
        dataSource={selectedQuestionsForAssign} // selectedQuestionsForAssign
      />

      <Row justify='space-between' style={{ marginTop: '10px' }}>
        <Col span={24}>
          <Checkbox checked={isRequired} onChange={(e) => setIsRequired(e.target.checked)}>Is Required?</Checkbox>
        </Col>
        <Row style={{ width: '100%' }}>
          <Form form={form} style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
            <Col span={12}>
              <span style={{ color: '#0067B1', fontWeight: 'bold' }}>Subtype</span>
              {/* Sub Type */}
              <Form.Item name='sub_type' style={{ width: '100%' }}>
                <Select
                  placeholder='Select Subtype'
                  style={{
                    width: '95%',
                  }}
                  onChange={setSubTypeId}
                >
                  {
                    subtypeOptions?.length > 0 && subtypeOptions.map((item, index) => {
                      return (
                        <Option key={index + 1} value={item.app_subtype_id}>
                          {item.app_subtype}
                        </Option>
                      )
                    })
                  }
                </Select>
              </Form.Item>
            </Col>

            <Col span={12}>
              {/* Trip Type */}
              <span style={{ color: '#0067B1', fontWeight: 'bold' }}>Trip</span>
              <Form.Item name='trip_type' style={{ width: '100%' }}>
                <Select
                  placeholder='Select Trip Type'
                  style={{
                    width: '95%',
                  }}
                  onChange={setTripType}
                >
                  {
                    allTripTypeList?.length > 0 && allTripTypeList.map((item, index) => {
                      return (
                        <Option key={index + 1} value={item.value}>
                          {item.label}
                        </Option>
                      )
                    })
                  }
                </Select>
              </Form.Item>
            </Col>
          </Form>
        </Row>
      </Row>
    </Modal>
  );
};

export default AssignQuestion;