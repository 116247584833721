import React from 'react'

// Import Components
import { Card, Space } from 'antd'
import SearchFilesReCPV from './SearchFilesReCPV'
import ApplicationDetailsContainer from './ApplicationDetailsContainer'

class CreateReCPVTrips extends React.PureComponent {
    render() {
        return (
            <Card title={ 'Create Re-CPV Trips' } hoverable>
                <Space direction={ 'vertical' } style={{ width: '100%' }}>
                    <SearchFilesReCPV />
                    <ApplicationDetailsContainer />
                </Space>
            </Card>
        )
    }
}

export default CreateReCPVTrips