import React from 'react'

// Import Components
import { Form, Input, Select } from 'antd'
import { AutoCoompleteAddressReCPV } from '../ReCPV'

// import actions and reducers
import { getTripTypeByConcernType } from '../../../Services/Actions/staticOptionAction'

class OptionalAdditionalTrip extends React.PureComponent {
    state = {
        selectedTripType: '',
        selectedConcern: '',
        trip_types: [],
        isSuggestionBoxOpen: false
    }

    // handle trip type
    _handleTripType = value => {
        this.setState({ selectedTripType: value })
    }

    // Handle Concern On Change
    _handleConcernOnChange = (value, optionObject) => {
        const getTripTypeOfSelectedConcernType = getTripTypeByConcernType(optionObject?.type)

        this.setState({ 
            selectedConcern: value ?? null,
            trip_types: getTripTypeOfSelectedConcernType
        })
    }

    // changes isSuggestionBoxOpenProp to open/close suggestion box for exact address
    _handleSuggestionOpenForxactAddress = (booleanValue) => {
        // if suggestion box is open then click in anywhere will close the box
            this.setState({isSuggestionBoxOpen: booleanValue})
    }

    render() {
        const { selectedTripType, selectedConcern, trip_types, isSuggestionBoxOpen } = this.state
        const { addressDetails, getExactAddress, handleNotExact, id, tripNo, handlePlaceName, concerns } = this.props

        return (
            <div>

                {
                    concerns && concerns.length > 0 &&
                    <div>
                        <Form.Item
                            label='Concern'
                            name={`concern_id${tripNo}`}
                            rules={[
                                {
                                    required: selectedConcern,
                                    message: 'Please Select a concern.',
                                },
                            ]}
                        >
                            <Select placeholder='Select Concern' onChange={this._handleConcernOnChange}>
                                <Select.Option
                                    id={id}
                                    value={''}
                                >
                                    {`None`}
                                </Select.Option>
                                {
                                    concerns && concerns.length > 0 &&
                                    concerns.map((concern, index) => (
                                        <Select.Option
                                            id={id}
                                            key={index}
                                            value={concern.id}
                                            type={ concern.type }
                                        >
                                            {concern.name}
                                        </Select.Option>

                                    ))
                                }
                            </Select>
                        </Form.Item>
                    </div>
                }
                <Form.Item
                    label='Select Trip Type'
                    name={`type${tripNo}`}
                    rules={[
                        {
                            required: selectedConcern,
                            message: 'Please select a trip type.',
                        },
                    ]}
                >
                    <Select placeholder='Select Trip Type' onChange={this._handleTripType}>
                        {
                            trip_types && trip_types.length > 0 &&
                            trip_types.map((type, index) => (
                                <Select.Option
                                    id={id}
                                    key={index}
                                    value={type.value}
                                >
                                    {type.label}
                                </Select.Option>
                            ))
                        }
                    </Select>
                </Form.Item>

                {
                    selectedTripType && selectedTripType === 'BANK' &&
                    <div>
                        <Form.Item
                            label='Bank Account Name'
                            name={`bankAccountName${tripNo}`}
                            rules={[{ required: selectedConcern, message: 'Bank account name cannot be empty.' }]}
                        >
                            <Input />
                        </Form.Item>

                        <Form.Item
                            label='Bank Account Number'
                            name={`bankAccountNumber${tripNo}`}
                            rules={[{ required: selectedConcern, message: 'Bank account number cannot be empty.' }]}
                        >
                            <Input />
                        </Form.Item>

                        <Form.Item
                            label='Bank Name'
                            name={`bankName${tripNo}`}
                            rules={[{ required: selectedConcern, message: 'Bank name cannot be empty.' }]}
                        >
                            <Input />
                        </Form.Item>

                        <Form.Item
                            label='Bank Branch'
                            name={`bankBranch${tripNo}`}
                            rules={[{ required: selectedConcern, message: 'Bank branch cannot be empty.' }]}
                        >
                            <Input />
                        </Form.Item>
                    </div>
                }

                <Form.Item
                    label='Given Address'
                    name={`address${tripNo}`}
                    rules={[{ required: selectedConcern, message: 'Given address cannot be empty.' }]}
                >
                    <Input />
                </Form.Item>

                <Form.Item>
                    <AutoCoompleteAddressReCPV
                        rule={
                            selectedConcern ?
                            [{ required: true, message: 'optional Exact address cannot be empty.' }]
                            :
                            [{ required: false, message: 'optional Exact address cannot be empty.' }]
                        }
                        id={id}
                        customWidth={'99%'}
                        onSelect={getExactAddress}
                        handleNotExact={handleNotExact}
                        isSuggestionBoxOpenProp={isSuggestionBoxOpen}
                        toggleSuggestionBox={this._handleSuggestionOpenForxactAddress}
                    />
                </Form.Item>

                <Form.Item
                    label={ selectedTripType === 'OFFICE' || selectedTripType === 'GUARANTOR_OFFICE' ?  'Office Name' : 'Place Name' }
                >
                    <Input value={addressDetails && addressDetails.placeName} onChange={handlePlaceName} />
                </Form.Item>
            </div>
        )
    }
}

export default OptionalAdditionalTrip