import React, { useState, useEffect } from 'react'

// Import Components
import { Form, Card, Upload, Button, Typography, Space, Popover, Select } from 'antd'

// ImPort Icons
import { InfoCircleOutlined } from '@ant-design/icons'

// Import CSS
// import 'antd/dist/antd.css'

// Import Sample File
import sampleFile from '../../../Assets/Files/sample.csv'

// Import Actions, Reducers, and Methods
import { useDispatch, useSelector } from 'react-redux'
import { showAlert } from '../../../Services/Actions/commonActions'
import { createFileByBulkUpload, getOrgOptions } from '../../../Services/Actions/createFileActions'

// Import Hooks
import { useForm } from 'antd/lib/form/Form'


const FileUpload = () => {
    const [_orgList, set_orgList] = useState([])
    const [bulkFile, setBulkFile] = useState(null)
    const [selectedOrgOptions, setSelectedOrgOptions] = useState(null)

    const { isFileUploading } = useSelector(state => state?.application ?? null)
    const { user } = useSelector(state => state?.auth ?? null)
    const { orgOptions } = useSelector(state => state?.createFile ?? null)

    const commonData = useSelector(state => state.common)
    const { orgList } = commonData

    const [form] = useForm()
    const dispatch = useDispatch()

    useEffect(() => {
        // prefetching org options to show subtype legends
        dispatch(getOrgOptions({ org_id: user?.org_id }))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    // 
    useEffect(() => {
        setSelectedOrgOptions(orgOptions[0])
    }, [orgOptions])

    // on orglist update
    useEffect(() => {
        const onlyList = orgList.filter(item => item.id !== -1)
        set_orgList(onlyList)

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [orgList?.length])

    // Handle LOI File Upload Change
    const _handleLOIFileUploadChange = (info) => {
        const file = info.fileList

        // Check if file is not valid
        if (file) {
            // Set to State
            setBulkFile(file)
        }
        else {
            setBulkFile(null)
        }
    }

    // Handle File Upload 
    const _handleFileUpload = (e) => {

        // console.log("e", e);
        // Check if File is uploaded
        if (bulkFile?.length > 0) {
            const formData = new FormData()

            bulkFile.forEach((list) => {
                formData.append(`file`, list.originFileObj)
            })

            if (e.org_id) {
                formData.append(`org_id`, e.org_id)
            }
            dispatch(createFileByBulkUpload(formData))
            setBulkFile(null)
        } else {
            // Show alert
            showAlert('error', 'Please upload at least one file.')
        }
    }

    // updating selected org option
    const updateSelectedOrgOption = (tripTypeID) => {
        orgOptions?.forEach(item => {
            if (item?.id === tripTypeID) {
                setSelectedOrgOptions(item)
            }
        })
    }

    const subtypeLegend = (
        orgOptions?.length > 0 ?
            orgOptions?.map((item, index) => {
                return (<div key={index}>
                    <p style={{ fontWeight: "bold" }}>
                        Name: {item?.label} - Value: {item?.value}
                    </p>

                    {
                        item?.subTypes?.map((item, index) => (<p key={index}>Subtype: {item?.label} Value: {item?.value}</p>))
                    }
                </div>)
            }
            )
            :
            (<p>Select Organization and Application Type First</p>)

    )

    return (
        <Card
            title={
                <div style={{display: 'flex', width: '100%', justifyContent: 'center', alignItems: 'center', gap: '5px'}}>
                    <Typography.Text>
                        Upload in Bulk
                    </Typography.Text>

                    {
                        <Popover placement="bottom" title={'Subtype Legend'} content={subtypeLegend} trigger="click">
                            <InfoCircleOutlined style={{ color: 'red', cursor: 'pointer' }} />
                        </Popover>

                    }
                </div>
            }
            headStyle={{ textAlign: 'center', fontWeight: 'bold' }}
        >


            <Form
                layout={'horizontal'}
                labelCol={{ span: 6, xs: 24, sm: 24, md: 12, lg: 12, xl: 12, xxl: 12 }}
                wrapperCol={{ span: 24, xs: 24, sm: 24, md: 12, lg: 12, xl: 12, xxl: 12 }}
                form={form}
                onFinish={_handleFileUpload}
            >
                <Form.Item name="org_id" rules={[{ required: true }]} style={{ marginBottom: '10px', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <Select
                        name="org_id"
                        style={{ width: '100%' }}
                        showSearch
                        allowClear
                        placeholder={'Select Organization'}
                        // filterOption={ (input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0 }
                        optionFilterProp={'children'}
                    >
                        {
                            _orgList?.length > 0 && _orgList?.map((item, index) => {
                                return (
                                    // all org removed index[0] == all org
                                    index !== 0 ?
                                        <Select.Option
                                            key={index + 1}
                                            value={item?.uuid}
                                        >
                                            {item?.name}
                                        </Select.Option>
                                        : ""
                                )
                            })
                        }
                    </Select>
                </Form.Item>

                <Form.Item
                    rules={[{ required: true, message: 'Please select file!' }]}
                    style={{ marginBottom: '10px', display: 'flex', flexDirection: 'column', alignItems: 'center' }}
                >
                    <Upload
                        name={'bulkFile'}
                        listType='picture-card'
                        onChange={_handleLOIFileUploadChange}
                        multiple={false}
                        maxCount={1}
                        beforeUpload={() => { return false }}
                        fileList={bulkFile}
                    >
                        <Space direction={'vertical'}>
                            <Typography.Title level={3} style={{ margin: '0' }}>+</Typography.Title>
                            <Typography.Text>Upload</Typography.Text>
                        </Space>
                    </Upload>
                </Form.Item>

                <Form.Item
                    wrapperCol={{
                        span: 24
                    }}
                >
                    <Button
                        loading={isFileUploading}
                        style={{ display: 'block', margin: '10px auto 0 auto' }}
                        type='primary'
                        htmlType='submit'
                    >
                        Submit
                    </Button>
                </Form.Item>
            </Form>
            <a href={sampleFile} style={{ display: 'block', textAlign: 'center' }} download>Need a Sample File? Click Here</a>
        </Card>
    )
}

export default FileUpload





