import axios from "axios";

// Import Types
import { BILL } from "../../Config/config";

// Import Actions
import { setAllBill, setIsDownloading } from "../Reducers/billReducer";
import { showAlert } from "./commonActions";

const XLSX = require('xlsx')

// Binary String To Array Buffer
const binaryStringToArrayBuffer = (binaryString) => {
  const buffer = new ArrayBuffer(binaryString.length);
  const view = new Uint8Array(buffer);
  for (let i = 0; i < binaryString.length; i++) {
    view[i] = binaryString.charCodeAt(i) & 0xFF;
  }
  return buffer;
}

// Post Bill -( Calculate Billing )
const postBill = (params) => {
  return async (dispatch) => {
    // start loader
    dispatch(setAllBill({ isLoading: true }));

    try {
      const response = await axios.post(BILL.CALCULATE_BILL, params);
      const data = response?.data;

      dispatch(setAllBill({ bill: data, isLoading: false }));
    } catch (error) {
      dispatch(setAllBill({ isLoading: false }));
    }
  };
};

// Get Trip History
const getTripHistory = (params, orgName) => {
  return async (dispatch) => {
    // start loader
    dispatch(setIsDownloading(true))

    try {
      const response = await axios.get(BILL.GET_TRIP_HISTORY, { params });
      const data = response?.data?.data?.trips;

      if (data?.length) {

        const normalizeData = []

        data?.forEach((_data, index) => {

          normalizeData.push({
            '#': index + 1,
            ..._data,
          })
        })

        // Define Workbook & Worksheet
        const workbook = XLSX.utils.book_new();
        const worksheet = XLSX.utils.json_to_sheet(normalizeData);
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');

        // Generate a data array for the Blob
        const blobData = XLSX.write(workbook, { bookType: 'xlsx', type: 'binary' });

        // Convert the binary string to a Blob
        const blob = new Blob([binaryStringToArrayBuffer(blobData)], { type: 'application/octet-stream' });

        // Create a URL for the Blob data
        const url = window.URL.createObjectURL(blob);

        // Generate a timestamp for the file name
        const timestamp = Date.now();

        // Create a download link and trigger the download
        const a = document.createElement('a');
        a.href = url;
        a.download = `${orgName}_Trip_History_${timestamp}.xlsx`;
        a.click();
        window.URL.revokeObjectURL(url);
      } else {
        showAlert('error', 'No Data Found')
      }

    } catch (error) {
      showAlert('error', 'Something Went Wrong')
    } finally {
      dispatch(setIsDownloading(false))
    }
  }

}

export { postBill, getTripHistory };
