import { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'

// Import Components
import { Button } from 'antd'

const TopsheetDownloadButton = ({ icon, size, downloadTopsheet, record, disabled }) => {
    const [selectedApplication, setSelectedApplication] = useState(null)
    const { isTopsheetUpdateLoading } = useSelector(state => state.reports)

    // in useEffect, check if isTopsheetUpdateLoading is false then set selectedApplication to null
    useEffect(() => {
        if (!isTopsheetUpdateLoading) {
            setSelectedApplication(null)
        }
    }, [isTopsheetUpdateLoading])

    const handleTopsheetDownload = () => {
        setSelectedApplication(record)
        downloadTopsheet()
    }

    return (
        <Button
            disabled={disabled}
            icon={icon}
            size={size}
            onClick={() => handleTopsheetDownload()}
            // we need to compare "selectedApplication?.id === record?.id" this because without this all other Row's button starts loading parallally
            loading={isTopsheetUpdateLoading && selectedApplication?.id === record?.id}
        />
    )
}

export default TopsheetDownloadButton