import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

//antd
import { Typography, Space, Card } from "antd";

// Import Components
import MapGL from "../Components/Layouts/MapGL";
import DateOrgFilterTaskMap from "../Components/Layouts/DateOrgFilterTaskMap";
import DrawerTaskMap from "../Components/Layouts/DrawerTaskMap";

// Import Actions
import {
  getInitialVerifiersGeoLocation,
  deactivateSocket,
} from "../Services/Actions/taskMapActions";
import {
  getTripStatusLabelAndColor,
  getVerifierStatusLabelAndColor,
} from "../Services/Actions/commonActions";

const { Text } = Typography;

class TaskMap extends React.PureComponent {
  state = {
    totalTripStatus: 7, // change this if new trip status is added
    legend: [],
    totalVerifierStatus: 5,
    verifierLegend: [],
  };

  componentDidMount = () => {
    const { dispatch } = this.props;

    const updatedLegend = this._getLegend();
    dispatch(getInitialVerifiersGeoLocation());

    this.setState({
      legend: updatedLegend,
    });

    const updatedVerifierLegend = this._getVerifierLegend();
    this.setState({
      verifierLegend: updatedVerifierLegend,
    });
  };

  _getLegend() {
    const { totalTripStatus } = this.state;
    let legendValue = [];
    for (var i = 1; i <= totalTripStatus; ++i) {
      legendValue.push(getTripStatusLabelAndColor(i));
    }
    return legendValue;
  }

  _getVerifierLegend() {
    const { totalVerifierStatus } = this.state;
    let legendValue = [];
    for (var i = 1; i <= totalVerifierStatus; ++i) {
      legendValue.push(getVerifierStatusLabelAndColor(i));
    }
    return legendValue;
  }

  componentWillUnmount = () => {
    deactivateSocket();
  };

  render() {
    const { legend, verifierLegend } = this.state;

    return (
      <div
        style={
          window.innerWidth <= 768 ? mapBoxStyleMobile : mapBoxStyleDesktop
        }
      >
        <DateOrgFilterTaskMap />
        <MapGL />
        <Card
          bodyStyle={{ padding: "2px 15px" }}
          style={legendCardStyle}
          hoverable
        >
          {
            <Space
              size={0}
              align={"end"}
              direction={"vertical"}
              style={{ padding: 0 }}
            >
              <Text
                style={{
                  fontWeight: "bold",
                  textAlign: "center",
                  borderBottom: "1px solid black",
                }}
              >
                Trips
              </Text>
              {legend.length > 0 &&
                legend.map((item) => (
                  <Text key={item.label} style={{ fontSize: "13px" }}>
                    <span
                      style={{
                        ...legendColorSpanStyle,
                        border: `4px solid ${item.color}`,
                      }}
                    ></span>
                    {` ${item.label}`}
                  </Text>
                ))}
            </Space>
          }
        </Card>
        <Card
          bodyStyle={{ padding: "8px 15px" }}
          style={legendStatusCardStyle}
          hoverable
        >
          {
            <Space
              size={0}
              align={"end"}
              direction={"vertical"}
              style={{ padding: 0 }}
            >
              <Text
                style={{
                  fontWeight: "bold",
                  textAlign: "center",
                  borderBottom: "1px solid black",
                }}
              >
                Status
              </Text>
              {verifierLegend.length > 0 &&
                verifierLegend.map((item) => (
                  <Text key={item.label} style={{ fontSize: "13px" }}>
                    <span
                      style={{
                        ...legendStatusColorSpanStyle,
                        backgroundColor: item.color,
                      }}
                    ></span>
                    {` ${item.label}`}
                  </Text>
                ))}
            </Space>
          }
        </Card>
        <DrawerTaskMap />
      </div>
    );
  }
}

const mapBoxStyleDesktop = {
  // minHeight: `${window.innerHeight - 130}px`,
  position: "relative",
  height: "calc(100vh - 130px)",
  overflow: "hidden",
};
const mapBoxStyleMobile = {
  // minHeight: `${window.innerHeight - 130}px`,
  position: "relative",
  height: "calc(100vmax - 70px)",
  overflow: "hidden",
};

const legendCardStyle = {
  padding: "0 !important",
  position: "absolute",
  bottom: "5px",
  right: "5px",
  pointerEvents: "none",
};

const legendStatusCardStyle = {
  padding: "0 !important",
  position: "absolute",
  bottom: "5px",
  left: "5px",
  pointerEvents: "none",
};

const legendStatusColorSpanStyle = {
  display: "inline-block",
  borderRadius: "100px",
  height: "10px",
  width: "10px",
};

const legendColorSpanStyle = {
  display: "inline-block",
  background: "white",
  borderRadius: "100px",
  height: "12px",
  width: "12px",
};

TaskMap.protypes = {
  dispatch: PropTypes.func,
};

TaskMap.defaultProps = {
  dispatch: () => null,
};

const mapDispatchToProps = (dispatch) => ({ dispatch });

export default connect(null, mapDispatchToProps)(TaskMap);
