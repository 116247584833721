import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setSelectedOrgStations } from "../../../Services/Reducers/adminPanelReducer";
import { Empty, Table } from "antd";

const SelectedOrgInfo = () => {
  const dispatch = useDispatch();
  const selectedOrgStation = useSelector(
    (state) => state.adminPanel.selectedOrgStations
  );

  useEffect(() => {
    dispatch(setSelectedOrgStations([]));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  // console.log(selectedOrgStation, "selectedOrgStation");

  // columns
  const columns = [
    {
      title: "Station Type",
      dataIndex: "station_type",
      key: "station_type",
      align: "center",
    },
    {
      title: "Turn Around Time",
      dataIndex: "turn_around_time",
      key: "station_id",
      align: "center",
      render: (text, record) => {
        const hour = Math.floor(text / 3600);
        const min = Math.floor((text % 3600) / 60);
        return hour ? `${hour} h ${min} min` : `${min} min`;
      },
    },
    {
      title: "Last Submission Time",
      dataIndex: "last_submission_time",
      key: "last_submission_time",
      align: "center"
    },
  ];
  return (
    <div>
      {selectedOrgStation.length > 0 ? <Table
        dataSource={selectedOrgStation}
        pagination={false}
        columns={columns}
      /> : <Empty />}
    </div>
  );
};

export default SelectedOrgInfo;
