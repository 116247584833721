import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

// Import Components
import { Card, Input, Modal, Radio, Space, Tag, Typography } from 'antd'

// Import Actions
import { closeStatusUpdateModal, updateStatus } from '../../../Services/Actions/reportsActions'
import { showAlert } from '../../../Services/Actions/commonActions'

class StatusUpdateModal extends React.PureComponent {
    state = {
        statusOptions: [
            {
                label: 'Complete Submitted', // NOTE: programmatically this status label is : Submitted, But here in UI we changed for better user understandind
                value: 6
            },
            {
                label: 'Incomplete Submitted ', // NOTE: programmatically this status label is : Finished. But here in UI we changed for better user understandind
                value: 7
            }
        ],
        selectedStatus: null,
        internalRemarks: ''
    }

    componentDidMount = () => {
        const { selectedApplication } = this.props
        const { statusOptions } = this.state

        // Check selectedApplication status.value is in statusOptions
        if (selectedApplication?.status?.value && statusOptions.find(item => item.value === selectedApplication?.status?.value)) {
            // Set selectedStatus to selectedApplication status
            this.setState({ selectedStatus: selectedApplication?.status?.value })
        }
    }

    componentDidUpdate = (prevProps) => {
        const { selectedApplication } = this.props

        // Check if selectedApplication has changed
        if (selectedApplication !== prevProps.selectedApplication) {
            // Clear selectedStatus
            this.setState({ selectedStatus: null })
        }        
    }

    // Handle Status Option Change
    _handleStatusOptionChange = (e) => {        
        const { value } = e.target

        this.setState({ selectedStatus: value })
    }

    // Handle Status Update
    _handleStatusUpdate = () => {        
        const { dispatch, selectedApplication, getParams, currentPage } = this.props
        const { selectedStatus, internalRemarks } = this.state
        let params = getParams(currentPage);

        // Check if Status is not selected
        if (!selectedStatus) {
            // Show error alert
            showAlert('error', 'Please select a status to update')
            return
        }

        // Check if selectedApplication is empty or null
        if (!selectedApplication) {
            // Show error alert
            showAlert('error', 'Please select an application to update')
            return
        }

        // Check if selectedApplication status.value is same as selectedStatus
        if (selectedApplication?.status?.value === selectedStatus) {
            // Show error alert
            showAlert('error', 'Selected status is same as current status')
            return
        }

        dispatch(updateStatus({ application_id: selectedApplication.id, application_status: selectedStatus, internal_remarks: internalRemarks }, params))
    }

    // handle remarks change
    _handleRemarks = (e) => {
        const value = e.target.value

        this.setState({
            internalRemarks: value
        })
    }

    render() {
        const { statusOptions, selectedStatus } = this.state
        const { dispatch, isStatusUpdateModalOpen, selectedApplication, isStatusUpdateLoading } = this.props

        return (
            <Modal
                title={ 
                    <Space 
                        direction={ 'horizontal' }
                    >
                        <Tag>{ `File: ${selectedApplication?.file_no ?? 'N/A'}` }</Tag>
                        <Tag color={ selectedApplication?.status?.color ?? '' }>
                            { selectedApplication?.status?.label ?? 'N/A' }
                        </Tag>
                    </Space> 
                }
                centered
                style={{ width: '100%' }}
                visible={ isStatusUpdateModalOpen }
                okText={ 'Update' }
                cancelText={ 'Cancel' }
                onCancel={ () => dispatch(closeStatusUpdateModal()) }
                onOk={ () => this._handleStatusUpdate() }
                maskClosable={ false }
                okButtonProps={{ loading: isStatusUpdateLoading }}
            >
                <Space direction={ 'vertical' } style={{ textAlign: 'left !important' }}>
                    <Space style={{ width: '100%' }}>
                        <Typography.Text style={{ width: '20%' }}>{ 'Select Status: ' }</Typography.Text>
                        <Radio.Group
                            options={ statusOptions }
                            optionType={ 'button' }
                            buttonStyle={ 'solid' }
                            onChange={ this._handleStatusOptionChange }
                            value={ selectedStatus }
                        />
                    </Space>
                    <div style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
                        <Typography.Text style={{ width: '20%' }}>Remarks</Typography.Text>
                        <Input style={{ width: '80%' }} onChange={ this._handleRemarks }/>
                    </div>
                </Space>
                {
                    <Card hoverable direction={ 'vertical' } align={ 'start' } bodyStyle={{ padding: '15px' }} style={{ cursor: 'default', display: 'flex', flexDirection: 'column', margin: '10px 5px 0 5px' }}>
                        <Card hoverable style={{ cursor: 'default' }}>
                            <Tag color={ 'error' }>Previous Remarks :</Tag> <br />
                            <Typography.Text  style={{ color: '#6e7b89' }}>{ selectedApplication?.internal_remarks ? selectedApplication?.internal_remarks : <p style={{ textAlign: 'center' }}>No remarks found</p> }</Typography.Text>
                        </Card>
                    </Card>
                }
            </Modal>
        )
    }
}

// Props Validation
StatusUpdateModal.propTypes = {
    isStatusUpdateModalOpen: PropTypes.bool,
    isStatusUpdateLoading: PropTypes.bool,
    selectedApplication: PropTypes.object,
}

StatusUpdateModal.defaultProps = {
    isStatusUpdateModalOpen: false,
    isStatusUpdateLoading: false,
    selectedApplication: null
}

// Map State To Props (Redux Store Passes State To Component)
const mapStateToProps = (state) => ({
    isStatusUpdateModalOpen: state.reports.isStatusUpdateModalOpen,
    selectedApplication: state.reports.selectedApplication,
    isStatusUpdateLoading: state.reports.isStatusUpdateLoading,
    currentPage: state?.reports?.pagination?.current_page,
})

// Map Dispatch To Props (Dispatch Actions To Reducers. Reducers Then Modify The Data And Assign It To Your Props)
const mapDispatchToProps = (dispatch) => ({ dispatch })

export default connect(mapStateToProps, mapDispatchToProps)(StatusUpdateModal)
