import React from 'react'
import PropTypes from 'prop-types'

// Import Components
import { Modal } from 'antd'
import { ConcernUpdateForm, AddNewConcern } from '../Application'

class ConcernModal extends React.PureComponent {
    render() {
        const { modalTitle, modalType, modalOnClose, isConcernModalOpen } = this.props
        return (
            <Modal
                visible={ isConcernModalOpen }
                title={ modalTitle }
                onCancel={ modalOnClose }
                maskClosable={ false }
                footer={ null }
            >
                {
                    modalType === 'concern_update' && (
                        <ConcernUpdateForm />
                    )
                }
                {
                    modalType === 'new_concern' && (
                        <AddNewConcern />
                    )
                }
            </Modal>
        )
    }
}

// Props Validation
ConcernModal.propTypes = {
    modalType: PropTypes.string,
    modalTitle: PropTypes.string,
    modalOnClose: PropTypes.func,
    isConcernModalOpen: PropTypes.bool,
}

ConcernModal.defaultProps = {
    modalType: '',
    modalTitle: '',
    modalOnClose: () => null,
    isConcernModalOpen: false,
}

export default ConcernModal