import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import parse from 'autosuggest-highlight/parse'
import match from 'autosuggest-highlight/match'

// Import Components
import { AutoComplete, Empty } from 'antd'
import Spinner from '../../Common/Spinner'

// Import Actions
import { searchApplication, getApplicationDetails } from '../../../Services/Actions/recpvActions'

class SearchFilesReCPV extends React.PureComponent {
    state = {
        value: '',
    }
    // Handle Search
    _handleSearch = value => {
        const { dispatch } = this.props

        if (value) {
            // Set value
            this.setState({ value })

            // Search Application with value
            dispatch(searchApplication({ q: value }))
        }
    }

    // Handle Select
    _handleSelect = (value, option) => {
        const { dispatch } = this.props
        // Check if option is not null and has file_id
        if (option && option?.file_id) {
            // Get Application Details
            dispatch(getApplicationDetails(option.file_id))
        }
    }
    render() {
        const { value } = this.state
        const { isFilesLoading, files } = this.props
        return (
            <AutoComplete
                style={{ width: '100%' }}
                placeholder={ 'Search application..' }
                allowClear
                defaultActiveFirstOption
                onSearch={ this._handleSearch }
                onSelect={ this._handleSelect }
                notFoundContent={ 
                    isFilesLoading ? 
                    <div style={ styles.spinnerStyles }>
                        <Spinner />
                    </div>
                    : 
                    <Empty description={ 'No application found' } /> }
            >                
                {
                    files?.length > 0 && !isFilesLoading && files.map((file, index) => {
                        const matches = match(file?.application_id ?? '', value)
                        const parts = parse(file?.application_id ?? '', matches)
                        return (
                            <AutoComplete.Option 
                                key={ file?.id ?? index + '_recpv_autocomp' }
                                value={ file?.application_id ?? '' }
                                file_id={ file?.id ?? '' }
                            >
                                {
                                    parts?.length > 0 && parts.map((part, index) => (
                                        <span key={ index } style={ part.highlight ? { fontWeight: 600 } : {} }>
                                            { part.text }
                                        </span>
                                    ))
                                }
                            </AutoComplete.Option>
                        )
                    })
                }
            </AutoComplete>
        )
    }
}

// JSS Styles
const styles = {
    spinnerStyles: {
        width: '100%',
        display: 'flex', 
        alignItems: 'center', 
        justifyContent: 'center', 
        padding: '2rem'
    }
}

// PropTypes Validation
SearchFilesReCPV.propTypes = {
    isFilesLoading: PropTypes.bool,
    files: PropTypes.array,
    dispatch: PropTypes.func,
}

SearchFilesReCPV.defaultProps = {
    isFilesLoading: false,
    files: [],
    dispatch: () => null,
}

const mapStateToProps = state => ({
    isFilesLoading: state.recpv.isFilesLoading,
    files: state.recpv.files,
})

const mapDispatchToProps = dispatch => ({ dispatch })

export default connect(mapStateToProps, mapDispatchToProps)(SearchFilesReCPV)