import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

// Import Library Components
import { Card, Table, Space, Result } from "antd";
import { LoadingOutlined } from "@ant-design/icons";

// Import Component
import FilterWithDateOrg from "../Components/Layouts/FilterWithDateOrg";

// Import Actions
import { getReportsByInput } from "../Services/Actions/reportsActions";
import { getPendingListByDateRange } from "../Services/Actions/pendingListActions";
import { isAllowedToAccess } from "../Services/Actions/permissionActions";
// import { getApplicationStatus } from "../Services/Actions/commonActions";

class PendingList extends React.PureComponent {

  state = {
    columns: [
      {
        title: "Sl No",
        key: "sl_no",
        render: (text, object, index) => (this.state.currentPage - 1) * this.state.pageSize + index + 1
      },
      {
        title: "Start Date",
        dataIndex: "start_date",
        key: "start_date",
      },
      {
        title: "File Number",
        dataIndex: "application_id",
        key: "application_id",
      },
      {
        title: "Name",
        dataIndex: "concerns",
        key: "concerns",
        render: (concerns) =>
          concerns?.map((item) => {
            if (item?.type === "APPLICANT") {
              return item?.name;
            }
          }),
      },
      {
        title: "SLA",
        dataIndex: "sla",
        key: "sla",
      },
      {
        title: "Partial Submission",
        dataIndex: "partially_submitted_at",
        key: "partially_submitted_at",
        // render: ( record ) => {
        //     const statuslabel = getApplicationStatus(record?.status)
        //     if(statuslabel?.label === 'Finished'){
        //         return record?.end_date
        //     }
        //     return '-'
        // }
      },
      {
        title: "Pending Issue",
        dataIndex: "internal_remarks",
        key: "internal_remarks",
      },
      {
        title: "Submision Date",
        dataIndex: "submitted_at",
        key: "submitted_at",
        // render: ( record ) => {
        //     const statuslabel = getApplicationStatus(record?.status)
        //     if(statuslabel?.label === 'Submitted'){
        //         return record?.end_date
        //     }
        //     return '-'
        // }
      },
    ],
    currentPage: 1,
    pageSize: 10,
    startDate: null,
    endDate: null,
    selectedGroup: null,
    selectedCity: null,
    inputValue: "",
    visible: false,
    loading: false,
    openingApplicationID: 0,
    file_no: 0,
    currentApplicationData: null,
  };

  componentDidMount = () => {
    // Get Reports
    this._getReports(1, true);
  };

  // Handle on submit
  _handleSubmit = (startDate, endDate, selectedGroup, selectedCity) => {
    this.setState({
      startDate,
      endDate,
      selectedGroup,
      selectedCity,
      inputValue: "",
    });
    setTimeout(() => {
      this._getReports(1, false);
    }, 100);
  };

  // Get Params
  _getParams = (page) => {
    const { startDate, endDate, selectedGroup, selectedCity } = this.state;

    // const formatedStartDate = startDate
    //   ? moment(startDate).format("YYYY-MM-DD")
    //   : moment(new Date()).format("YYYY-MM-DD");
    // const formatedEndDate = endDate
    //   ? moment(endDate).format("YYYY-MM-DD")
    //   : moment(new Date()).format("YYYY-MM-DD");

    // set params
    let params = {
      // dateFrom: formatedStartDate,
      // dateTill: formatedEndDate,
      page: page ?? 1,
    };
    if (selectedGroup && selectedGroup?.uuid !== -1) {
      params = { ...params, org_id: selectedGroup.uuid };
    }

    if (selectedCity && selectedCity?.id !== -1) {
      params = { ...params, city: selectedCity.value };
    }
    return params;
  };

  // Get Reports
  _getReports = (page, key) => {
    const { dispatch } = this.props;

    // get params
    const params = this._getParams(page);

    // dispatch action
    // dispatch( getReportsByDateRange(params, key) )
    dispatch(getPendingListByDateRange(params, key));
  };

  // Handle Search
  _handleSearch = (value) => {
    const { dispatch } = this.props;
    dispatch(getReportsByInput({ q: value }));

    // Set value to state
    this.setState({ inputValue: value });
  };

  // Hanlde Pagination
  _handlePagination = (page, key, path) => {
    const { inputValue } = this.state;
    const { dispatch } = this.props;

    // Check if the path end with search
    if (path && path.endsWith("search")) {
      dispatch(getReportsByInput({ q: inputValue, page: page ?? 1 }));
    } else {
      this._getReports(page, key);
    }
  };

  render() {
    const {
      pendingList,
      isLoading,
      pendingListPegination,
      isSubmitButtonLoading,
      isSearchButtonLoading,
      allowedPermission
    } = this.props;
    const { columns } = this.state;

    return (
      <div style={{ width: "100%" }}>
        <Card title={"Pending List"} style={{ width: "100%" }} hoverable>
          <Space
            direction={"vertical"}
            size={"middle"}
            style={{ width: "100%" }}
          >
            <FilterWithDateOrg
              // showSearch={ true }
              onSubmit={this._handleSubmit}
              isSubmitButtonLoading={isSubmitButtonLoading}
              isSearchButtonLoading={isSearchButtonLoading}
              showDate={false}
              showOrg={isAllowedToAccess(allowedPermission, { menuName: 'pending-list', subMenuName: 'pending-list', permissionID: '49' })}
              showCity={isAllowedToAccess(allowedPermission, { menuName: 'pending-list', subMenuName: 'pending-list', permissionID: '50' })}
              handleSearch={this._handleSearch}
            />
            { // PERMISSION ID OF "pending-list.pending-list.filter.organization" is 51
            isAllowedToAccess(allowedPermission, { menuName: 'pending-list', subMenuName: 'pending-list', permissionID: '52' })?<Table
              style={{ width: "100%" }}
              size={"small"}
              columns={columns}
              dataSource={pendingList}
              bordered={true}
              loading={{
                indicator: <LoadingOutlined style={{ fontSize: 24 }} spin />,
                spinning: isLoading,
              }}
              onChange={(page) => {
                const { current, pageSize } = page

                this.setState({
                  currentPage: current,
                  pageSize: pageSize
                })
              }}
              pendingListPegination={{
                pageSize: pendingListPegination?.per_page,
                total: pendingListPegination?.total,
                pageSizeOptions: [10],
                showSizeChanger: false,
                showTotal: (total, range) =>
                  `${range[0]}-${range[1]} of ${total} files`,
                onChange: (page) => {
                  console.log(page, 'page')
                  this._handlePagination(
                    page,
                    true,
                    pendingListPegination?.path
                  );
                },
                current: pendingListPegination?.current_page,
                size: "small",
              }}
            />:<Result
            style={{ maxWidth: '700px', margin: 'auto' }}
              status="403"
              title="403"
              subTitle="Sorry, you are not authorized to access Pending List."
            />}
          </Space>
        </Card>
      </div>
    );
  }
}

// PropTypes
PendingList.propTypes = {
  reports: PropTypes.array,
  isLoading: PropTypes.bool,
  pendingListPegination: PropTypes.object,
  dispatch: PropTypes.func,
  isSubmitButtonLoading: PropTypes.bool,
  isSearchButtonLoading: PropTypes.bool,
  showDownloadButton: PropTypes.bool,
  isDownloadButtonLoading: PropTypes.bool,
  isBankReportLoading: PropTypes.bool,
  isDeleteButtonLoading: PropTypes.bool,
  selectedApplication: PropTypes.object,
  isStatusUpdateModalOpen: PropTypes.bool,
};

PendingList.defaultProps = {
  // reports: [],
  pendingList: [],
  isLoading: false,
  pendingListPegination: {},
  dispatch: () => null,
  isSubmitButtonLoading: false,
  isSearchButtonLoading: false,
  showDownloadButton: false,
  isDownloadButtonLoading: false,
  isBankReportLoading: false,
  isDeleteButtonLoading: false,
  selectedApplication: null,
  isStatusUpdateModalOpen: false,
  allowedPermission: null,
};

const mapStateToProps = (state) => ({
  isLoading: state.reports.isLoading,
  // reports: state.reports.reports,
  pendingList: state?.pendingList?.pendingList ?? [],
  pendingListPegination: state.pendingList.pendingListPegination,

  isSubmitButtonLoading: state.reports.isSubmitButtonLoading,
  isSearchButtonLoading: state.reports.isSearchButtonLoading,
  showDownloadButton: state.reports.showDownloadButton,
  isDownloadButtonLoading: state.reports.isDownloadButtonLoading,
  isBankReportLoading: state.reports.isBankReportLoading,
  isDeleteButtonLoading: state.reports.isDeleteButtonLoading,
  selectedApplication: state.reports.selectedApplication,
  isStatusUpdateModalOpen: state.reports.isStatusUpdateModalOpen,
  reloadModal: state.reports.reloadModal,
  allowedPermission: state?.permission?.allowedPermission ?? null,
});

const mapDispatchToProps = (dispatch) => ({ dispatch });

export default connect(mapStateToProps, mapDispatchToProps)(PendingList);
