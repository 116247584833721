import React, { PureComponent } from "react"
import PropTypes from 'prop-types'
import _isEqual from 'fast-deep-equal'
import { connect } from 'react-redux'

class RouteLegend extends PureComponent {
  state = {
    routeDistance: '',
  }

  componentDidUpdate = (prevProps) => {
    const { routeDistance } = this.props
    if (!_isEqual(routeDistance !== prevProps.routeDistance)) {
      this.setState({ routeDistance })
    }
  }

  render() {
    const { routeDistance } = this.state

    if (routeDistance) {
      return (
        <div style={containerStyles}>
          <h4 style={{ textAlign: 'center' }}>Distance: {routeDistance}</h4>
        </div>
      )
    }

    return null
  }
}

const containerStyles = {
  margin: '1rem',
  position: 'absolute',
  top: 0,
  left: 0,
  zIndex: 10,
  height: '200px',
  minWidth: '200px',
}

RouteLegend.propTypes = {
  routeDistance: PropTypes.string
}
RouteLegend.defaultProps = {
  routeDistance: 0
}

// Map State To Props
const mapStateToProps = (state) => ({
  routeDistance: state?.routeStatistics?.routeDistance ?? 0
})

export default connect(mapStateToProps, null)(RouteLegend)
