import React, { useState } from 'react';

// Import Components
import { Button, Card, Space, Tag, Select } from 'antd';

// Import Icons
import { SearchOutlined } from '@ant-design/icons';

// Import Redux
import { useDispatch, useSelector } from 'react-redux';

// Import CSS 
import '../../../App.css'

import { fetchIndividualVerifierPermission } from '../../../Services/Actions/permissionActions'

const IndividualUserPermission = ({verifiers}) => {
    const [selectedVerifierId, setSelectedVerifierId] = useState('')
    const { selectedVerifierPermission } = useSelector(state => state.permission)
    const { isLoadingForPermissionAction } = useSelector(state => state.permission)
    const dispatch = useDispatch()

    // changing selected verifier by id
    const _handleSelectedVerifier = (value) => {
        setSelectedVerifierId(value)
    }

    // fetch selected verifier's permission
    const _getSelectedVerifierPermission = () => {
        dispatch(fetchIndividualVerifierPermission(selectedVerifierId))
    }

    return(
        <Space direction={'vertical'} className='testing' style={fillWidthStyle}>
                <Select
                    style={{ width: '100%' }}                                        
                    showSearch
                    placeholder={ 'Select Verifiers' }
                    onChange={ _handleSelectedVerifier }
                    value={ selectedVerifierId ?? null }
                    filterOption={ (input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0 }
                    optionFilterProp={ 'children' }
                >
                    {
                        verifiers?.length > 0 && verifiers.map((item, index) => {
                            return (
                                <Select.Option key={ index } value={ item.id }>{ item.name }</Select.Option>
                            )
                        })
                    }
                </Select>
                <Button 
                    icon={ <SearchOutlined /> } 
                    type='primary' 
                    size={ 'small' } 
                    loading={ isLoadingForPermissionAction }
                    onClick={ _getSelectedVerifierPermission }
                >
                    Check Permission
                </Button>
            <Card style={containerStyle} >
                <p>
                    Current Permission
                </p> 
                {
                    selectedVerifierPermission?.length > 0 ?
                    selectedVerifierPermission.map(item => (
                            <Tag key={ item?.id } color={ '#969fff' } style={{ margin: '2px' }}>{item?.name.split('.').join(' ').toUpperCase() ?? 'N/A' }</Tag>
                        ))
                        :
                        'No Permission Found'
                }
            </Card>
        </Space>
    )

} 


const containerStyle = {
    overflowY: 'auto',
    height: '400px',
    maxHeight: '400px',
}

// intentional multiple minWidth
const fillWidthStyle = {
    width: '100%',
    minWidth: '-moz-available',          /* WebKit-based browsers will ignore this. */
    minWidth: '-webkit-fill-available',  /* Mozilla-based browsers will ignore this. */
    minWidth: 'fill-available',
} 

export default IndividualUserPermission