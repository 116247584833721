import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import store from '../../../Services/Store/store'

// Import Components
import { Form, Select, Input, Radio } from 'antd'
import UploadOCRDocuments from './OCR/UploadOCRDocuments'

// Import Actions and reducers
import { getOrgOptions } from '../../../Services/Actions/createFileActions'
import { setSelectedOrg, setSelectedOption, setCoApplicantNo, setPreviewImages, setOcrResults, setFormData } from '../../../Services/Reducers/createFileReducer'
import { getUrgencyOption } from '../../../Services/Actions/staticOptionAction'
import { getOrganizationStation } from '../../../Services/Actions/adminPanelActions'

const { Item } = Form
const { Option } = Select

class ApplicationTypes extends React.PureComponent {
    state = {
        stationOptions: [],
        urgencyOption: [],
        orgName: '',
        type: ''
    }

    componentDidMount = () => {
        // const fetchedStationOption = getStationOption()
        const fetchugencyOption = getUrgencyOption()

        // storing urgency Option
        this.setState({
            urgencyOption: fetchugencyOption
        })
    }

    componentDidUpdate = (prevProps, prevState) => {
        const { isResetClicked } = this.props

        if (isResetClicked !== prevProps.isResetClicked) {
            this.setState({
                isUrgent: false,
            })
        }
    }

    // Handle Selected Org
    _handleSelectOrg = (value) => {
        const { orgList, formRef, clearForm, dispatch } = this.props

        // Clear Form
        clearForm()

        this.setState({
            stationOptions: [],
        })

        // Set values for selected org
        formRef.current.setFieldsValue({
            group_uuid: value,
        })

        // Set Redux Form Data
        dispatch(setFormData({
            group_uuid: value,
        }))


        const selectedOrg = orgList.find(org => org.uuid === value)


        // Set States
        if (selectedOrg && selectedOrg.name === 'LankaBangla Finance Limited') {
            this.setState({
                orgName: 'lanka_bangla',
                type: ''
            })

        } else if (selectedOrg && selectedOrg.name === 'Jamuna Bank') {
            this.setState({
                orgName: 'jamuna',
                type: ''
            })

        } else if (selectedOrg && selectedOrg.name === 'IDLC') {
            this.setState({
                orgName: 'idlc',
                type: ''
            })

        } else if (selectedOrg && selectedOrg.name === 'City Bank Limited') {
            this.setState({
                orgName: 'city',
                type: ''
            })
        } else {
            this.setState({
                orgName: '',
                type: ''
            })
        }

        // Clear Previous States
        dispatch(setOcrResults(null))
        dispatch(setPreviewImages([]))

        // Check if selected org is null or undefined and set selected org to empty object
        if (!selectedOrg) {
            dispatch(setSelectedOrg({}))
        }

        // Set selected org
        dispatch(setSelectedOrg(selectedOrg))

        // Get Selected Org Options
        dispatch(getOrgOptions({ org_id: selectedOrg?.uuid }))
    }

    // Handle Application Type
    _handleApplicationType = (value) => {

        const { dispatch, orgOptions, formRef, selectedOrg } = this.props

        // Get Current Redux Form Data
        const formData = store.getState().createFile.formData

        // Clear Application Type and Sub Type of Form
        formRef.current.setFieldsValue({
            application: {
                sub_type: null,
                application_id: '',
            }
        })

        // Set Redux Form Data
        dispatch(setFormData({
            ...formData,
            application: {
                ...formData?.application,
                application_type: value,
                sub_type: null,
                application_id: '',
                urgent_tag: 0,
                coapplicant_no: 0
            }

        }))

        // Get Selected Application Type
        const selectedApplicationType = orgOptions.find(applicationType => applicationType.id === value)

        // Get Selected Organization Station Options
        dispatch(getOrganizationStation({
            org_id: selectedOrg?.uuid,
            application_type: selectedApplicationType?.id
        }))
            .then(result => {
                this.setState({
                    stationOptions: result,
                })
            })
        // console.log(_stationOptions, '_stationOptions')

        // Set State
        if (selectedApplicationType && selectedApplicationType.value === 'Card') {
            this.setState({
                type: 'card'
            })
        } else if (selectedApplicationType && selectedApplicationType.value === 'Loan') {
            this.setState({
                type: 'loan'
            })
        } else {
            this.setState({
                type: '',
            })
        }

        // Check if selected application type is null or undefined and set selected application type to empty object
        if (!selectedApplicationType) {
            dispatch(setSelectedOption({}))
        }

        // Set selected application type
        dispatch(setSelectedOption(selectedApplicationType))
    }

    // Handle Application Sub Type
    _handleApplicationSubType = value => {

        const { selectedOption, formRef, dispatch } = this.props
// console.log(selectedOption, 'selectedOption');
        // Get Current Redux Form Data
        const formData = store.getState().createFile.formData

        // Find selected application sub type
        const selectedApplicationSubType = selectedOption?.subTypes?.find(subType => subType.id === value)
// console.log(selectedApplicationSubType, 'selectedApplicationSubType');
        // Set Application Number Prefix
        formRef.current.setFieldsValue({
            application: {
                application_id: selectedApplicationSubType?.prefix ?? ''
            }
        })

        // Set Redux Form Data
        dispatch(setFormData({
            ...formData,
            application: {
                ...formData?.application,
                sub_type: value,
                application_id: selectedApplicationSubType?.prefix ?? ''
            }

        }))

    }

    // Handle Application Number
    _handleApplicationNumber = value => {
        const { dispatch } = this.props

        // Get Current Redux Form Data
        const formData = store.getState().createFile.formData

        // Set Redux Form Data
        dispatch(setFormData({
            ...formData,
            application: {
                ...formData?.application,
                application_id: value
            }

        }))
    }

    // handles CoApplication Number
    _handleCoApplicantNo = value => {
        const { dispatch, formRef } = this.props

        // Get Current Redux Form Data
        const formData = store.getState().createFile.formData

        // Set CoApplication Number
        formRef.current.setFieldsValue({
            application: {
                coapplicant_no: value ?? 1
            }
        })

        // Set Redux Form Data
        dispatch(setFormData({
            ...formData,
            application: {
                ...formData?.application,
                coapplicant_no: value ?? 1
            }

        }))

        //  sets co-applicant's number and genrates form based on that
        dispatch(setCoApplicantNo(value));

    }

    // Handle Application Urgency
    _handleApplicationUrgency = value => {
        const { dispatch } = this.props

        // Get Current Redux Form Data
        const formData = store.getState().createFile.formData

        // Set Redux Form Data
        dispatch(setFormData({
            ...formData,
            application: {
                ...formData?.application,
                urgent_tag: value
            }

        }))
    }

    // Handle Application Station
    _handleApplicationStation = value => {
        const { dispatch } = this.props

        // Get Current Redux Form Data
        const formData = store.getState().createFile.formData

        // Set Redux Form Data
        dispatch(setFormData({
            ...formData,
            application: {
                ...formData?.application,
                station: value
            }

        }))
    }

    // Handle LankaBangla Branch
    _handleLankaBanglaBranch = value => {
        const { dispatch } = this.props

        // Get Current Redux Form Data
        const formData = store.getState().createFile.formData

        // Set Redux Form Data
        dispatch(setFormData({
            ...formData,
            application: {
                ...formData?.application,
                branch_name: value
            }

        }))
    }

    // Handle Client ID
    _handleClientID = value => {
        const { dispatch } = this.props

        // Get Current Redux Form Data
        const formData = store.getState().createFile.formData

        // Set Redux Form Data
        dispatch(setFormData({
            ...formData,
            application: {
                ...formData?.application,
                client_id: value
            }

        }))
    }

    // Handle Application Tag
    _handleApplicationTag = value => {
        const { dispatch } = this.props

        // Get Current Redux Form Data
        const formData = store.getState().createFile.formData

        // Set Redux Form Data
        dispatch(setFormData({
            ...formData,
            application: {
                ...formData?.application,
                tag: +value
            }

        }))
    }

    // Get Org List
    _getOrgList = (user) => {
        const { orgList } = this.props

        if (user) {
            return <Option key={'1'} value={user?.org_id}>{user?.org_name}</Option>
        }
        const withoutAllOrg = orgList?.length > 0 ? orgList.filter(org => org.id !== -1) : []
        let orgOptions = []

        withoutAllOrg?.length > 0 && withoutAllOrg.forEach((item, index) => {
            orgOptions.push(<Option key={index + 1} value={item.uuid}>{item.name}</Option>)
        })
        return orgOptions
    }


    render() {
        const { selectedOrg, orgOptions, orgBranch, selectedOption, applicationTags } = this.props
        const { stationOptions, urgencyOption, orgName, type } = this.state

        return (
            <div>
                <Item
                    label={'Select Organization'}
                    name={'group_uuid'}
                    rules={[
                        {
                            required: true,
                            message: 'Please, select an organization.'
                        }
                    ]}
                >
                    <Select
                        style={{ width: '100%' }}
                        showSearch
                        placeholder={'Select Organization'}
                        onChange={this._handleSelectOrg}
                        value={selectedOrg?.uuid}
                        filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                        optionFilterProp={'children'}
                    >
                        {
                            // this._getOrgList(defaultUserOrg)
                            this._getOrgList()
                        }
                    </Select>
                </Item>
                {selectedOrg?.name === 'LankaBangla Finance Limited' && (
                    <Item
                        label={'Branch Name'}
                        name={['application', 'branch_name']}
                        rules={[
                            {
                                required: false,
                                message: 'Please, select branch.'
                            }
                        ]}
                    >
                        <Select
                            style={{ width: '100%' }}
                            showSearch
                            placeholder={'Select Branch'}
                            filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                            optionFilterProp={'children'}
                            onChange={(value) => this._handleLankaBanglaBranch(value)}
                        >
                            {
                                orgBranch?.[0]?.branch.map((item) => {
                                    return (
                                        <Option key={item?.id} value={item.value}>{item.label}</Option>
                                    )
                                })
                            }
                        </Select>
                    </Item>
                )}
                <Item
                    label={'Application Type'}
                    name={['application', 'application_type']}
                    rules={[
                        {
                            required: true,
                            message: 'Please, select an application type.'
                        }
                    ]}
                >
                    <Select
                        style={{ width: '100%' }}
                        showSearch
                        placeholder={'Select Application Type'}
                        onChange={this._handleApplicationType}
                        filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                        optionFilterProp={'children'}
                    >
                        {
                            orgOptions?.length > 0 && orgOptions.map((item, index) => {
                                return (
                                    <Option key={index + 1} value={item.id}>{item.label}</Option>
                                )
                            })
                        }
                    </Select>
                </Item>

                <Item
                    label={'Application Sub Type'}
                    name={['application', 'sub_type']}
                    rules={[
                        {
                            required: true,
                            message: 'Please, select an application sub type.'
                        }
                    ]}
                >
                    <Select
                        style={{ width: '100%' }}
                        showSearch
                        placeholder={'Select Application Sub Type'}
                        onChange={this._handleApplicationSubType}
                        filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                        optionFilterProp={'children'}
                    >
                        {
                            selectedOption?.subTypes && selectedOption?.subTypes?.length > 0 && selectedOption?.subTypes?.map((item, index) => {
                                return (
                                    <Option key={index + 1} value={item.id}>{item.label}</Option>
                                )
                            })
                        }
                    </Select>
                </Item>

                <Item
                    label={'Application Number'}
                    name={['application', 'application_id']}
                    rules={[
                        {
                            required: true,
                            message: 'Please, enter an application number without any space.'
                        },
                        {
                            required: true,
                            // type: "regexp",
                            pattern: new RegExp(/^\S+$/g),
                            message: "Wrong Format! Space cannot be used"
                        }
                    ]}
                >
                    <Input onChange={(e) => this._handleApplicationNumber(e.target.value)} placeholder={'Enter Application Number'} />
                </Item>

                {
                    selectedOrg?.id === 13 && selectedOrg?.name === 'Jamuna Bank' &&
                    <Form.Item
                        label={'Client ID'}
                        name={['application', 'client_id']}
                        rules={[
                            {
                                required: true,
                                message: 'Please, select Client ID.'
                            }
                        ]}
                    >
                        <Input
                            style={{ textAlign: 'left' }}
                            placeholder='Client ID'
                            onChange={(e) => this._handleClientID(e.target.value)}
                        />
                    </Form.Item>

                }

                <Item
                    label={'Application Tag'}
                    name={['application', 'tag']}
                    rules={[
                        {
                            required: true,
                            message: 'Please, select an application tag.'
                        }
                    ]}
                >
                    <Select
                        style={{ width: '100%' }}
                        showSearch
                        placeholder={'Select Application Tag'}
                        filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                        optionFilterProp={'children'}
                        onChange={(value) => this._handleApplicationTag(value)}
                    >
                        {
                            applicationTags?.length > 0 && applicationTags?.map((item, index) => {
                                return (
                                    <Option key={index + 1} value={item.id}>{item.label}</Option>
                                )
                            })
                        }
                    </Select>
                </Item>

                {
                    //  only if the application type is loan
                    //  co-applicant option is available
                    //  selectedOption.id = 1 is aplication type loan's id
                    selectedOption?.id === 1
                    &&
                    <Item
                        label={'Co-Applicant Number'}
                        name={['application', 'coapplicant_no']}
                        rules={[
                            {
                                required: true,
                                message: 'Please, select total co-applicants.'
                            }
                        ]}
                    >
                        <Select
                            style={{ width: '100%' }}
                            showSearch
                            placeholder={'Select Co-Applicant No'}
                            optionFilterProp={'children'}
                            onChange={this._handleCoApplicantNo}
                        >
                            <Option value={0}>0</Option>
                            <Option value={1}>1</Option>
                            <Option value={2}>2</Option>
                            <Option value={3}>3</Option>
                            <Option value={4}>4</Option>
                            <Option value={5}>5</Option>
                        </Select>
                    </Item>
                }

                <Form.Item
                    label={'Application Urgency'}
                    name={['application', 'urgent_tag']}
                    initialValue={0}
                    onChange={(e) => this._handleApplicationUrgency(e.target.value)}
                >
                    <Radio.Group
                        options={urgencyOption}
                        buttonStyle={'solid'}
                    />
                </Form.Item>

                {stationOptions.length > 0 && (
                    <Form.Item
                        label={'Application Station'}
                        name={['application', 'station']}
                        initialValue={1}
                        rules={[
                            {
                                required: true,
                                message: 'Please, select a station.'
                            }
                        ]}
                        onChange={(e) => this._handleApplicationStation(e.target.value)}
                    >
                        <Radio.Group
                            options={stationOptions}
                            optionType={'button'}
                            buttonStyle={'solid'}
                        />
                    </Form.Item>
                )}

                {/* Upload OCR Images */}
                <>
                    {(
                        (orgName === 'lanka_bangla' && type === 'card') ||
                        (orgName === 'jamuna' && type === 'card') ||
                        (orgName === 'city' && type === 'loan') ||
                        (orgName === 'city' && type === 'card') ||
                        (orgName === 'idlc' && type === 'loan')) &&
                        <UploadOCRDocuments orgName={orgName} type={type} />
                    }
                </>
            </div>
        )
    }
}

// PropTypes
ApplicationTypes.propTypes = {
    orgList: PropTypes.array,
    selectedOrg: PropTypes.object,
    orgOptions: PropTypes.array,
    selectedOption: PropTypes.object,
    applicationTags: PropTypes.array,
    isResetClicked: PropTypes.bool,
    dispatch: PropTypes.func,
    defaultUserOrg: PropTypes.object,
    formData: PropTypes.object
}

ApplicationTypes.defaultProps = {
    orgList: [],
    orgBranch: [],
    selectedOrg: null, // object
    orgOptions: [],
    selectedOption: null,  // object
    applicationTags: [],
    isResetClicked: false,
    dispatch: () => null,
    defaultUserOrg: null,
    formData: {}
}

const mapStateToProps = state => ({
    orgList: state?.common?.orgList ?? [],
    orgBranch: state?.createFile?.orgBranch,
    selectedOrg: state?.createFile?.selectedOrg ?? null,
    orgOptions: state?.createFile?.orgOptions ?? [],
    selectedOption: state?.createFile?.selectedOption ?? null,
    applicationTags: state?.createFile?.applicationTags ?? [],
    isResetClicked: state?.createFile?.isResetClicked ?? false,
    coApplicantNo: state?.createFile?.coApplicantNo ?? 0,
    formDatas: state?.createFile?.formData ?? {}
})

const mapDispatchToProps = dispatch => ({ dispatch })

export default connect(mapStateToProps, mapDispatchToProps)(ApplicationTypes)