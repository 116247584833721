import React from 'react'

// Import Components
import { Row, Col } from 'antd'
import MetaConcernDetails from './MetaConcernDetails'

const MetaDataDetails = ({ applicationDetails }) => {
    return(
        <Row gutter={[ 12, 12 ]}>
            <Col span={ 10 } xs={ 24 } md={ 24 }>
                <MetaConcernDetails 
                    isPostActionRestricted={ true }
                />
            </Col>
        </Row>
    )
}

export default MetaDataDetails