import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import _isEqual from 'fast-deep-equal'

// Import Components
import { Modal, Card, Space, Segmented, Popover, Typography, Empty, Button, Tooltip, Tag, Select, Divider } from 'antd'
import DescriptionItem from '../../Common/DescriptionItem'
import UpdateTrip from '../../Layouts/UpdateTrip'
import { MarkAsReCPV } from '../../Layouts/ReCPV'

// Import Icons
import { ExclamationCircleOutlined } from '@ant-design/icons';

// Import Actions, and Reducers
import { foreceTripFinish, cancelTrip, getTripTypeStringAndFilledIcon, getFillColorTripStatus, getTripStatusLabelAndColor, formatDateIntoFullDateAndTime, showAlert, assignVerifier, openTripUpdateModal, getDateTimeDuration } from '../../../Services/Actions/commonActions'
import { getApplicationDetails } from '../../../Services/Actions/applicationActions'
import { isAllowedToAccess } from '../../../Services/Actions/permissionActions'
import { setSelectedTrip, setPrevSegmentTripID } from '../../../Services/Reducers/applicationReducer'
import { setIsLoading } from '../../../Services/Reducers/commonReducer'
import { setSelectedMultipleTripToUpdate } from '../../../Services/Reducers/commonReducer'

class TripDetails extends React.PureComponent {
    state = {
        selectedVerifier: {},
        openUpdateModal: false,
        openReCPVModal: false
    }

    componentDidMount = () => {
        const { selectedTrip, verifiers } = this.props

        // Check if selected trip is not null
        if (selectedTrip?.assigned_to) {
            const assigned_to = Number(selectedTrip.assigned_to)
            // Check if verifier is not null and selected verifier is not null
            if (verifiers && verifiers?.length > 0) {
                // Get selected verifier
                const selectedVerifier = verifiers.find(verifier => verifier.id === assigned_to)
                this.setState({ selectedVerifier })
            }
        }
        this._openAllTripMap()
    }

    componentDidUpdate = (prevProps) => {
        const { selectedTrip, verifiers, prevSegmentTripID, prevSegmentConcernID, selectedConcern, applicationDetails, dispatch } = this.props
        // setting previously selected trip for trip segment tab after update address  
        if (prevSegmentTripID && selectedTrip?.id !== prevSegmentTripID && _isEqual(prevProps.selectedConcern, selectedConcern)) {
            let currentConcern = selectedConcern

            // after trip update page refresshes. therfore matching the  prevSegmentConcernID if exist to set the previous concern segment tab selected again 
            if (prevSegmentConcernID && prevSegmentConcernID !== selectedConcern?.id) {
                currentConcern = applicationDetails.concerns.find(concern => concern.id === prevSegmentConcernID)
            }
            // Find Selected Trip from current Concern - if trip updates, there will be a prevSegmentTripID, which is matched to make it selected again as after update page refreshes
            const selectedMatchedTrip = currentConcern?.trips?.length > 0 && currentConcern.trips.find(trip => trip.id === prevSegmentTripID)

            // setting application id inside trip details as it is alwasy necessary while setting trip
            const selectedTripWithApplicationID = { ...selectedMatchedTrip, application_id: selectedConcern?.application_id }
            // Set Selected Trip
            dispatch(setSelectedTrip(selectedTripWithApplicationID ?? {})
            )
        }

        // Check if previous selected trip and current selected trip are not same
        if (prevProps.selectedTrip?.id !== selectedTrip?.id) {

            // Check if selected trip is not null
            if (selectedTrip?.assigned_to) {
                const assigned_to = Number(selectedTrip.assigned_to)
                // Check if verifier is not null and selected verifier is not null
                if (verifiers && verifiers?.length > 0) {
                    // Get selected verifier
                    const selectedVerifier = verifiers.find(verifier => verifier.id === assigned_to)
                    this.setState({ selectedVerifier })
                }
            }

        }

    }

    // iactivating an activated trip
    _cancelTrip = (tripID) => {
        const { dispatch, applicationDetails } = this.props
        dispatch(setIsLoading(true))

        cancelTrip(tripID)
            .then(res => {
                if (res) {
                    dispatch(getApplicationDetails(applicationDetails.id))
                    showAlert('success', 'Trip Inactivated Successfully')
                }
                else {
                    showAlert('error', 'Cannot Inactivate Trip')
                }
            })
            .catch(err => {
                console.error(err)
                dispatch(setIsLoading(false))
                showAlert('error', err?.message ?? 'Trip Inactivation Failed')
            })

    }

    // Forcefully finishing a trip
    _forceFinish = (tripID) => {
        const { dispatch, applicationDetails } = this.props
        dispatch(setIsLoading(true))

        foreceTripFinish(tripID)
            .then(res => {
                if (res) {
                    dispatch(getApplicationDetails(applicationDetails.id))
                    showAlert('success', 'Trip Forcefully Finished Successfully')
                }
                else {
                    showAlert('error', 'Cannot Finish The Trip Forcefully')
                }
            })
            .catch(err => {
                console.error(err)
                dispatch(setIsLoading(false))
                showAlert('error', err?.message ?? 'Trip Force Finish Failed')
            })

    }

    // Hanlde Trip Change
    _handleTripChange = (value) => {
        const { dispatch, selectedConcern } = this.props

        // Find Selected Trip from Selected Concern
        const selectedTrip = selectedConcern?.trips?.length > 0 && selectedConcern.trips.find(trip => trip.id === value)

        const selectedTripWithApplicationID = { ...selectedTrip, application_id: selectedConcern?.application_id }


        // Set Selected Trip and currently selected rip id for next cycle reference to match it and by default making it selected in trip details segment tab 
        dispatch(dispatch => {
            dispatch(setPrevSegmentTripID(value))
            dispatch(setSelectedTrip(selectedTripWithApplicationID ?? {}))
        }
        )
        this._openAllTripMap()
    }

    // Handle Verifier Change
    _handleVerifierChange = (value) => {
        const { verifiers } = this.props
        if (verifiers?.length > 0) {
            const selectedVerifier = verifiers.find(verifier => verifier.id === value)
            this.setState({ selectedVerifier: selectedVerifier ?? {} })
        }
    }

    // Handle Popover Visibility Change
    _handlePopoverVisibilityChange = (visible) => {
        // Check if popover is not visible then set selected verifier as empty object
        if (!visible) {
            this.setState({ selectedVerifier: {} })
        } else {
            const { selectedTrip, verifiers } = this.props
            // Check if selected trip is not null
            if (selectedTrip?.assigned_to) {
                const assigned_to = Number(selectedTrip.assigned_to)
                // Check if verifier is not null and selected verifier is not null
                if (verifiers && verifiers?.length > 0) {
                    // Get selected verifier
                    const selectedVerifier = verifiers.find(verifier => verifier.id === assigned_to)
                    this.setState({ selectedVerifier })
                }
            }
        }
    }

    // Handle Assign Verifier
    _handleAssignVerifier = () => {
        const { selectedTrip, applicationDetails, dispatch } = this.props
        const { selectedVerifier } = this.state
        // Check if selected verifier is not valid
        if (!selectedVerifier?.id) {
            // Show Alert
            showAlert('error', 'Please select a verifier')
            return
        }

        // Check if selected trip is not valid
        if (!selectedTrip?.id) {
            // Show Alert
            showAlert('error', 'Selected trip is not valid')
            return
        }

        // Check if selected trip has assigned_to
        if (selectedTrip?.assigned_to) {
            const assigned_to = Number(selectedTrip.assigned_to)

            // Check if selected verifier id and selected trip id are same
            if (selectedVerifier?.id === assigned_to) {
                // Show Alert
                showAlert('error', 'Selected verifier is already assigned to this trip')
                return
            }
        }
        // Assign Verifier
        dispatch(assignVerifier({ task_id: selectedTrip.id, tasker_id: selectedVerifier.id }, 'application', applicationDetails))
    }

    //extract all trips including the selected trip
    _extractAllTripsFromApplication(selectedTrip, application) {
        let allTrips = []
        application?.concerns?.forEach(item => {
            allTrips = [...allTrips, ...item.trips.map(trip => { return { ...trip, application_id: application?.id } })]
        })
        return allTrips
    }

    // Handle Update Trip for Map
    _handleUpdateTrip = () => {
        const { dispatch, selectedTrip } = this.props

        // dispatching to show only selected trip marker and verifier in map
        dispatch(openTripUpdateModal(selectedTrip))
        this.setState({
            openUpdateModal: true
        })
    }

    // Handle Update Trip for Map
    _handleRecpv = () => {
        this.setState({
            openRecpvModal: true
        })
    }

    // Open All Trip with end task in map Map
    _openAllTripMap = () => {
        const { dispatch, selectedTrip, applicationDetails } = this.props

        // otherTrip => all trips including selected trip 
        const allTripOfApplication = this._extractAllTripsFromApplication(selectedTrip, applicationDetails)

        // dispatching to show only selected trip marker and verifier in map
        dispatch(openTripUpdateModal(selectedTrip, allTripOfApplication))
    }

    // Confirm modal before inactivate trip
    _showConfirm = () => {
        const { selectedTrip } = this.props
        const { confirm } = Modal;
        // const callCaneclFunc = () => { this._inactivateTrip(selectedTrip.id) }
        const callCaneclFunc = () => { this._cancelTrip(selectedTrip.id) }
        confirm({
            title: 'Do you Want to inactivate this trip?',
            icon: <ExclamationCircleOutlined />,
            content: 'If you click ok, this trip will be inactivated and you will not be able to activate it again',

            onOk() {
                callCaneclFunc()
            },
        });
    };

    // Confirm modal before forcing trip to finish
    _showForceEndConfirm = () => {
        const { selectedTrip } = this.props
        const { confirm } = Modal;

        // const callCaneclFunc = () => { this._inactivateTrip(selectedTrip.id) }
        const callForceFinish = () => { this._forceFinish(selectedTrip.id) }
        confirm({
            title: 'Do you want to finish this trip forcefully?',
            icon: <ExclamationCircleOutlined />,
            content: 'If you click ok, trip status will be changed to finish',

            onOk() {
                callForceFinish()
            },
        });
    };

    componentWillUnmount() {
        const { dispatch } = this.props
        dispatch(setSelectedMultipleTripToUpdate([]))
    }

    render() {
        const { selectedVerifier, openUpdateModal, openRecpvModal } = this.state
        const { selectedConcern, selectedTrip, verifiers, isAssignLoading, allowedPermission } = this.props
        const currentStatus = getTripStatusLabelAndColor(selectedTrip?.status)

        return (
            <Card title={'Trip Details'} hoverable style={{ height: '100%' }}>
                {
                    selectedTrip?.id ?
                        <Space direction={'vertical'} style={{ width: '100%', height: '100%' }}>
                            <Segmented
                                options={
                                    selectedConcern?.trips?.length > 0 && selectedConcern?.trips?.map(trip => ({
                                        value: trip.id,
                                        label: trip.id + ' - ' + getTripTypeStringAndFilledIcon(trip.type)?.label,
                                        icon:
                                            <Tooltip title={getTripTypeStringAndFilledIcon(trip.type)?.label}>
                                                {getTripTypeStringAndFilledIcon(trip.type, getFillColorTripStatus(trip?.status))?.icon}
                                            </Tooltip>
                                    }))
                                }
                                value={selectedTrip?.id}
                                onChange={this._handleTripChange}
                                // block={selectedConcern?.trips?.length > 4}
                                block={true}
                                key={selectedConcern?.trips?.length}
                            />
                            <Card>
                                <DescriptionItem
                                    title={'Trip ID'}
                                    content={
                                        <Typography.Text copyable>
                                            {selectedTrip?.id}
                                        </Typography.Text>
                                    }
                                />
                                <DescriptionItem title={'Trip Type'} content={getTripTypeStringAndFilledIcon(selectedTrip?.type)?.label} />
                                <DescriptionItem
                                    title={'Trip Status'}
                                    content={
                                        <Tag color={currentStatus?.color}>
                                            {currentStatus?.label}
                                        </Tag>
                                    }
                                />
                                {
                                    selectedTrip?.status === 5 &&
                                    <DescriptionItem
                                        title={'Approx Completion Time'}
                                        content={
                                            <Tag color={currentStatus?.color}>
                                                {getDateTimeDuration(selectedTrip?.tasker_got_out, selectedTrip?.tasker_got_in)}
                                            </Tag>
                                        }
                                    />
                                }
                                {

                                    // PERMISSION ID OF "reports.reports.trip.assign" is 26
                                    isAllowedToAccess(allowedPermission, { menuName: 'reports', subMenuName: 'reports', permissionID: '26' }) &&
                                    <DescriptionItem
                                        title={'Verifier'}
                                        content={
                                            <Space>
                                                <span style={{ color: !selectedTrip?.agent && 'red', fontWeight: !selectedTrip?.agent && 500 }}>
                                                    {selectedTrip?.agent?.name ?? 'Verifier not assigned'}
                                                </span>
                                                <Popover
                                                    trigger={['click']}
                                                    placement={'bottom'}
                                                    title={selectedTrip.assigned_to ? 'Reassign Verifier' : 'Assign Verifier'}
                                                    onVisibleChange={this._handlePopoverVisibilityChange}
                                                    content={
                                                        <div style={{ width: '224px' }}>
                                                            <Select
                                                                style={{ width: '100%' }}
                                                                showSearch
                                                                allowClear
                                                                placeholder={'Select a verifier'}
                                                                onChange={this._handleVerifierChange}
                                                                value={selectedVerifier?.id ?? ''}
                                                                filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                                                optionFilterProp={'children'}
                                                            >
                                                                {
                                                                    verifiers?.length > 0 && verifiers.map((item, index) => {
                                                                        return (
                                                                            <Select.Option key={index + 1} value={item.id}>{item.name}</Select.Option>
                                                                        )
                                                                    })
                                                                }
                                                            </Select>
                                                            <div style={{ display: 'flex', justifyContent: 'flex-end', width: '100%', marginTop: '0.4rem' }}>
                                                                <Button
                                                                    type={'primary'}
                                                                    onClick={this._handleAssignVerifier}
                                                                    loading={isAssignLoading}
                                                                >
                                                                    {selectedTrip.assigned_to ? 'Reassign' : 'Assign'}
                                                                </Button>
                                                            </div>
                                                        </div>
                                                    }
                                                >
                                                    <Button
                                                        size={'small'}
                                                        type={'link'}
                                                        disabled={(selectedTrip?.status === 6) || (selectedTrip?.status === 7)}
                                                    >
                                                        {selectedTrip.assigned_to ? 'Reassign' : 'Assign'}
                                                    </Button>
                                                </Popover>
                                            </Space>
                                        }
                                    />
                                }
                                {
                                    <DescriptionItem
                                        title={'Assigned By'}
                                        content={
                                            <Typography.Text copyable>
                                                {selectedTrip?.assigned_by ?? '-'}
                                            </Typography.Text>
                                        }
                                    />
                                }


                                <DescriptionItem
                                    title={'Given Address'}
                                    content={
                                        [
                                            <Typography.Text
                                                key={1}
                                                copyable
                                                type={selectedTrip?.is_address_exact === 0 && 'danger'}
                                                strong={selectedTrip?.is_address_exact === 0}
                                            >
                                                {selectedTrip?.address ?? '-'}
                                            </Typography.Text>,
                                            <Divider key={2} type={'vertical'} />,
                                            <Typography.Text key={3}>
                                                {selectedTrip?.is_address_exact === 0 ? '(Not Exact)' : '(Exact)'}
                                            </Typography.Text>
                                        ]

                                    }
                                />
                                <DescriptionItem
                                    title={'Exact Address'}
                                    content={
                                        <Typography.Text copyable >
                                            {selectedTrip?.exact_address ?? '-'}
                                        </Typography.Text>
                                    }
                                />
                                <DescriptionItem
                                    title={'Created Time'}
                                    content={selectedTrip?.created_at ? formatDateIntoFullDateAndTime(selectedTrip?.created_at) : '-'}
                                />
                                <DescriptionItem
                                    title={'Start Time'}
                                    content={selectedTrip?.start_time ? formatDateIntoFullDateAndTime(selectedTrip?.start_time) : '-'}
                                />
                                <DescriptionItem
                                    title={'End Time'}
                                    content={selectedTrip?.end_time ? formatDateIntoFullDateAndTime(selectedTrip?.end_time) : '-'}
                                />
                                <DescriptionItem
                                    title={'Remarks'}
                                    content={selectedTrip?.remarks ?? '-'}
                                />
                                <DescriptionItem
                                    title={'Comment'}
                                    content={
                                        <Typography.Text>
                                            {selectedTrip?.comment ?? '-'}
                                        </Typography.Text>
                                    }
                                />
                                <DescriptionItem
                                    title={(selectedTrip?.type === 'OFFICE' || selectedTrip?.type === 'GUARANTOR_OFFICE') ? 'Office Name' : 'Place Name'}
                                    content={
                                        <Typography.Text>
                                            {selectedTrip?.place_name ?? '-'}
                                        </Typography.Text>
                                    }
                                />
                                {
                                    <Space>
                                        {
                                            // PERMISSION ID OF "reports.reports.trip.update" is 23
                                            isAllowedToAccess(allowedPermission, { menuName: 'reports', subMenuName: 'reports', permissionID: '23' }) &&
                                            <Button onClick={this._handleUpdateTrip} type={'primary'}>Address Update</Button>

                                        }
                                        {
                                            // PERMISSION ID OF "reports.reports.trip.mark_as_re_cpv" is 25
                                            isAllowedToAccess(allowedPermission, { menuName: 'reports', subMenuName: 'reports', permissionID: '25' }) &&
                                            selectedTrip?.status === 5 &&
                                            <Button
                                                type='primary'
                                                style={{ backgroundColor: '#e3bb61', borderColor: '#e3bb61' }}
                                                onClick={this._handleRecpv}
                                            >
                                                ReCPV
                                            </Button>
                                        }
                                        {
                                            // PERMISSION ID OF "reports.reports.trip.delete" is 24
                                            isAllowedToAccess(allowedPermission, { menuName: 'reports', subMenuName: 'reports', permissionID: '24' }) &&
                                            currentStatus?.label !== 'Cancelled' &&
                                            <Button onClick={() => this._showConfirm()} type={'primary'} danger>Cancel</Button>
                                        }
                                        {
                                            // PERMISSION ID OF "reports.reports.trip.update" is 23
                                            isAllowedToAccess(allowedPermission, { menuName: 'reports', subMenuName: 'reports', permissionID: '23' }) &&
                                            currentStatus?.label !== 'Finished' && currentStatus?.label !== 'Cancelled' &&
                                            <Button onClick={() => this._showForceEndConfirm()} danger>Force Finish</Button>
                                        }
                                    </Space>

                                }

                            </Card>
                        </Space>
                        : <Empty description={'Trips unavailable'} image={Empty.PRESENTED_IMAGE_SIMPLE} />
                }

                <Modal
                    key={openUpdateModal}
                    title={'Update Trip'}
                    visible={openUpdateModal} // for antd < 4.23.0v this is used instead of open
                    cancelText={'Cancel'}
                    onCancel={() => this.setState({ openUpdateModal: false })}
                    maskClosable={false}
                    // okButtonProps={{ loading: isUpdateTripButtonLoading }}
                    width={'85%'}
                    okButtonProps={{ style: { display: 'none' } }}
                >
                    <UpdateTrip mapContainerHeight={'400px'} />
                </Modal>

                <Modal
                    title={<Tag> ReCPV Trip - {selectedTrip?.id} </Tag>}
                    visible={openRecpvModal}
                    cancelText={'Cancel'}
                    onCancel={() => this.setState({ openRecpvModal: false })}
                    maskClosable={false}
                    // okButtonProps={{ loading: isUpdateTripButtonLoading }}
                    width={'50%'}
                    minWidth={'320px'}
                    okButtonProps={{ style: { display: 'none' } }}
                >
                    <MarkAsReCPV selectedTrip={selectedTrip} />
                </Modal>
            </Card>
        )
    }
}


// Props Validation
TripDetails.propTypes = {
    dispatch: PropTypes.func,
    selectedConcern: PropTypes.object,
    selectedTrip: PropTypes.object,
    verifiers: PropTypes.array,
    isUpdateTripModalOpen: PropTypes.bool,
    isAssignLoading: PropTypes.bool,
    prevSegmentTripID: PropTypes.number,
    prevSegmentConcernID: PropTypes.number,
    applicationDetails: PropTypes.object,
    selectedTripToUpdate: PropTypes.object,
    allowedPermission: PropTypes.object
}

TripDetails.defaultProps = {
    dispatch: () => null,
    selectedConcern: {},
    selectedTrip: {},
    verifiers: [],
    isUpdateTripModalOpen: false,
    isAssignLoading: false,
    prevSegmentTripID: 0,
    prevSegmentConcernID: 0,
    applicationDetails: null,
    selectedTripToUpdate: {},
    allowedPermission: null,
}

// Map State To Props
const mapStateToProps = (state) => ({
    applicationDetails: state.application.applicationDetails ?? null,
    prevSegmentTripID: state.application.prevSegmentTripID ?? 0,
    prevSegmentConcernID: state.application.prevSegmentConcernID ?? 0,
    selectedConcern: state.application.selectedConcern ?? null,
    selectedTrip: state.application.selectedTrip ?? null,
    verifiers: state.common.verifiers ?? [],
    isAssignLoading: state.common.isAssignLoading ?? false,
    isUpdateTripModalOpen: state?.common?.isUpdateTripModalOpen ?? false,
    selectedTripToUpdate: state?.common?.selectedTripToUpdate ?? {},
    allowedPermission: state?.permission?.allowedPermission ?? null
})

// Map Dispatch To Props
const mapDispatchToProps = (dispatch) => ({ dispatch })

export default connect(mapStateToProps, mapDispatchToProps)(TripDetails)