import React from 'react';

// Import Components
import { Card, Col, Row } from 'antd';
import AllDocuments from '../Components/Layouts/Documents/AllDocuments';

const Documents = () => {
  return (
    <div style={container}>
      <Row gutter={[12, 8]} style={rowContainer}>
        <Col span={24} xs={24} style={colContainer}>
          <Card title={'Documents'} bodyStyle={{ paddingLeft: '0', paddingRight: '0', }}>
            <AllDocuments />
          </Card>
        </Col>
      </Row>
    </div>
  );
};

// Styles
const container = {
  borderRadius: '4px',
}

const rowContainer = {
  height: '100%',
}

const colContainer = {
  background: 'transparent',
  width: '100%',
}

export default Documents;