import { UploadOutlined } from "@ant-design/icons";
import { Button, Modal, Upload } from "antd";
import React, { useState } from "react";

const LoiUpload = ({ setFile, fileList , buttonVisible}) => {
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [previewTitle, setPreviewTitle] = useState("");
  // const [fileList, setFileList] = useState([]);

  // console.log(fileList, "fileList");
  // Get Base64
  const _getBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  const _handlePreview = async (file) => {
    // console.log(file);
    if (!file.url && !file.preview) {
      file.preview = await _getBase64(file.originFileObj);
    }
    setPreviewImage(file.url || file.preview);
    setPreviewOpen(true);
    setPreviewTitle(file.name || file.url.substring(file.url.lastIndexOf('/') + 1));
  };
  return (
    <div>
      <Upload
        listType="picture"
        // maxCount={1}
        // ref={ref}
        multiple
        fileList={fileList}
        onChange={({ fileList: newFileList }) => setFile(newFileList)}
        accept="image/jpeg, image/png, image/jpg"
        beforeUpload={() => {
          return false;
        }}
        onPreview={(e) => _handlePreview(e)}
      >
        {buttonVisible && <Button icon={<UploadOutlined />}>Upload</Button>}
      </Upload>

      {/* Modal */}
      <Modal visible={previewOpen} title={previewTitle} footer={null} onCancel={() => setPreviewOpen(false)}>
        <img
          alt="example"
          style={{
            width: '100%',
          }}
          src={previewImage}
        />
      </Modal>
    </div>
  );
};

export default LoiUpload;
