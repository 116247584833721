import React from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

// Import Components
import { Result, Button } from 'antd'
import Spinner from '../Components/Common/Spinner'
import { ApplicationDetails } from '../Components/Layouts/Application'

// Import Actions and reducers
import { getApplicationDetails } from '../Services/Actions/applicationActions'
import { setPrevSegmentTripID, setPrevSegmentConcernID } from  '../Services/Reducers/applicationReducer'


const Application = () => {
    const { id } = useParams()
    const dispatch = useDispatch()
    const applications = useSelector(state => state.application)
    const navigate = useNavigate()    
    
    React.useEffect(() => {
        dispatch(getApplicationDetails(id))

        return () => {
            dispatch(dispatch => (
                dispatch(setPrevSegmentConcernID(0)),
                dispatch(setPrevSegmentTripID(0))
            ))
        }
    }, [dispatch, id])

    // Check if applicationDetailsLoading is true
    if (applications.applicationDetailsLoading) {
        return (
            <div style={ containerStyles }>
                <Spinner />
            </div>            
        )
    }

    // Check if id is not valid or applicationDetails is empty    
    if (!id || !applications.applicationDetails) {
        return (
            <div style={ containerStyles }>
                <Result                    
                    status={ '404' }                
                    subTitle={ 'Sorry, this application does not exist.' }
                    extra={
                        <Button 
                            type={ 'primary' }
                            onClick={ () => navigate('/') }
                        >
                            { 'Back Home' }
                        </Button>
                    }
                />
            </div>
        )
    }
        
    return (
        <div>
            <ApplicationDetails />
        </div>
    )
}

// JSS Styles
const containerStyles = {
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
}

export default Application
