import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

// Import Components
import { Button, Table } from 'antd'

// Import Icons
import { LoadingOutlined } from '@ant-design/icons'

class DashboardSummaryTable extends React.PureComponent {
    state = {
        tableCols: [
            { title: 'Organization', dataIndex: 'org_name' },
            { title: 'Total Apps', dataIndex: 'total_applications' },
            { title: 'Loan', dataIndex: 'total_loan' },
            { title: 'Card', dataIndex: 'total_card' },
            { title: 'Apps Finished In One Go', dataIndex: 'application_finished_in_one_go' },
            { title: 'Total Trips', dataIndex: 'total_trips' },
            { title: 'Total ReCPV', dataIndex: 'total_re_cpv' },
            {
                title: 'Not Accepted',
                dataIndex: 'trip_not_accepted',
                render: trip_not_accepted => {
                    return (
                        <Button
                            type={'link'}
                            size={'small'}
                        >
                            {trip_not_accepted}
                        </Button>
                    )
                }
            },
            { title: 'On Process Trips', dataIndex: 'trip_on_process' },
            { title: 'Finished Trips', dataIndex: 'trip_finished' },
            {
                title: 'On Hold Trips',
                dataIndex: 'trip_on_hold',
                render: trip_on_hold => {
                    return (
                        <Button type={'link'} size={'small'}>
                            {trip_on_hold}
                        </Button>
                    )
                }
            },
            { title: 'Trip Finished %', dataIndex: 'trip_finish_percentage' },
            { title: 'App. Finished %', dataIndex: 'application_finish_percentage' },
        ]
    }

    render() {
        const { tableCols } = this.state
        const { summary, isLoading } = this.props
        return (
            <Table
                columns={tableCols}
                dataSource={summary}
                style={STYLES.tableStyle}
                size={'small'}
                pagination={false}
                bordered={true}
                loading={{ indicator: <LoadingOutlined style={{ fontSize: 24 }} spin />, spinning: isLoading }}
            />
        )
    }
}

// styles
const STYLES = {
    tableStyle: {
        width: '100%',
        overflow: 'auto'
    }
}

// PropTypes Validation
DashboardSummaryTable.propTypes = {
    summary: PropTypes.array.isRequired,
    isLoading: PropTypes.bool,
    getSummary: PropTypes.func
}

DashboardSummaryTable.defaultProps = {
    summary: [],
    isLoading: false,
    getSummary: () => null
}

const mapStateToProps = state => ({
    isLoading: state.dashboard.isLoading,
    summary: state.dashboard.summary,
})

export default connect(mapStateToProps, null)(DashboardSummaryTable)