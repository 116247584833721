import axios from "axios";
import Pusher from "pusher-js";
import moment from "moment";

// Import Actions
import {
  setIsTopsheetUpdateLoading,
  setApplicationTopsheet,
  setIsLoading,
  setReports,
  setError,
  setPagination,
  setIsSubmitButtonLoading,
  setIsSearchButtonLoading,
  setShowDownloadButton,
  setIsDownloadButtonLoading,
  setIsStatusUpdateModalOpen,
  setSelectedApplication,
  setIsDeleteButtonLoading,
  setIsBankReportLoading,
  setIsStatusUpdateLoading,
  setProcesses,
  setSocketStatus,
} from "../Reducers/reportsReducer";
import { showAlert } from "../Actions/commonActions";

// Import Types
import { REPORTS } from "../../Config/config";

// Get Reports Data by Date Range
const getReportsByDateRange = (params, key) => {

  return async (dispatch) => {
    dispatch(setIsLoading(true));
    if (!key) {
      dispatch(setIsSubmitButtonLoading(true));
    }
    try {
      const response = await axios.get(REPORTS.GET_REPORTS, { params });
      const pagination = response?.data?.data?.applications;
      const data = response?.data?.data?.applications?.data;
      const processes = response?.data?.data?.processes;

      dispatch(setProcesses(processes));

      // Check if there are any data
      if (!data) {
        // Set Pagination and Data to empty
        dispatch(setReports([]));
        dispatch(setPagination(null));

        // Set isLoading to false
        dispatch(setIsLoading(false));
        dispatch(setIsSubmitButtonLoading(false));
        // showAlert('success', 'Currently no data available')
        return;
      }
      // transform data
      const transformedData = transformReportData(data);
      const updatedData = {
        ...pagination,
        data: transformedData,
      };

      // Set Pagination
      dispatch(setPagination(updatedData));

      // Set Reports
      dispatch(setReports(transformedData));

      // Set loading to false
      dispatch(setIsLoading(false));
      dispatch(setIsSubmitButtonLoading(false));

      // Set Show Download Button
      dispatch(setShowDownloadButton(true));
    } catch (error) {
      // Set loading to false
      dispatch(setIsLoading(false));
      dispatch(setIsSubmitButtonLoading(false));
      dispatch(setError(error.response.data.message));
      showAlert("error", "Reports loading failed");

      // Set Show Download Button
      dispatch(setShowDownloadButton(false));
    }
  };
};

// Get topsheet of specific application
const getTopSheet = (application_id) => {
  return async (dispatch) => {
    dispatch(setIsTopsheetUpdateLoading(true));
    try {
      const response = await axios.get(
        `${REPORTS.GET_POST_APPLICATIONS_OUTCOME}${application_id}/outcome`
      );

      const data = response?.data?.data?.outcome;

      // Check if data is not valid
      if (!data) {
        // Set loading to false

        dispatch(setIsTopsheetUpdateLoading(false));

        // Show Error Alert
        showAlert("error", "Application not found");
        return;
      }
      // storing topsheet data to reducer
      dispatch(setApplicationTopsheet(data));

      // Set loading to false
      dispatch(setIsTopsheetUpdateLoading(false));
    } catch (error) {
      console.error(error);

      // Set loading to false
      dispatch(setIsTopsheetUpdateLoading(false));
      // Show Error Alert
      showAlert(
        "error",
        error?.response?.data?.message ??
        error?.message ??
        "Something went wrong."
      );
    }
  };
};

// Get topsheet of specific application
const downloadTopsheet = (applicationRowID, applicationFileID) => {
  return async (dispatch) => {
    // dispatch(setApplicationDetailsLoading(true))
    dispatch(setIsTopsheetUpdateLoading(true));
    try {
      const res = await axios.get(
        `${REPORTS.DOWNLOAD_APPLICATION_TOPSHEET}/${applicationRowID}`,
        { responseType: "arraybuffer" }
      );
      const data = res?.data;

      // Check if data is not valid
      if (!data) {
        // Set loading to false
        dispatch(setIsTopsheetUpdateLoading(false));

        // Show Error Alert
        showAlert("error", "Data Not Found");
        return;
      }
      const blob = new Blob([res.data], { type: "application/pdf" });
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = applicationFileID + "_Topsheet";
      link.click();

      dispatch(setIsTopsheetUpdateLoading(false));

      // Show Error Alert
      showAlert("success", "Download Successfull");

      // Set loading to false
      dispatch(setIsTopsheetUpdateLoading(false));
    } catch (error) {
      console.error(error);

      // Set loading to false
      dispatch(setIsTopsheetUpdateLoading(false));

      // Show Error Alert
      showAlert(
        "error",
        error?.response?.data?.message ?? "Topsheet Download Failed."
      );
    }
  };
};

// Get Reports by Input
const getReportsByInput = (params) => {
  return async (dispatch) => {
    dispatch(setIsLoading(true));
    dispatch(setIsSearchButtonLoading(true));

    // Set Show Download Button
    dispatch(setShowDownloadButton(false));
    try {
      const response = await axios.get(REPORTS.SEARCH, { params });
      const pagination = response?.data?.data?.applications;
      const data = response?.data?.data?.applications?.data;
      const transformedData = transformReportData(data);
      const updatedData = {
        ...pagination,
        data: transformedData,
      };
      dispatch(setPagination(updatedData));
      dispatch(setReports(transformedData));
      dispatch(setIsLoading(false));
      dispatch(setIsSearchButtonLoading(false));
    } catch (error) {
      dispatch(setIsLoading(false));
      dispatch(setIsSearchButtonLoading(false));
      dispatch(setError(error.response.data.message));
    }
  };
};

// Transform Report Data
function transformReportData(data) {
  if (!data) {
    return [];
  }
  return data.map((item, index) => {
    return {
      applicant_name: getConcernName(item.concerns),
      file_no: item.application_id,
      internal_remarks: item?.internal_remarks,
      status: getReportStatus(item.status),
      attachments: getAttachmentStatus(item.has_all_required_attachments),
      org_name: item?.group?.name,
      is_verified: item?.is_verified,
      is_download_enabled: item?.is_download_enabled,
      verifier_id: item?.issues?.[0]?.user_id ?? '',
      trips: getTripsWithIssues(item.trips),
      start_date: formatReportDate(item.start_date),
      sla: getSLAStatus(item.sla, item.end_date),
      end_date: formatReportDate(item?.end_date),
      id: item.id,
      key: index + 1,
    };
  });
}

// Get Concern Name
function getConcernName(concerns) {
  if (!concerns) {
    return "N/A";
  }
  // Check if there are any concerns and concern type === 'APPLICANT' and return name
  const applicants = concerns.filter((item) => item.type === "APPLICANT");
  if (applicants?.length > 0) {
    return applicants.map((item) => item.name).join(", ");
  }

  // Check if there are any concerns and concern type === 'GUARANTOR' and return name
  const guarantors = concerns.filter((item) => item.type === "GUARANTOR");
  if (applicants?.length === 0 && guarantors.length > 0) {
    return guarantors.map((item) => item.name).join(", ");
  }

  return "N/A";
}

// Get Trips IDs where issues are present
function getTripsWithIssues(trips) {
  if (!trips) {
    return [];
  }
  return trips.map((item) => ({
    trip_id: item.id,
    trip_status: getTripStatus(item.status),
    trip_is_verified: item.is_verified,
    trip_type: item.type,
    trip_issues: item?.issues?.length > 0 ? true : false,
    issues: item?.issues?.length > 0 ? item?.issues : [],
  }));
}

// Get Attachment Status
function getAttachmentStatus(attachments) {
  if (attachments === 1) {
    return { label: "Clear", color: "#35AA80" };
  }

  if (attachments === 0) {
    return { label: "Pending", color: "#FFA500" };
  }

  return { label: "N/A", color: "" };
}

// Get SLA Date and Status
function getSLAStatus(sla, end_date) {
  const sla_date = moment(sla).format("DD-MMM-YYYY hh:mm A");

  const sla_status =
    end_date && moment(end_date).isAfter(sla)
      ? { label: "SLA missed", color: "#FF6363", sla_date }
      : { label: "SLA met", color: "#35AA80", sla_date };

  return sla_status;
}

// Get Report Status // Application Status
function getReportStatus(status) {
  // 'CREATED'   => 1, - purple  764FA0
  // 'ONGOING'   => 2, - blue  4154A4
  // 'HOLD'      => 3, - yellow F5B629
  // 'FINISHED'  => 4, - green  -> removed (35AA80) -> updated with antd 'green'  => Meaning submitted with full documents or Done with trips
  // 'CANCELED'  => 5, - red C82128
  // 'SUBMITTED' => 6, - antd 'red' => meaning submitted but pending attachments
  // 'PARTIALLY SUBMITTED' => 7, - antd 'blue' => chosen incomplete submission from status update modal on report table

  // Check status number and return label and color
  switch (status) {
    case 1:
      return { label: "Created", color: "#764FA0", value: 1 };
    case 2:
      return { label: "On Going", color: "#4154A4", value: 2 };
    case 3:
      return { label: "Hold", color: "#F5B629", value: 3 };
    case 4:
      return { label: "Finished", color: "green", value: 4 };
    case 5:
      return { label: "Canceled", color: "#C82128", value: 5 };
    case 6:
      return { label: "Submitted", color: "red", value: 6 };
    case 7:
      return { label: "Partially Submitted", color: "blue", value: 7 };
    default:
      return { label: "N/A", color: "", value: -1 };
  }
}

// Format Report Date
function formatReportDate(date) {
  if (!date) {
    return "";
  }
  return moment(date).format("DD-MMM-YYYY hh:mm A");
}

// Get Trip Status
function getTripStatus(status) {
  // 'CREATED'   => 1, - purple  764FA0
  // 'ALLOCATED' => 2, - orange  5BCAE8
  // 'STARTED'   => 3, - blue  4154A4
  // 'HOLD'      => 4, - yellow F5B629
  // 'FINISHED'  => 5, - green  35AA80
  // 'CANCELED'  => 6, - red C82128
  // 'INACTIVE'  => 7  - grey A8AAAC

  // Check status number and return label and color
  switch (status) {
    case 1:
      return { label: "Created", color: "#764FA0" };
    case 2:
      return { label: "Allocated", color: "#5BCAE8" };
    case 3:
      return { label: "Started", color: "#4154A4" };
    case 4:
      return { label: "Hold", color: "#F5B629" };
    case 5:
      return { label: "Finished", color: "#35AA80" };
    case 6:
      return { label: "Canceled", color: "#C82128" };
    case 7:
      return { label: "Inactive", color: "#A8AAAC" };
    default:
      return { label: "N/A", color: "" };
  }
}

// Download SLA Report
const downloadSLAReport = (params) => {
  return async (dispatch) => {
    // Set is loading to true
    dispatch(setIsDownloadButtonLoading(true));
    try {
      const response = await axios.post(REPORTS.DOWNLOAD_SLA_REPORT, params, {
        responseType: "blob",
      });

      // Set is loading to false
      dispatch(setIsDownloadButtonLoading(false));

      if (response.status === 200) {
        // Set file name
        const filename =
          "SLA_Report_" + params.dateFrom + "_" + params.dateTill + ".xlsx";

        // Set file type
        const url = URL.createObjectURL(
          new Blob([response.data], {
            type: "application/vnd.ms-excel",
          })
        );

        // Set file download
        var link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", filename);
        document.body.appendChild(link);
        link.click();
      } else {
        showAlert(
          "error",
          response?.message ? response?.message : "Something went wrong"
        );
      }
    } catch (error) {
      console.error(error);
      showAlert("error", error?.message ?? "Download Failed");
      // Set is loading to false
      dispatch(setIsDownloadButtonLoading(false));
    }
  };
};

const downloadTripStatusReport = (params) => {
  return async (dispatch) => {
    dispatch(setIsDownloadButtonLoading(true));

    try {
      axios
        .post(REPORTS.DOWNLOAD_TRIP_STATUS_REPORT, params, { responseType: 'blob' })
        .then((res) => {
          if (res?.status !== 200) {
            showAlert("error", res?.message ?? "Something went wrong!");
            return;
          }
          const blob = new Blob([res?.data], { type: "application/vnd.ms-excel" });

          // Set file name
          const filename =
            "Trip_Status_Report_" +
            params.dateFrom +
            "_" +
            params.dateTill +
            ".xls";

          // Creating an object for downloading url
          const url = window.URL.createObjectURL(blob);

          // Creating an anchor(a) tag of HTML
          const a = document.createElement("a");

          // Passing the blob downloading url
          a.setAttribute("href", url);

          // Setting the anchor tag attribute for downloading
          // and passing the download file name
          a.setAttribute("download", filename);

          // Performing a download with click
          a.click();
          showAlert("success", res?.message ?? "Download Successfull");
        })
        .catch((err) => {
          console.error(err);
        });
    } catch (error) {
      showAlert("error", error?.message ?? "Report Download Failed");
      console.error(error);
    } finally {
      dispatch(setIsDownloadButtonLoading(false));
    }
  };
};

// Download Executive Report
const downloadExecutiveReport = (params) => {
  return async (dispatch) => {
    // Set is loading to true
    dispatch(setIsDownloadButtonLoading(true));
    try {
      const response = await axios.get(REPORTS.DOWNLOAD_EXECUTIVE_REPORT, {
        params,
        responseType: 'arraybuffer' // set response type
      });
      // Set is loading to false
      dispatch(setIsDownloadButtonLoading(false));

      if (response.status === 200) {
        // Set file name
        const filename =
          "Executive_Report_" + params.date_from + "_" + params.date_to + ".xlsx";

        // Set file type
        const url = URL.createObjectURL(
          new Blob([response.data], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          })
        );

        // Set file download
        var link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", filename);
        document.body.appendChild(link);
        link.click();
      } else {
        showAlert(
          "error",
          response?.message ? response?.message : "Something went wrong"
        );
      }
    } catch (error) {
      console.error(error);
      // Create a new Blob from the error response data
      const errorData = new Blob([error.response.data], {
        type: 'application/json'
      });
      // Create a new FileReader to read this Blob
      const reader = new FileReader();
      // This will execute once the reader has finished reading the file
      reader.onload = function (event) {
        // The file's text will be printed here
        const errorMsg = JSON.parse(event.target.result);
        showAlert("error", errorMsg.message || "Download Failed");
      };
      // Start reading the Blob as text
      reader.readAsText(errorData);
      // Set is loading to false
      dispatch(setIsDownloadButtonLoading(false));
    }
  };
};

// Close Status Update Modal
const closeStatusUpdateModal = () => {
  return (dispatch) => {
    // Set Modal Visibility to false
    dispatch(setIsStatusUpdateModalOpen(false));

    // Reset status update modal data
    dispatch(setSelectedApplication(null));
  };
};

// Open Status Update Modal
const openStatusUpdateModal = (selectedApplication) => {
  return (dispatch) => {
    // Check if selected application is null
    if (!selectedApplication) {
      // Show alert
      showAlert("error", "Please select an application to update status.");
      return;
    }

    // only allowing file status with => hold: 3 / Finish: 4 / Submitted: 6 / completed: 7
    // if( selectedApplication.status.value !== 4 && selectedApplication.status.value !== 6 && selectedApplication.status.value !== 7) {
    //     // Show alert and return
    //     showAlert('error', 'Please select an application with status "Finished" or "Submitted" or "Completed" to update status.')
    //     return
    // }

    // Set Modal Visibility to true
    dispatch(setIsStatusUpdateModalOpen(true));

    // Set selected application
    dispatch(setSelectedApplication(selectedApplication));
  };
};

// Update Status
const updateStatus = (params, dateRange) => {
  return async (dispatch) => {
    // Set is loading to true
    dispatch(setIsStatusUpdateLoading(true));

    try {
      const response = await axios.post(REPORTS.UPDATE_STATUS, { ...params });

      // Check response status
      if (response.data.status === 200) {
        // Set is loading to false
        dispatch(setIsStatusUpdateLoading(false));

        // Set is status update modal open to false
        dispatch(closeStatusUpdateModal());

        // Set selected application
        dispatch(setSelectedApplication(null));

        // Get Updated Report
        dispatch(getReportsByDateRange(dateRange, true));

        // Set success message
        showAlert(
          "success",
          response?.data?.message ?? "Status updated successfully"
        );
        return;
      } else {
        // Set is loading to false
        dispatch(setIsStatusUpdateLoading(false));

        // Show error alert
        showAlert("error", response?.data?.message ?? "Something went wrong");
        throw new Error(response.data.message);
      }
    } catch (error) {
      console.error(error);

      // Set is loading to false
      dispatch(setIsStatusUpdateLoading(false));

      // Return error alert
      showAlert(
        "error",
        error?.response?.data?.message ??
        error?.message ??
        "Something went wrong"
      );
    }
  };
};

// update topsheet
const updateTopsheet = async (application_id, params) => {

  return await axios
    .post(
      `${REPORTS.GET_POST_APPLICATIONS_OUTCOME}${application_id}/outcome`,
      params
    )
    .then((res) => {
      if (res.status !== 200) {
        return [];
      }
      return res;
    })
    .catch((err) => {
      console.error(err);
      showAlert("error", err?.message ?? "Update Failed");
    });
};

// Bank Report Download
const downloadBankReport = (id) => {
  return async (dispatch) => {
    // Set is loading to true
    dispatch(setIsBankReportLoading(true));
    try {
      const response = await axios.get(
        REPORTS.GET_POST_APPLICATIONS_OUTCOME +
        id +
        REPORTS.DOWNLOAD_BANK_REPORT,
        { responseType: "arraybuffer" }
      );

      // Check if response status is 200
      if (response.status === 200) {
        // Set is loading to false
        dispatch(setIsBankReportLoading(false));

        // Show success alert
        showAlert("success", "Bank Report Downloaded Successfully");

        const blob = new Blob([response.data], { type: "application/pdf" });
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = "Bank_Report_" + id;
        link.click();
      } else {
        // Set is loading to false
        dispatch(setIsBankReportLoading(false));

        // Return error alert
        showAlert("error", response?.data?.message ?? "Something went wrong");
        throw new Error(response?.data?.message ?? "Something went wrong");
      }
    } catch (error) {
      console.error(error)

      // Set is loading to false
      dispatch(setIsBankReportLoading(false));

      // Return error alert
      showAlert(
        "error",
        error?.response?.data?.message ??
        error?.message ??
        "Something went wrong"
      );
    }
  };
};

// Application Delete
const deleteApplication = (id, params) => {
  return async (dispatch) => {
    // Set is loading to true
    dispatch(setIsDeleteButtonLoading(true));
    try {
      const response = await axios.delete(
        REPORTS.GET_POST_APPLICATIONS_OUTCOME + id
      );
      // Check if response status is 200
      if (response.status === 200) {
        // Set is loading to false
        dispatch(setIsDeleteButtonLoading(false));

        // Get Updated Report
        dispatch(getReportsByDateRange(params, true));

        // Show success alert
        showAlert("success", "Application Deleted Successfully");
      } else {
        // Set is loading to false
        dispatch(setIsDeleteButtonLoading(false));

        // Return error alert
        showAlert("error", response?.data?.message ?? "Something went wrong");
        throw new Error(response?.data?.message ?? "Something went wrong");
      }
    } catch (error) {
      console.error(error);

      // Set is loading to false
      dispatch(setIsDeleteButtonLoading(false));

      // Return error alert
      showAlert(
        "error",
        error?.response?.data?.message ??
        error?.message ??
        "Something went wrong"
      );
    }
  };
};

// Socket For Status Update of Report
// Activate Socket Connection
const activateStatusSocketConnection = () => {

  return (dispatch, getState) => {
    // Config Pusher
    window.pusher = new Pusher(
      REPORTS.STATUS_SOCKET.CHANNEL.appKey,
      REPORTS.STATUS_SOCKET.CONFIG
    );

    // Subscribe to channel
    window.pusher
      .subscribe(REPORTS.STATUS_SOCKET.CHANNEL.channel)
      .bind(REPORTS.STATUS_SOCKET.CHANNEL.event, (data) => {
        console.log('socket', data)
        dispatch(setSocketStatus(data?.status));
      });
  };
};

// Deactivate Socket Status Report
const deactivateStatusSocket = () => {

  return () => {
    if (window.pusher) {
      window.pusher.unsubscribe(REPORTS.STATUS_SOCKET.CHANNEL.channel);
      delete window.pusher;
    }
  };
};

export {
  updateTopsheet,
  downloadTopsheet,
  getTopSheet,
  getReportsByDateRange,
  getReportsByInput,
  getReportStatus,
  downloadSLAReport,
  closeStatusUpdateModal,
  openStatusUpdateModal,
  downloadBankReport,
  deleteApplication,
  updateStatus,
  downloadTripStatusReport,
  activateStatusSocketConnection,
  deactivateStatusSocket,
  downloadExecutiveReport,
};
