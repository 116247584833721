import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

// Import Components
import { Form, Input, Select, Button } from 'antd'

// Import Actions
import { updateConcern } from '../../../Services/Actions/applicationActions'

class ConcernUpdateForm extends React.PureComponent {
    onFinish = values => {
        const { dispatch, selectedConcern } = this.props
        
        const formData = new FormData()

        if (values && values.name) {
            formData.append('name', values.name)
            formData.append('type', values.type)
            if (values.image) {
                formData.append('image', values.image)
            }
            if (values.phone) {
                formData.append('phone', values.phone)
            }
            if (values.designation) {
                formData.append('designation', values.designation)
            }
        }

        dispatch(updateConcern(selectedConcern, formData))
    }

    normFile = (e) => {
        if (Array.isArray(e)) {
            return e
        }
        return e && e.target.files[0]
    }
    render() {
        const { selectedConcern } = this.props
        return (
            <Form
                name={ 'basic' }
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
                initialValues={{
                    type: selectedConcern && selectedConcern.type ? selectedConcern.type : '',
                    name: selectedConcern && selectedConcern.name ? selectedConcern.name : '',
                    designation: selectedConcern && selectedConcern.designation ? selectedConcern.designation : '',
                    phone: selectedConcern && selectedConcern.phone ? selectedConcern.phone : ''
                }}
                onFinish={ this.onFinish }
                layout={ 'vertical' }
            >

                <Form.Item
                    name={ 'type' }
                >
                    <Select placeholder={ 'Select Concern Type' }>
                        <Select.Option value={ 'APPLICANT' }>{ 'Applicant' }</Select.Option>
                        <Select.Option value={ 'CO_APPLICANT' }>{ 'Co-Applicant' }</Select.Option>
                        <Select.Option value={ 'GUARANTOR' }>{ 'Guarantor' }</Select.Option>
                    </Select>
                </Form.Item>
                <Form.Item
                    label={ 'Name' }
                    name={ 'name' }
                >
                    <Input />
                </Form.Item>

                <Form.Item
                    label={ 'Designation' }
                    name={ 'designation' }
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label={ 'Phone' }
                    name={ 'phone' }
                >
                    <Input />
                </Form.Item>

                <Form.Item
                    name={ 'image' }
                    label={ 'Concern Photo' }
                    valuePropName={ 'fileList' }
                    getValueFromEvent={ this.normFile }
                >
                    <input
                        accept='image/*'
                        id='second-guarantor-image'
                        name='image'
                        type='file'
                    />
                </Form.Item>

                <Form.Item >
                    <Button 
                        type={ 'primary' } 
                        htmlType={ 'submit' }
                    >
                        { 'Update' }
                    </Button>
                </Form.Item>
            </Form>
        )
    }
}

// Props Validation
ConcernUpdateForm.propTypes = {
    selectedConcern: PropTypes.object,
    dispatch: PropTypes.func,
}

ConcernUpdateForm.defaultProps = {
    selectedConcern: {},
    dispatch: () => null,
}

// Map State To Props
const mapStateToProps = (state) => ({
    selectedConcern: state.application.selectedConcern,
})

// Map Dispatch To Props
const mapDispatchToProps = (dispatch) => ({ dispatch })

export default connect(mapStateToProps, mapDispatchToProps)(ConcernUpdateForm)