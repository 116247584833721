import React from 'react'
import { Button, Card, Form, Input, Select } from 'antd'

// actions and reducers
import { markTripAsRecpv } from '../../../Services/Actions/recpvActions'
import { showAlert } from '../../../Services/Actions/commonActions'


const MarkAsReCPV = ({ selectedTrip }) => {
    const [form] = Form.useForm()

    const markAsRecpv = (values) => {
        const tripID = selectedTrip?.id
        markTripAsRecpv(tripID, values)
            .then(res => {
                showAlert('success', res?.message ?? 'Successfully Marked as ReCPV')
                form.resetFields()
            })
            .catch(err => {
                console.error(err)
                showAlert('error', err?.message ?? 'Failed to mark as ReCPV')
            })
    }

    return(
        <Card>
            <Form
                layout={ 'vertical' }
                form={ form }
                onFinish={ markAsRecpv }
                initialValues={{
                }}
            >
                <Form.Item 
                    label={ 'Reason' } 
                    name={ 'reason' }
                    rules={[
                        {
                            required: true,
                            message: 'Missing Reason',
                        }
                    ]}
                >
                    <Input placeholder={ 'Enter Reason' } />
                </Form.Item>
                <Form.Item 
                    label={ 'Billing Type' } 
                    name={ 'type' }
                    rules={[
                        {
                            required: true,
                            message: 'Missing Billing Type',
                        }
                    ]}
                    >
                    <Select>
                        <Select.Option value={ 'REGULAR' }>Regular Billing</Select.Option>
                        <Select.Option value={ 'FULL_IN' }>Full In Billing</Select.Option>
                    </Select>
                </Form.Item>
                <Form.Item>
                    <Button type="primary" htmlType="submit">
                        Mark ReCPV
                    </Button>
                </Form.Item>
            </Form>
        </Card>
    )
}

export default MarkAsReCPV