import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import Cookies from 'js-cookie';
// Import Components
import { Form, Typography, Input, Button, Divider, Checkbox } from 'antd'

// Import Icons
import { MailOutlined, LockOutlined } from '@ant-design/icons'

// Import Actions & Methods
import { login } from '../Services/Actions/authActions'

class Login extends React.PureComponent {
    formRef = React.createRef(); 
    state = {
        rememberMe: false
    };
    componentDidMount() {
        // Use Cookies.get instead of localStorage.getItem
        const email = Cookies.get('rememberedEmail');
        const password = Cookies.get('rememberedPassword');
        if (email) {
            // Access the form instance using the ref and then call setFieldsValue
            this.formRef.current.setFieldsValue({ email });
            this.formRef.current.setFieldsValue({ password });
            this.setState({ rememberMe: true });
        }
    }
    // On Form Submit
    _onSubmit = values => {
        const { dispatch } = this.props;
        
        if (this.state.rememberMe) {
            // You can specify additional options as the third parameter, e.g., { expires: 7 } for 7 days expiration
            Cookies.set('rememberedEmail', values.email, { expires: 7 });
            Cookies.set('rememberedPassword', values.password, { expires: 7 });
        } else {
            // Optionally remove the cookie if rememberMe is false
            Cookies.remove('rememberedEmail');
        }
        dispatch(login(values));
    }

    // On Submit Error
    _onSubmitError = err => {
        console.error(err)
    }

    _handleRememberMeChange = e => {
        this.setState({ rememberMe: e.target.checked });
    }
    render() {
        const { loading, error } = this.props
        const { rememberMe } = this.state
        
        return (
            <div style={{ height: '100vh', width: '100vw', padding: '1rem' }}>                
                <div style={ containerStyles }>
                    <Form
                        layout={ 'vertical' }
                        size={ 'middle' }
                        autoComplete={ 'off' }
                        onFinish={ this._onSubmit }
                        validateTrigger={ 'onChange' }
                        style={ shadowStyles }
                        ref={this.formRef}
                    >
                        <Form.Item name={ 'title' }>
                            <Typography.Title
                                level={ 2 }
                                style={{ textAlign: 'center' }}
                            >
                                { 'Verify Admin' }
                            </Typography.Title>
                        </Form.Item>
                        <Divider />
                        <Form.Item
                            label='Email'
                            name='email'
                            hasFeedback={ true }
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input your email.'
                                },
                                {
                                    type: 'email',
                                    message: 'Please enter a valid email address.'
                                }
                            ]}
                        >
                            <Input style={{ borderLeft: '4px solid #1890ff' }} prefix={ <MailOutlined style={{ color: '#1890ff' }} /> } />
                        </Form.Item>

                        <Form.Item
                            label='Password'
                            name='password'
                            hasFeedback={ true }
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input your password.'
                                },
                                {
                                    min: 6,
                                    message: 'Password must be at least 6 characters.'
                                },
                                {                                    
                                    default: error
                                }
                            ]}
                        >
                            <Input.Password style={{ borderLeft: '4px solid #1890ff' }} prefix={ <LockOutlined style={{ color: '#1890ff' }} /> } />
                        </Form.Item>
                        <Form.Item>
                            <Checkbox checked={rememberMe} onChange={this._handleRememberMeChange}>Remember me</Checkbox>
                        </Form.Item>
                        <Form.Item>
                            <Button
                                type={ 'primary' }
                                htmlType={ 'submit' }
                                style={{ width: '100%' }}
                                loading={ loading }
                            >
                                { 'Login' }
                            </Button>
                        </Form.Item>
                    </Form>
                </div>
            </div>
        )
    }
}

// JSS Styles
const containerStyles = {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
}

const shadowStyles = {
    borderColor: 'transparent',
    boxShadow: '0 1px 2px -2px rgba(0, 0, 0, 0.16), 0 3px 6px 0 rgba(0, 0, 0, 0.12), 0 5px 12px 4px rgba(0, 0, 0, 0.09)',
    padding: '2rem 2rem 1rem',
    borderRadius: '2px'
}

// Prop Types
Login.propTypes = {
    dispatch: PropTypes.func,
    loading: PropTypes.bool,
    error: PropTypes.string
}

Login.defaultProps = {
    dispatch: () => null,
    loading: false,
    error: null
}

const mapStateToProps = state => ({
    loading: state.auth.isValidating,
    error: state.auth.error
})

const mapDispatchToProps = dispatch => ({ dispatch })

export default connect(mapStateToProps, mapDispatchToProps)(Login)