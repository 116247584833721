import React from 'react';

// Import Components
import { Card, Input, List, Skeleton, Space, Tag, Typography } from 'antd';

// Import Icons
import { SearchOutlined } from '@ant-design/icons';

// Import Styles
import '../../../App.css'

const QuestionList = ({ questionSource, onSearch }) => {

    return (
        <Space direction={'vertical'} style={fillWidthStyle}>
            <Input
                onChange={(e) => onSearch(e)}
                suffix={<SearchOutlined />}
                size={'small'}
                placeholder={'Search by Question, Type or Key'}
                style={{ marginBottom: '10px' }}
            />
            <Card style={questionContainerStyle} >
                <List
                    itemLayout={'horizontal'}
                    dataSource={questionSource}
                    key={questionSource?.length}
                    renderItem={(item, index) => (
                        <List.Item>
                            <Skeleton avatar title={false} loading={item.loading} active>
                                <List.Item.Meta
                                    style={listMetaSyyle}
                                    avatar={[
                                        <Tag key={index + ' - 1'} color={
                                            item?.field_type === 'MCQ' ? '#4cc4b2' :
                                                item?.field_type === 'SCQ' ? '#052e16' :
                                                    '#969fff'}>
                                            {item?.field_type ?? 'Not Available'}
                                        </Tag>,
                                        <Tag key={index + ' - 2'}>
                                            Key - {item?.key ?? 'Not Available'}
                                        </Tag>
                                    ]}
                                    title={item?.question ?? 'N/A'}
                                    description={
                                        <Space direction={'vertical'}>
                                            <Typography.Text type={'secondary'}>
                                                {
                                                    'Option: ' + ((item?.field_type === 'MCQ' || item?.field_type === 'SCQ') ? (item?.children?.map((option, index) => ' ' + (index + 1) + ') ' + option?.name)) : 'Input field')
                                                }
                                            </Typography.Text>
                                        </Space>
                                    }
                                />
                            </Skeleton>
                        </List.Item>
                    )}
                />
            </Card>
        </Space>
    )

}

const listMetaSyyle = {
    display: 'inline',
    width: '100% !important'
}

const questionContainerStyle = {
    overflowY: 'scroll',
    height: '400px',
    maxHeight: '400px',
}

const fillWidthStyle = {
    width: '100%',
    minWidth: '-moz-available',          /* WebKit-based browsers will ignore this. */
    minWidth: '-webkit-fill-available',  /* Mozilla-based browsers will ignore this. */
    minWidth: 'fill-available',
}

export default QuestionList