import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isLoading: false,
  reports: [],
  socketStatus: null,
  pagination: null,
  processes: [],
  isSubmitButtonLoading: false,
  isSearchButtonLoading: false,
  showDownloadButton: false,
  isDownloadButtonLoading: false,
  isStatusUpdateButtonLoading: false,
  isStatusUpdateModalOpen: false,
  isTopsheetUpdateLoading: false,
  applicationTopsheet: null,
  selectedApplication: null,
  isBankReportLoading: false,
  isDeleteButtonLoading: false,
  isStatusUpdateLoading: false,
  reloadModal: true,
};

const reportsSlice = createSlice({
  name: "reports",
  initialState,
  reducers: {
    setIsLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    setReports: (state, action) => {
      state.reports = action.payload;
    },
    setSocketStatus: (state, action) => {
      state.socketStatus = action.payload;
    },
    setError: (state, action) => {
      state.error = action.payload;
    },
    setPagination: (state, action) => {
      state.pagination = action.payload;
    },
    setProcesses: (state, action) => {
      state.processes = action.payload;
    },
    setIsSubmitButtonLoading: (state, action) => {
      state.isSubmitButtonLoading = action.payload;
    },
    setIsSearchButtonLoading: (state, action) => {
      state.isSearchButtonLoading = action.payload;
    },
    setShowDownloadButton: (state, action) => {
      state.showDownloadButton = action.payload;
    },
    setIsDownloadButtonLoading: (state, action) => {
      state.isDownloadButtonLoading = action.payload;
    },
    setIsStatusUpdateButtonLoading: (state, action) => {
      state.isStatusUpdateButtonLoading = action.payload;
    },
    setIsStatusUpdateModalOpen: (state, action) => {
      state.isStatusUpdateModalOpen = action.payload;
    },
    setIsTopsheetUpdateLoading: (state, action) => {
      state.isTopsheetUpdateLoading = action.payload;
    },
    setSelectedApplication: (state, action) => {
      state.selectedApplication = action.payload;
    },
    setApplicationTopsheet: (state, action) => {
      state.applicationTopsheet = action.payload;
    },
    setIsBankReportLoading: (state, action) => {
      state.isBankReportLoading = action.payload;
    },
    setIsDeleteButtonLoading: (state, action) => {
      state.isDeleteButtonLoading = action.payload;
    },
    setIsStatusUpdateLoading: (state, action) => {
      state.isStatusUpdateLoading = action.payload;
    },
    setReloadModal: (state, action) => {
      state.reloadModal = action.payload;
    },
  },
});

export const {
  setReloadModal,
  setIsTopsheetUpdateLoading,
  setApplicationTopsheet,
  setIsLoading,
  setError,
  setReports,
  setSocketStatus,
  setPagination,
  setProcesses,
  setIsSubmitButtonLoading,
  setIsSearchButtonLoading,
  setShowDownloadButton,
  setIsDownloadButtonLoading,
  setIsStatusUpdateButtonLoading,
  setIsStatusUpdateModalOpen,
  setSelectedApplication,
  setIsBankReportLoading,
  setIsDeleteButtonLoading,
  setIsStatusUpdateLoading,
} = reportsSlice.actions;
export default reportsSlice.reducer;
