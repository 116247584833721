import React, { useEffect, useState } from "react";
import PropTypes from 'prop-types'

import { Form, Input, Button, Select, Space } from "antd";

// import Actions, and Methods
import { useDispatch, useSelector } from "react-redux";
import { getApplicationType } from '../../../Services/Actions/staticOptionAction'
import { addApplicationTypeToOrg } from '../../../Services/Actions/adminPanelActions'


const ApplicationTypeAssigner = () => {

    // Get Data From Redux
    const commonData = useSelector(state => state.common)

    // States
    const dispatch = useDispatch()
    const [_orgList, set_orgList] = useState([])
    const [applicationType, setApplicationType] = useState([])
    const [form] = Form.useForm();
    const { orgList } = commonData

    // On Load Get Application Type
    useEffect(() => {
        setApplicationType(getApplicationType())
    }, [])

    // On Load Get Org List
    useEffect(() => {
        const onlyList = orgList.filter(item => item.id !== -1)
        set_orgList(onlyList)

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [orgList?.length])


    // Clearing the field data of the form
    const clearFormField = () => {
        form.resetFields();
    }

    // On Form Submit
    const onFinish = (values) => {
        // sending post request to add sub-type adn prfix
        dispatch(addApplicationTypeToOrg(values, () => clearFormField()))
    };

    return (
        <Space direction={'vertical'} style={{ width: '100%' }}>
            <Form
                name="application_type_assigner"
                onFinish={onFinish}
                labelCol={{ span: 24 }}
                form={form}
            >
                <Form.Item name="org_id" label="Organization" rules={[{ required: true }]}>
                    <Select
                        style={{ width: '100%' }}
                        showSearch
                        allowClear
                        placeholder={'Select Organization'}
                        // filterOption={ (input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0 }
                        optionFilterProp={'children'}
                    >
                        {
                            _orgList?.length > 0 && _orgList?.map((item, index) => {
                                return (
                                    <Select.Option key={index + 1} value={item?.uuid}>{item?.name}</Select.Option>
                                )
                            })
                        }
                    </Select>
                </Form.Item>

                <Form.Item name="app_type_id" label="Application Type" rules={[{ required: true }]}>
                    <Select
                        style={{ width: '100%' }}
                        showSearch
                        allowClear
                        placeholder={'Select Application Type'}
                        filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                        optionFilterProp={'children'}
                    >
                        {
                            applicationType?.length > 0 && applicationType.map((item, index) => {
                                return (
                                    <Select.Option key={index + 1} value={item.app_type_id}>{item.app_type}</Select.Option>
                                )
                            })
                        }
                    </Select>
                </Form.Item>

                <Form.Item name="app_subtype" label="Application Sub Type" rules={[{ required: true }]}>
                    <Input placeholder="Enter Application Sub Type" />
                </Form.Item>

                <Form.Item name="app_prefix" label="Application Prefix" rules={[{ required: true }]}>
                    <Input placeholder="Enter Application Prefix" />
                </Form.Item>

                <Form.Item>
                    <Button type="primary" htmlType="submit">
                        Add
                    </Button>
                </Form.Item>
            </Form>
        </Space>
    )

}

// Props Validation
ApplicationTypeAssigner.propTypes = {
    dispatch: PropTypes.func,
    orgList: PropTypes.array,
}

ApplicationTypeAssigner.defaultProps = {
    dispatch: () => null,
    orgList: []
}

export default ApplicationTypeAssigner