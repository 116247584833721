import React, { useEffect, useState } from 'react';
import moment from 'moment';

// Import Components
import { Button, Form, InputNumber, Select, Space, TimePicker } from 'antd';

// import Actions, and Methods
import { useDispatch, useSelector } from "react-redux";
import { createOrganizationStation, getOrganizationStation } from '../../../Services/Actions/adminPanelActions';
import { getStationOption } from '../../../Services/Actions/staticOptionAction';
import { getOrgOptions } from '../../../Services/Actions/createFileActions';
import { setSelectedOrgStations } from '../../../Services/Reducers/adminPanelReducer';

const { Option } = Select

const CreateOrgStation = () => {
  // Get Data From Redux
  const commonData = useSelector((state) => state.common)
  const orgOptions = useSelector((state) => state?.createFile?.orgOptions)

  // States
  const dispatch = useDispatch()
  const [form] = Form.useForm();
  const [_orgList, set_orgList] = useState([])
  const [stationOptions, setStationOptions] = useState([])
  const { orgList } = commonData
  const orgId = Form.useWatch("org_id", form)
  const applicationType = Form.useWatch("application_type", form)

  // Clearing the field data of the form
  const clearFormField = () => {
    form.resetFields();
  }

  // 
  useEffect(() => {
    if (orgId && applicationType) {
      // console.log(orgId, applicationType)
      dispatch(getOrganizationStation({ org_id: orgId, application_type: applicationType }))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orgId, applicationType])

  // On Form Submit
  const onFinish = (values) => {
    const params = {
      ...values,
      turn_around_time: values.turn_around_time * 60 * 60, // convert hours to seconds
      last_submission_time: moment(values.last_submission_time).format('HH:mm:ss'),
    }

    dispatch(createOrganizationStation(params, () => clearFormField()))
  };

  // Handle Select Org
  const _handleSelectOrg = (value) => {

    form.setFieldsValue({
      application_type: undefined,
      station_type: undefined,
      turn_around_time: undefined,
      last_submission_time: undefined
    })

    const selectedOrg = orgList.find(org => org.uuid === value)

    dispatch(setSelectedOrgStations([]))
    // Get Selected Org Options
    dispatch(getOrgOptions({ org_id: selectedOrg?.uuid }))
  }

  // On Load Get Application Type
  useEffect(() => {
    setStationOptions(getStationOption())
  }, [])

  // On Load Get Org List
  useEffect(() => {
    const onlyList = orgList.filter(item => item.id !== -1)
    set_orgList(onlyList)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orgList?.length])

  return (
    <Space direction={'vertical'} style={{ width: '100%' }}>
      <Form
        name='create_org_station'
        onFinish={onFinish}
        labelCol={{ span: 24 }}
        form={form}
      >

        {/* Org */}
        <Form.Item
          name="org_id"
          label="Organization"
          rules={[{ required: true }]}
        >
          <Select
            style={{ width: '100%' }}
            showSearch
            allowClear
            placeholder={'Select Organization'}
            optionFilterProp={'children'}
            onChange={_handleSelectOrg}
          >
            {
              _orgList?.length > 0 && _orgList?.map((item, index) => {
                return (
                  <Select.Option key={index + 1} value={item?.uuid}>{item?.name}</Select.Option>
                )
              })
            }
          </Select>
        </Form.Item>

        {/* Application Type */}
        <Form.Item
          name="application_type"
          label="Application Type"
          rules={[{ required: true }]}
        >
          <Select
            style={{ width: '100%' }}
            showSearch
            allowClear
            placeholder={'Select Application Type'}
            filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
            optionFilterProp={'children'}
          >
            {
              orgOptions?.length > 0 && orgOptions.map((item, index) => {
                return (
                  <Option key={index + 1} value={item.id}>{item.label}</Option>
                )
              })
            }
          </Select>
        </Form.Item>

        {/* Station Type */}
        <Form.Item
          name='station_type'
          label='Station Type'
          rules={[{ required: true }]}
        >
          <Select
            options={stationOptions}
            placeholder='Select Station Type'
          />
        </Form.Item>

        {/* Turn Around Time */}
        <Form.Item
          name='turn_around_time'
          label='Turn Around Time (Hours)'
          rules={[{ required: true }]}
        >
          <InputNumber
            style={{ width: '100%' }}
            placeholder="Enter Turn Around Time"
            min={1}
          />
        </Form.Item>

        {/* Last Submission Time */}
        <Form.Item
          name='last_submission_time'
          label='Last Submission Time'
          rules={[{ required: true }]}
        >
          <TimePicker
            format={'HH:mm:ss A'}
            style={{ width: '100%' }}
          />
        </Form.Item>

        {/* Create */}
        <Form.Item>
          <Button type="primary" htmlType="submit">
            Create
          </Button>
        </Form.Item>
      </Form>
    </Space>
  );
};

export default CreateOrgStation;