import axios from "axios";
import Pusher from "pusher-js";
import moment from "moment";

// Import Types
import { COMMON, ADDRESS, TRIPS } from "../../Config/config";

// Import Actions
import {
  setTaskMapSummary,
  setIsLoading,
  setOrgList,
  setVerifiers,
  setUsers,
  setIsAssignLoading,
  setIsUpdateTripModalOpen,
  setSelectedTripToUpdate,
  setIsUpdateTripButtonLoading,
  setSelectedMultipleTripToUpdate,
  setNotifications,
  setNewNotificationCount,
} from "../Reducers/commonReducer";
import { setSelectedTrip } from "../Reducers/applicationReducer";
import { getApplicationDetails } from "../Actions/applicationActions";
import {
  getTripDetails,
  getTripList,
  getAppointmentList,
} from "../Actions/taskMapActions";

// Import Alert Component
import { message, Typography } from "antd";
import {
  HomeFilled,
  BankFilled,
  FileTextFilled,
  CarFilled,
  ShoppingFilled,
  ReconciliationFilled,
  UserOutlined,
  CrownFilled,
} from "@ant-design/icons";

// Get Organization List
const getOrgList = () => {
  return (dispatch) => {
    // Set `isLoading` to true
    dispatch(setIsLoading(true));
    axios
      .get(COMMON.GET_GROUP)
      .then((res) => {
        let groups = res?.data?.groups;
        if (groups) {
          groups = [{ uuid: -1, name: "All Org" }, ...groups];
        }

        // Set `orgList` to groups
        dispatch(setOrgList(groups ?? []));

        // Set `isLoading` to false
        dispatch(setIsLoading(false));
      })
      .catch((error) => {
        // Set `isLoading` to false
        dispatch(setIsLoading(false));
        console.error(error);

        // Show Alert
        showAlert(
          "error",
          error?.response?.data?.message ??
          error?.message ??
          "Something went wrong"
        );
      });
  };
};

// Get Verifiers
const getVerifiers = () => {
  return async (dispatch) => {
    try {
      const response = await axios.get(COMMON.GET_VERIFIERS);
      const verifiers = response?.data?.taskers;
      if (verifiers) {
        const withAllVerifierObject = [
          { id: -1, name: "All Verifier" },
          ...verifiers,
        ];
        dispatch(setVerifiers(withAllVerifierObject));
        return;
      } else {
        dispatch(setVerifiers([]));
        // Show Alert
        showAlert("error", response?.data?.message ?? "Something went wrong");
        return;
      }
    } catch (error) {
      console.error(error);

      // Show Alert
      showAlert(
        "error",
        error?.response?.data?.message ??
        error?.message ??
        "Something went wrong"
      );
    }
  };
};

// Get Users
const getUsers = () => {
  return async (dispatch) => {
    try {
      const response = await axios.get(COMMON.GET_USERS);
      const users = response?.data?.userList;
      if (users) {
        dispatch(setUsers(users));
        return;
      } else {
        // Show Alert
        showAlert("error", response?.data?.message ?? "Something went wrong");
        return;
      }
    } catch (error) {
      console.error(error);

      // Show Alert
      showAlert(
        "error",
        error?.response?.data?.message ??
        error?.message ??
        "Something went wrong"
      );
    }
  };
};

// Get Fill Color according to status
const getFillColorVerifiersLocation = (status) => {
  if (!status) {
    return null;
  }
  // console.log("status", status);
  switch (status) {
    case 1:
      // CREATED
      return [0, 0, 125];
    case 2:
      // WAITING
      return [255, 178, 107];
    case 3:
      // BREAK
      return [232, 72, 69];
    case 4:
      // AWAY
      return [0, 0, 0];
    default:
      // OFFLINE
      return [114, 188, 212];
  }
};

// Get Fill Color according to Trip Status
const getFillColorTripStatus = (status) => {
  // 1 => CREATED => rgb(118, 79, 160) => PURPLE
  // 2 => ALLOCATED => rgb(91, 202, 232) => SKY BLUE
  // 3 => STARTED => rgb(65, 84, 164) => BLUE
  // 4 => HOLD => rgb(245, 182, 41) => YELLOW
  // 5 => FINISHED => rgb(53, 170, 128) => GREEN
  // 6 => CANCELLED => rgb(200, 33, 40) => RED
  // 7 => INACTIVE => rgb(168, 170, 172) => GREY
  if (!status) {
    return null;
  }
  switch (status) {
    case 1:
      // CREATED
      return "rgb(118, 79, 160)";
    case 2:
      // ALLOCATED
      return "rgb(91, 202, 232)";
    case 3:
      // STARTED
      return "rgb(65, 84, 164)";
    case 4:
      // HOLD
      return "rgb(245, 182, 41)";
    case 5:
      // FINISHED
      return "rgb(53, 170, 128)";
    case 6:
      // CANCELLED
      return "rgb(200, 33, 40)";
    case 7:
      // INACTIVE
      return "rgb(168, 170, 172)";
    default:
      return null;
  }
};

// Show Alert
const showAlert = (type, content, duration,key) => {
  // const color = type ?
  message[type]({
    content: (
      <Typography.Text
        type={type === "error" ? "danger" : "success"}
        strong
        style={{ fontSize: "15px", zIndex: 9999 }}
      >
        {content}
      </Typography.Text>
    ),
    duration: duration ? duration : 1.0,
    style: {
      textAlign: "center",
    },
    key: key??null
  }
);
};

// Get Fill Color and Label according to Trip Status
const getTripTypeStringAndFilledIcon = (tripType, color) => {
  switch (tripType) {
    case "HOUSE":
      return {
        label: "House",
        icon: <HomeFilled style={{ marginRight: "0.4rem", color }} />,
      };
    case "OFFICE":
      return {
        label: "Office",
        icon: <ShoppingFilled style={{ marginRight: "0.4rem", color }} />,
      };
    case "BANK":
      return {
        label: "Bank",
        icon: <BankFilled style={{ marginRight: "0.4rem", color }} />,
      };
    case "GUARANTOR_HOUSE":
      return {
        label: "Guarantor House",
        icon: <HomeFilled style={{ marginRight: "0.4rem", color }} />,
      };
    case "GUARANTOR_OFFICE":
      return {
        label: "Guarantor Office",
        icon: <ShoppingFilled style={{ marginRight: "0.4rem", color }} />,
      };
    case "DOCUMENT":
      return {
        label: "Document",
        icon: <FileTextFilled style={{ marginRight: "0.4rem", color }} />,
      };
    case "RENTAL":
      return {
        label: "Rental",
        icon: <ReconciliationFilled style={{ marginRight: "0.4rem", color }} />,
      };
    case "CAR_QUATATION":
      return {
        label: "Car Quatation",
        icon: <CarFilled style={{ marginRight: "0.4rem", color }} />,
      };
    case "PROPERTY":
      return {
        label: "Property",
        icon: <CrownFilled style={{ marginRight: "0.4rem", color }} />,
      };
    default:
      return {
        label: "",
        icon: "",
      };
  }
};
// 1 => CREATED => rgb(118, 79, 160) => PURPLE
// 2 => ALLOCATED => rgb(91, 202, 232) => SKY BLUE
// 3 => STARTED => rgb(65, 84, 164) => BLUE
// 4 => HOLD => rgb(245, 182, 41) => YELLOW
// 5 => FINISHED => rgb(53, 170, 128) => GREEN
// 6 => CANCELLED => rgb(200, 33, 40) => RED
// 7 => INACTIVE => rgb(168, 170, 172) => GREY

// Get Fill Color according to Trip Status
const getTripStatusLabelAndColor = (status) => {
  if (!status) {
    return null;
  }
  switch (status) {
    case 1:
      // CREATED
      return { label: "Created", color: "rgb(118, 79, 160)" };
    case 2:
      // ALLOCATED
      return { label: "Allocated", color: "rgb(91, 202, 232)" };
    case 3:
      // STARTED
      return { label: "Started", color: "rgb(65, 84, 164)" };
    case 4:
      // HOLD
      return { label: "Hold", color: "rgb(245, 182, 41)" };
    case 5:
      // FINISHED
      return { label: "Finished", color: "rgb(53, 170, 128)" };
    case 6:
      // CANCELLED
      return { label: "Cancelled", color: "rgb(200, 33, 40)" };
    case 7:
      // INACTIVE
      return { label: "Inactive", color: "rgb(168, 170, 172)" };
    default:
      return { label: "-", color: "" };
  }
};

// Get Fill Color according to Trip Status
const getVerifierStatusLabelAndColor = (status) => {
  if (!status) {
    return null;
  }
  switch (status) {
    case 1:
      // CREATED
      return { label: "Online", color: "#171789" };
    case 2:
      // ALLOCATED
      return { label: "Offline", color: "#7EC1D7" };
    case 3:
      // AWAY
      return { label: "Away", color: "black" };
    case 4:
      // WAITING
      return { label: "Waiting", color: "#FDB879" };
    case 5:
      // BREAK
      return { label: "Break", color: "#E85856" };
    default:
      return { label: "-", color: "" };
  }
};

// get duration between stare and end date
const getDateTimeDuration = (start, end) => {
  const startTime = moment(start, "YYYY-MM-DD hh:mm:ss");
  const endTime = moment(end, "YYYY-MM-DD hh:mm:ss");
  const hoursDiff = startTime.diff(endTime, "hours");
  const minutesDiff = startTime.diff(endTime, "minutes");
  let calculatedTime = "";

  if (hoursDiff) {
    calculatedTime = calculatedTime + hoursDiff + " Hours ";
  }
  if (minutesDiff % 60 > 0) {
    calculatedTime = calculatedTime + (minutesDiff % 60) + " Minutes";
  }

  return calculatedTime;
};

// Formate Date using moment
const formatDateIntoFullDateAndTime = (date) => {
  if (!date) {
    return "";
  }
  return moment(date).format("DD-MMM-YYYY hh:mm A");
};

// Get Attachment Type Label
const getAttachmentTypeLabel = (type) => {
  // Check if type is undefined or null or empty or 0
  if (!type) {
    return "";
  }
  switch (type) {
    case 1:
      return "LOI";
    case 2:
      return "Bank Statement";
    case 3:
      return "Guarantor Sign";
    case 4:
      return "Applicant Residence";
    case 5:
      return "Rental Agreement";
    case 6:
      return "Withdraw Letter";
    case 7:
      return "Salary Statement";
    case 8:
      return "Applicant Office";
    case 9:
      return "Guarantor Residense";
    case 10:
      return "Guarantor Office";
    case 11:
      return "Applicant Sign";
    case 12:
      return "Rental Images";
    case 13:
      return "Co-Applicant LOI";
    case 14:
      return "Co Applicant Office";
    case 15:
      return "Co-Applicant Residence";
    case 16:
      return "Co-Applicant Sign";
    case 17:
      return "Car Quotation";
    case 18:
      return "Applicant Bank Image";
    default:
      return "-";
  }
};

// Get Rupantor Address
const getRupantorAddress = async (value) => {
  const data = new FormData();
  data.append("q", value);
  try {
    const response = await axios.post(ADDRESS.RUPANTOR, data);
    if (!response?.data?.geocoded_address) {
      throw new Error("No Address Found");
    }

    const addr = {
      address: response?.data?.geocoded_address?.Address,
      latitude: Number(response?.data?.geocoded_address?.latitude),
      longitude: Number(response?.data?.geocoded_address?.longitude),
      area: response?.data?.geocoded_address?.area,
      placename: response?.data?.geocoded_address?.place_name
        ? response?.data?.geocoded_address?.place_name
        : "",
      city: response?.data?.geocoded_address?.city ?? "",
    };
    return await addr;
  } catch (error) {
    throw error;
  }
};

// Get Auto Complete Address
const getAutoCompleteAddress = async (params) => {
  try {
    const response = await axios.get(ADDRESS.AUTOCOMPLETE_SEARCH, { params });

    if (!response?.data || response?.data?.length === 0) {
      throw new Error("No Address Found");
    }

    const addr = response?.data?.map((place) => ({
      address: place?.address
        ? place?.address + ", " + place?.area + ", " + place?.city
        : place?.area + ", " + place?.city,
      latitude: Number(place?.latitude),
      longitude: Number(place?.longitude),
      area: place?.area ?? "",
      placename: place?.place_name ?? "",
      city: place?.city ?? "",
    }));
    return await addr;
  } catch (error) {
    throw error;
  }
};

// Assign Verifer to Trip
const assignVerifier = (params, type, type_details) => {
  return async (dispatch) => {
    // Set isAssigning to true
    dispatch(setIsAssignLoading(true));
    try {
      const response = await axios.post(COMMON.ASSIGN_VERIFIER, params);

      // Check if response is success
      if (response?.status === 200) {
        // Set isAssigning to false
        dispatch(setIsAssignLoading(false));

        // Show alert
        showAlert(
          "success",
          response?.data?.message ?? "Verifier Assigned Successfully"
        );

        // Check if type is 'application' and type_details id is valid
        if (type === "application" && type_details?.id) {
          // Get Application Details
          dispatch(getApplicationDetails(type_details?.id));
        }

        // Check if type is 'trip' and type_details id is valid
        if (type === "trip" && type_details?.id) {
          // Get Trip Details
          dispatch(getTripDetails(type_details?.id, type_details?.index));
        }
        return;
      } else {
        // Set isAssigning to false
        dispatch(setIsAssignLoading(false));

        // Show alert
        showAlert(
          "error",
          response?.data?.message ?? "Error Assigning Verifier"
        );
        return;
      }
    } catch (error) {
      console.error(error);

      // Set isAssigning to false
      dispatch(setIsAssignLoading(false));

      // Return error alert
      showAlert(
        "error",
        error?.response?.data?.message ??
        error?.message ??
        "Something went wrong"
      );
    }
  };
};

// Get Report Status
const getApplicationStatus = (status) => {
  // 'CREATED'   => 1, - purple  764FA0
  // 'ONGOING'   => 2, - blue  4154A4
  // 'HOLD'      => 3, - yellow F5B629
  // 'FINISHED'  => 4, - green  -> removed (35AA80) -> updated with antd 'green'  => Meaning submitted with full documents or Done with trips
  // 'CANCELED'  => 5, - red C82128
  // 'SUBMITTED' => 6, - antd 'red' => meaning submitted but pending attachments
  // 'PARTIALLY SUBMITTED' => 7, - antd 'blue' => chosen incomplete submission form modal

  // Check status number and return label and color
  switch (status) {
    case 1:
      return { label: "Created", color: "#764FA0", value: 1 };
    case 2:
      return { label: "On Going", color: "#4154A4", value: 2 };
    case 3:
      return { label: "Hold", color: "#F5B629", value: 3 };
    case 4:
      return { label: "Finished", color: "green", value: 4 };
    case 5:
      return { label: "Canceled", color: "#C82128", value: 5 };
    case 6:
      return { label: "Submitted", color: "red", value: 6 };
    case 7:
      return { label: "Partially Submitted", color: "blue", value: 7 };
    default:
      return { label: "N/A", color: "", value: -1 };
  }
};

// Open Trip Update Modal  // applicationOfCurrentTrip is optional and for showing all trip marker and verifier in map where necessary - E.g View Profile Map
const openTripUpdateModal = (selectedTrip, otherMultipleTrip) => {
  return (dispatch) => {
    // Check if selected trip is not valid
    if (!selectedTrip) {
      // Show alert
      showAlert("error", "Selected Trip is not valid");
      return;
    }
    //if otherMultipleTrip exist and must be an array
    if (otherMultipleTrip && Array.isArray(otherMultipleTrip)) {
      // storing all other trips except the selectedTrip
      dispatch(setSelectedMultipleTripToUpdate(otherMultipleTrip));

      // Open Trip Update Modal
      dispatch(setIsUpdateTripModalOpen(true));
      return;
    }

    // Set selected trip
    dispatch(setSelectedTripToUpdate(selectedTrip));

    // Open Trip Update Modal
    dispatch(setIsUpdateTripModalOpen(true));
  };
};

// Get ReverseGeo Data
const getReverseGeoData = (params) => {
  return async (dispatch) => {
    try {
      const response = await axios.get(COMMON.GET_REVERSE_GEOCODE_ADDRESS, {
        params,
      });
      // Check if response is success
      if (response?.data?.status === 200) {
        const data = response?.data?.place;
        return data;
      } else {
        // Show alert
        showAlert(
          "error",
          response?.data?.message ?? "Error Getting Reverse Geo Data"
        );
        return;
      }
    } catch (error) {
      console.error(error);

      // Show alert
      showAlert(
        "error",
        error?.response?.data?.message ??
        error?.message ??
        "Something went wrong"
      );
    }
  };
};

// Update Trip Data
const updateTrip = (
  params,
  type,
  type_details,
  previousTaskMapFilterParams
) => {
  return async (dispatch) => {
    // Set isUpdateTripButtonLoading to true
    dispatch(setIsUpdateTripButtonLoading(true));
    try {
      const response = await axios.put(
        COMMON.UPDATE_TRIP + type_details.id,
        params
      );
      // Set isUpdateTripButtonLoading to false
      dispatch(setIsUpdateTripButtonLoading(false));
      // Check if response is success
      if (response?.data?.status === 200) {
        // Show alert
        showAlert(
          "success",
          response?.data?.data ??
          response?.data?.message ??
          "Trip Data Updated Successfully"
        );
        // Close Trip Update Modal
        // dispatch(setIsUpdateTripModalOpen(false))
        // Check if type is 'application' and type_details id is valid
        if (type === "application" && type_details?.application_id) {
          // Get Application Details
          dispatch(getApplicationDetails(type_details?.application_id));
        }

        // Check if type is 'trip' and type_details id is valid
        if (type === "trip" && type_details?.id) {
          // Get Trip Details
          dispatch(getTripDetails(type_details?.id, type_details?.index));
        }

        // if no filter is given/necessary. e.g -> in trip details map no filter param exists or required
        if (
          previousTaskMapFilterParams?.dateTill ||
          previousTaskMapFilterParams?.dateFrom
        ) {
          // Get updated trip
          dispatch(getTripList(previousTaskMapFilterParams));

          // Get updated appointment
          dispatch(getAppointmentList(previousTaskMapFilterParams));
        }

        dispatch(setSelectedTripToUpdate(type_details));
        dispatch(setSelectedTrip(type_details));

        return;
      } else {
        // Show alert
        showAlert(
          "error",
          response?.data?.message ?? "Error Updating Trip Data"
        );
        return;
      }
    } catch (error) {
      console.error(error);

      // Set isUpdateTripButtonLoading to false
      dispatch(setIsUpdateTripButtonLoading(false));

      // Show alert
      showAlert(
        "error",
        error?.response?.data?.message ??
        error?.message ??
        "Something went wrong"
      );
    }
  };
};

// Get Concern Label by Concern Type
const getConcernLabelByConcernType = (concern_type) => {
  switch (concern_type) {
    case "APPLICANT":
      return "Applicant";
    case "CO_APPLICANT":
      return "Co-Applicant";
    case "GUARANTOR":
      return "Guarantor";
    default:
      return "N/A";
  }
};

// Get Finished Trips
const getFinishedTrips = (trips) => {
  // Check if trips is not valid and length is 0
  if (!trips || !trips.length) {
    return [];
  }

  // Filter Trips Where Status is 5 aka finished
  const finishedTrips = trips.filter((trip) => trip.status === 5);
  return finishedTrips;
};

// Inactivate trip
const cancelTrip = async (tripID) => {
  return await axios
    .post(TRIPS.CANCEL_TRIP + `/${tripID}/status?status=${6}`)
    .then((res) => {
      if (res.status === 200) {
        return res;
      }
    })
    .catch((err) => {
      showAlert("error", err?.message ?? "Action Failed");
      console.error(err);
    });
};

// Fore trip finish
const foreceTripFinish = async (tripID) => {
  return await axios
    .post(TRIPS.CANCEL_TRIP + `/${tripID}/status?status=${5}`)
    .then((res) => {
      if (res.status !== 200) {
        return [];
      }
      return res;
    })
    .catch((err) => {
      showAlert("error", err?.message ?? "Cannot finish the trip");
      console.error(err);
    });
};

// Get Summary
const getAllSummary = ({ dateFrom, dateTill, city }) => {
  return async (dispatch) => {
    axios
      .get(
        `${COMMON.GET_SUMMERY}?dateFrom=${dateFrom}&dateTill=${dateTill}&city=${city}`
      )
      .then((res) => {
        if (res.status === 200) {
          dispatch(setTaskMapSummary(res?.data?.data));
        }
      })
      .catch((err) => {
        showAlert("error", err?.message ?? "Failed to load summary");
        console.error(err);
      });
  };
};

// Get Notifications
const getNotifications = () => {
  return async (dispatch) => {
    axios
      .get(`${COMMON.GET_NOTIFICATIONS}`)
      .then((res) => {
        if (res.status === 200) {
          let notifications = res?.data;
          // let totalNotifications =  notificationCount(notifications)
          // dispatch(setNewNotificationCount(totalNotifications))

          dispatch(setNotifications(notifications));
          dispatch(activateNotifySocketConnection());

          // //  set notification count
          // let totalNotifications =  notificationCount(notifications)
          // dispatch(setNewNotificationCount(totalNotifications))
        }
      })
      .catch((err) => {
        showAlert("error", err?.message ?? "Failed to load notification");
        console.error(err);
      });
  };
};

// Activate Notification Socket Connection
const activateNotifySocketConnection = () => {
  return (dispatch, getState) => {
    // Config Pusher
    window.pusher = new Pusher(
      COMMON.NOTIFICATION_SOCKET.CHANNEL.appKey,
      COMMON.NOTIFICATION_SOCKET.CONFIG
    );
      // console.log(window.pusher, 'notification')
    // Pusher.log = function(message) {
    //     if (window.console && window.console.log) {
    //       window.console.log(message);
    //     }
    //   };
    // Subscribe to channel
    window.pusher
      .subscribe(COMMON.NOTIFICATION_SOCKET.CHANNEL.channel)
      .bind(COMMON.NOTIFICATION_SOCKET.CHANNEL.event, (data) => {
        //  get previous notifications list [..]
        const { notifications } = getState().common;
        // console.log("Socket Notifications", data);

        // console.log("Socket Notification", data);

        let newNotification = data;

        showAlert("success", newNotification?.message);
        showAlert("success", newNotification?.message ?? "New Notification");

        let newNotifications = notifications;
        //  adding new notification to old list[]
        newNotifications = [newNotification, ...newNotifications];

        //  set notification count
        let totalNotifications = notificationCount(newNotifications);
        dispatch(setNewNotificationCount(totalNotifications));

        dispatch(setNotifications(newNotifications));
      });
  };
};

//  set new notification count
const notificationCount = (newNotifications) => {
  let count = 0;
  //  checking each group if there is any new notifications
  //  setting counter
  newNotifications?.map((item) => {
    if (item?.notifications.length > 0) {
      let totalNotificationNo = item?.notifications.length;
      count = count + totalNotificationNo;
    }
  });
  // console.log("count", count);
  return count;
};

// Deactivate Notification Socket
const deactivateNotifySocket = () => {
  return () => {
    if (window.pusher) {
      window.pusher.unsubscribe(COMMON.NOTIFICATION_SOCKET.CHANNEL.channel);
      delete window.pusher;
    }
  };
};

export {
  getDateTimeDuration,
  getAllSummary,
  cancelTrip,
  getOrgList,
  getVerifiers,
  getUsers,
  getFillColorVerifiersLocation,
  getFillColorTripStatus,
  showAlert,
  getTripTypeStringAndFilledIcon,
  getTripStatusLabelAndColor,
  getVerifierStatusLabelAndColor,
  formatDateIntoFullDateAndTime,
  getAttachmentTypeLabel,
  getRupantorAddress,
  getAutoCompleteAddress,
  assignVerifier,
  getApplicationStatus,
  openTripUpdateModal,
  updateTrip,
  getReverseGeoData,
  getConcernLabelByConcernType,
  getFinishedTrips,
  foreceTripFinish,
  getNotifications,
  activateNotifySocketConnection,
  deactivateNotifySocket,
};
