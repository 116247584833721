import axios from 'axios'

// Import Types
import { RECPV } from '../../Config/config'

// Import Actions
import { setIsTrackerTableDataLoading, setTrackerTableData, setFiles, setIsFilesLoading, setSelectedFile, setIsSelectedFileLoading, setIsReCPVTracksReportDownloading } from '../Reducers/recpvReducer'
import { formatDateIntoFullDateAndTime, showAlert } from '../Actions/commonActions'

// Get ReCPV Tracker Table Data
const getRecpvTrackerTableData = params => {
    return async dispatch => {
        // Set Tracker Table Data Loading
        dispatch(setIsTrackerTableDataLoading(true))
        try {
            const response = await axios.get(RECPV.GET_RECPV_TRACKER_DATA, { params })
            const data = response?.data?.data?.re_cpv_tracks            

            // Check if data is empty or not
            if (!data || data.length === 0) {                

                // Set Tracker Table Data to empty array
                dispatch(setTrackerTableData([]))

                // Set Tracker Table Data Loading to false
                dispatch(setIsTrackerTableDataLoading(false))

                // Throw error
                throw new Error('No data found.')
            }

            // Transform Data
            const transformedData = data?.map(item => ({
                key: item.id,
                applicant_name: item?.application?.applicant?.name ?? 'N/A',
                file_no: item?.application?.application_id ?? 'N/A',
                date: item?.created_at ? formatDateIntoFullDateAndTime(item?.created_at) : 'N/A',
                trips: item?.trips?.length > 0 ? item?.trips?.map(trip => trip?.id).join(', ') : '',
                billing_type: item?.type ?? 'N/A',
                reason: item?.reason ?? 'N/A',
            }))
            
            // Set Tracker Table Data
            dispatch(setTrackerTableData(transformedData ?? []))

            // Set Tracker Table Data Loading
            dispatch(setIsTrackerTableDataLoading(false))
        } catch (error) {
            console.error(error)

            // Set Tracker Table Data
            dispatch(setTrackerTableData([]))

            // Set Tracker Table Data Loading
            dispatch(setIsTrackerTableDataLoading(false))

            // Show Alert
            showAlert('error', error?.response?.data?.message ?? error?.message ?? 'Something went wrong.')
        }
    }
}

// Search Application
const searchApplication = params => {
    return async dispatch => {
        // Set Files Loading to true
        dispatch(setIsFilesLoading(true))
        try {
            const response = await axios.get(RECPV.SEARCH, { params })
            const data = response?.data?.data?.applications?.data ?? []

            // Check if data is empty or not
            if (!data || data.length === 0) {
                // Set Files to empty array
                dispatch(setFiles([]))

                // Set Files Loading to false
                dispatch(setIsFilesLoading(false))

                // Throw error
                throw new Error('No data found.')
            }

            // Set Files
            dispatch(setFiles(data))

            // Set Files Loading to false
            dispatch(setIsFilesLoading(false))

        } catch (error) {
            console.error(error)

            // Set Files Loading to false
            dispatch(setIsFilesLoading(false))

            // Show Alert
            showAlert('error', error?.response?.data?.message ?? error?.message ?? 'Something went wrong.')
        }
    }
}

// Get Application Details
const getApplicationDetails = id => {
    return async dispatch => {
        // Set Files Loading to true
        dispatch(setIsSelectedFileLoading(true))
        try {
            const response = await axios.get(RECPV.GET_APPLICATIONS_DETAILS + id)
            const data = response?.data?.data?.application

            // Check if data is empty or not
            if (!data) {
                // Show Alert                
                showAlert('error', 'No data found.')

                // Set Files to empty array
                dispatch(setSelectedFile({}))

                // Set Files Loading to false
                dispatch(setIsSelectedFileLoading(false))

                // Throw error
                throw new Error('No data found.')
            }

            // Set Files
            dispatch(setSelectedFile(data))

            // Set Files Loading to false
            dispatch(setIsSelectedFileLoading(false))

        } catch (error) {
            console.error(error)

            // Set Files Loading to false
            dispatch(setIsSelectedFileLoading(false))

            // Show Alert
            showAlert('error', error?.response?.data?.message ?? error?.message ?? 'Something went wrong.')
        }
    }
}

// Download ReCPV Tracks Report
const downloadRecpvTracksReport = ( params ) => {    
    return async dispatch => {
        // Set is loading to true
        dispatch(setIsReCPVTracksReportDownloading(true))
        try {
            const response = await axios.get(RECPV.DOWNLOAD_RECPV_TRACKS_REPORT,{ params , responseType: 'blob'})
            // Set is loading to false
            dispatch(setIsReCPVTracksReportDownloading(false))
            if (response.status === 200) {

                const blob = new Blob([response.data], { type: 'application/vnd.ms-excel'});
                const link = document.createElement('a')
                link.href = window.URL.createObjectURL(blob)
                link.download = `Recpv_tracks_${params.dateFrom}_${params.dataTill}.xlsx`
                link.click()
            }
            else{
                showAlert('error', 'Download Failed.')
            }
        } catch(error) {
            console.error(error)
            showAlert('error', error?.message ?? 'Download Failed')
            // Set is loading to false
            dispatch(setIsReCPVTracksReportDownloading(false))            
        }
    }
}

const markTripAsRecpv = async (tripID, params) => {
    axios.post( `${ RECPV.MARK_TRIP_AS_RECPV }/${ tripID }/mark-as-re-cpv`, params)
    .then(res => {
        if(res?.status === 200){
            return res?.data?.data 
        }
    })
    .catch(err => {

    })
}

export { getRecpvTrackerTableData, searchApplication, getApplicationDetails, downloadRecpvTracksReport, markTripAsRecpv }