// Import Layers
import { CompositeLayer, IconLayer, TextLayer } from 'deck.gl'

// Import Actions
import { getIdFromLayerProperties, getIconBaseOnStatus } from '../../Services/Actions/taskMapActions'

class LabeledIconLayer extends CompositeLayer {
    renderLayers() {
        const { data } = this.props

        return [
            new IconLayer({
                id: 'icon-layer',
                data,
                pickable: true,
                sizeScale: 8,
                getPosition: d => d.geometry.coordinates,
                getSize: () => 4,
                getIcon: d => getIconBaseOnStatus(d.properties),
            }),
            new TextLayer({
                id: 'icon-text-layer',
                data,
                pickable: true,
                getPosition: d => d.geometry.coordinates,
                getText: d => getIdFromLayerProperties(d.properties),
                getSize: 12,
                getAngle: 0,
                getTextAnchor: 'middle',
                getAlignmentBaseline: 'top',
                getPixelOffset: [ 0, 0 ],
                getColor: [0, 0, 0, 255],
                maxWidth: 970
            }),
        ]
    }
}

LabeledIconLayer.layerName = 'LabeledIconLayer'

export default LabeledIconLayer