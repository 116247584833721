import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    isLoading: false,
    isDateOrgFilterLoading: false,
    orgList: [],
    verifiers: [],
    users: [],
    isAssignLoading: false,
    isUpdateTripModalOpen: false,
    shouldRenderMapTripUpdate: true,
    isUpdateTripButtonLoading: false,
    selectedTripToUpdate: {}, // for plotting single trip and identify the selected trip when multiple trip exist on map
    selectedMultipleTripToUpdate: [], // for plotting multiple trips
    markerDataTripUpdate: [],
    multipleMarkerDataTripUpdate: [],
    taskMapSummary: {},
    notifications:[],
    newNotificationCount: 0,
    navMenuItems: [],
}

const commonSlice = createSlice({
    name: 'common',
    initialState,
    reducers: {
        setIsLoading: (state, action) => {
            state.isLoading = action.payload
        },
        setIsDateOrgFilterLoading: (state, action) => {
            state.isDateOrgFilterLoading = action.payload
        },
        setOrgList: (state, action) => {
            state.orgList = action.payload
        },
        setVerifiers: (state, action) => {
            state.verifiers = action.payload
        },
        setUsers: (state, action) => {
            state.users = action.payload
        },
        setIsAssignLoading: (state, action) => {
            state.isAssignLoading = action.payload
        },
        setIsUpdateTripModalOpen: (state, action) => {
            state.isUpdateTripModalOpen = action.payload
        },
        setIsUpdateTripButtonLoading: (state, action) => {
            state.isUpdateTripButtonLoading = action.payload
        },
        setSelectedTripToUpdate: (state, action) => {
            state.selectedTripToUpdate = action.payload
        },
        setSelectedMultipleTripToUpdate: (state, action) => {
            state.selectedMultipleTripToUpdate = action.payload
        },
        setMarkerDataTripUpdate: (state, action) => {
            state.markerDataTripUpdate = action.payload
        },
        setMultipleMarkerDataTripUpdate: (state, action) => {
            state.multipleMarkerDataTripUpdate = action.payload
        },
        setTaskMapSummary: (state, action) => {
            state.taskMapSummary = action.payload
        },
        setNotifications: (state, action) => {
            state.notifications = action.payload
        },
        setNewNotificationCount: (state, action) => {
            state.newNotificationCount = action.payload
        },
        setNavMenuItems: (state, action) => {
            state.navMenuItems = action.payload
        },
        
    }
})

export const { setMultipleMarkerDataTripUpdate, setSelectedMultipleTripToUpdate, setTaskMapSummary, setIsLoading, setIsDateOrgFilterLoading, setOrgList, setVerifiers, setUsers, setIsAssignLoading, setIsUpdateTripModalOpen, setIsUpdateTripButtonLoading, setSelectedTripToUpdate, setMarkerDataTripUpdate, setNotifications, setNewNotificationCount, setNavMenuItems } = commonSlice.actions
export default commonSlice.reducer