import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    isAuthenticated: false,
    user: null,
    isValidating: true,
    error: null,
    token: 'null',
    isLoading: false,
    responseData:{
        msg: null,
        error: null,
    }
}

const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        setIsAuthenticated: (state, action) => {
            state.isAuthenticated = action.payload
        },
        setIsValidating: (state, action) => {
            state.isValidating = action.payload
        },
        setToken: (state, action) => {
            state.token = action.payload
        },
        setUser: (state, action) => {
            state.user = action.payload
        },
        setError: (state, action) => {
            state.error = action.payload
        },
        setIsLoading: (state, action) => {
            state.isLoading = action.payload
        },
        setResponseData: (state, action) => {
            state.responseData = action.payload
        },    
    }
})

export const { setIsAuthenticated, setIsValidating, setToken, setUser, setError, setIsLoading, setResponseData } = authSlice.actions
export default authSlice.reducer