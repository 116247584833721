// Import Layers
import { CompositeLayer, ScatterplotLayer, TextLayer } from 'deck.gl'

// Import Actions
import { getFillColorVerifiersLocation } from '../../Services/Actions/commonActions'

class LabeledPointLayer extends CompositeLayer {
    renderLayers() {
        const { data } = this.props
        return [
            new ScatterplotLayer({
                id: 'scatterplot-layer',
                data,
                pickable: true,
                opacity: 0.8,
                stroked: false,
                filled: true,
                radiusUnits: 'pixels',
                radiusScale: 7,
                radiusMinPixels: 1,
                radiusMaxPixels: 100,
                lineWidthMinPixels: 1,
                getPosition: d => d.geometry.coordinates,
                getRadius: 1,
                getFillColor: d => getFillColorVerifiersLocation(d?.properties?.state),
                // getFillColor: d => getFillColorVerifiersLocation(d?.properties?.state && d?.properties?.state),
            }),
            new TextLayer({
                id: 'text-layer',
                data,
                pickable: true,
                getPosition: d => d.geometry.coordinates,
                getText: d => d.properties.name,
                getColor: [0, 0, 0, 255],
                getSize: 10.5,
                getAngle: 0,
                getPixelOffset: [ 8, 0 ],
                getTextAnchor: () => 'start',
                getAlignmentBaseline: 'center',
                fontFamily: 'Lato, Roboto, monospace',
                fontWeight: 'bold',
                transitions: { getPosition: { duration: 100 } },
            }),
        ]
    }
}

LabeledPointLayer.layerName = 'LabeledPointLayer'

export default LabeledPointLayer