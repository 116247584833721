import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import moment from "moment";
import * as XLSX from "xlsx";

// Import Components
import { Button, Card, Space } from "antd";
import FilterWithDateOrg from "../Components/Layouts/FilterWithDateOrg";
import BasicTable from "../Components/Layouts/BasicTable";

// Import Actions
import {
  getApplicationStatistics,
  getTripsStatistics,
  getTripsStatisticsByType,
  getIssuesStatistics,
  getRemarksStatistics,
  getAgentAllocatedTripsStatistics,
  getAgentRaisedIssuesStatistics,
} from "../Services/Actions/analyticsActions";
import { isAllowedToAccess } from "../Services/Actions/permissionActions";

class Analytics extends React.PureComponent {
  state = {
    appStatisticsColumns: [
      {
        title: "Total Applications",
        dataIndex: "total_applications",
        key: "total_applications",
      },
      {
        title: "Running Applications",
        dataIndex: "running_applications",
        key: "running_applications",
      },
      {
        title: "Finished Applications",
        dataIndex: "finished_applications",
        key: "finished_applications",
      },
      {
        title: "Loan Applications",
        dataIndex: "loan_applications",
        key: "loan_applications",
      },
      {
        title: "Card Applications",
        dataIndex: "card_applications",
        key: "card_applications",
      },
      {
        title: "Finished In One Go",
        dataIndex: "finished_in_one_go",
        key: "finished_in_one_go",
      },
      {
        title: "Finish Percentage",
        dataIndex: "finished_percentage",
        key: "finish_percentage",
      },
    ],
    tripsStatisticsColumns: [
      {
        title: "Total Trips",
        dataIndex: "total_trips",
        key: "total_trips",
      },
      {
        title: "Running Trips",
        dataIndex: "running_trips",
        key: "running_trips",
      },
      {
        title: "Not Accepted Trips",
        dataIndex: "not_accepted_trips",
        key: "not_accepted_trips",
      },
      {
        title: "Finished Trips",
        dataIndex: "finished_trips",
        key: "finished_trips",
      },
      {
        title: "Finished In One Go",
        dataIndex: "finished_in_one_go_trips",
        key: "finished_in_one_go_trips",
      },
      {
        title: "Finish Percentage",
        dataIndex: "finish_percentage",
        key: "finish_percentage",
      },
    ],
    tripsStatisticsByTypeColumns: [
      {
        title: "Trip Type",
        dataIndex: "trip_type",
        key: "trip_type",
      },
      {
        title: "Total Trips",
        dataIndex: "total_trips",
        key: "total_trips",
      },
    ],
    issuesStatisticsColumns: [
      {
        title: "Issue",
        dataIndex: "issue",
        key: "issue",
      },
      {
        title: "Total",
        dataIndex: "total",
        key: "total",
      },
    ],
    remarksStatisticsColumns: [
      {
        title: "Trip ID",
        dataIndex: "id",
        key: "id",
      },
      {
        title: "Trip Type",
        dataIndex: "trip_type",
        key: "trip_type",
      },
      {
        title: "Remarks",
        dataIndex: "remarks",
        key: "remarks",
      },
      {
        title: "Wrote By",
        dataIndex: "name",
        key: "name",
      },
    ],
    agentAllocatedTripsStatisticsColumns: [
      {
        title: "Agent",
        dataIndex: "name",
        key: "name",
      },
      {
        title: "House",
        dataIndex: "trips",
        key: "house_trips",
        render: (trips) => {
          return trips.find((trip) => trip.type === "HOUSE")?.total_allocated;
        },
      },
      {
        title: "Office",
        dataIndex: "trips",
        key: "office_trips",
        render: (trips) => {
          return trips.find((trip) => trip.type === "OFFICE")?.total_allocated;
        },
      },
      {
        title: "Bank",
        dataIndex: "trips",
        key: "bank_trips",
        render: (trips) => {
          return trips.find((trip) => trip.type === "BANK")?.total_allocated;
        },
      },
      {
        title: "PG House",
        dataIndex: "trips",
        key: "pg_house_trips",
        render: (trips) => {
          return trips.find((trip) => trip.type === "GUARANTOR_HOUSE")?.total_allocated;
        },
      },
      {
        title: "PG Office",
        dataIndex: "trips",
        key: "pg_office_trips",
        render: (trips) => {
          return trips.find((trip) => trip.type === "GUARANTOR_OFFICE")?.total_allocated;
        },
      },
      {
        title: "Car Quotation",
        dataIndex: "trips",
        key: "car_q_trips",
        render: (trips) => {
          return trips.find((trip) => trip.type === "CAR_QUOTATION")?.total_allocated;
        },
      },
      {
        title: "Document",
        dataIndex: "trips",
        key: "document_trips",
        render: (trips) => {
          return trips.find((trip) => trip.type === "DOCUMENT")?.total_allocated;
        },
      },
      {
        title: "Rental",
        dataIndex: "trips",
        key: "rental_trips",
        render: (trips) => {
          return trips.find((trip) => trip.type === "RENTAL")?.total_allocated;
        },
      },
      {
        title: "Total Trips",
        dataIndex: "total_allocated",
        key: "total_allocated",
      },
    ],
    agentFinishedTripsStatisticsColumns: [
      {
        title: "Agent",
        dataIndex: "name",
        key: "name",
      },
      {
        title: "House",
        dataIndex: "trips",
        key: "house_trips",
        render: (trips) => {
          return trips.find((trip) => trip.type === "HOUSE")?.total_finished;
        },
      },
      {
        title: "Office",
        dataIndex: "trips",
        key: "office_trips",
        render: (trips) => {
          return trips.find((trip) => trip.type === "OFFICE")?.total_finished;
        },
      },
      {
        title: "Bank",
        dataIndex: "trips",
        key: "bank_trips",
        render: (trips) => {
          return trips.find((trip) => trip.type === "BANK")?.total_finished;
        },
      },
      {
        title: "PG House",
        dataIndex: "trips",
        key: "pg_house_trips",
        render: (trips) => {
          return trips.find((trip) => trip.type === "GUARANTOR_HOUSE")?.total_finished;
        },
      },
      {
        title: "PG Office",
        dataIndex: "trips",
        key: "pg_office_trips",
        render: (trips) => {
          return trips.find((trip) => trip.type === "GUARANTOR_OFFICE")?.total_finished;
        },
      },
      {
        title: "Car Quotation",
        dataIndex: "trips",
        key: "car_q_trips",
        render: (trips) => {
          return trips.find((trip) => trip.type === "CAR_QUOTATION")?.total_finished;
        },
      },
      {
        title: "Document",
        dataIndex: "trips",
        key: "document_trips",
        render: (trips) => {
          return trips.find((trip) => trip.type === "DOCUMENT")?.total_finished;
        },
      },
      {
        title: "Rental",
        dataIndex: "trips",
        key: "rental_trips",
        render: (trips) => {
          return trips.find((trip) => trip.type === "RENTAL")?.total_finished;
        },
      },
      {
        title: "Total Trips",
        dataIndex: "total_finished",
        key: "total_finished",
      },
    ],
    agentRaisedIssuesStatisticsColumns: [
      {
        title: "Agent",
        dataIndex: "name",
        key: "name",
      },
      {
        title: "Total",
        dataIndex: "total",
        key: "total",
      },
    ],
  };

  // Handle Download Agent Allocated Stats
  _handleDownloadAgentAllocatedStats = () => {
    const { agentAllocatedTripsStatistics } = this.props;

    // Binary String To Array Buffer
    const binaryStringToArrayBuffer = (binaryString) => {
      const buffer = new ArrayBuffer(binaryString.length);
      const view = new Uint8Array(buffer);
      for (let i = 0; i < binaryString.length; i++) {
        view[i] = binaryString.charCodeAt(i) & 0xff;
      }
      return buffer;
    };

    const generatedData = [];
    agentAllocatedTripsStatistics?.statistics?.forEach((item, index) => {
      generatedData.push({
        SL: index + 1,
        Agent: item.name,
        House: item.trips.find((trip) => trip.type === "HOUSE")?.total_allocated || 0,
        Office: item.trips.find((trip) => trip.type === "OFFICE")?.total_allocated || 0,
        Bank: item.trips.find((trip) => trip.type === "BANK")?.total_allocated || 0,
        "PG House":
          item.trips.find((trip) => trip.type === "GUARANTOR_HOUSE")?.total_allocated || 0,
        "PG Office":
          item.trips.find((trip) => trip.type === "GUARANTOR_OFFICE")?.total_allocated || 0,
        "Car Quotation":
          item.trips.find((trip) => trip.type === "CAR_QUOTATION")?.total_allocated || 0,
        Document:
          item.trips.find((trip) => trip.type === "DOCUMENT")?.total_allocated || 0,
        Rental: item.trips.find((trip) => trip.type === "RENTAL")?.total_allocated || 0,
        "Total Allocated Trips": item.total_allocated,
      });
    });

    // Define Workbook & Worksheet
    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.json_to_sheet(generatedData);
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

    // Generate a data array for the Blob
    const blobData = XLSX.write(workbook, { bookType: "xlsx", type: "binary" });

    // Convert the binary string to a Blob
    const blob = new Blob([binaryStringToArrayBuffer(blobData)], {
      type: "application/octet-stream",
    });

    // Create a URL for the Blob data
    const url = window.URL.createObjectURL(blob);

    // Generate a timestamp for the file name
    const timestamp = Date.now();

    // Create a download link and trigger the download
    const a = document.createElement("a");
    a.href = url;
    a.download = `Agent_Trips_Allocation_Statistics_${timestamp}.xlsx`;
    a.click();
    window.URL.revokeObjectURL(url);
  };

  // Handle Download Agent Finished Stats
  _handleDownloadAgentFinishedStats = () => {
    const { agentAllocatedTripsStatistics } = this.props;

    // Binary String To Array Buffer
    const binaryStringToArrayBuffer = (binaryString) => {
      const buffer = new ArrayBuffer(binaryString.length);
      const view = new Uint8Array(buffer);
      for (let i = 0; i < binaryString.length; i++) {
        view[i] = binaryString.charCodeAt(i) & 0xff;
      }
      return buffer;
    };

    const generatedData = [];
    agentAllocatedTripsStatistics?.statistics?.forEach((item, index) => {
      generatedData.push({
        SL: index + 1,
        Agent: item.name,
        House: item.trips.find((trip) => trip.type === "HOUSE")?.total_finished || 0,
        Office: item.trips.find((trip) => trip.type === "OFFICE")?.total_finished || 0,
        Bank: item.trips.find((trip) => trip.type === "BANK")?.total_finished || 0,
        "PG House":
          item.trips.find((trip) => trip.type === "GUARANTOR_HOUSE")?.total_finished || 0,
        "PG Office":
          item.trips.find((trip) => trip.type === "GUARANTOR_OFFICE")?.total_finished || 0,
        "Car Quotation":
          item.trips.find((trip) => trip.type === "CAR_QUOTATION")?.total_finished || 0,
        Document:
          item.trips.find((trip) => trip.type === "DOCUMENT")?.total_finished || 0,
        Rental: item.trips.find((trip) => trip.type === "RENTAL")?.total_finished || 0,
        "Total Finished Trips": item.total_finished,
      });
    });

    // Define Workbook & Worksheet
    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.json_to_sheet(generatedData);
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

    // Generate a data array for the Blob
    const blobData = XLSX.write(workbook, { bookType: "xlsx", type: "binary" });

    // Convert the binary string to a Blob
    const blob = new Blob([binaryStringToArrayBuffer(blobData)], {
      type: "application/octet-stream",
    });

    // Create a URL for the Blob data
    const url = window.URL.createObjectURL(blob);

    // Generate a timestamp for the file name
    const timestamp = Date.now();

    // Create a download link and trigger the download
    const a = document.createElement("a");
    a.href = url;
    a.download = `Agent_Trips_Finished_Statistics_${timestamp}.xlsx`;
    a.click();
    window.URL.revokeObjectURL(url);
  };

  // Handle Submit
  _handleSubmit = (startDate, endDate, selectedGroup, selectedCity) => {
    const { dispatch } = this.props;

    const formatedStartDate = startDate
      ? moment(startDate).format("YYYY-MM-DD")
      : moment(new Date()).format("YYYY-MM-DD");
    const formatedEndDate = endDate
      ? moment(endDate).format("YYYY-MM-DD")
      : moment(new Date()).format("YYYY-MM-DD");

    // set params
    let params = { dateFrom: formatedStartDate, dateTill: formatedEndDate };
    if (selectedGroup && selectedGroup?.uuid !== -1) {
      params = { ...params, org_id: selectedGroup.uuid };
    }

    if (selectedCity && selectedCity?.id !== -1) {
      params = { ...params, city: selectedCity.value };
    }

    dispatch(getApplicationStatistics(params));
    dispatch(getTripsStatistics(params));
    dispatch(getTripsStatisticsByType(params));
    dispatch(getIssuesStatistics(params));
    dispatch(getRemarksStatistics(params));
    dispatch(getAgentAllocatedTripsStatistics(params));
    dispatch(getAgentRaisedIssuesStatistics(params));
  };
  render() {
    const {
      appStatisticsColumns,
      tripsStatisticsColumns,
      tripsStatisticsByTypeColumns,
      issuesStatisticsColumns,
      remarksStatisticsColumns,
      agentAllocatedTripsStatisticsColumns,
      agentFinishedTripsStatisticsColumns,
      agentRaisedIssuesStatisticsColumns,
    } = this.state;
    const {
      appStatistics,
      tripsStatistics,
      tripsStatisticsByType,
      issuesStatistics,
      remarksStatistics,
      agentAllocatedTripsStatistics,
      agentRaisedIssuesStatistics,
      allowedPermission,
    } = this.props;

    return (
      <Card title={"Analytics"} hoverable>
        <Space direction={"vertical"} size={"middle"} style={{ width: "100%" }}>
          <FilterWithDateOrg
            showSearch={false}
            onSubmit={this._handleSubmit}
            showDate={isAllowedToAccess(allowedPermission, {
              menuName: "admin",
              subMenuName: "analytics",
              permissionID: "53",
            })}
            showOrg={isAllowedToAccess(allowedPermission, {
              menuName: "admin",
              subMenuName: "analytics",
              permissionID: "54",
            })}
            showCity={isAllowedToAccess(allowedPermission, {
              menuName: "admin",
              subMenuName: "analytics",
              permissionID: "55",
            })}
            isSubmitButtonLoading={appStatistics.isLoading}
          />

          {
            // PERMISSION ID OF "admin.analytics.application" is 56
            isAllowedToAccess(allowedPermission, {
              menuName: "admin",
              subMenuName: "analytics",
              permissionID: "56",
            }) && (
              <Card
                title={"Application Statistics"}
                hoverable
                bodyStyle={{ padding: 0 }}
              >
                <BasicTable
                  column={appStatisticsColumns}
                  data={appStatistics.statistics}
                  isLoading={appStatistics.isLoading}
                  pagination={false}
                />
              </Card>
            )
          }

          {
            // PERMISSION ID OF "admin.analytics.trip" is 57
            isAllowedToAccess(allowedPermission, {
              menuName: "admin",
              subMenuName: "analytics",
              permissionID: "57",
            }) && (
              <Card
                title={"Trip Statistics"}
                hoverable
                bodyStyle={{ padding: 0 }}
              >
                <BasicTable
                  column={tripsStatisticsColumns}
                  data={tripsStatistics.statistics}
                  isLoading={tripsStatistics.isLoading}
                  pagination={false}
                />
              </Card>
            )
          }

          {
            // PERMISSION ID OF "admin.analytics.trip_by_type" is 58
            isAllowedToAccess(allowedPermission, {
              menuName: "admin",
              subMenuName: "analytics",
              permissionID: "58",
            }) && (
              <Card
                title={"Trip Statistics by Type"}
                hoverable
                bodyStyle={{ padding: 0 }}
              >
                <BasicTable
                  column={tripsStatisticsByTypeColumns}
                  data={tripsStatisticsByType.statistics}
                  isLoading={tripsStatisticsByType.isLoading}
                  pagination={false}
                />
              </Card>
            )
          }


          { // PERMISSION ID OF "admin.analytics.issue" is 59 and 60
          (isAllowedToAccess(allowedPermission, {
            menuName: "admin",
            subMenuName: "analytics",
            permissionID: "59",
          }) ||
            isAllowedToAccess(allowedPermission, {
              menuName: "admin",
              subMenuName: "analytics",
              permissionID: "60",
            })) && (
            <Card
              title={"Agent Statistics"}
              hoverable
              bodyStyle={{ paddingTop: 0 }}
            >
              <Space
                direction={"vertical"}
                size={"middle"}
                style={{ width: "100%" }}
              >
                {
                  // PERMISSION ID OF "admin.analytics.agent" is 59
                  isAllowedToAccess(allowedPermission, {
                    menuName: "admin",
                    subMenuName: "analytics",
                    permissionID: "59",
                  }) && (
                    <Card
                      title={
                        <div style={tripAllocatedTableHeaderStyles}>
                          <span>Trip Allocated</span>
                          {agentAllocatedTripsStatistics?.statistics?.length >
                            0 && (
                            <div style={agentsStatsDownloadButtonStyles}>
                              <Button
                                type="primary"
                                size="middle"
                                onClick={this._handleDownloadAgentAllocatedStats}
                              >
                                Download
                              </Button>
                            </div>
                          )}
                        </div>
                      }
                      bodyStyle={{ padding: 0 }}
                    >
                      <BasicTable
                        column={agentAllocatedTripsStatisticsColumns}
                        data={agentAllocatedTripsStatistics.statistics}
                        isLoading={agentAllocatedTripsStatistics.isLoading}
                        pagination={{
                          pageSize: 15,
                          showSizeChanger: false,
                          position: ["bottomCenter"],
                        }}
                      />
                    </Card>
                  )
                }
                {/* Trip Finished */}
                <Card
                      title={
                        <div style={tripAllocatedTableHeaderStyles}>
                          <span>Trip Finished</span>
                          {agentAllocatedTripsStatistics?.statistics?.length >
                            0 && (
                            <div style={agentsStatsDownloadButtonStyles}>
                              <Button
                                type="primary"
                                size="middle"
                                onClick={this._handleDownloadAgentFinishedStats}
                              >
                                Download
                              </Button>
                            </div>
                          )}
                        </div>
                      }
                      bodyStyle={{ padding: 0 }}
                    >
                      <BasicTable
                        column={agentFinishedTripsStatisticsColumns}
                        data={agentAllocatedTripsStatistics.statistics}
                        isLoading={agentAllocatedTripsStatistics.isLoading}
                        pagination={{
                          pageSize: 15,
                          showSizeChanger: false,
                          position: ["bottomCenter"],
                        }}
                      />
                    </Card>
                {
                  // PERMISSION ID OF "admin.analytics.agent" is 60
                  isAllowedToAccess(allowedPermission, {
                    menuName: "admin",
                    subMenuName: "analytics",
                    permissionID: "60",
                  }) && (
                    <Card
                      title={"Raised Issues Count"}
                      bodyStyle={{ padding: 0 }}
                    >
                      <BasicTable
                        column={agentRaisedIssuesStatisticsColumns}
                        data={agentRaisedIssuesStatistics.statistics}
                        isLoading={agentRaisedIssuesStatistics.isLoading}
                        pagination={{
                          pageSize: 15,
                          showSizeChanger: false,
                          position: ["bottomCenter"],
                        }}
                      />
                    </Card>
                  )
                }
              </Space>
            </Card>
          )}

          {
            // PERMISSION ID OF "admin.analytics.issue" is 61
            isAllowedToAccess(allowedPermission, {
              menuName: "admin",
              subMenuName: "analytics",
              permissionID: "61",
            }) && (
              <Card
                title={"Issues Statistics"}
                hoverable
                bodyStyle={{ padding: 0 }}
              >
                <BasicTable
                  column={issuesStatisticsColumns}
                  data={issuesStatistics.statistics}
                  isLoading={issuesStatistics.isLoading}
                  pagination={{
                    pageSize: 10,
                    showSizeChanger: false,
                    position: ["bottomCenter"],
                  }}
                />
              </Card>
            )
          }

          {
            // PERMISSION ID OF "admin.analytics.remarks" is 62
            isAllowedToAccess(allowedPermission, {
              menuName: "admin",
              subMenuName: "analytics",
              permissionID: "62",
            }) && (
              <Card
                title={"Remarks Statistics"}
                hoverable
                bodyStyle={{ padding: 0 }}
              >
                <BasicTable
                  column={remarksStatisticsColumns}
                  data={remarksStatistics.statistics}
                  isLoading={remarksStatistics.isLoading}
                  pagination={{
                    pageSize: 10,
                    showSizeChanger: false,
                    position: ["bottomCenter"],
                  }}
                />
              </Card>
            )
          }
        </Space>
      </Card>
    );
  }
}

// PropTypes
Analytics.propTypes = {
  appStatistics: PropTypes.object,
  tripsStatistics: PropTypes.object,
  tripsStatisticsByType: PropTypes.object,
  issuesStatistics: PropTypes.object,
  remarksStatistics: PropTypes.object,
  agentAllocatedTripsStatistics: PropTypes.object,
  agentRaisedIssuesStatistics: PropTypes.object,
  dispatch: PropTypes.func,
};

Analytics.defaultProps = {
  appStatistics: {},
  tripsStatistics: {},
  tripsStatisticsByType: {},
  issuesStatistics: {},
  remarksStatistics: {},
  agentAllocatedTripsStatistics: {},
  agentRaisedIssuesStatistics: {},
  dispatch: () => null,
  allowedPermission: null,
};

const mapStateToProps = (state) => ({
  appStatistics: state.analytics.appStatistics,
  tripsStatistics: state.analytics.tripsStatistics,
  tripsStatisticsByType: state.analytics.tripsStatisticsByType,
  issuesStatistics: state.analytics.issuesStatistics,
  remarksStatistics: state.analytics.remarksStatistics,
  agentAllocatedTripsStatistics: state.analytics.agentAllocatedTripsStatistics,
  agentRaisedIssuesStatistics: state.analytics.agentRaisedIssuesStatistics,
  allowedPermission: state?.permission?.allowedPermission ?? null,
});

const mapDispatchToProps = (dispatch) => ({ dispatch });

// Styles
const tripAllocatedTableHeaderStyles = {
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
};

const agentsStatsDownloadButtonStyles = {
  display: "flex",
  justifyContent: "flex-end",
  margin: "5px",
};

export default connect(mapStateToProps, mapDispatchToProps)(Analytics);
