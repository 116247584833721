import React, { useEffect, useState } from 'react';

// Import Icons
import { AiOutlineLeftCircle, AiOutlineRightCircle } from 'react-icons/ai';

// Import Components
import { Col, Row, Table, Tag, Typography, Button, Modal, Input } from 'antd';
import { AssignQuestion, AddQuestion } from './index'

// Import Actions, Methods, & Reducers
import { useDispatch, useSelector } from 'react-redux';
import { getQuestions } from '../../../Services/Actions/adminPanelActions';

// Import Constants
const { Title } = Typography;

// Define the columns for the left table
const columns = [
  {
    title: 'Questions',
    dataIndex: 'question',
    width: '70%',
  },
  {
    title: 'Field Type',
    dataIndex: 'field_type',
    width: '30%',
    align: 'center',
    render: (text) => (
      <div style={{ textAlign: 'center' }}>
        <Tag
          color={
            text === 'MCQ' ? '#4cc4b2' :
              text === 'SCQ' ? '#052e16' :
                '#969fff'}
        >
          {text}
        </Tag>
      </div>
    ),
  },
];


// Define the QuestionnaireAddQuestion component
const ListingQuestion = () => {
  // Get Value From Redux
  const { allQuestionList } = useSelector(state => state.adminPanel)

  // States
  const dispatch = useDispatch()
  // Table States
  const [leftTableData, setLeftTableData] = useState([]); // All Questions
  const [rightTableData, setRightTableData] = useState([]); // Selected Questions
  const [selectedRowsLeft, setSelectedRowsLeft] = useState([]); // State for selected rows in the left table
  const [selectedRowsRight, setSelectedRowsRight] = useState([]); // State for selected rows in the right table
  const [filteredLeftTableData, setFilteredLeftTableData] = useState([])
  const [filteredRightTableData, setFilteredRightTableData] = useState([])

  // Search Keyword
  const [searchKeywordLeft, setSearchKeywordLeft] = useState(''); // State for search keyword in the left table
  const [searchKeywordRight, setSearchKeywordRight] = useState(''); // State for search keyword in the right table

  // Modal States
  const [isModalOpen, setIsModalOpen] = useState(false); // State to control the "Add Question" modal
  const [isAssignModalVisible, setIsAssignModalVisible] = useState(false); // State to control the "Assign Questions" modal
  const [selectedQuestionsForAssign, setSelectedQuestionsForAssign] = useState(
    rightTableData.map((question) => ({ ...question, sort_order: 0 }))
  ); // State to manage questions selected for assignment with a sort order
  const [isAssignQuestions, setIsAssignQuestions] = useState(false)

  // Functions to control the "Add Question" modal
  const showModal = () => {
    setIsModalOpen(true);
  };


  // Handle Cancel Create Question Modal
  const _handleCancelCreateQuestionModal = () => {
    setIsModalOpen(false);
  };

  // Function to move selected rows from the left table to the right table
  const _handleRightMove = () => {
    // Update the right table data and remove selected rows from the left table
    const updatedRightTableData = [...rightTableData, ...selectedRowsLeft];
    setRightTableData(updatedRightTableData);
    setLeftTableData(leftTableData.filter((item) => !selectedRowsLeft.includes(item)));
    setSelectedRowsLeft([]);

    // Make sort_order = 0
    let sortOrderCollection = []
    updatedRightTableData.forEach((data) => sortOrderCollection.push({ ...data, sort_order: 0 }))

    // Update selected questions for assignment
    setSelectedQuestionsForAssign(sortOrderCollection);
  };

  // Function to show the "Assign Questions" modal
  const _showAssignModal = () => {
    setIsAssignModalVisible(true);
  };

  // Function to cancel the "Assign Questions" modal
  const _handleAssignModalCancel = () => {
    setIsAssignModalVisible(false);
  };

  // Function to move selected rows from the right table to the left table
  const _handleLeftMove = () => {
    // Update the left table data and remove selected rows from the right table
    setLeftTableData([...leftTableData, ...selectedRowsRight]);
    setRightTableData(rightTableData.filter((item) => !selectedRowsRight.includes(item)));
    setSelectedRowsRight([]); // Clear selected rows in the right table
  };

  // Row selection configuration for the left table
  const rowSelectionLeft = {
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedRowsLeft(selectedRows);
    },
    selectedRowKeys: selectedRowsLeft.map((row) => row.key), // Pass selected row keys
  };

  // Row selection configuration for the right table
  const rowSelectionRight = {
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedRowsRight(selectedRows);
    },
    selectedRowKeys: selectedRowsRight.map((row) => row.key), // Pass selected row keys for the right table
  };


  // On Change Left Side Keyword
  useEffect(() => {
    if (searchKeywordLeft) {
      const filteredData = leftTableData.filter(
        (item) =>
          item.question.toLowerCase().includes(searchKeywordLeft.toLowerCase()) ||
          item.field_type.toLowerCase() === searchKeywordLeft.toLowerCase()
      );
      setFilteredLeftTableData(filteredData)
    }
  }, [leftTableData, searchKeywordLeft])

  // On Change Right Side Keyword
  useEffect(() => {
    if (searchKeywordRight) {
      const filteredData = rightTableData.filter(
        (item) =>
          item.question.toLowerCase().includes(searchKeywordRight.toLowerCase()) ||
          item.field_type.toLowerCase() === searchKeywordRight.toLowerCase()
      );
      setFilteredRightTableData(filteredData)
    }
  }, [rightTableData, searchKeywordRight])


  useEffect(() => {
    // fetching all questions from API
    dispatch(getQuestions())
  }, [dispatch])

  // setting question to state whenever allQuestionList gets updated in reducer
  useEffect(() => {
    const modifiedQuestion = []

    allQuestionList?.questions?.forEach(question => {
      modifiedQuestion.push({
        id: question.id,
        key: question.key,
        field_type: question.field_type,
        question: question.question
      })
    })

    setLeftTableData(modifiedQuestion)

  }, [allQuestionList?.questions])

  // If Assign Question Reset Sorting Table
  useEffect(() => {
    if (isAssignQuestions) {
      const modifiedQuestion = []

      allQuestionList?.questions?.forEach(question => {
        modifiedQuestion.push({
          id: question.id,
          key: question.key,
          field_type: question.field_type,
          question: question.question
        })
      })

      setLeftTableData(modifiedQuestion)
      setRightTableData([])
      setIsAssignQuestions(false)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAssignQuestions])

  return (
    <div style={{ background: '#F6F6F6', padding: '24px 42px' }}>
      {/* '#F6F6F6' */}
      <Row style={{ background: '#fff' }}>
        <Col span={24}>
          <Title level={4} style={{ padding: '5px 5px 0px 5px', color: '#0067B1' }}>Assign Question To The Organization</Title>
        </Col>
        <Col xs={24} sm={24} md={24} lg={11} xl={11} style={{ border: '1px solid #D9D9D9' }}>
          {/* Question Count */}
          <div
            style={{
              width: '100%',
              height: '30px',
              backgroundColor: '#D9D9D9',
              color: '#000'
            }}
          >
            <p style={{ padding: '2px 5px', fontSize: '16px' }}>
              {leftTableData.length} Questions
            </p>
          </div>

          <Row style={{ background: 'white', padding: '12px 24px' }}>
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              {/* Left Search */}
              <Row justify="end">
                <Input
                  placeholder="Search Question"
                  value={searchKeywordLeft}
                  onChange={(e) => setSearchKeywordLeft(e.target.value)}
                />
              </Row>

              <Row>
                {/* Title & Add Question Button */}
                <Row style={{ width: '100%', display: 'flex', justifyContent: 'space-between', margin: '12px 0' }}>
                  <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                    <Title level={5} style={{ color: '#0067B1' }}>
                      Question List
                    </Title>
                  </Col>
                  <Col xs={24} sm={24} md={24} lg={12} xl={12} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <Button type="primary" onClick={showModal}>
                      Add Question
                    </Button>
                  </Col>
                </Row>

                {/* All Available Questions */}
                <Table
                  showHeader={false}
                  pagination={false}
                  style={{
                    width: '100%',
                    border: '1px solid #d9d9d9',
                  }}
                  scroll={{ y: 250 }}
                  rowSelection={{
                    type: 'checkbox',
                    ...rowSelectionLeft,
                  }}
                  columns={columns}
                  dataSource={searchKeywordLeft ? filteredLeftTableData : leftTableData}
                />
              </Row>
            </Col>
          </Row>
        </Col>

        {/* Swap */}
        <Col style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', fontSize: '28px' }} xs={24} sm={24} md={24} lg={2} xl={2}>
          <AiOutlineRightCircle style={{ cursor: 'pointer' }} onClick={_handleRightMove} />
          <AiOutlineLeftCircle style={{ cursor: 'pointer' }} onClick={_handleLeftMove} />
        </Col>

        {/* Right Side */}
        <Col xs={24} sm={24} md={24} lg={11} xl={11} style={{ border: '1px solid #D9D9D9' }}>
          {/* Question Count */}
          <div
            style={{
              width: '100%',
              height: '30px',
              backgroundColor: '#D9D9D9',
              color: '#000'
            }}
          >
            <p style={{ padding: '2px 5px', fontSize: '16px' }}>
              {rightTableData.length} Questions
            </p>
          </div>
          <Row style={{ background: 'white', padding: '12px 24px' }}>
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              {/* Right Search */}
              <Row justify="end">
                <Input
                  placeholder="Search Question"
                  value={searchKeywordRight}
                  onChange={(e) => setSearchKeywordRight(e.target.value)}
                />
              </Row>
              <Row>
                {/* Title */}
                <Title level={5} style={{ color: '#0067B1', margin: '12px 0' }}>
                  Selected Questions
                </Title>

                {/* Selected Questions */}
                <Table
                  showHeader={false}
                  pagination={false}
                  style={{
                    width: '100%',
                    border: '1px solid #d9d9d9',
                  }}
                  scroll={{ y: 250 }}
                  rowSelection={{
                    type: 'checkbox',
                    ...rowSelectionRight, // Use rowSelectionRight for the right table
                  }}
                  columns={columns}
                  dataSource={searchKeywordRight ? filteredRightTableData : rightTableData}
                />
              </Row>

              <Row style={{ margin: '12px 0' }} justify="end">
                {rightTableData.length > 0 && (
                  <Button onClick={_showAssignModal} type="primary">
                    Assign
                  </Button>
                )}
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>

      {/* Create Question Modal */}
      <Modal
        title="Add Question"
        visible={isModalOpen}
        onCancel={_handleCancelCreateQuestionModal}
        footer={null}
      >
        <AddQuestion />
      </Modal>

      {/* Assign Questions Modal*/}
      <AssignQuestion
        setIsAssignQuestions={setIsAssignQuestions}
        isAssignModalVisible={isAssignModalVisible}
        handleAssignModalCancel={_handleAssignModalCancel}
        setIsAssignModalVisible={setIsAssignModalVisible}
        selectedQuestionsForAssign={selectedQuestionsForAssign}
        setSelectedQuestionsForAssign={setSelectedQuestionsForAssign}
        columns={columns}
      />
    </div>
  );
};

export default ListingQuestion;


