import axios from 'axios'
import { APPLICATION } from '../../Config/config'
import { setPendingList, setPendingListPegination } from '../Reducers/pendingListReducer'
import { setIsLoading, setIsSubmitButtonLoading } from '../Reducers/reportsReducer'
import { showAlert } from './commonActions'


// Get Reports Data by Date Range
const getPendingListByDateRange = ( params, key ) => {      
    return async (dispatch) => {
        dispatch(setIsLoading(true))
        if(!key) {
            dispatch(setIsSubmitButtonLoading(true))
        }  
        try {
            const response = await axios.get(APPLICATION.GET_PENDING_LIST , { params })
            const tableDataWithPagination = response?.data?.data

            // Set Pending List
            dispatch(setPendingList( tableDataWithPagination?.applications ))
            dispatch(setPendingListPegination( tableDataWithPagination?.applications ))

            // Set loading to false
            dispatch(setIsLoading(false))
            dispatch(setIsSubmitButtonLoading(false))

        } catch (err) {
            showAlert('error', err?.message ?? 'Failed to load')
            // Set loading to false
            console.error({ err })

        }
    }
}

export { getPendingListByDateRange }