import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import moment from 'moment'


// Import Components
import { Card, Empty, Space } from 'antd'
import FilterWithDateOrg from '../Components/Layouts/FilterWithDateOrg'
import BasicTable from '../Components/Layouts/BasicTable'

// Import Actions
import { getTripHistory, postBill } from '../Services/Actions/billActions'
import { LoadingOutlined } from '@ant-design/icons'
import { showAlert } from '../Services/Actions/commonActions'

class Bill extends React.PureComponent {
    state = {
        columns: [
            {
                title: 'In Station',
                dataIndex: 'in_station',
                key: 'in_station',
            },
            {
                title: 'Middle Station',
                dataIndex: 'middle_station',
                key: 'middle_station',
            },
            {
                title: 'Out Station',
                dataIndex: 'out_station',
                key: 'out_station',
            },
        ],
    }

    componentDidMount() {
        // this._handleSubmit()
    }

    // Handle Submit
    _handleSubmit = (startDate, endDate, selectedGroup, selectedCity) => {
        const { dispatch } = this.props

        // transform startDate EndDate
        const formatedStartDate = startDate ? moment(startDate).format('YYYY-MM-DD') : moment(new Date()).format('YYYY-MM-DD')
        const formatedEndDate = endDate ? moment(endDate).format('YYYY-MM-DD') : moment(new Date()).format('YYYY-MM-DD')

        // set params
        let params = { date_from: formatedStartDate, date_till: formatedEndDate }

        if (selectedGroup && selectedGroup?.uuid !== -1) {
            params = { ...params, org_id: selectedGroup.uuid }
        }

        // if (selectedCity && selectedCity?.id !== -1) {
        //     params = { ...params, city: selectedCity.value }
        // }

        dispatch(postBill(params))
    }

    // Handle Download
    _handleDownload = (startDate, endDate, selectedGroup) => {
        const { dispatch } = this.props

        // transform startDate EndDate
        const formatedStartDate = startDate ? moment(startDate).format('YYYY-MM-DD') : moment(new Date()).format('YYYY-MM-DD')
        const formatedEndDate = endDate ? moment(endDate).format('YYYY-MM-DD') : moment(new Date()).format('YYYY-MM-DD')



        // Check if time difference is less than 2 months
        function checkTimeDifference(date1, date2) {
            const twoMonths = 60 * 24 * 60 * 60 * 1000; // Two months in milliseconds
            if (Math.abs(date1 - date2) < twoMonths) {
                return true;
            } else {
                return false;
            }
        }

        const date1 = new Date(formatedStartDate);
        const date2 = new Date(formatedEndDate);
        const result = checkTimeDifference(date1, date2);

        if (result) {
            // set params
            let params = { date_from: formatedStartDate, date_to: formatedEndDate }
            let orgName = ''

            if (selectedGroup && selectedGroup?.uuid !== -1) {
                params = { ...params, org_id: selectedGroup.uuid }
                orgName = selectedGroup.name
            }

            dispatch(getTripHistory(params, orgName))
        } else {
            showAlert('error', 'Two months of past data cannot be retrieved.')
        }
    }


    render() {
        const { columns } = this.state
        const { allBill, isDownloading } = this.props

        return (
            <Card title={'Bill Calculation'} hoverable>
                <Space direction={'vertical'} size={'middle'} style={{ width: '100%' }}>
                    <FilterWithDateOrg
                        showSearch={false}
                        onSubmit={this._handleSubmit}
                        onDownload={this._handleDownload}
                        showDate={true}
                        showOrg={true}
                        showCity={false}
                        isSubmitButtonLoading={allBill.isLoading}
                        isDownloadButtonLoading={isDownloading}
                        removeAllOrg={true}
                    />
                    <Card title={'Regular'} hoverable>
                        <div className='row'>
                            {
                                allBill.bill ? (allBill?.bill?.REGULAR?.map((item, index) =>
                                    <div className='col-md-4 col-lg-3'>
                                        <Card title={item?.type} hoverable bodyStyle={{ padding: 0 }}>
                                            <BasicTable
                                                column={columns}
                                                data={[item]}
                                                isLoading={allBill?.isLoading}
                                                pagination={false}
                                                key={index}
                                            />
                                        </Card>
                                    </div>)
                                ) : allBill?.isLoading ?
                                    // Loader
                                    <LoadingOutlined style={{ fontSize: 24, color: 'blue' }} spin />
                                    :
                                    // No Data
                                    <Empty
                                        image={Empty.PRESENTED_IMAGE_SIMPLE}
                                        isLoading={allBill?.isLoading}
                                    />
                            }
                        </div>
                    </Card>
                    <Card title={'ReCPV'} hoverable>
                        <div className='row'>
                            {
                                allBill.bill ? (allBill?.bill?.RE_CPV?.map((item, index) =>
                                    <div className='col-md-4 col-lg-3'>
                                        <Card title={item?.type} hoverable bodyStyle={{ padding: 0 }}>
                                            <BasicTable
                                                column={columns}
                                                data={[item]}
                                                isLoading={allBill?.isLoading}
                                                pagination={false}
                                                key={index}
                                            />
                                        </Card>
                                    </div>)
                                ) : allBill?.isLoading ?
                                    // Loader
                                    <LoadingOutlined style={{ fontSize: 24, color: 'blue' }} spin />
                                    :
                                    // No Data
                                    <Empty
                                        image={Empty.PRESENTED_IMAGE_SIMPLE}
                                        isLoading={allBill?.isLoading}
                                    />
                            }
                        </div>
                    </Card>
                </Space>
            </Card>
        )
    }
}

// PropTypes
Bill.propTypes = {
    allBill: PropTypes.object,
    dispatch: PropTypes.func,
}

// Default Props
Bill.defaultProps = {
    allBill: {},
    isDownloading: false,
    dispatch: () => null,
}

const mapStateToProps = state => ({
    allBill: state?.bill?.allBill,
    isDownloading: state?.bill?.isDownloading
})

const mapDispatchToProps = dispatch => ({ dispatch })

export default connect(mapStateToProps, mapDispatchToProps)(Bill)