import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom'
import * as Sentry from "@sentry/react"

// Import Actions
import { validateUser } from './Services/Actions/authActions'

// Import Components
import ProtectedRoute from './Routes/ProtectedRoute.jsx'
import Spinner from './Components/Common/Spinner'

// Import Routes
import {
  // Menu Routes
  Login,
  Dashboard,
  CreateFile,
  Reports,
  Appointments,
  TaskMap,
  ReCPV,
  PendingList,
  // Sub Menu Routes
  Analytics,
  AdminPanel,
  Documents,
  Questionnaire,
  RouteStatistics,
  PermissionPanel,
  RequestedTrips,
  Bill,
  Register,
  // Dynamic Routes
  Application,
  PrivacyPolicy,
} from './Pages'

// Import Styles
// import 'antd/dist/antd.min.css'
// import 'antd/dist/antd.js'
import './App.css'

class App extends React.PureComponent {

  componentDidMount() {
    const { dispatch } = this.props

    // Validate User
    const token = localStorage.getItem('token')
    dispatch(validateUser(token))
  }

  render() {
    const { isAuthenticated, isValidating } = this.props
    const token = localStorage.getItem('token')

    return (
      <div style={containerStyles}>
        {isValidating ?
          <div style={spinnerContainerStyle} >
            <Spinner />
          </div>
          :
          <BrowserRouter>
            <Routes>
              <Route
                exact={true}
                path='/'

                element={
                  token && isAuthenticated && !isValidating ?
                    <Navigate to='/dashboard' />
                    : isValidating ? <Spinner /> : <Navigate to='/login' />
                }
              />

              <Route
                exact={true}
                path='/dashboard'

                element={
                  token && isAuthenticated && !isValidating ?
                    <ProtectedRoute isAuthenticated={isAuthenticated} permission={'dashboard'}>
                      <Dashboard />
                    </ProtectedRoute>
                    : isValidating ? <Spinner /> : <Navigate to='/login' />
                }
              />

              <Route
                exact={true}
                path='/reports'

                element={
                  token && isAuthenticated && !isValidating ?
                    <ProtectedRoute isAuthenticated={isAuthenticated} permission={'reports'}>
                      <Reports />
                    </ProtectedRoute>
                    : isValidating ? <Spinner /> : <Navigate to='/login' />
                }
              />

              <Route
                exact={true}
                // path='/task-map'
                path='/map-view'

                element={
                  <ProtectedRoute isAuthenticated={isAuthenticated} permission={'map-view'}>
                    <TaskMap />
                  </ProtectedRoute>
                }
              />

              <Route
                exact={true}
                path='/analytics'

                element={
                  <ProtectedRoute isAuthenticated={isAuthenticated} permission={'admin'}>
                    <Analytics />
                  </ProtectedRoute>
                }
              />

              <Route
                exact={true}
                path='/appointments'

                element={
                  <ProtectedRoute isAuthenticated={isAuthenticated} permission={'appointments'}>
                    <Appointments />
                  </ProtectedRoute>
                }
              />

              <Route
                exact={true}
                path='/pending-list'

                element={
                  <ProtectedRoute isAuthenticated={isAuthenticated} permission={'pending-list'}>
                    <PendingList />
                  </ProtectedRoute>
                }
              />

              <Route
                exact={true}
                path='/requested-trips'

                element={
                  <ProtectedRoute isAuthenticated={isAuthenticated} permission={'admin'}>
                    <RequestedTrips />
                  </ProtectedRoute>
                }
              />

              <Route
                exact={true}
                path='/re-cpv'

                element={
                  <ProtectedRoute isAuthenticated={isAuthenticated} permission={'re-cpv'}>
                    <ReCPV />
                  </ProtectedRoute>
                }
              />

              <Route
                exact={true}
                path='/create-file'

                element={
                  <ProtectedRoute isAuthenticated={isAuthenticated} permission={'create-file'}>
                    <CreateFile />
                  </ProtectedRoute>
                }
              />

              <Route
                exact={true}
                path='/documents'

                element={
                  <ProtectedRoute isAuthenticated={isAuthenticated} permission={'admin'}>
                    <Documents />
                  </ProtectedRoute>
                }
              />
              <Route
                exact={true}
                path='/company-profile'

                element={
                  <ProtectedRoute isAuthenticated={isAuthenticated} permission={'admin'}>
                    <Questionnaire />
                  </ProtectedRoute>
                }
              />

              <Route
                exact={true}
                path='/permissions'

                element={
                  <ProtectedRoute isAuthenticated={isAuthenticated} permission={'admin'}>
                    <PermissionPanel />
                  </ProtectedRoute>
                }
              />

              <Route
                exact={true}
                path='/bill-calculation'

                element={
                  <ProtectedRoute isAuthenticated={isAuthenticated} permission={'admin'}>
                    <Bill />
                  </ProtectedRoute>
                }
              />

              <Route
                exact={true}
                path='/admin-panel'

                element={
                  <ProtectedRoute isAuthenticated={isAuthenticated} permission={'admin'}>
                    <AdminPanel />
                  </ProtectedRoute>
                }
              />

              <Route
                exact={true}
                path='/application/:id'

                element={
                  <ProtectedRoute isAuthenticated={isAuthenticated} permission={'reports'}>
                    <Application />
                  </ProtectedRoute>
                }
              />

              <Route
                exact={true}
                path='/route-statistics'

                element={
                  token && isAuthenticated && !isValidating ?
                    <ProtectedRoute isAuthenticated={isAuthenticated} permission={'admin'}>
                      <RouteStatistics />
                    </ProtectedRoute>
                    : isValidating ? <Spinner /> : <Navigate to='/login' />
                }
              />

              <Route
                path='/login'
                element={
                  token && isAuthenticated && !isValidating ?
                    <Navigate to='/' />
                    : isValidating ? <Spinner /> : <Login />
                }
              />

              <Route
                exact={true}
                path='/register'

                element={
                  <ProtectedRoute isAuthenticated={isAuthenticated} permission={'admin'}>
                    <Register />
                  </ProtectedRoute>
                }
              />
              <Route
                exact={true}
                path='verify-app-privacy'
                element={
                  <PrivacyPolicy />
                }
              />

              <Route path='*' element={<Navigate to='/' />} />

            </Routes>
          </BrowserRouter>
        }
      </div>
    )
  }
}

// Styles
const spinnerContainerStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)'
}
const containerStyles = {
  // boxSizing: 'border-box',
  // margin: 0,
  // padding: 0,
  // // width: '100vw',
  // height: '100%',
  // display: 'flex',
  // flexDirection: 'row',
  // justifyContent: 'center',
  // alignItems: 'center',
}

// Prop Types
App.propTypes = {
  isAuthenticated: PropTypes.bool,
  isValidating: PropTypes.bool,
  dispatch: PropTypes.func,
}

App.defaultProps = {
  isAuthenticated: false,
  isValidating: false,
  dispatch: () => null,
}

const mapStateToProps = state => ({
  isAuthenticated: state?.auth?.isAuthenticated ?? false,
  isValidating: state?.auth?.isValidating ?? false,
  allowedPermission: state?.permission?.allowedPermission ?? null

})

const mapDispatchToProps = dispatch => ({ dispatch })

export default Sentry.withProfiler(connect(mapStateToProps, mapDispatchToProps)(App))