import { createSlice } from '@reduxjs/toolkit'
import { setSelectedOrg } from './createFileReducer'

const initialState = {
    menus: [
        { id: 1, label: 'Agent Management', value: 1 },
        { id: 2, label: 'Organization Management', value: 2 },
        // { id: 3, label: 'Questionnaire Management', value: 3 },
    ],
    selectedMenu: 1,
    createNewOrgInput: '',
    isCreateNewOrgLoading: false,
    isUpdateOrgLoading: false,
    selectedOrgUpdateOrg: {},
    isDeleteOrgLoading: false,
    selectedOrgDeleteOrg: {},
    isAddUserToOrgLoading: false,
    selectedOrgId: null,
    selectedOrgAddUserToOrg: {},
    selectedUserAddUserToOrg: {},
    isDeleteUserFromOrgLoading: false,
    selectedOrgDeleteUserFromOrg: {},
    selectedUserDeleteUserFromOrg: {},
    isAddAgentLoading: false,
    selectedUserAddAgent: {},
    isRemoveAgentLoading: false,
    selectedUserRemoveAgent: {},
    //my work
    isQuestionnaireLoading: false,
    allQuestionList: [],
    allVerifierPermission: [],
    tripWiseQuestionList: [],
    subtypeOptions: [],
    appTypeOptions: [],
    selectedOrgStations: [],
}

const adminPanelReducer = createSlice({
    name: 'adminPanel',
    initialState,
    reducers: {
        setMenus: (state, action) => {
            state.menus = action.payload
        },
        setSelectedMenu: (state, action) => {
            state.selectedMenu = action.payload
        },
        setCreateNewOrgInput: (state, action) => {
            state.createNewOrgInput = action.payload
        },
        setIsCreateNewOrgLoading: (state, action) => {
            state.isCreateNewOrgLoading = action.payload
        },
        setSelectedOrgId: (state, action) => {
            state.selectedOrgId = action.payload
        },
        setIsUpdateOrgLoading: (state, action) => {
            state.isUpdateOrgLoading = action.payload
        },
        setSelectedOrgUpdateOrg: (state, action) => {
            state.selectedOrgUpdateOrg = action.payload
        },
        setIsDeleteOrgLoading: (state, action) => {
            state.isDeleteOrgLoading = action.payload
        },
        setSelectedOrgDeleteOrg: (state, action) => {
            state.selectedOrgDeleteOrg = action.payload
        },
        setIsAddUserToOrgLoading: (state, action) => {
            state.isAddUserToOrgLoading = action.payload
        },
        setSelectedOrgAddUserToOrg: (state, action) => {
            state.selectedOrgAddUserToOrg = action.payload
        },
        setSelectedUserAddUserToOrg: (state, action) => {
            state.selectedUserAddUserToOrg = action.payload
        },
        setIsDeleteUserFromOrgLoading: (state, action) => {
            state.isDeleteUserFromOrgLoading = action.payload
        },
        setSelectedOrgDeleteUserFromOrg: (state, action) => {
            state.selectedOrgDeleteUserFromOrg = action.payload
        },
        setSelectedUserDeleteUserFromOrg: (state, action) => {
            state.selectedUserDeleteUserFromOrg = action.payload
        },
        setIsAddAgentLoading: (state, action) => {
            state.isAddAgentLoading = action.payload
        },
        setSelectedUserAddAgent: (state, action) => {
            state.selectedUserAddAgent = action.payload
        },
        setIsRemoveAgentLoading: (state, action) => {
            state.isRemoveAgentLoading = action.payload
        },
        setSelectedUserRemoveAgent: (state, action) => {
            state.selectedUserRemoveAgent = action.payload
        },
        setAllQuestionList: (state, action) => {
            state.allQuestionList = action.payload
        },
        setTripWiseQuestionList: (state, action) => {
            state.tripWiseQuestionList = action.payload
        },
        setSubtypeOptions: (state, action) => {
            state.subtypeOptions = action.payload
        },
        setAppTypeOptions: (state, action) => {
            state.appTypeOptions = action.payload
        },
        setSelectedOrgStations: (state, action) => {
            state.selectedOrgStations = action.payload
        },
        resetState: () => { return { ...initialState } }
    }
})

export const {
    setMenus,
    setSelectedMenu,
    setCreateNewOrgInput,
    setIsCreateNewOrgLoading,
    setSelectedOrgId,
    setIsUpdateOrgLoading,
    setSelectedOrgUpdateOrg,
    setIsDeleteOrgLoading,
    setSelectedOrgDeleteOrg,
    setIsAddUserToOrgLoading,
    setSelectedOrgAddUserToOrg,
    setSelectedUserAddUserToOrg,
    setIsDeleteUserFromOrgLoading,
    setSelectedOrgDeleteUserFromOrg,
    setSelectedUserDeleteUserFromOrg,
    setIsAddAgentLoading,
    setSelectedUserAddAgent,
    setIsRemoveAgentLoading,
    setSelectedUserRemoveAgent,
    resetState,
    setAllQuestionList,
    setTripWiseQuestionList,
    setSubtypeOptions,
    setAppTypeOptions,
    setSelectedOrgStations
} = adminPanelReducer.actions
export default adminPanelReducer.reducer