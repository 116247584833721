import React from 'react'
import PropTypes from 'prop-types'

// Import Components
import { Form, Input, Select } from 'antd'
import AutoCoompleteAddressReCPV from './AutoCoompleteAddressReCPV'

// import actions and reducers
import { showAlert } from '../../../Services/Actions/commonActions'
import { getTripTypeByConcernType } from '../../../Services/Actions/staticOptionAction'

class NewAdditionalReCPVTrip extends React.PureComponent {
    state = {
        selectedConcern: null,
        selectedTripType: '',
        trip_types: [],
        isSuggestionBoxOpen: false,
        shouldRequireNow: false,
        placeName: '',
        givenAddress: '',
        exactAddress: ''
    }

    componentDidUpdate(prevProps, prevState){
        const { selectedConcernType } = this.props
        const { selectedTripType, placeName, givenAddress, trip_types, exactAddress } = this.state

        // if concern type change trip type gets changed
        if(prevProps.selectedConcernType !== selectedConcernType && selectedConcernType){
            const getTripTypeOfSelectedConcernType = getTripTypeByConcernType(selectedConcernType)

            this.setState({ 
                trip_types: getTripTypeOfSelectedConcernType
            })
        }

        // for setting form field is mandatory 
        if((prevState.selectedTripType !== selectedTripType) || (prevState.placeName !== placeName) || (prevState.givenAddress !== givenAddress || prevState.exactAddress !== exactAddress)){

            // if all of the below fields are empty
            if(!selectedTripType && !placeName && !givenAddress && trip_types?.length === 0 && !exactAddress){
                this.setState({
                    shouldRequireNow: false
                })
            }
            else{
                this.setState({
                    shouldRequireNow: true
                })
            }
        }
    }

    // handle trip type
    _handleTripType = value => {
        this.setState({ selectedTripType: value })
    }

    // Handle Concern On Change
    _handleConcernOnChange = (value, optionObject) => {
        if(!value) {
            showAlert('error', 'Please select a concern!')
        }

        const getTripTypeOfSelectedConcernType = getTripTypeByConcernType(optionObject?.type)

        this.setState({ 
            trip_types: getTripTypeOfSelectedConcernType
        })
    }

     // changes isSuggestionBoxOpenProp to open/close suggestion box for exact address
     _handleSuggestionOpenForxactAddress = (booleanValue) => {
        // if suggestion box is open then click in anywhere will close the box
            this.setState({isSuggestionBoxOpen: booleanValue})
    }

    // keeping track of optional input change to render weather other input field value at current time is mandatory or not
    _onPlaceNameChange = (e) => {
        const { handlePlaceName } = this.props
        const inputValue = e.target.value
        
        //calling prop function to set callback func prop value
        handlePlaceName(e)

        this.setState({
            placeName: inputValue
        })
    }

    // keeping track of optional input change to render weather other input field value at current time is mandatory or not
    _onGivenAddressChange = (e) => {
        const value = e.target.value

        this.setState({
            givenAddress: value
        })
    }

    //setting value to check empty field and calling the callback function to get addess
    _handleExactAddressChange = (value) => {

        this.setState({
            exactAddress: value
        })
    }

    render() {
        const { addressDetails, getExactAddress, handleNotExact, id, tripNo, concerns, isRequired } = this.props
        const { selectedTripType, trip_types, isSuggestionBoxOpen, shouldRequireNow } = this.state

        return (
            <div>
                {
                    concerns && concerns.length > 0 &&
                    <div>
                        <Form.Item
                            label='Concern'
                            name={`concern_id${tripNo}`}
                            rules={[
                                {
                                    required: ( isRequired || shouldRequireNow ) ? true : false,
                                    message: 'Please Select a concern.',
                                },
                            ]}
                        >
                            <Select 
                                placeholder='Select Concern'
                                onChange={ this._handleConcernOnChange }
                            >
                                {
                                    concerns && concerns.length > 0 &&
                                    concerns.map((concern, index) => (
                                        <Select.Option
                                            id={id}
                                            key={index}
                                            value={concern.id}
                                            type={ concern.type }
                                        >
                                            {concern.name}
                                        </Select.Option>
                                    ))
                                }
                            </Select>
                        </Form.Item>
                    </div>
                }
                <Form.Item
                    label='Select Trip Type'
                    name={`type${tripNo}`}
                    rules={[
                        {
                            required: ( isRequired || shouldRequireNow ) ? true : false,
                            message: 'Please select a trip type.',
                        },
                    ]}
                >
                    <Select placeholder='Select Trip Type' onChange={ this._handleTripType }>
                        {
                            trip_types && trip_types.length > 0 &&
                            trip_types.map((type, index) => (
                                <Select.Option
                                    id={id}
                                    key={index}
                                    value={type.value}
                                >
                                    {type.label}
                                </Select.Option>
                            ))
                        }
                    </Select>
                </Form.Item>

                {
                    selectedTripType && selectedTripType === 'BANK' &&
                    <div>
                        <Form.Item
                            label='Bank Account Name'
                            name={`bankAccountName${tripNo}`}
                            rules={[{ 
                                required: true,
                                message: 'Bank account name cannot be empty.' 
                            }]}
                        >
                            <Input />
                        </Form.Item>

                        <Form.Item
                            label='Bank Account Number'
                            name={`bankAccountNumber${tripNo}`}
                            rules={[{ 
                                required: true,
                                message: 'Bank account number cannot be empty.' 
                            }]}
                        >
                            <Input />
                        </Form.Item>

                        <Form.Item
                            label='Bank Name'
                            name={`bankName${tripNo}`}
                            rules={[{ 
                                required: true,
                                message: 'Bank name cannot be empty.' 
                            }]}
                        >
                            <Input />
                        </Form.Item>

                        <Form.Item
                            label='Bank Branch'
                            name={`bankBranch${tripNo}`}
                            rules={[{ 
                                required: true,
                                message: 'Bank branch cannot be empty.' 
                            }]}
                        >
                            <Input />
                        </Form.Item>
                    </div>
                }
                <Form.Item
                    label='Given Address'
                    name={`address${tripNo}`}
                    rules={[{ 
                        required: ( shouldRequireNow || isRequired  ) ? true : false, 
                        message: 'Given address cannot be empty.' 
                    }]}
                >
                    <Input onChange={ this._onGivenAddressChange }/>
                </Form.Item>
                <Form.Item>
                    <AutoCoompleteAddressReCPV
                        id={id}
                        customWidth={'99%'}
                        onSelect={getExactAddress}
                        onTextareaInputChange={ this._handleExactAddressChange }
                        handleNotExact={handleNotExact}
                        isSuggestionBoxOpenProp={isSuggestionBoxOpen}
                        toggleSuggestionBox={this._handleSuggestionOpenForxactAddress}
                        isRequired={ ( shouldRequireNow || isRequired  ) ? true : false }
                    />
                </Form.Item>
                <Form.Item
                    label={ selectedTripType === 'OFFICE' || selectedTripType === 'GUARANTOR_OFFICE' ?  'Office Name' : 'Place Name' }
                >
                    <Input value={ addressDetails && addressDetails.placeName } onChange={ this._onPlaceNameChange } />
                </Form.Item>
            </div>
        )
    }
}

NewAdditionalReCPVTrip.propTypes = {
    selectedConcernType: PropTypes.string,
    concerns: PropTypes.array,
    getExactAddress: PropTypes.func,
    handleNotExact: PropTypes.func,
    handlePlaceName: PropTypes.func,
    tripNo: PropTypes.oneOfType([ PropTypes.string, PropTypes.number ]),
    id: PropTypes.oneOfType([ PropTypes.string, PropTypes.number ]),
    isRequired: PropTypes.bool

}

NewAdditionalReCPVTrip.defaultProps = {
    selectedConcernType: '',
    concerns: [],
    getExactAddress: () => null,
    handleNotExact: () => null,
    handlePlaceName: () => null,
    tripNo: '',
    id: '',
    isRequired: false
}

export default NewAdditionalReCPVTrip