import React from 'react'
import PropTypes from 'prop-types'
import axios from 'axios'

// Import Components
import { Row, Col, Checkbox, Form, Input, Button, Select } from 'antd'

// Import Actions
import { getConcernLabelByConcernType, getFinishedTrips, showAlert } from '../../../Services/Actions/commonActions'

// Import Types
import { RECPV } from '../../../Config/config'

class ChooseTrips extends React.PureComponent {
    state = {
        selectedTripList: [],
        loading: false,
        update: new Date()
    }
    _handleSelectedTrips = e => {
        if (e.target.checked) {
            const { selectedTripList } = this.state
            this.setState({ selectedTripList: selectedTripList.concat(e.target.value) })
        } else {
            const { selectedTripList } = this.state
            const updatedTripList = selectedTripList && selectedTripList.length > 0 && selectedTripList.filter(item => item !== e.target.value)
            this.setState({ selectedTripList: updatedTripList })
        }
    }

    // on submit 
    _onSubmit = values => {
        const { selectedTripList } = this.state
        const { selectedApplicationId } = this.props

        if (values && selectedTripList && selectedTripList.length > 0) {
            this.setState({ loading: true })

            const reason = values.reason
            const type = values.type
            const recpvIdList = selectedTripList.map(tripId => {
                const params = { reason }
                const recpvId = axios.post(RECPV.RECPV_1 + tripId + RECPV.RECPV_2, params)
                    .then(res => {
                        if (res.data.status === 200) {
                            // success
                            const recpvTripId = res.data.data.trips_id
                            return recpvTripId
                        } else {
                            // error toast
                            showAlert('error', res?.data?.message ?? 'Something went wrong')
                            this.setState({ loading: false })
                        }
                    })
                    .catch(error => {
                        console.error(error)

                        // Show alert
                        showAlert('error', error?.response?.data?.message ?? error?.message ?? 'Something went wrong')
                        this.setState({ loading: false })
                    })
                return recpvId
            })
            // 
           
            Promise.all(recpvIdList)
                .then(recpvIdList => {
                    // need to validate response
                    if (recpvIdList && recpvIdList.length === selectedTripList.length) {
                        this._submitToReCPVTracks(selectedApplicationId, recpvIdList, reason, type)

                    } else {
                        // error toast
                        showAlert('error', 'Something went wrong')
                        this.setState({ loading: false })
                    }
                })
                .catch(error => {
                    console.error(error)

                    // Show alert
                    showAlert('error', error?.response?.data?.message ?? error?.message ?? 'Something went wrong')
                    this.setState({ loading: false })
                })
        } else {

            // Show Alert
            showAlert('error', 'Trips not selected.')
        }

    }

    // RE-CPV TRACKS
    _submitToReCPVTracks = (applicationId, recpvIdList, reason, type) => {
        if (applicationId && recpvIdList && recpvIdList.length > 0 && reason && type) {
            const { reload } = this.props
            const formData = new FormData()
            recpvIdList.map((trip, index) => formData.append(`trips[${index}]`, trip))
            formData.append('reason', reason)
            formData.append('type', type)
            formData.append('application_id', applicationId)

            // data post
            axios.post(RECPV.GET_RECPV_TRACKER_DATA, formData).then(res => {
                if (res.data.status === 200) {
                    this.setState({ loading: false, selectedTripList: [] })

                    // Show alert
                    showAlert('success', res?.data?.message ?? 'Re-CPV Trips Created')
                    this._clearFormData()
                    reload()
                    this._updateComponent()

                } else {
                    showAlert('error', res?.data?.message ?? 'Something went wrong')
                    this.setState({ loading: false })
                }
            }).catch(error => {
                console.error(error)

                // Show Alert
                showAlert('error', error?.response?.data?.message ?? error?.message ?? 'Something went wrong')
                
                // Loading false
                this.setState({ loading: false })
            })
        }
    }

    // Request for Re-CPV
    _createReCPVTrips = (tripId, reason, index, length) => {
        if (tripId && reason) {
            const params = { reason }
            const { reload } = this.props
            axios.post(RECPV.RECPV_1 + tripId + RECPV.RECPV_2, params)
                .then(res => {
                    if (res.status === 200) {
                        if (index + 1 === length) {
                            // Show alert
                            showAlert('success', res?.data?.message ?? 'Re-CPV Trips Created')

                            this.setState({ loading: false, selectedTripList: [] })
                            this._clearFormData()
                            reload()
                            this._updateComponent()
                        }
                    } else {
                        this.setState({ loading: false })
                        // Show alert
                        showAlert('error', res?.data?.message ?? 'Something went wrong')
                    }
                })
                .catch(error => {
                    console.error(error)

                    this.setState({ loading: false })

                    // Show alert
                    showAlert('error', error?.response?.data?.message ?? error?.message ?? 'Something went wrong')
                })
        }
    }

    // on submit failed
    _onSubmitFailed = error => {
        console.error('error found', error)
    }

    // update component
    _updateComponent = () => {
        this.setState({ update: new Date() })
    }

    // clear Form Data
    formRef = React.createRef()
    _clearFormData = () => {
        this.formRef.current.resetFields()
    }
    render() {
        const { loading, selectedTripList } = this.state
        const { concerns } = this.props
        return (
            <div>
                <div>
                    <Form
                        layout={ 'vertical' }
                        onFinish={ this._onSubmit }
                        onFinishFailed={ this._onSubmitFailed }
                        ref={ this.formRef }
                    >
                        <Form.Item lable='Reason'>
                            <Input.Group>
                                <Form.Item
                                    label='Billing Type'
                                    name='type'
                                    noStyle
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please Select a billing type..',
                                        },
                                    ]}
                                >
                                    <Select
                                        placeholder='Billing Type'
                                        style={{
                                            width: window.innerWidth < 768 ? '100%' : '28%',
                                            textAlign: 'left'
                                        }}
                                    >
                                        <Select.Option value='REGULAR'>Regular Billing</Select.Option>
                                        <Select.Option value='FULL_IN'>Full In Billing</Select.Option>
                                    </Select>
                                </Form.Item>

                                <Form.Item
                                    name='reason'
                                    noStyle
                                    rules={[{ required: true, message: 'Reason cannot be empty.' }]}
                                >
                                    <Input
                                        style={{
                                            width: window.innerWidth < 768 ? '100%' : '72%',
                                            textAlign: 'left'
                                        }}
                                        placeholder='Reason'
                                    />
                                </Form.Item>
                            </Input.Group>
                        </Form.Item>

                        <div>
                            <Row gutter={[8, 8]} justify='start'>
                                {
                                    concerns && concerns.length > 0 &&
                                    concerns.map((concern, index) => (
                                        <Col key={index}>
                                            <div style={{ ...container, }}>
                                                <div>
                                                    <div
                                                        style={{
                                                            color: 'white',
                                                            background: 'linear-gradient(to right, rgba(57, 106, 252, 1), rgba(41, 72, 255, 1))',
                                                            padding: '0.2rem .8rem',
                                                            borderRadius: '4px'
                                                        }}
                                                    >
                                                        <div style={{ display: 'inline-block', }}>
                                                            <div style={{ fontWeight: 600, fontSize: '16px' }}>
                                                                {concern.name}
                                                            </div>
                                                            <div style={{ textAlign: 'left', lineHeight: '0.8rem', fontSize: '12px' }}>
                                                                {getConcernLabelByConcernType(concern.type)}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div
                                                        style={{
                                                            padding: '.8rem',
                                                        }}
                                                    >
                                                        <Checkbox.Group
                                                            style={{ width: '100%' }}
                                                        >
                                                            {
                                                                concern.trips && concern.trips.length > 0 ?
                                                                    (
                                                                        <div>
                                                                            {
                                                                                getFinishedTrips(concern.trips) && getFinishedTrips(concern.trips).length > 0 ?
                                                                                    getFinishedTrips(concern.trips).map((trip, index) => (
                                                                                        <Checkbox
                                                                                            key={ index }
                                                                                            value={ trip.id }
                                                                                            onChange={ this._handleSelectedTrips }
                                                                                        >
                                                                                            {`${trip.id} (${trip.type})`}
                                                                                        </Checkbox>
                                                                                    ))
                                                                                    :
                                                                                    <div>
                                                                                        Re-CPV Trips Unavailable
                                                                                    </div>
                                                                            }
                                                                        </div>
                                                                    )
                                                                    :
                                                                    (
                                                                        <div>
                                                                            Trips Unavailable
                                                                        </div>
                                                                    )
                                                            }
                                                        </Checkbox.Group>
                                                    </div>
                                                </div>
                                            </div>
                                        </Col>
                                    ))
                                }
                            </Row>
                        </div>
                        <div style={{ float: 'right', margin: '1rem 0 0 0' }}>
                            <Form.Item noStyle>
                                <Button
                                    loading={loading}
                                    type='primary'
                                    htmlType='submit'>
                                    Submit
                                </Button>
                            </Form.Item>
                        </div>
                    </Form>
                </div>
            </div>
        )
    }
}

// proptypes
ChooseTrips.propTypes = {
    concerns: PropTypes.array,
    selectedApplicationId: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string
    ]),
    reload: PropTypes.func
}
ChooseTrips.defaultProps = {
    concerns: [],
    selectedApplicationId: '',
    reload: () => null
}

// JSS Styles
const container = {
    display: 'block',
    justifyContent: 'center',
    height: '100%',
    border: '1px solid rgba(0, 0, 0, 0.1)',
    borderRadius: '4px',
    padding: '0.8rem',
}

export default ChooseTrips