import React from 'react'
import axios from 'axios'

// Import Components
import { Form, Input, Button, Row, Col } from 'antd'
import { NewAdditionalReCPVTrip } from '../ReCPV'

// Import Actions
import { showAlert } from '../../../Services/Actions/commonActions'

// Import Types
import { RECPV } from '../../../Config/config'

class AdditionalReCPVTrip extends React.PureComponent {
    state = {
        loading: false,
        update: new Date(),
        selectedTripType: '',

        addressDetailsOne: {},
        addressDetailsTwo: {},
        notExact: {
            tripOne: false,
            tripTwo: false,
        },
    }

    // Handle AutoComplete Exact Address
    _getExactAddress = (value) => {
        if (value?.id) {
            const exactAddressDetails = value
            if (value.id === `default-additional-recpv-trip`) {
                this.setState({ addressDetailsOne: exactAddressDetails })
            } else if (value.id === `optional-additional-recpv-trip`) {
                this.setState({ addressDetailsTwo: exactAddressDetails })
            } else {
                // Show alert
                showAlert('error', 'Something went wrong!')
            }
        }
    }

    // handle not exact
    _handleNotExact = e => {
        const { notExact } = this.state
        if (e.target.name === 'default-additional-recpv-trip') {
            this.setState({ notExact: { ...notExact, tripOne: e.target.checked } })
        } else if (e.target.name === 'optional-additional-recpv-trip') {
            this.setState({ notExact: { ...notExact, tripTwo: e.target.checked } })
        } else {
            // Show alert
            showAlert('error', 'Something went wrong!')
        }
    }

    // create additional trip
    _onSubmit = values => {

        const { addressDetailsOne, addressDetailsTwo, notExact } = this.state
        this.setState({ loading: true })
        if (values) {
            // NOTE: this is just a random number 
            // In Add concern tab of Recpv panel there are two trip creation form (created by <NewAdditionalReCPVTrip />). they use id = 1 and id = 2
            //Therefore for overrideing conflict here first trip has id = 50, second has id = 60
            const defaultId = 50
            const optionalId = 60
            const defaultTripConcern = values[`concern_id${defaultId}`]
            const optionalTripConcern = values[`concern_id${optionalId}`]

            const defaultTripDetails = values[`concern_id${defaultId}`] && {
                concernId: values[`concern_id${defaultId}`],
                type: values[`type${defaultId}`],
                givenAddress: values[`address${defaultId}`],
                addressDetails: addressDetailsOne,
                notExact: notExact.tripOne,
                bankDetails: values[`type${defaultId}`] === 'BANK' && {
                    accountName: values[`bankAccountName${defaultId}`],
                    accountNumber: values[`bankAccountNumber${defaultId}`],
                    bankName: values[`bankName${defaultId}`],
                    bankBranch: values[`bankBranch${defaultId}`],
                },
            }

            // for the optional trip
            let optionalTripDetails = ''
            if(optionalTripConcern){
                optionalTripDetails = values[`concern_id${optionalId}`] && {
                    concernId: values[`concern_id${optionalId}`],
                    type: values[`type${optionalId}`],
                    givenAddress: values[`address${optionalId}`],
                    addressDetails: addressDetailsTwo,
                    notExact: notExact.tripTwo,
                    bankDetails: values[`type${optionalId}`] === 'BANK' && {
                        accountName: values[`bankAccountName${optionalId}`],
                        accountNumber: values[`bankAccountNumber${optionalId}`],
                        bankName: values[`bankName${optionalId}`],
                        bankBranch: values[`bankBranch${optionalId}`],
                    },
                }
            }

            const reason = values.reason

            if (defaultTripConcern) {
                const defaultRecpvTripIdList = this._createAdditionalTrips(defaultTripDetails, reason)

                // if the optional trip exist
                let OptionalRecpvTripIdList = ''
                if(optionalTripConcern){
                    OptionalRecpvTripIdList = this._createAdditionalTrips(optionalTripDetails, reason)
                }

                Promise.all(OptionalRecpvTripIdList?.concernId ? [defaultRecpvTripIdList, OptionalRecpvTripIdList] : [defaultRecpvTripIdList])
                    .then(tripsIdList => {
                        this._submitToReCPVTracks(tripsIdList, reason)
                    })
                    .catch(err => {
                        console.error(err)
                        // Show Alert
                        showAlert('error', err?.response?.data?.message ?? err?.message ?? 'Something went wrong')

                        this.setState({ loading: false })
                    })

            } 
            else {
                // Show Alert
                showAlert('error', 'Something went wrong')

                this.setState({ loading: false })
            }


        } else {
            // Show Alert
            showAlert('error', 'Something went wrong')

            this.setState({ loading: false })
        }
    }

    _createAdditionalTrips = (tripDetails, reason) => {
        const { selectedApplicationId } = this.props
        if (tripDetails && reason) {
            const data = new FormData()
            data.append('application_id', selectedApplicationId)
            data.append('concern_id', tripDetails.concernId)
            data.append('type', tripDetails.type)
            data.append('reason', reason)

            data.append('tag', 'RE_CPV')
            data.append('comment', '')

            data.append('address', tripDetails.givenAddress)
            data.append('exact_address', tripDetails.addressDetails.address)
            data.append('area', tripDetails.addressDetails.area)
            data.append('place_name', tripDetails.addressDetails.placeName)
            data.append('latitude', Number(tripDetails.addressDetails.latitude))
            data.append('longitude', Number(tripDetails.addressDetails.longitude))

            data.append('is_address_exact', tripDetails.notExact ? 0 : 1)

            if (tripDetails.bankDetails) {
                const bank = tripDetails.bankDetails
                data.append('bank[account_title]', bank.accountName)
                data.append('bank[account_number]', bank.accountNumber)
                data.append('bank[name]', bank.bankName)
                data.append('bank[branch]', bank.bankBranch)
            }

            const newReCPVTrip = axios.post(RECPV.GET_TRIP_LIST, data).then(res => {
                if (res.data.status === 200) {
                    const tripId = res.data.data.trips[0]                    
                    return tripId
                } else {
                    // Show Alert
                    showAlert('error', res?.data?.message ?? 'Something went wrong')
                    this.setState({ loading: false })

                }

            }).catch(error => {
                console.error(error)
                // Show Alert
                showAlert('error', error?.response?.data?.message ?? error?.message ?? 'Something went wrong')
                this.setState({ loading: false })
            })

            return newReCPVTrip
        } else {
            // Show Alert
            showAlert('error', 'Something went wrong')
            this.setState({ loading: false })
        }
    }

    // RE-CPV TRACKS
    _submitToReCPVTracks = (tripIdList, reason) => {
        const { selectedApplicationId } = this.props
        if (selectedApplicationId && tripIdList && tripIdList.length > 0 && reason) {
            const { reload } = this.props
            const formData = new FormData()
            tripIdList.map((trip, index) => formData.append(`trips[${index}]`, trip))
            formData.append('reason', reason)
            formData.append('type', 'REGULAR')
            formData.append('application_id', selectedApplicationId)

            // data post
            axios.post(RECPV.GET_RECPV_TRACKER_DATA, formData).then(res => {
                if (res.data.status === 200) {
                    this.setState({ loading: false, addressDetailsOne: {}, addressDetailsTwo: {}, })

                    // Show Alert
                    showAlert('success', res?.data?.message ?? 'Re-CPV Trip created successfully')
                    this._clearFormData()
                    reload()
                    this._updateComponent()

                } else {
                    // Show Alert
                    showAlert('error', res?.data?.message ?? 'Something went wrong')
                    this.setState({ loading: false })
                }
            }).catch(error => {
                // Show Alert
                showAlert('error', error?.response?.data?.message ?? error?.message ?? 'Something went wrong')
                this.setState({ loading: false })
                console.error(error)
            })
        }
    }

    // update component
    _updateComponent = () => {
        this.setState({ update: new Date() })
    }

    // handle trip type
    _handleTripType = value => {
        this.setState({ selectedTripType: value })
    }

    // handle place name
    _handlePlaceName = e => {
        this.setState({ placeName: e.target.value })
    }

    // handle place name1
    _handlePlaceName1 = e => {        
        this.setState(prevState => ({
            addressDetailsOne: {
                ...prevState.addressDetailsOne,
                placeName: e.target.value
            }
        }))
    }

    // handle place name2
    _handlePlaceName2 = e => {
        this.setState(prevState => ({
            addressDetailsTwo: {
                ...prevState.addressDetailsTwo,
                placeName: e.target.value
            }
        }))
    }

    // clear Form Data
    formRef = React.createRef()
    _clearFormData = () => {
        this.formRef.current.resetFields()
    }
    render() {
        const { loading, addressDetailsOne, addressDetailsTwo } = this.state
        const { concerns } = this.props
        return (
            <div>
                <Form
                    layout='vertical'
                    onFinish={this._onSubmit}
                    ref={this.formRef}
                >
                    <div >
                        <Form.Item lable='Reason'>
                            <Form.Item
                                name='reason'
                                noStyle
                                rules={[{ required: true, message: 'Reason cannot be empty.' }]}
                            >
                                <Input
                                    style={{ textAlign: 'left' }}
                                    placeholder='Reason'
                                />
                            </Form.Item>
                        </Form.Item>
                    </div>

                    <Row gutter={[16, 24]}>
                        <Col span={12} xs={ 24 } md={ 12 }>
                            <div style={{ ...container }}>
                                <div style={{ ...titleStyle }}>
                                    <div style={{ color: 'red', margin: '0 4px 0 0' }}>{`* `}</div>
                                    <div>{` Required`}</div>
                                </div>
                                <NewAdditionalReCPVTrip
                                    concerns={concerns}
                                    id={`default-additional-recpv-trip`}
                                    addressDetails={addressDetailsOne}
                                    getExactAddress={this._getExactAddress}
                                    handleNotExact={this._handleNotExact}
                                    handlePlaceName={this._handlePlaceName1}
                                    tripNo={50}
                                    isRequired={ true }
                                />
                            </div>
                        </Col>

                        <Col span={12} xs={ 24 } md={ 12 }>
                            <div style={{ ...container }}>
                                <div style={{ ...titleStyle }}>
                                    Optional
                                </div>
                                {/* <OptionalAdditionalTrip
                                    concerns={concerns}
                                    id={`optional-additional-recpv-trip`}
                                    addressDetails={addressDetailsTwo}
                                    getExactAddress={this._getExactAddress}
                                    handleNotExact={this._handleNotExact}
                                    handlePlaceName={this._handlePlaceName2}
                                    tripNo={60}
                                /> */}
                                <NewAdditionalReCPVTrip
                                    concerns={concerns}
                                    id={`optional-additional-recpv-trip`}
                                    addressDetails={addressDetailsTwo}
                                    getExactAddress={this._getExactAddress}
                                    handleNotExact={this._handleNotExact}
                                    handlePlaceName={this._handlePlaceName2}
                                    tripNo={60}
                                    isRequired={ false }
                                />
                            </div>
                        </Col>
                    </Row>

                    <div style={{ float: 'right', margin: '1rem 0 0 0' }}>
                        <Form.Item noStyle>
                            <Button
                                // block
                                loading={loading}
                                type='primary'
                                htmlType='submit'>
                                Submit
                            </Button>
                        </Form.Item>
                    </div>
                </Form>
            </div>
        )
    }
}

// JSS Styles
const container = {
    display: 'block',
    justifyContent: 'center',
    height: '100%',
    border: '1px solid rgba(0, 0, 0, 0.1)',
    borderRadius: '4px',
    padding: '0.8rem',
}

const titleStyle = {
    display: 'flex',
    padding: '0 0 0.5rem',
    fontSize: '1.2rem',
    fontWeight: 500
}

export default AdditionalReCPVTrip