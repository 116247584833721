import React, { useEffect, useState } from 'react';

// Import Methods
import { useSelector } from 'react-redux';

// Import CSS
import '../../../App.css'

// Import Components
import { Space } from 'antd';
import QuestionList from './QuestionList';

const QuestionListContainer = () => {
    // Get Data From Redux
    const {allQuestionList} = useSelector(state => state.adminPanel)

    // States
    const [questionOnState, setQuestionOnState] = useState([])

    // setting question to state whenever allQuestionList gets updated in reducer
    useEffect(() => {
        setQuestionOnState(allQuestionList?.questions)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[allQuestionList?.questions?.length])


    // searching question from the question list
    const searchQuestion = (e) => {
        const keyword = e.target.value.toLowerCase()

        if(keyword.length === 0){
            setQuestionOnState(allQuestionList?.questions)
        }

        // filtering data by keyword in quetion name or type
        const filteredData =  []
        allQuestionList?.questions?.forEach(item => {
            if(item?.field_type?.toLowerCase()?.includes(keyword) || item?.question?.toLowerCase()?.includes(keyword) || item?.key?.toLowerCase()?.includes(keyword)){
                filteredData.push(item)
            }
        })
        setQuestionOnState(filteredData)
    }

    return(
        <Space direction={ 'vertical' } style={ fillWidthStyle }>
            <QuestionList 
                questionSource={ questionOnState }
                onSearch={ searchQuestion }
            />
        </Space>
    )

} 
const fillWidthStyle = {
    width: '100%',
    minWidth: '-moz-available',          /* WebKit-based browsers will ignore this. */
    minWidth: '-webkit-fill-available',  /* Mozilla-based browsers will ignore this. */
    minWidth: 'fill-available',
} 

export default QuestionListContainer