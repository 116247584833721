import axios from "axios"
import { DOCUMENTS } from '../../Config/config'
import { showAlert } from "./commonActions"

export const uploadDocument = (formData) => {
  // Get Token & Set Headers
  const token = localStorage.getItem('token')
  const headers = { headers: { Authorization: `Bearer ${token}`, 'Content-type': 'multipart/form-data' } }

  return axios.post(DOCUMENTS.UPLOAD_DOCUMENT, formData, headers)
    .then(res => {
      if (res.status === 200) {
        showAlert('success', 'File Uploaded Successfull')
        return res.data
      }
    })
    .catch(err => {
      showAlert(
        "error",
        err?.response?.data?.message
          ? Object.values(err?.response?.data?.message)[0]
          : "File Upload Failed"
      );
    })
}

export const getDocuments = () => {
  // Get Token & Set Headers
  const token = localStorage.getItem('token')
  const headers = { headers: { Authorization: `Bearer ${token}` } }

  return axios.get(DOCUMENTS.GET_DOCUMENTS, headers)
    .then(res => {
      if (res.status === 200) {
        return res?.data?.data?.files
      }
    })
    .catch(err => console.log(err))
}