import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

// Import Components
import { Space, Select, Button, Modal } from 'antd'

// Import Actions, and Reducers
import { removeAgent } from '../../../Services/Actions/adminPanelActions'
import { showAlert } from '../../../Services/Actions/commonActions'
import { setSelectedUserRemoveAgent } from '../../../Services/Reducers/adminPanelReducer'

// Import Constants
const { confirm } = Modal

class RemoveAgent extends React.PureComponent {    

    // Handle Selected User On Change
    _handleSelectUser = value => {
        const { dispatch, verifiers } = this.props
        
        // Check if value is empty or undefined or null
        if (!value) {
            // Set Selected User
            dispatch(setSelectedUserRemoveAgent({}))
            return
        }
        // Find Selected User
        const selectedUser = verifiers.find(item => item.id === value)
        
        // If Selected User Not Exist
        if (!selectedUser) {
            // Set Selected User
            dispatch(setSelectedUserRemoveAgent({}))

            // Show Error Alert
            showAlert('error', 'Selected User Not Found')
            return
        }
        // Set Selected User
        dispatch(setSelectedUserRemoveAgent(selectedUser))
    }

    // Handle Modal
    _handleModal = () => {
        const { dispatch, selectedUserRemoveAgent, isRemoveAgentLoading } = this.props

        // If Selected User Not Exist
        if (!selectedUserRemoveAgent || !selectedUserRemoveAgent.id) {
            // Show Error Alert
            showAlert('error', 'Please Select User')
            return
        }

        confirm({
            title: 'Are you sure delete this agent?',
            content: `"${selectedUserRemoveAgent?.name ?? 'N/A'}" will be removed as an agent permanently.`,
            okText: 'Yes',
            okType: 'danger',
            cancelText: 'No',
            onOk: () => {
                dispatch(removeAgent({ user_id: selectedUserRemoveAgent.id }))
            },
            okButtonProps: {
                loading: isRemoveAgentLoading,
            },
        })
    }
    render() {
        const { verifiers, selectedUserRemoveAgent } = this.props                
        
        return (
            <div>
                <Space direction={ 'vertical' } style={{ width: '100%' }}>                    
                    <Select
                        style={{ width: '100%' }}                                        
                        showSearch
                        allowClear                    
                        placeholder={ 'Select User' }
                        onChange={ this._handleSelectUser }
                        value={ selectedUserRemoveAgent?.id ?? null }
                        filterOption={ (input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0 }
                        optionFilterProp={ 'children' }
                    >
                        {
                            verifiers?.length > 0 && verifiers.map((item, index) => {
                                return (
                                    <Select.Option key={ index + 1 } value={ item.id }>{ item.name }</Select.Option>
                                )
                            })
                        }
                    </Select>
                    <div style={{ float: 'right' }}>
                        <Button type={ 'primary' } onClick={ this._handleModal } danger>{ 'Remove' }</Button>
                    </div>
                </Space>
            </div>
        )
    }
}

// Props Validation
RemoveAgent.propTypes = {
    dispatch: PropTypes.func,
    verifiers: PropTypes.array,    
    selectedUserRemoveAgent: PropTypes.object,
    isRemoveAgentLoading: PropTypes.bool,
}

RemoveAgent.defaultProps = {
    dispatch: () =>  null,
    verifiers: [],
    selectedUserRemoveAgent: {},    
    isRemoveAgentLoading: false,
}

// Map State To Props
const mapStateToProps = (state) => ({
    verifiers: state.common.verifiers,
    selectedUserRemoveAgent: state.adminPanel.selectedUserRemoveAgent,    
    isRemoveAgentLoading: state.adminPanel.isRemoveAgentLoading,
})

// Map Dispatch To Props
const mapDispatchToProps = (dispatch) => ({ dispatch })

export default connect(mapStateToProps, mapDispatchToProps)(RemoveAgent)