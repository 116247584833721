import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import _isEqual from 'fast-deep-equal'


// Import Components
import { Input, Space, Checkbox, Form, Row, Col, Button, Typography } from 'antd'
import ExactAddressAutocomplete from './ExactAddressAutocomplete'
import UpdateTripMap from './UpdateTripMap'

// Import Actions
import { getReverseGeoData, showAlert, updateTrip } from '../../Services/Actions/commonActions'
import { setMarkerDataTripUpdate } from '../../Services/Reducers/commonReducer'
import { isAllowedToAccess } from '../../Services/Actions/permissionActions'

class UpdateTrip extends React.PureComponent {
    state = {
        address: '',
        exact_address: '',
        is_address_exact: 0,
        place_name: '',
        area: '',
        remarks: '',
        comment: '',
        latitude: null,
        longitude: null,
        initialTripData: null,
        isSuggestionBoxOpen: false,
    }

    // setting map data for update form into state
    _setFormDataToState(singleSelectedTrip, selectedTripFromApplicationReducer) {

        const tripData = singleSelectedTrip.id ? singleSelectedTrip : selectedTripFromApplicationReducer

        const selectedTripData = {
            address: tripData?.address ?? '',
            exact_address: tripData?.exact_address ?? '',
            is_address_exact: tripData.is_address_exact ?? 0,
            place_name: tripData?.place_name ?? '',
            area: tripData?.area ?? '',
            remarks: tripData?.remarks ?? '',
            comment: tripData?.comment ?? '',
            latitude: tripData?.latitude ?? null,
            longitude: tripData?.longitude ?? null,
        }

        // Set state from selected trip
        this.setState({ ...selectedTripData, initialTripData: tripData })
    }

    // setting marker data for only single selected trip
    _setMarkerDataForSingleSelectedTrip(singleSelectedTrip) {
        let markerData = []

        // Initialize marker data

        // Check if selected trip has agent and agent has user_last_lat and user_last_lon
        if (singleSelectedTrip?.agent && singleSelectedTrip?.agent?.user_last_lat && singleSelectedTrip?.agent?.user_last_lon) {
            // Create agent marker
            const agentLocation = {
                id: singleSelectedTrip?.agent?.id ?? 'agent',
                trip_id: singleSelectedTrip?.id,
                latitude: singleSelectedTrip?.agent?.user_last_lat ?? null,
                longitude: singleSelectedTrip?.agent?.user_last_lon ?? null,
                label: singleSelectedTrip?.agent?.name ?? '',
                draggable: false,
                isVerifier: true,
            }
            markerData.push(agentLocation)
        }

        // Create exact address marker
        const tripLocation = {
            id: singleSelectedTrip?.exact_address,
            trip_id: singleSelectedTrip?.id,
            concern: 'Exact Address',
            label: 'Exact Address',
            latitude: singleSelectedTrip?.latitude ?? null,
            longitude: singleSelectedTrip?.longitude ?? null,
            // tripType: singleSelectedTrip?.type ?? '',
            draggable: true,
        }
        markerData.push(tripLocation)

        return markerData
    }


    componentDidMount = () => {
        const { selectedTripToUpdate, selectedTrip, dispatch } = this.props
        let markerData = []
        //storing update form data for selected trip into state
        this._setFormDataToState(selectedTripToUpdate, selectedTrip)

        // setting marker data only for single trip
        if (selectedTripToUpdate && selectedTripToUpdate?.id) {
            markerData = this._setMarkerDataForSingleSelectedTrip(selectedTripToUpdate)
        }

        // Set markerData for Trip Update
        dispatch(setMarkerDataTripUpdate(markerData))
    }

    componentDidUpdate(prevProps) {
        const { selectedTripToUpdate, selectedTrip, selectedConcern, dispatch } = this.props
        console.log(this.state.exact_address, 'exact_address');
        if (!_isEqual(prevProps.selectedTripToUpdate, selectedTripToUpdate) ||
            !_isEqual(prevProps.selectedConcern, selectedConcern) ||
            !_isEqual(prevProps.selectedTrip, selectedTrip)
        ) {
            
            let markerData = []

            // setting marker data only for single trip
            if (selectedTripToUpdate && selectedTripToUpdate?.id) {
                markerData = this._setMarkerDataForSingleSelectedTrip(selectedTripToUpdate)
            }

            // setting form data
            const selectedTripData = {
                address: selectedTripToUpdate?.address ?? '',
                exact_address: selectedTripToUpdate?.exact_address ?? '',
                is_address_exact: selectedTripToUpdate.is_address_exact === 0 ? 0 : 1,
                place_name: selectedTripToUpdate?.place_name ?? '',
                area: selectedTripToUpdate?.area ?? '',
                remarks: selectedTripToUpdate?.remarks ?? '',
                comment: selectedTripToUpdate?.comment ?? '',
                latitude: selectedTripToUpdate?.latitude ?? null,
                longitude: selectedTripToUpdate?.longitude ?? null,
            }

            this.setState({
                ...this.state,
                ...selectedTripData
            })

            // Set markerData for Trip Update
            dispatch(setMarkerDataTripUpdate(markerData))
        }

    }

    // Handle On Change
    _handleOnChange = (e) => {
        const { name, value, checked } = e.target
        const checkedValue = checked === true ? 0 : 1

        this.setState({ [name]: name === 'is_address_exact' ? checkedValue : value })
    }


    // Handle Get Exact Address
    _handleGetExactAddress = (address) => {
        const { exact_address } = this.state
        const { dispatch, markerDataTripUpdate } = this.props

        // Fiktered out previous markerData
        const updatedMarkerData = markerDataTripUpdate.filter(item => item.id !== exact_address)

        this.setState({
            exact_address: address?.address ?? '',
            area: address?.area ?? '',
            latitude: address?.latitude ?? null,
            longitude: address?.longitude ?? null,
        })

        // // Set markerData for Trip Update
        dispatch(setMarkerDataTripUpdate([
            ...updatedMarkerData,
            {
                id: address?.address ?? '',
                concern: 'Exact Address',
                label: 'Exact Address',
                latitude: address?.latitude ?? null,
                longitude: address?.longitude ?? null,
                draggable: true,
            }
        ]))
    }

    // Get dragend long lat
    _handleDragend = (longitude, latitude) => {
        const { dispatch } = this.props
        dispatch(getReverseGeoData({ latitude, longitude }))
            .then(data => {
                const modifiedData = {
                    ...data,
                    latitude,
                    longitude,
                }
                this._handleGetExactAddress(modifiedData)
                console.log(data, 'data');
                if (data) {
                    this.setState({
                        exact_address: data.address ?? '',
                        area: data.area ?? '',
                        latitude,
                        longitude,
                    })
                }
            })
    }

    // Handle Trip Update
    _handleTripUpdate = () => {
        const { address, is_address_exact, exact_address, place_name, area, latitude, longitude, remarks, comment,  initialTripData } = this.state
        const { selectedTripToUpdate, dispatch, previousTaskMapFilterParams } = this.props

        // Create params
        const params = {
            address,
            exact_address,
            place_name,
            area,
            latitude,
            longitude,
            is_address_exact,
            remarks,
            comment,
            reason: '',
            _method: 'PUT',
        }

        // Create State value without initialTripData, in order to compare changes between initialTripData
        const stateWithOutInitialTripData = this.state
        delete stateWithOutInitialTripData.initialTripData

        // Check if initialTripData values are same as current state values without initialTripData
        if (JSON.stringify(initialTripData) === JSON.stringify(stateWithOutInitialTripData)) {
            showAlert('warning', 'No changes detected.')
            return
        }

        // Check if selected trip object among single trip in map has index and index is not equal to -1
        if ('index' in selectedTripToUpdate && selectedTripToUpdate.index !== -1) {
            // Update trip
            dispatch(updateTrip(params, 'trip', selectedTripToUpdate, previousTaskMapFilterParams))
            return
        }

        // Check if selected trip object among single trip in map has application_id and application_id is not null
        if ('application_id' in selectedTripToUpdate && selectedTripToUpdate?.application_id) {
            // Update trip
            dispatch(updateTrip(params, 'application', selectedTripToUpdate, previousTaskMapFilterParams))
            return
        }

        // Show alert
        showAlert('warning', 'Cannot Update.')
    }

     // changes isSuggestionBoxOpenProp to open/close suggestion box for exact address
     _handleSuggestionOpenForxactAddress = (booleanValue) => {
        // if suggestion box is open then click in anywhere will close the box
            this.setState({isSuggestionBoxOpen: booleanValue})
    }

    render() {
        const { address, is_address_exact, place_name, remarks, comment, exact_address, isSuggestionBoxOpen } = this.state
        const { selectedTripToUpdate, mapContainerHeight, allowedPermission } = this.props
        return (
            <div onClick={isSuggestionBoxOpen ? () => this._handleSuggestionOpenForxactAddress(false) : null} >
                <Space direction={'vertical'} style={{ width: '100%' }}>
                    <Row gutter={[12, 12]}>
                        <Col span={24} xs={24}>
                            <UpdateTripMap
                                getDragEndLngLat={this._handleDragend}
                                mapContainerHeight={mapContainerHeight}
                            />
                        </Col>
                        <Col span={24} xs={24}>
                            <Form
                                layout={'vertical'}
                                labelWrap={true}
                            >
                                <Form.Item label={'Address'}>
                                    <Input
                                        name={'address'}
                                        placeholder={'Given Address'}
                                        value={address}
                                        onChange={this._handleOnChange}
                                    />
                                </Form.Item>
                                <Form.Item label={<Typography.Text>Exact Address</Typography.Text>}>
                                    <ExactAddressAutocomplete
                                        getExactAddress={this._handleGetExactAddress}
                                        input={exact_address ?? selectedTripToUpdate?.exact_address ?? ''}
                                        isSuggestionBoxOpenProp={isSuggestionBoxOpen}
                                        toggleSuggestionBox={this._handleSuggestionOpenForxactAddress}
                                    />
                                </Form.Item>
                                <Form.Item
                                    label={'Not Exact'}
                                    valuePropName={'checked'}

                                >
                                    <Checkbox
                                        name={'is_address_exact'}
                                        onChange={this._handleOnChange}
                                        value={is_address_exact}
                                        checked={!is_address_exact}
                                    />
                                </Form.Item>
                                <Form.Item label={ (selectedTripToUpdate?.type === 'OFFICE' || selectedTripToUpdate?.type === 'GUARANTOR_OFFICE') ? 'Office Name' : 'Place Name'}>
                                    <Input
                                        name={'place_name'}
                                        placeholder={'Place Name'}
                                        value={place_name}
                                        onChange={this._handleOnChange}
                                    />
                                </Form.Item>
                                <Form.Item label={'Remarks'}>
                                    <Input
                                        name={'remarks'}
                                        placeholder={'Remarks'}
                                        value={remarks}
                                        onChange={this._handleOnChange}
                                    />
                                </Form.Item>
                                <Form.Item label={'Comment'}>
                                    <Input
                                        name={'comment'}
                                        placeholder={'Comment'}
                                        value={comment}
                                        onChange={this._handleOnChange}
                                    />
                                </Form.Item>
                            </Form>
                            {
                                // PERMISSION ID OF "map-view.map-view.trip.update" is 38
                                isAllowedToAccess( allowedPermission ,{  menuName: 'map-view', subMenuName: 'map-view', permissionID: '38' }) &&            
                                <Button type="primary" onClick={() => this._handleTripUpdate()}>Update Address</Button>
                            }
                        </Col>

                    </Row>
                </Space>
            </div>
        )
    }
}


// Props Validation
UpdateTrip.propTypes = {
    dispatch: PropTypes.func,
    isUpdateTripModalOpen: PropTypes.bool,
    isUpdateTripButtonLoading: PropTypes.bool,
    selectedTripToUpdate: PropTypes.object,
    selectedMultipleTripToUpdate: PropTypes.array,
    markerDataTripUpdate: PropTypes.array,
}

UpdateTrip.defaultProps = {
    dispatch: () => null,
    isUpdateTripModalOpen: false,
    isUpdateTripButtonLoading: false,
    selectedTripToUpdate: {},
    selectedMultipleTripToUpdate: [],
    markerDataTripUpdate: [],
    allowedPermission: null,
}

// Map State To Props
const mapStateToProps = state => ({
    isUpdateTripModalOpen: state.common.isUpdateTripModalOpen,
    isUpdateTripButtonLoading: state.common.isUpdateTripButtonLoading,
    selectedTripToUpdate: state.common.selectedTripToUpdate,
    selectedMultipleTripToUpdate: state.common.selectedMultipleTripToUpdate,
    markerDataTripUpdate: state.common.markerDataTripUpdate,
    previousTaskMapFilterParams: state.taskMap.previousTaskMapFilterParams,
    selectedTrip: state.application.selectedTrip,
    selectedConcern: state.application.selectedConcern,
    applicationDetails: state.application.applicationDetails,
    allowedPermission: state?.permission?.allowedPermission ?? null,
})

// Map Dispatch To Props
const mapDispatchToProps = dispatch => ({ dispatch })

export default connect(mapStateToProps, mapDispatchToProps)(UpdateTrip)