import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import store from '../../../Services/Store/store'

// Import Components
import { Collapse, Form, Input } from 'antd'
import TripForm from './TripForm'

// Import Reducers
import { setFormData } from '../../../Services/Reducers/createFileReducer'

class ConcernForm extends React.PureComponent {
    state = {
        name: '',
        spouse: '',
        phone: '',
        designation: '',
        image: null,
        isRequired: false,
        concernKeys: [],
        tripKeys: []
    }

    componentDidUpdate = (prevProps) => {
        const { isResetClicked } = this.props

        // Reset form data
        if (isResetClicked !== prevProps.isResetClicked) {
            this.setState({
                name: '',
                spouse: '',
                phone: '',
                designation: '',
                image: null,
                isRequired: false,
                concernKeys: [],
                tripKeys: []
            })
        }
    }

    // Handle On Change
    _handleOnChange = e => {
        const { concern, formRef, dispatch } = this.props
        const { name, value } = e.target

        // Get Current Redux Form Data
        const formData = store.getState().createFile.formData

        // Set values
        this.setState({ [name]: value })

        // Set Form Value
        formRef.current.setFieldsValue({
            [concern.identifier]: {
                [name]: value
            }
        })

        // Set Redux Form Data
        dispatch(setFormData({
            ...formData,
            [concern.identifier]: {
                ...formData?.[concern.identifier],
                [name]: value
            }

        }))

        // Set required        
        setTimeout(() => {
            this._handleRequiredFields()
        }, 100)
    }

    // Handle Required Fields
    _handleRequiredFields = () => {
        const { name, spouse, phone, designation } = this.state
        const { concern, formRef } = this.props

        // Check if state values are empty or not and return false
        if (name === '' || spouse === '' || phone === '' || designation === '') {
            // Clear error msg from formRef for name field
            formRef.current.setFields([{ name: [`${concern.identifier}`, 'name'], errors: null }])

            this.setState({ isRequired: false })
        }

        // Check if one of state values has value and return true
        if (name !== '' || spouse !== '' || phone !== '' || designation !== '') {
            this.setState({ isRequired: true })
        }
    }

    // Get User Defined Label
    _getFormLabel = (trip) => {
        const { selectedOrg } = this.props

        // for jamuna bank's office trip
        if (trip?.label.includes('Office') && selectedOrg?.name?.includes('ShopUp')) {
            return 'Business Location Information'
        }

        return trip.concern.label + ' ' + trip.label
    }


    // Normalize File
    _normalizeFile = (e) => {
        if (Array.isArray(e)) {
            return e
        }
        return e && e.target.files[0]
    }

    render() {
        const { isRequired } = this.state
        const { title, concern, trips, formRef } = this.props

        return (
            <Collapse >
                <Collapse.Panel header={title}>
                    {
                        concern.label !== 'Optional' &&
                        <>
                            <Form.Item
                                label={'Name'}
                                name={[`${concern.identifier}`, 'name']}
                                rules={[{ required: isRequired, message: 'Please enter name' }]}
                            >
                                <Input placeholder={`Enter ${concern.label} name..`} name={'name'} onChange={this._handleOnChange} />
                            </Form.Item>
                            <Form.Item
                                label={'Spouse Name'}
                                name={[`${concern.identifier}`, 'spouse']}
                            >
                                <Input placeholder={`Enter spouse name..`} name={'spouse'} onChange={this._handleOnChange} />
                            </Form.Item>
                            <Form.Item
                                label={'Mobile Number'}
                                name={[`${concern.identifier}`, 'phone']}
                            >
                                <Input placeholder={`Enter phone number..`} name={'phone'} onChange={this._handleOnChange} />
                            </Form.Item>
                            <Form.Item
                                label={'Designation'}
                                name={[`${concern.identifier}`, 'designation']}
                            >
                                <Input placeholder={`Enter designation..`} name={'designation'} onChange={this._handleOnChange} />
                            </Form.Item>
                            <Form.Item
                                name={[`${concern.identifier}`, 'image']}
                                label={'Image'}
                                valuePropName={'fileList'}
                                getValueFromEvent={this._normalizeFile}
                            >
                                <input
                                    accept='image/*'
                                    name='image'
                                    type='file'
                                    onChange={this._handleOnChange}
                                />
                            </Form.Item>
                        </>
                    }

                    {/* Trip Wise Form */}
                    <Collapse>
                        {
                            trips?.length > 0 && trips.map(trip => (
                                <Collapse.Panel header={this._getFormLabel(trip)} key={trip.id}>
                                    <TripForm trip={trip} formRef={formRef} isRequired={isRequired} />
                                </Collapse.Panel>
                            ))
                        }
                    </Collapse>
                </Collapse.Panel>
            </Collapse>
        )
    }
}

// PropTypes Validation
ConcernForm.propTypes = {
    title: PropTypes.string,
    concern: PropTypes.object,
    trips: PropTypes.array,
    formRef: PropTypes.object,
    isResetClicked: PropTypes.bool,
    selectedOrg: PropTypes.object,
}

ConcernForm.defaultProps = {
    title: '',
    concern: {},
    trips: [],
    formRef: {},
    isResetClicked: false,
    selectedOrg: null,
}

// Map State To Props
const mapStateToProps = state => ({
    isResetClicked: state.createFile.isResetClicked ?? false,
    selectedOrg: state?.createFile?.selectedOrg ?? null,
})

// Map Dispatch To Props
const mapDispatchToProps = dispatch => ({ dispatch })

export default connect(mapStateToProps, mapDispatchToProps)(ConcernForm)