import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// Import library Components
import { Form, Select, Button, Space } from 'antd'

// style 
import '../../../App.css'

//import actions and reducers
import { fetchIndividualVerifierPermission, syncIndividualVerifierPermission } from '../../../Services/Actions/permissionActions'


const GrantPermission = ({verifiers}) => {
    const [ selectedVerifierId, setSelectedVerifierId ] = useState('')
    const [ valueForRestriction, setValueForRestriction ] = useState(null)
    const { selectedVerifierPermission } = useSelector(state => state.permission)
    const { allPermissionList } = useSelector(state => state.permission)
    const dispatch = useDispatch()

    // changing selected verifier by id
    const _handleSelectedVerifier = (value) => {
        setSelectedVerifierId(value)
        dispatch(fetchIndividualVerifierPermission(value))
    }

    // on form submit post post form data 
    const _submitFormData = (values, a, b) => {

        const user_id = values.verifierId 
        let permissions = {}
        let indexCount = 0
        
        // setting permission list for selected permission
        values?.selectedPermission?.forEach(item => {
            permissions = {
                ...permissions,
                [`permissions[${indexCount}]`]: item
            }
            indexCount += 1
        })

        // gathering already allowed permission
        selectedVerifierPermission?.forEach(item => {
            const isAlreadtExist = Object.values(permissions).indexOf(item?.id)

            // if exist index will be greater than 0. if doesn't exist index will be -1
            if(isAlreadtExist < 0){
                permissions = {
                    ...permissions,
                    [`permissions[${indexCount}]`]: item?.id 
                }
            }
            indexCount += 1
        })

        // request sync permission
        dispatch(syncIndividualVerifierPermission(user_id, permissions))

    }

    // store current state value of form. this is needed to restrict permission
    const _storeCurrentValue = (currentDataOfFormEvent, currentDataOfFormState) => {
        setValueForRestriction(currentDataOfFormState)
    }

    // restrict verifier access
    const _removeAccess = () => {

        const user_id = valueForRestriction?.verifierId 
        let permissions = {}
        let indexCount = 0

        // setting permission list for selected permission
        valueForRestriction?.selectedPermission?.forEach(item => {
            let isExist = false
            // checking previously allowed or not 
            selectedVerifierPermission?.forEach(previousPermission => {
                if(previousPermission?.id === item){
                    isExist = true
                }
            })
            if(!isExist){
                permissions = {
                    ...permissions,
                    [`permissions[${indexCount}]`]: item
                }
                indexCount += 1
            }
        })

        // request sync permission
        dispatch(syncIndividualVerifierPermission(user_id, permissions))
    }
    

    return(
        <Space direction={'vertical'} className='testing' style={fillWidthStyle}>
             <Form 
                name='assignedProperty' 
                {...formItemLayout} 
                onFinish={ _submitFormData }
                // form={form}
                onValuesChange={ _storeCurrentValue }
            >
                <Form.Item
                    name='verifierId'
                    label='Select Verifier'
                    rules={[
                    {
                        required: true,
                        message: 'Missing Verifier',
                    },
                    ]}
                >
                    <Select
                        style={{ width: '100%' }}                                        
                        showSearch
                        placeholder={ 'Select Verifiers' }
                        onChange={ _handleSelectedVerifier }
                        value={ selectedVerifierId ?? null }
                        filterOption={ (input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0 }
                        optionFilterProp={ 'children' }
                    >
                        {
                            verifiers?.length > 0 && verifiers.map((item, index) => {
                                return (
                                    <Select.Option key={ index } value={ item.id }>{ item.name }</Select.Option>
                                )
                            })
                        }
                    </Select>
                </Form.Item>
                <Form.Item
                    name={'selectedPermission'}
                    label='Select Permission'
                    rules={[
                    {
                        required: true,
                        message: 'Missing Permissions',
                    },
                    ]}
                >
                    <Select
                        mode={ 'multiple' }
                        style={{ width: '100%' }}                                        
                        placeholder={ 'Select Verifiers' }
                        filterOption={ (input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0 }
                        optionFilterProp={ 'children' }
                    >
                        {
                            allPermissionList?.length > 0 && allPermissionList.map((item, index) => {
                                return (
                                    <Select.Option key={ index } value={ item.id }>{ item?.name.includes('application') ? item.name.replace('application', 'file')?.split('.')?.join(' ') : item?.name?.split('.')?.join(' ') }</Select.Option>
                                )
                            })
                        }
                    </Select>
                </Form.Item>

                <Space direction={ 'horizontal' }>
                    <Form.Item>
                        <Button type='outlined' danger onClick={ _removeAccess }>
                            Restrict Access
                        </Button>
                    </Form.Item> 
                    <Form.Item>
                        <Button type='primary' htmlType='submit'>
                            Grant Access
                        </Button>
                    </Form.Item> 
                </Space>
            </Form>
        </Space>
    )

} 

// form label and field ratio
const formItemLayout = {
    labelAlign: 'left',
    labelCol: {
      xs: {
        span: 24,
      },
    },
    wrapperCol: {
      xs: {
        span: 24,
      },
    },
};


const fillWidthStyle = {
    width: '100%',
    minWidth: '-moz-available',          /* WebKit-based browsers will ignore this. */
    minWidth: '-webkit-fill-available',  /* Mozilla-based browsers will ignore this. */
    minWidth: 'fill-available',
} 

export default GrantPermission