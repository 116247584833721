import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

// Import Components
import { Modal, Input } from 'antd'

// Import Actions, and Reducers
import { updateFileNo } from '../../../Services/Actions/applicationActions'
import { showAlert } from '../../../Services/Actions/commonActions'
import { setIsUpdateFileNoModalOpen } from '../../../Services/Reducers/applicationReducer'

class UpdateFileNo extends React.PureComponent {
    state = {
        fileNo: '',
    }

    componentDidMount = () => {
        const { applicationDetails } = this.props
        console.log('applicationDetails', applicationDetails);
        this.setState({
            fileNo: applicationDetails?.application_id,
        })
    }
    // Handle On Change
    _handleOnChange = e => {
        this.setState({
            fileNo: e.target.value
        })
    }

    // Handle File No Update
    _handleFileNoUpdate = () => {
        const { fileNo } = this.state
        const { dispatch, applicationDetails } = this.props

        // Check if file no is empty
        if(fileNo === '' || !fileNo) {
            // Show alert
            showAlert('error', 'File No. cannot be empty.')
            return
        }

        // Check if file no is same as applicationDetails?.application_id
        else if(fileNo === applicationDetails?.application_id) {
            // Show alert
            showAlert('error', 'File No. has not been changed.')
            return
        }

        // Create payload
        const formData = new FormData()
        formData.append('_method', 'PUT')
        formData.append('file_no', fileNo)

        // Update file no
        dispatch(updateFileNo(applicationDetails, formData))
    }
    render() {
        const { fileNo } = this.state
        const { isUpdateFileNoModalOpen, isUpdateFileNoButtonLoading, dispatch } = this.props
        return (
            <Modal
                title={ 'Update File No.' }
                visible={ isUpdateFileNoModalOpen }
                maskClosable={ false }
                okText={ 'Update' }
                cancelText={ 'Cancel' }
                okButtonProps={{ loading: isUpdateFileNoButtonLoading }}
                onCancel={ () => dispatch(setIsUpdateFileNoModalOpen(false)) }
                onOk={ this._handleFileNoUpdate }
            >
                <Input 
                    placeholder={ 'Enter File No.' }
                    onChange={ this._handleOnChange }
                    value={ fileNo }
                />
            </Modal>
        )
    }
}

// Props Validation
UpdateFileNo.propTypes = {
    applicationDetails: PropTypes.object,
    isUpdateFileNoModalOpen: PropTypes.bool,
    isUpdateFileNoButtonLoading: PropTypes.bool,
    dispatch: PropTypes.func,
}

UpdateFileNo.defaultProps = {
    applicationDetails: {},
    isUpdateFileNoModalOpen: false,
    isUpdateFileNoButtonLoading: false,
    dispatch: () => null,
}

// Map State To Props
const mapStateToProps = state => ({
    applicationDetails: state.application.applicationDetails,
    isUpdateFileNoModalOpen: state.application.isUpdateFileNoModalOpen,
    isUpdateFileNoButtonLoading: state.application.isUpdateFileNoButtonLoading,
})

// Map Dispatch To Props
const mapDispatchToProps = dispatch => ({ dispatch })

export default connect(mapStateToProps, mapDispatchToProps)(UpdateFileNo)