import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

// Library Components
import { Card, Row, Col, Segmented } from 'antd'


// Import Components
import FileUpload from './FileUpload';
import CreateFileForm from './CreateFileForm';
import OcrDocuments from './OCR/OCRDocuments';
import MapViewCF from './MapViewCF';

// Import Actions
import { resetState } from '../../../Services/Reducers/createFileReducer';
import { isAllowedToAccess } from '../../../Services/Actions/permissionActions';
import BulkUpload from './BulkUpload';


class NormalCreateFile extends React.PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            menu: ['Documents', 'Map View'],
            selectedMenu: 'Documents'
        };
    }

    // Handle Menu On Change
    _handleMenuOnChange = (value) => {
        this.setState({
            selectedMenu: value
        })
    }

    componentWillUnmount = () => {
        const { dispatch } = this.props

        // Reset Redux State
        dispatch(resetState())
    }

    render() {
        const { allowedPermission } = this.props
        const { menu, selectedMenu } = this.state
        
        return (
            <div style={container}>
                <Row gutter={[12, 8]} style={rowContainer}>
                    
                    <Col span={14} xs={24} lg={24} xl={12} style={colContainer}>
                        <Card title={'Create File'}>
                            <CreateFileForm />
                        </Card>
                    </Col>
                    <Col span={10} xs={24} lg={24} xl={12} style={colContainer}>
                        <Card title={'Documents & Map View'} style={cardWrapperStyles}>
                            <Segmented
                                options={menu}
                                value={selectedMenu}
                                onChange={this._handleMenuOnChange}
                            />
                            {selectedMenu === 'Documents' && <OcrDocuments />}
                            {selectedMenu === 'Map View' && <MapViewCF />}
                        </Card>
                    </Col>
                </Row>
            </div>
        )
    }
}

// Styles
const container = {
    borderRadius: '4px',
    // height: `${window.innerHeight - 96}px`,    
}

const rowContainer = {
    height: '100%',
}

const colContainer = {
    background: 'transparent',
    width: '100%',
}

const cardWrapperStyles = {
    width: '100%',
    position: 'sticky',
    top: '80px',
}

// PropTypes
NormalCreateFile.propTypes = {
    dispatch: PropTypes.func,
    allowedPermission: PropTypes.object
}

NormalCreateFile.defaultProps = {
    dispatch: () => null,
    allowedPermission: null
}

// Map State To Props
const mapStateToProps = state => ({
    allowedPermission: state?.permission?.allowedPermission ?? null
})

// Map Dispatch To Props
const mapDispatchToProps = dispatch => ({ dispatch })

export default connect(mapStateToProps, mapDispatchToProps)(NormalCreateFile)