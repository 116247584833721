import * as turf from '@turf/turf'


export const createGeofence = (center, radius=0.3) => {   // 1 kilometer === 1000 meter
    if(!center?.length || center?.length !== 2 || radius <= 0) {
        return null
    }
    // const options = { 
    //     steps: 30,
    //     units: 'kilometers',
    // }

    const circle = turf.circle(center, radius, )
    return circle ?? null
}

