// Import Components
import { Table } from 'antd'

// Import Icons
import { LoadingOutlined } from '@ant-design/icons'

const BasicTable = ({ title, column, data, isLoading, ...restProps }) => (
    <Table 
        title={ title ? () => title : undefined }
        columns={ column }
        dataSource={ data } 
        size={ 'small' }
        style={ tableStyle }
        bordered={ true } 
        loading={{ 
            indicator: <LoadingOutlined style={{ fontSize: 24 }} spin />, 
            spinning: isLoading 
        }}
        { ...restProps }
    />
)

// styles
const tableStyle = {
    width: '100%',
    overflow: 'auto'
}

export default BasicTable