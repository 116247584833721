import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

// Import Components
import { Space, Select, Button } from 'antd'

// Import Actions, and Reducers
import { addAgent } from '../../../Services/Actions/adminPanelActions'
import { showAlert } from '../../../Services/Actions/commonActions'
import { setSelectedUserAddAgent } from '../../../Services/Reducers/adminPanelReducer'

class AddAgent extends React.PureComponent {    

    // Handle Selected User On Change
    _handleSelectUser = value => {
        const { dispatch, users } = this.props
        
        // Check if value is empty or undefined or null
        if (!value) {
            // Set Selected User
            dispatch(setSelectedUserAddAgent({}))
            return
        }
        
        // Find Selected User
        const selectedUser = users.find(item => item.id === value)
        
        // If Selected User Not Exist
        if (!selectedUser) {
            // Set Selected User
            dispatch(setSelectedUserAddAgent({}))

            // Show Error Alert
            showAlert('error', 'Selected User Not Found')
            return
        }
        // Set Selected User
        dispatch(setSelectedUserAddAgent(selectedUser))
    }

    // Handle Add Agent
    _handleAddAgent = () => {
        const { dispatch, selectedUserAddAgent } = this.props

        // If Selected User Not Exist
        if (!selectedUserAddAgent || !selectedUserAddAgent.id) {
            // Show Error Alert
            showAlert('error', 'Please Select User')
            return
        }

        // Add Agent
        dispatch(addAgent({ user_id: selectedUserAddAgent.id }))
    }
    render() {
        const { users, selectedUserAddAgent, isAddAgentLoading } = this.props                
        
        return (
            <div>
                <Space direction={ 'vertical' } style={{ width: '100%' }}>                    
                    <Select
                        style={{ width: '100%' }}                                        
                        showSearch
                        allowClear                    
                        placeholder={ 'Select User' }
                        onChange={ this._handleSelectUser }
                        value={ selectedUserAddAgent?.id ?? null }
                        filterOption={ (input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0 }
                        optionFilterProp={ 'children' }
                    >
                        {
                            users?.length > 0 && users.map((item, index) => {
                                return (
                                    <Select.Option key={ index + 1 } value={ item.id }>{ item.name }</Select.Option>
                                )
                            })
                        }
                    </Select>
                    <div style={{ float: 'right' }}>
                        <Button 
                            type={ 'primary' }
                            loading={ isAddAgentLoading }
                            onClick={ this._handleAddAgent }
                        >
                            { 'Add' }
                        </Button>
                    </div>
                </Space>
            </div>
        )
    }
}

// Props Validation
AddAgent.propTypes = {
    dispatch: PropTypes.func,
    users: PropTypes.array,    
    selectedUserAddAgent: PropTypes.object,
    isAddAgentLoading: PropTypes.bool,
}

AddAgent.defaultProps = {
    dispatch: () =>  null,
    users: [],
    selectedUserAddAgent: {},    
    isAddAgentLoading: false,
}

// Map State To Props
const mapStateToProps = (state) => ({
    users: state.common.users,
    selectedUserAddAgent: state.adminPanel.selectedUserAddAgent,    
    isAddAgentLoading: state.adminPanel.isAddAgentLoading,
})

// Map Dispatch To Props
const mapDispatchToProps = (dispatch) => ({ dispatch })

export default connect(mapStateToProps, mapDispatchToProps)(AddAgent)